import React from "react";
import {SlideUpTransition} from "./Transitions";
import axios from "axios";
import {
    Dialog,
    Button,
    IconButton,
    DialogTitle,
    DialogActions,
    DialogContent,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLifeRing} from "@fortawesome/free-solid-svg-icons";
import Context from "../context/Context";
import {PropTypes} from "prop-types";
import Constants from "../context/Constants";
import {enqueueSnackbar} from "notistack";

class Feedback extends React.Component {
    static contextType = Context;
    static defaultProps = {
        renderButton: true,
        open: false,
        buttonSize: "sm",
        info: "",
        onClose: null,
        iconSize: 'medium'
    };
    static propTypes = {
        renderButton: PropTypes.bool,
        open: PropTypes.bool,
        buttonSize: PropTypes.oneOf(["sm", "md", "lg"]),
        info: PropTypes.string,
        onClose: PropTypes.func,
        iconSize: PropTypes.oneOf(['large','medium','small'])
    };

    constructor(props) {
        super(props);
        this.handleMessageChange = this.handleMessageChange.bind(this);
        this.sendFeedback = this.sendFeedback.bind(this);
        this.onClose = this.onClose.bind(this);
        this.state = {
            open: false,
            userMessage: "",
            fetching: false,
        };
    }

    sendFeedback() {
        this.setState({fetching: true}, () => {
            const toPost = JSON.stringify({
                token: this.context.user.token,
                info: this.props.info,
                message: this.state.userMessage,
            });
            axios
                .post(Constants.paths.ajaxBasePath + "feedback/", toPost)
                .then((resp) => {
                    this.onClose();
                    this.props.enqueueSnackbar("Feedback inviato con successo!", {
                        variant: "success",
                    });
                })
                .catch((err) => {
                    alert(err);
                    this.setState({fetching: false});
                });
        });
    }

    handleMessageChange(e) {
        this.setState({userMessage: e.target.value});
    }

    shouldComponentUpdate(nextProps, nextState) {
        //Evita aggiornamenti inutili
        if (this.state.open || this.props.open) {
            return true;
        } else if (
            (this.state.open == false && nextState.open == true) ||
            (this.props.open == false && nextProps.open == true)
        ) {
            return true;
        } else {
            return false;
        }
    }

    onClose() {
        this.setState({open: false, userMessage: ""});
        if (this.props.onClose) this.props.onClose();
    }

    render() {
        return (
            <React.Fragment>
                {this.props.renderButton && (
                    <Tooltip title="Invia feedback">
                        <IconButton
                            style={{...this.props.style}}
                            onClick={(e) => this.setState({open: true})}
                            size={this.props.iconSize}>
                            <FontAwesomeIcon size={this.props.buttonSize} icon={faLifeRing}/>
                        </IconButton>
                    </Tooltip>
                )}
                <Dialog
                    fullWidth={false}
                    fullScreen={!this.context.matches.sm}
                    maxWidth="sm"
                    //style={{ top: 0, bottom: "auto" }}
                    open={this.state.open || this.props.open}
                    TransitionComponent={SlideUpTransition}
                    onClose={this.onClose}
                    //onOpen={this.props.onOpen}
                >
                    <DialogTitle>Invia feedback</DialogTitle>
                    <DialogContent>
                        <Typography
                            style={{
                                fontStyle: "italic",
                                marginBottom: this.context.theme.spacing(2),
                            }}
                            variant="body2"
                        >
                            {this.props.info}
                        </Typography>
                        <TextField
                            //Uncontrolled
                            value={this.state.userMessage}
                            onChange={this.handleMessageChange}
                            variant="outlined"
                            label="Messaggio"
                            multiline
                            rows={4}
                            maxRows={10}
                            fullWidth
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.onClose}>Annulla</Button>
                        <Button
                            disabled={this.state.fetching || this.state.userMessage === ""}
                            variant="contained"
                            onClick={this.sendFeedback}
                        >
                            Invia
                        </Button>
                    </DialogActions>
                </Dialog>{" "}
            </React.Fragment>
        );
    }
}

export default Feedback;
