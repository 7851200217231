import React from "react";
import Context from "../../context/Context";
import Constants from "../../context/Constants";
import ProfileCard from "../../components/ProfileCard";
import {
    faArchive,
    faClipboard,
    faBox,
    faLightbulb,
} from "@fortawesome/free-solid-svg-icons";
import {
    Paper,
    Tabs,
    Tab,
    Button,
    Box,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import axios from "axios";
// import {withSnackbar} from "notistack";
import localization from "moment/locale/it";
import moment from "moment";
import BozzePanel from "./BozzePanel";
import OrdinePanel from "./OrdinePanel";
import ProdottiPanel from "./ProdottiPanel";
import StoricoPanel from "./StoricoPanel";
import {enqueueSnackbar} from "notistack";

moment.locale("it", localization);

class Ordini extends React.Component {
    static contextType = Context;

    constructor(props) {
        super(props);
        this.handleTabChange = this.handleTabChange.bind(this);
        this.loadActive = this.loadActive.bind(this);
        this.deleteDraft = this.deleteDraft.bind(this);
        this.deleteDraftRemote = this.deleteDraftRemote.bind(this);
        this.getDraftsFromLocalStorage = this.getDraftsFromLocalStorage.bind(this);
        this.getDraftsRemote = this.getDraftsRemote.bind(this);
        // this.getRemoteDraftsStatus = this.getRemoteDraftsStatus.bind(this);

        this.syncDrafts = this.syncDrafts.bind(this);
        this.initClearOrder = this.initClearOrder.bind(this);
        this.setActualOrderItemIds = this.setActualOrderItemIds.bind(this);
        this.setActualOrderOfferta = this.setActualOrderOfferta.bind(this);
        this.sendDraft = this.sendDraft.bind(this);
        this.state = {
            activeTab: 0,
            fetching: false,
            dataProdotti: {},
            dataOfferta: {},
            dataBozze: {},
            dataStorico: {},
            actualOrderOfferta: {
                id: undefined,
                title: undefined,
            },
            actualOrderItemIds: new Set([]),
            clearOrderDialog: {
                open: false,
            },
            draftsCloudSyncStatus: {},
            draftsCloudSyncing: false,
        };
    }

    componentDidMount() {
        this.syncDrafts();

        this.updateTimer = setInterval(
            () => {
                this.syncDrafts();
            },
            Constants.heartbeat.main
        );
    }

    componentWillUnmount() {
        clearInterval(this.updateTimer);
    }

    setActualOrderItemIds(ids) {
        this.setState(
            {
                actualOrderItemIds: new Set(ids),
            }
            // () => console.log(arguments)
        );
    }

    setActualOrderOfferta(id, title) {
        this.setState(
            {
                actualOrderOfferta: {
                    title: title,
                    id: id,
                },
            },
            () => {
            }
        );
    }

    async getDraftsRemote(ids = []) {
        return await axios
            .get(
                Constants.paths.ajaxBasePath +
                "ordini-agenti/bozze/?token=" +
                this.context.user.token
            );
    }

    async syncDrafts(forceEnableIds = []) {
        // console.log('Richiesta Sync.');
        let promises = [];
        await new Promise(
            res => this.setState(
                {
                    draftsCloudSyncing: true,
                }, () => res()
            )
        );

        let lDrafts = await this.getDraftsFromLocalStorage(true);

        let activeOrder = JSON.parse(localStorage.getItem("orderActive"));
        if (!lDrafts) lDrafts = {}; // Normalizza drafts per evitare storie
        let rDrafts = (await this.getDraftsRemote()).data;
        // console.log(rDrafts);
        if (!rDrafts) rDrafts = {};
        //Inserisci nel local storage tutte le bozze assenti
        let rUuids = Object.keys(rDrafts);//Remote UUIDS
        let lUuids = Object.keys(lDrafts);
        let allUuids = [...new Set([...lUuids, ...rUuids])]; //Set per evitare duplicati, array per avere le funzioni giuste

        for (let i = 0; i < allUuids.length; i++) { //For every uuid
            let thisUuid = allUuids[i];
            let thisDraftL = lUuids.includes(thisUuid) ? lDrafts[thisUuid] : null;
            let thisDraftR = rUuids.includes(thisUuid) ? rDrafts[thisUuid]['object'] : null;
            let thisDraftRState = rUuids.includes(thisUuid) ? rDrafts[thisUuid]['stato'] : null;

            if (!thisDraftL) {
                if (thisUuid !== activeOrder?.["id"]) { //Non salvare nuovamente se si tratta dell'ordine attivo
                    if (thisDraftRState == 1) { //Se non presente in locale, importalo
                        await this.saveDraft(thisUuid,
                            JSON.parse(thisDraftR), true);
                        // console.log('Import from remote: ', thisDraftR);
                        continue; // Non c'è altro da fare
                    }
                }
            } else { //È presente nel LS, controlla se va eliminato
                if (thisDraftRState == 0) { //Sul DB è disattivato?
                    if (!forceEnableIds.includes(thisUuid)) { //Non eliminare se va riattivato
                        // console.log('Deleting from Local: ', thisUuid);
                        await this.deleteDraft(thisUuid, true);
                        continue; // Non c'è altro da fare
                    }
                }
            }

            //Invia tutte le bozze non presenti nel DB
            // Object.keys(lDrafts).forEach((k, i) => {
            //     console.log('Local drafts:', lDrafts);
            //     console.log('Remote drafts:', rDrafts);
            //     try {
            //         console.log("This Remote draft", JSON.parse(rDrafts?.[lDrafts[k]['id']]) || "{}");
            //     } catch (e) {
            //         console.log('No Remote draft');
            //     }
            if (!thisDraftR && thisDraftL) { //Presente solo in L
                // console.log('Invio Draft non presente in DB...');
                await this.sendDraft(thisUuid, thisDraftL);
                continue; //Non c'è altro da controllare
            }
            // });
            //Aggiorna tutte le bozze più recenti sul device
            // Object.keys(rDrafts).forEach((k, i) => {
            if (thisDraftL && thisDraftR) { //Aggiorna se presente in L ed R
                // let thisDraftR = JSON.parse(rDrafts[k]["object"]);
                // let thisDraftL = lDrafts[k];
                let remoteLastChange =
                    parseInt(thisDraftR?.["ultimaModifica"]) || 0;
                let localLastChange =
                    parseInt(thisDraftL?.["ultimaModifica"]) || 0;
                // console.log('Local time', localLastChange);
                // console.log('Remote time', remoteLastChange);
                if (remoteLastChange != localLastChange) {
                    // console.log('Local', localLastChange);
                    // console.log('Remote', remoteLastChange);
                    if (remoteLastChange > localLastChange) {
                        //Il DB è più recente
                        await this.saveDraft(thisUuid, thisDraftR, true);
                        // continue; //Non c'è altro da fare
                    } else {
                        //Il locale è più recente
                        console.log('Sending to the cloud...', thisUuid, thisDraftL);
                        promises.push(() => this.sendDraft(thisUuid, thisDraftL));
                        // continue; //Non c'è altro da fare
                    }
                }
            }
        }
        //     }
        // );
        await Promise.all(promises);
        await this.getDraftsFromLocalStorage();
        await new Promise(res => this.setState(
            {
                draftsCloudSyncing: false,
            }, () => res()));
    }


    async getDraftsFromLocalStorage(returnOnly = false) {
        let drafts = localStorage.getItem("orderDrafts");
        if (!drafts) drafts = {};
        else
            drafts = JSON.parse(drafts);
        if (!returnOnly) {
            await new Promise(res => this.setState({dataBozze: {...drafts}}, () => res()));
        }
        return drafts;
    }

    async sendDraft(id, draftObj) {
        const toPost = {
            token: this.context.user.token,
            uuid: id,
            object: draftObj,
        };
        try {
            await axios
                .post(Constants.paths.ajaxBasePath + "ordini-agenti/bozze/", toPost);
        } catch (e) {
            // alert("Bozza non inviata al server! [" + e.message + "]");
        }

    }

    async saveDraft(id, draftObj, localOnly = false) {
        let storage = JSON.parse(localStorage.getItem("orderDrafts"));
        if (!storage) {
            storage = {}; //Normalize
        }
        storage[id] = draftObj;
        localStorage.setItem("orderDrafts", JSON.stringify(storage));
        if (!localOnly) {
            await this.sendDraft(id, draftObj);
        }
        await this.getDraftsFromLocalStorage();
    }

    async deleteDraftRemote(id) {
        try {
            await axios
                .delete(
                    Constants.paths.ajaxBasePath +
                    "ordini-agenti/bozze/?token=" +
                    this.context.user.token +
                    "&uuid=" +
                    id
                );
        } catch (error) {
            alert("Non è stato possibile eliminare la bozza dal DB [" + error.message + "]");
            return false;
        }
        return true;
    }

    async deleteDraft(id, localOnly = false) {
        if (!localOnly) await this.deleteDraftRemote(id);
        let newStorage = await this.getDraftsFromLocalStorage(true);
        // newStorage = JSON.parse(newStorage);
        delete newStorage[id];
        newStorage = JSON.stringify(newStorage);
        localStorage.setItem("orderDrafts", newStorage);
        if (!localOnly) {
            enqueueSnackbar("Bozza eliminata.", {
                variant: "warning",
            });
        }
        await this.getDraftsFromLocalStorage();
    }

    loadActive(orderObj = undefined,
               draftIDToDelete = undefined) {
        if (typeof orderObj != "undefined") {
            const orderStr = JSON.stringify(orderObj);
            localStorage.setItem("orderActive", orderStr);
            enqueueSnackbar("Ordine caricato", {
                variant: "info",
            });
        }

        this.setState(
            {
                activeOffertaId: orderObj.id,
                activeTab: 0,
            },
            () => {
                if (draftIDToDelete != undefined)
                    this.deleteDraft(draftIDToDelete, false); //Elimina la bozza anche dal db in caso di utilizzo
            }
        );
    }

    handleTabChange(e, newVal) {
        this.setState({
            activeTab: newVal,
        });
    }

    initClearOrder(
        e,
        title = "Sei sicuro?",
        body = "Così facendo l'intero ordine verrà annullato.",
        then = undefined,
        saveDraftFn = undefined
    ) {
        let onConfirm = () => {
            this.setState(
                (prev) => ({
                    clearOrderDialog: {
                        ...prev.clearOrderDialog,
                        open: false,
                    },
                }),
                () => {
                    if (then) then();
                }
            );
        };
        let onSave = () => {
            this.setState(
                (prev) => ({
                    clearOrderDialog: {
                        ...prev.clearOrderDialog,
                        open: false,
                    },
                }),
                () => {
                    saveDraftFn();
                    if (then) then();
                }
            );
        };
        this.setState(
            {
                clearOrderDialog: {
                    open: true,
                    title: title,
                    body: body,
                    onConfirm: onConfirm,
                    onSave: onSave,
                },
            },
            () => this.setActualOrderOfferta(undefined, undefined)
        );
    }

    render() {
        // const {enqueueSnackbar, closeSnackbar} = this.props;
        return (
            <Context.Consumer>
                {(consumer_data) => {
                    return (
                        <>
                            <ProfileCard/>
                            <Paper
                                style={{
                                    margin: !consumer_data.matches.sm
                                        ? consumer_data.theme.spacing(1) //XS
                                        : !consumer_data.matches.md
                                            ? consumer_data.theme.spacing(2) //SM
                                            : consumer_data.theme.spacing(4), //MD+>
                                }}
                            >
                                <Tabs
                                    style={
                                        this.state.fetching
                                            ? {
                                                cursor: "progress",
                                                pointerEvents: "none",
                                            }
                                            : {}
                                    }
                                    indicatorColor="primary"
                                    textColor="primary"
                                    value={this.state.activeTab}
                                    onChange={this.handleTabChange}
                                    centered
                                >
                                    <Tab
                                        label="Ordine"
                                        icon={<FontAwesomeIcon icon={faClipboard}/>}
                                    />
                                    <Tab
                                        disabled={true}
                                        label="Prodotti"
                                        icon={<FontAwesomeIcon icon={faBox}/>}
                                    />
                                    <Tab
                                        label="Bozze"
                                        icon={<FontAwesomeIcon icon={faLightbulb}/>}
                                    />
                                    <Tab
                                        label="Storico"
                                        icon={<FontAwesomeIcon icon={faArchive}/>}
                                    />
                                </Tabs>
                            </Paper>
                            <br/>
                            <OrdinePanel
                                onRefreshDrafts={this.syncDrafts}
                                //onRefreshHistory={this.getHistory}
                                theme={consumer_data.theme}
                                key={this.state.activeOffertaId}
                                activeTab={this.state.activeTab}
                                onClearOrder={this.initClearOrder}
                                fetching={this.state.fetching}
                                updateParentOfferta={this.setActualOrderOfferta}
                                updateParentItemIds={this.setActualOrderItemIds}
                                setOrderIdFn={(id) => this.setState({activeOffertaId: id})}
                            />
                            <ProdottiPanel
                                theme={consumer_data.theme}
                                activeTab={this.state.activeTab}
                                fetching={this.state.fetching}
                                activeOfferta={this.state.actualOrderOfferta}
                                activeProducts={this.state.actualOrderItemIds}
                            />
                            <BozzePanel
                                theme={consumer_data.theme}
                                activeTab={this.state.activeTab}
                                onSetActive={this.loadActive}
                                onDeleteDraft={this.deleteDraft}
                                syncDrafts={this.syncDrafts}
                                draftsCloudSyncStatus={this.state.draftsCloudSyncStatus}
                                drafts={this.state.dataBozze}
                                fetching={this.state.fetching}
                                syncing={this.state.draftsCloudSyncing}
                            />
                            <StoricoPanel
                                theme={consumer_data.theme}
                                activeTab={this.state.activeTab}
                                fetching={this.state.fetching}
                                loadActiveFn={this.loadActive}
                            />

                            <Dialog
                                onClose={() =>
                                    this.setState((prev) => ({
                                        clearOrderDialog: {
                                            ...prev.clearOrderDialog,
                                            open: false,
                                        },
                                    }))
                                }
                                open={this.state.clearOrderDialog.open}
                            >
                                <DialogTitle id="alert-dialog-title">
                                    {this.state.clearOrderDialog.title}
                                </DialogTitle>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                        {this.state.clearOrderDialog.body}
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        onClick={() => {
                                            this.setState((prev) => ({
                                                clearOrderDialog: {
                                                    ...prev.clearOrderDialog,
                                                    open: false,
                                                },
                                            }));
                                        }}
                                        autoFocus
                                    >
                                        <Box color="text.secondary">Annulla</Box>
                                    </Button>
                                    <Button onClick={
                                        () => this.state.clearOrderDialog.onSave()
                                    }>
                                        <Box color="text.secondary">Salva bozza</Box>
                                    </Button>
                                    <Button
                                        onClick={() => {
                                            this.state.clearOrderDialog.onConfirm();
                                        }}
                                        color="primary"
                                    >
                                        Elimina
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </>
                    );
                }}
            </Context.Consumer>
        );
    }
}

export default Ordini;
