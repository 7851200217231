
import React, { useContext } from "react";
import axios from "axios";
import Constants from "../context/Constants";
import Context from "../context/Context";
import {
  Typography,
  Grid,
  TextField,
  Button,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class SKUReplace extends React.Component {
    static contextType = Context;
    constructor() {
      super();
      this.getData = this.getData.bind(this);
      this.sendData = this.sendData.bind(this);
      this.state = {
        oldSKUs: "",
      };
    }
    componentDidMount() {
      this.getData();
    }
    getData() {
      const token = this.context.user.token;
      const thisSKU = this.props.row.cod;
      const thisId = this.props.row.id || this.props.row.id_prod;
      axios
        .get(
          Constants.paths.ajaxBasePath +
            `prodotti/utils/old-skus/?token=${token}&prodId=${thisId}`
        )
        .then((res) => {
          this.setState({ oldSKUs: res.data[0]?.["old"] || "" });
        });
    }

    sendData() {
      const thisSKU = this.props.row.sku;
      const token = this.context.user.token;
      const oldSKUs = this.state.oldSKUs;
      const thisId = this.props.row.id||this.props.row.id_prod;

      new Promise((res, rej) => {
        const toPost = JSON.stringify({
          token: token,
          prodId: String(thisId),
          oldSKUs: oldSKUs,
        });
          console.log(toPost);
        axios
          .post(Constants.paths.ajaxBasePath + "prodotti/utils/old-skus/", toPost)
          .then(() =>
            this.props.enqueueSnackbar("Codice aggiornato.", {
              variant: "success",
            })
          )
          .catch(() =>
            this.props.enqueueSnackbar("Errore!", {
              variant: "error",
            })
          );
      });
    }
    render() {
      return (
        <Grid
          container
          style={{
            minHeight: "100%",
          }}
        >
          <Grid item style={{ textAlign: "center" }}>
            <Typography
              style={{ fontSize: "10px", textAlign: "center" }}
              variant="overline"
            >
              SKU separati da ";"
            </Typography>
            <TextField
              error={this.state.oldSKUs === ""}
              style={{ borderColor: "red" }}
              label="Vecchi SKU"
              multiline
              rows={2}
              variant="outlined"
              value={this.state.oldSKUs}
              onChange={(e) =>
                this.setState({
                  oldSKUs: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item style={{ width: "100%" }}>
            <Button onClick={this.sendData} fullWidth>
              Applica
            </Button>
          </Grid>
        </Grid>
      );
    }
  }

export default SKUReplace;