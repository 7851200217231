function roundUp(value: number, precision: number = 2): number {
    precision = Math.pow(10, precision);
    return Math.ceil(value * precision) / precision
}

function roundDown(value: number, precision: number = 2): number {
    precision = Math.pow(10, precision);
    return Math.floor(value * precision) / precision
}

export {roundUp, roundDown}