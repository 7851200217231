import React from "react";
import ProfileCard from "../../components/ProfileCard";
import CalendarDisplay from "../../components/CalendarDisplay";
import Context from "../../context/Context";
import Constants from "../../context/Constants";
import axios from "axios";
import { Grid, Paper, TableCell } from "@mui/material";
import MaterialTable from "material-table";
import materialTableLocalisationIT from "../../support/materialTableLocalisationIT";
import materialTableIcons from "../../support/materialTableIcons";
import {
  DatePicker,
  TimePicker,
  TimeRangePicker
} from "../../components/DatePicker";
import TextareaInput from "../../components/TextareaInput";

class ItinerariAgenti extends React.Component {
  static contextType = Context;
  constructor(props) {
    super(props);
    this.state = {};
    this.getData = this.getData.bind(this);
    this.updateScheduler = this.updateScheduler.bind(this);
    this.firstTimeCellStyle = {};
    this.timeCellStyle = {};
  }
  componentDidMount() {
    // this.context.functions.setActiveRouteName("itinerari agenti");
    this.getData();
  }

  //! getAvailableHours(step = 1) {
  //   let availH = {};
  //   for (let i = 0; i < 24; i += step) {
  //     // let thisStr = i.toString().padStart(2, "0"); //* Don't add padding to the obj key to leave everything ordered
  //     availH[i] = i.toString().padStart(2, 0);
  //   }
  //   return availH;
  // }

  //! getAvailableMinutes(step = 15) {
  //   let availM = {};
  //   for (let i = 0; i < 60; i += step) {
  //     availM[i] = i.toString().padStart(2, "0");
  //   }
  //   return availM;
  // }

  convertHoursMin(data, toMode = "DateTime") {
    if (toMode === "DateTime") {
      data["data_dalle"] = Date.parse(data["data_dalle"]);
      data["data_alle"] = Date.parse(data["data_alle"]);
      data["esito_data_dalle"] = Date.parse(data["esito_data_dalle"]);
      data["esito_data_alle"] = Date.parse(data["esito_data_alle"]);
    } else {
      //ConvertBack?
    }
    return data;
  }

  returnCleanTime(datetime) {
    if (datetime) {
      let thisDate = new Date(datetime);
      let str = thisDate
        .getHours()
        .toString()
        .padStart(2, "0");
      str += ":";
      str += thisDate
        .getMinutes()
        .toString()
        .padStart(2, "0");
      return str;
    } else {
      return "";
    }
  }

  getData() {
    let finalData = [];
    axios
      .get(
        Constants.paths.ajaxBasePath +
          "itinerari-agenti/?token=" +
          this.context.user.token
      )
      .then(res => {
        finalData = res.data;
        this.setState(
          {
            data: finalData,
            scheduler: this.prepareForScheduler(finalData)
          },
          () => console.log(this.state)
        );
      });
  }

  prepareForScheduler(data) {
    let newData = [];
    for (let i = 0; i < data.length; i++) {
      newData.push({
        startDate: new Date(data[i]["data_giorno"]).setHours(
          new Date(data[i]["data_dalle"]).getHours(),
          new Date(data[i]["data_dalle"]).getMinutes()
        ),
        endDate: new Date(data[i]["data_giorno"]).setHours(
          new Date(data[i]["data_alle"]).getHours(),
          new Date(data[i]["data_alle"]).getMinutes()
        ),
        title: data[i]["cliente"],
        id: data[i]["id"]
      });
    }
    return newData;
  }

  updateScheduler() {
    this.setState({ scheduler: this.prepareForScheduler(this.state.data) });
  }

  render() {
    return (
      <Context.Consumer>
        {consumer_data => {
          return (
            <React.Fragment>
              <Grid
                container
                spacing={3}
                justifyContent="space-around"
                alignItems="center"
              >
                <Grid item xs={12} lg={4}>
                  <ProfileCard />
                </Grid>
                <Grid item xs={12} lg={8}>
                  {/*<CalendarDisplay*/}
                  {/*  height={700}*/}
                  {/*  view="Month"*/}
                  {/*  date={this.state.currentDate}*/}
                  {/*  data={this.state.scheduler}*/}
                  {/*/>*/}
                </Grid>
              </Grid>
              <br />
              <div className="data-table itinerari-agenti">
                <MaterialTable
                  data={this.state.data}
                  title="Itinerari"
                  options={{
                    tableLayout: "auto",
                    pageSize: 5,
                    pageSizeOptions: [5, 15, 30, 50],
                    addRowPosition: "first",
                    detailPanelColumnAlignment: "right",
                    actionsColumnIndex: 100,
                    actionsCellStyle: {
                      textAlign: "center"
                    }
                  }}
                  icons={materialTableIcons}
                  localization={materialTableLocalisationIT}
                  columns={[
                    {
                      title: "Zona",
                      field: "zona",
                      type: "string"
                    },
                    {
                      title: "Cliente",
                      field: "cliente",
                      type: "string"
                    },
                    {
                      title: "Giorno",
                      field: "data_giorno",
                      type: "date",
                      editComponent: props => <DatePicker input_props={props} />
                    },
                    {
                      title: "Dalle",
                      field: "data_dalle",
                      type: "string",
                      cellStyle: this.timeCellStyle,
                      headerStyle: this.timeCellStyle,
                      initialEditValue: new Date().setHours(0, 0, 0),
                      render: value => (
                        <React.Fragment>
                          {this.returnCleanTime(value.data_dalle)}
                        </React.Fragment>
                      ),
                      editComponent: props => <TimePicker input_props={props} />
                    },
                    {
                      title: "Alle",
                      field: "data_alle",
                      type: "string",
                      cellStyle: this.timeCellStyle,
                      headerStyle: this.timeCellStyle,
                      initialEditValue: "",
                      render: value => (
                        <React.Fragment>
                          {this.returnCleanTime(value.data_alle)}
                        </React.Fragment>
                      ),
                      editComponent: props => <TimePicker input_props={props} />
                    },
                    {
                      title: "Dalle",
                      field: "esito_data_dalle",
                      type: "string",
                      cellStyle: this.timeCellStyle,
                      headerStyle: this.timeCellStyle,
                      initialEditValue: "",
                      render: value => (
                        <React.Fragment>
                          {this.returnCleanTime(value.esito_data_dalle)}
                        </React.Fragment>
                      ),
                      editComponent: props => <TimePicker input_props={props} />
                    },
                    {
                      title: "Alle",
                      field: "esito_data_alle",
                      type: "time",
                      cellStyle: this.timeCellStyle,
                      headerStyle: this.timeCellStyle,
                      initialEditValue: new Date().setHours(0, 0, 0),
                      render: value => (
                        <React.Fragment>
                          {this.returnCleanTime(value.esito_data_alle)}
                        </React.Fragment>
                      ),
                      editComponent: props => <TimePicker input_props={props} />
                    },
                    {
                      title: "Note",
                      field: "note",
                      type: "string",
                      cellStyle: this.timeCellStyle,
                      headerStyle: this.timeCellStyle,
                      editComponent: props => (
                        <TextareaInput input_props={props} />
                      )
                    }
                  ]}
                  editable={{
                    onRowAdd: newData => {
                      new Promise((resolve, reject) => {
                        //this.updateScheduler(); //Do it in resolve()
                        const data = this.state.data;
                        data.push(newData);
                        const toPost = JSON.stringify({
                          row: newData,
                          token: consumer_data.user.token,
                          action: "create"
                        });
                        console.log(toPost);
                        axios
                          .post(
                            Constants.paths.ajaxBasePath + "itinerari-agenti/",
                            toPost
                          )
                          .then(res => {
                            if (res.status >= 200 && res.status < 300) {
                              this.setState(
                                {
                                  data: data,
                                  scheduler: this.prepareForScheduler(data)
                                },
                                () => resolve()
                              );
                              //this.getData();
                            } else {
                              reject();
                              throw new Error(res);
                            }
                          })
                          .catch(e => {
                            reject();
                            alert("Errore nel form:" + e.response.status);
                          });
                      });
                    },
                    onRowUpdate: (newData, oldData) =>
                      new Promise((resolve, reject) => {
                        const data = this.state.data;
                        const index = data.indexOf(oldData);
                        data[index] = newData;
                        const toPost = JSON.stringify({
                          row: newData,
                          token: consumer_data.user.token,
                          action: "update"
                        });
                        console.log(toPost);
                        axios
                          .post(
                            Constants.paths.ajaxBasePath + "itinerari-agenti/",
                            toPost
                          )
                          .then(res => {
                            if (res.status >= 200 && res.status < 300) {
                              this.setState(
                                {
                                  data: data,
                                  scheduler: this.prepareForScheduler(data)
                                },
                                () => resolve()
                              );
                              //resolve();
                              //this.getData();
                            } else {
                              throw new Error(res);
                            }
                          })
                          .catch(e => {
                            alert(
                              "Il form non è stato inviato a causa di un errore: " +
                                e
                            );
                            reject();
                          });
                      }),
                    onRowDelete: oldData =>
                      new Promise((resolve, reject) => {
                        this.updateScheduler();
                        let data = this.state.data;
                        const index = data.indexOf(oldData);
                        data.splice(index, 1);

                        const toPost = JSON.stringify({
                          row: oldData,
                          token: consumer_data.user.token,
                          action: "delete"
                        });

                        axios
                          .post(
                            Constants.paths.ajaxBasePath + "itinerari-agenti/",
                            toPost
                          )
                          .then(res => {
                            if (res.status >= 200 && res.status < 300) {
                              this.setState(
                                {
                                  data: data,
                                  scheduler: this.prepareForScheduler(data)
                                },
                                () => resolve()
                              );
                            } else {
                              throw new Error(res);
                            }
                          })
                          .catch(e => {
                            alert(
                              "La riga non è stata eliminata a causa di un errore: " +
                                e
                            );
                            reject();
                          });
                      })
                  }}
                ></MaterialTable>
              </div>
            </React.Fragment>
          );
        }}
      </Context.Consumer>
    );
  }
}

export default ItinerariAgenti;
