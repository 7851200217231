import {TextField, Typography, Divider} from "@mui/material";
import {Autocomplete} from '@mui/material';
import axios from "axios";
import React from "react";
import Constants from "../context/Constants";
import Context from "../context/Context";

export default class CategoriesDropdown extends React.Component {
    static contextType = Context;

    constructor(props) {
        super(props);
        this.getData = this.getData.bind(this);
        this.state = {
            data: [],
        };
    }

    getData() {
        axios
            .get(
                `${Constants.paths.ajaxBasePath}prodotti/categorie/?token=${this.context.user.token}`
            )
            .then((res) => {
                this.setState({data: res.data});
            });
    }

    componentDidMount() {
        if (this.props.catData) {
            this.setState({
                data: this.props.catData,
            });
        } else {
            this.getData();
        }
    }

    render() {
        return (
            <Autocomplete
                autoSelect
                disabled={this.props.disabled}
                // defaultValue={}
                // renderOption={(option) => (
                //   <React.Fragment>
                //     {option.cat2 ? (
                //       <Typography>
                //         {option.percorso.replace(/^(.*>)(.*)$/gi, "$1")}
                //         <strong>
                //           {option.percorso.replace(/^(.*>)(.*)$/gi, "$2")}
                //         </strong>
                //       </Typography>
                //     ) : (
                //       <Typography>
                //         <strong>{option.percorso}</strong>
                //       </Typography>
                //     )}
                //     <Divider />
                //   </React.Fragment>
                // )}
                options={this.state.data}
                // getOptionLabel={(option) =>
                //   option.percorso?.replace(/^(.*>)(.*)$/gi, "$2") || ""
                // }
                getOptionLabel={option =>
                    option.percorso || ""
                }
                isOptionEqualToValue={(o, v) => o.id === v.id}
                style={{width: "100%"}}
                value={this.state.data?.find((c) => c.id == this.props.value) || {}}
                onChange={(e, val) => this.props.onChange(val)}
                renderInput={(params) => (
                    <TextField {...params} label="Categoria" variant="outlined"/>
                )}
            />
        );
    }
}
