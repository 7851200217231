import React, { useContext } from "react";
import Context from "../context/Context";
import { Grid, Typography } from "@mui/material";
import { ToggleButtonGroup, ToggleButton } from '@mui/material';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faArrowDown } from "@fortawesome/free-solid-svg-icons";

function TableSorting(props) {
  const context = useContext(Context);
  return (
    <React.Fragment>
      <Typography color="textSecondary" variant="overline">
        Ordina per
      </Typography>
      <br />

      <Grid
        container
        justifyContent="space-around"
        spacing={1}
        style={{ marginBottom: context.theme.spacing(2) }}
      >
        <Grid item>
          <ToggleButtonGroup
            onChange={(e, val) => val != null && props.onChange(e,val)}
            size={context.matches.sm ? "medium" : "medium"}
            exclusive
            value={props.value}
            // style={{ marginBottom: context.theme.spacing(2) }}
          >
            {props.options.map((o,i) => (
              <ToggleButton value={o.value} key={i}>
                <Typography>{context.matches.sm ? o.desc : o.short}</Typography>
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </Grid>
        <Grid item xs={12}>
          {" "}
          <ToggleButtonGroup
            exclusive
            value={props.orderValue}
            size="small"
            onChange={(e, val) => val != null && props.onOrderChange(e, val)}
          >
            <ToggleButton value="down">
              <FontAwesomeIcon icon={faArrowDown} />
            </ToggleButton>

            <ToggleButton value="up">
              <FontAwesomeIcon icon={faArrowUp} />
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default TableSorting;
