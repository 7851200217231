import React from "react";
import { Divider, TextField } from "@mui/material";
import { Autocomplete } from '@mui/material';
import axios from "axios";
import Constants from "../context/Constants";
import Context from "../context/Context";

interface PropsIF {
  disabled?: boolean;
  value: string;
  onChange(arg0: string): void;
}

interface StateIF {
  data: object[];
}

class TipicalNamesDropdown extends React.Component<PropsIF, StateIF> {
  static contextType = Context;
  constructor(props: any) {
    super(props);
    this.getData = this.getData.bind(this);
    this.state = {
      data: [],
    };
  }

  getData(isInit: boolean = false): void {
    axios
      .get(
    // @ts-ignore
        `${Constants.paths.ajaxBasePath}prodotti/nomi-tipici/?token=${this.context.user.token}`
      )
      .then((res) => {
        this.setState({ data: res.data });
      });
  }

  componentDidMount(): void {
    this.getData(true);
  }

  componentDidUpdate(): void {
    // console.log(this.props, this.state);
  }

  render() {
    // @ts-ignore
    return (
      <Autocomplete
        autoSelect
        disabled={this.props.disabled}
        // renderOption={(option: any) => (
        //   <React.Fragment>
        //     {option.nome}
        //     <Divider />
        //   </React.Fragment>
        // )}
        options={this.state.data}
        getOptionLabel={(option: any) => option.nome || ""}
        style={{ width: "100%" }}
        //@ts-ignore
        value={this.state.data.find((d: any) => d.id == this.props.value) || ""}
        onChange={(e, val: any) => this.props.onChange(val)}
        renderInput={(params) => (
          <TextField {...params} label="Nome tipico" variant="outlined" />
        )}
      />
    );
  }
}

export default TipicalNamesDropdown;
