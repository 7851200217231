import {
  Grid,
  Typography,
  Paper,
  Divider,
  List,
  ListItem,
  ListSubheader,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  IconButton,
} from "@mui/material";
import React from "react";
import Constants from "../context/Constants";
import Context from "../context/Context";
import axios from "axios";
import moment from "moment";
import localization from "moment/locale/it";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faArrowUp, faClone } from "@fortawesome/free-solid-svg-icons";
moment.locale("it", localization);

class ProductOrderHistory extends React.Component {
  static contextType = Context;
  static Cols = [
    {
      id: "_date",
      label: "Data",
      minWidth: "150px",
    },
    {
      id: "v_prezzo",
      label: "Base",
    },
    {
      id: "user_sconto",
      label: "Sconto",
    },
    {
      id: "user_prezzo",
      label: "Prezzo",
    },
    {
      id: "qta",
      label: "Qtà",
      maxWidth: "25px",
    },
    {
      id: "_total",
      label: "Totale",
    },
    {
      id: "action",
      label: "Clona",
    },
  ];
  constructor(props) {
    super(props);
    this.getData = this.getData.bind(this);
    this.generateRows = this.generateRows.bind(this);
    this.handleAction = this.handleAction.bind(this);
    this.state = {
      data: undefined,
    };
  }
  componentDidMount() {
    this.getData();
    console.log(this.props);
  }

  handleAction(v_prezzo, user_sconto, qta, user_prezzo) {
    this.props.overrideFn(v_prezzo, user_sconto, qta, user_prezzo);
    this.props.handleClose();
  }

  getData() {
    axios
      .get(
        Constants.paths.ajaxBasePath +
          `prodotti/storici/ordini/clienti/?` +
          "&token=" +
          this.context.user.token +
          "&id_cliente=" +
          this.props.id_cliente +
          "&id_prodotti=" +
          this.props.id_prod
      )
      .then((res) => {
        this.setState({ data: res.data[this.props.id_prod] });
        console.log(this.state);
      });
  }

  generateRows() {
    if (!this.state.data?._all || this.state.data?._all?.length < 1)
      return (
        <TableRow>
          <TableCell>
            <i>Storico non disponibile</i>
          </TableCell>
        </TableRow>
      );
    let rows = this.state.data._all.map((el, i) => (
      <TableRow>
        <TableCell>
          {moment.unix(el.timestamp).format("DD/MM/YYYY")} •{" "}
          {moment.unix(el.timestamp).fromNow()}
        </TableCell>
        <TableCell>
          {parseFloat(el.v_prezzo).toFixed(2).replace(".", ",")} €
        </TableCell>
        <TableCell>-{el.user_sconto}%</TableCell>
        <TableCell>
          {parseFloat(el.user_prezzo).toFixed(2).replace(".", ",")} €
        </TableCell>
        <TableCell>x{el.qta}</TableCell>
        <TableCell>
          <strong>
            {parseFloat(parseFloat(el.user_prezzo) * parseInt(el.qta))
              .toFixed(2)
              .replace(".", ",")}
            €
          </strong>
        </TableCell>
        <TableCell>
          <IconButton
            onClick={e=>this.handleAction(el.v_prezzo, el.user_sconto, el.qta, el.user_prezzo)}
            size="large">
            <FontAwesomeIcon size='sm' icon={faClone} />
          </IconButton>
        </TableCell>
      </TableRow>
    ));
    // console.log(rows);
    return rows;
  }

  render() {
    return (
      <Paper style={{ padding: this.context.theme.spacing(1) }}>
        <React.Fragment>
          <Grid container style={{ textAlign: "center" }} spacing={1}>
            <Grid item xs={12}>
              <Typography variant="overline">Statistiche</Typography>
            </Grid>
            <Grid container item xs={5}>
              <Grid item xs={12}>
                <Typography variant="overline">
                  <FontAwesomeIcon
                    style={{ color: this.context.theme.palette.success.main }}
                    icon={faArrowDown}
                  />{" "}
                  Min
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  <strong>
                    {this.state.data?._min?.user_prezzo
                      ? parseFloat(this.state.data._min.user_prezzo)
                          .toFixed(2)
                          .replace(".", ",") + " €"
                      : "N/D"}
                  </strong>
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={2}>
              <Divider
                style={{ display: "inline-flex" }}
                orientation="vertical"
              />
            </Grid>
            <Grid container item xs={5}>
              <Grid item xs={12}>
                <Typography variant="overline">
                  <FontAwesomeIcon
                    style={{ color: this.context.theme.palette.error.main }}
                    icon={faArrowUp}
                  />{" "}
                  Max
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  <strong>
                    {this.state.data?._max?.user_prezzo
                      ? parseFloat(this.state.data._max.user_prezzo)
                          .toFixed(2)
                          .replace(".", ",") + " €"
                      : "N/D"}
                  </strong>
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="overline">Ultimo ordine</Typography>
            </Grid>
            <Grid item xs={12}>
              {this.state.data?._last?.timestamp
                ? moment.unix(this.state.data._last.timestamp).fromNow()
                : "N/D"}
            </Grid>
            <Grid item xs={12}>
              {this.state.data?._last?.timestamp
                ? moment.unix(this.state.data._last.timestamp).format("L")
                : "N/D"}
            </Grid>
            <Grid item xs={12}>
              {this.state.data?._last?.v_prezzo
                ? parseFloat(this.state.data._last.v_prezzo)
                    .toFixed(2)
                    .replace(".", ",") + " €"
                : "N/D"}
              {" - "}
              {(this.state.data?._last?.user_sconto
                ? this.state.data._last.user_sconto
                : "0") + "%"}
              <Grid item xs={12}>
                <br />
                <Typography variant="h6">
                  {this.state.data?._last?.user_prezzo
                    ? parseFloat(this.state.data._last.user_prezzo)
                        .toFixed(2)
                        .replace(".", ",") + " €"
                    : "N/D"}{" "}
                  x {this.state.data?._last?.qta || "N/D"}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </React.Fragment>
        <br />
        <Grid container justifyContent='center'>
          <Grid item>
        <Typography style={{textAlign: 'center', width:'100%'}} variant="overline">Storico</Typography>
          </Grid>
        </Grid>
        <TableContainer style={{ maxHeight: "75vh" }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {ProductOrderHistory.Cols.map((c) => (
                  <TableCell key={c.id}>{c.label}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {this.generateRows()}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    );
  }
}
export default ProductOrderHistory;
