import { TextField } from "@mui/material";
import { Autocomplete } from '@mui/material';
import axios from "axios";
import React from "react";
import Constants from "../context/Constants";
import Context from "../context/Context";

export default class BrandsDropdown extends React.Component {
  static contextType = Context;
  constructor(props) {
    super(props);
    this.getData = this.getData.bind(this);
    this.state = {
      data: [],
    };
  }

  getData() {
    axios
      .get(
        `${Constants.paths.ajaxBasePath}prodotti/brands/?token=${this.context.user.token}`
      )
      .then((res) => {
        this.setState({ data: res.data });
      });
  }

  componentDidMount() {
    if (this.props.brandData) {
      this.setState({
        data: this.props.brandData,
      });
    } else {
      this.getData();
    }
  }

  render() {
    return (
      <Autocomplete
        autoSelect
        defaultValue={{}}
        options={this.state.data}
        getOptionLabel={(option) => (option.nome ? option.nome : "")}
        isOptionEqualToValue={(o,v)=>o.id===v.id}
        style={{ width: "100%" }}
        value={this.state.data?.find(b => b.id == this.props.value) || {}}
        onChange={(e, val) => this.props.onChange(val)}
        renderInput={(params) => (
          <TextField {...params} label="Brand" variant="outlined" />
        )}
      />
    );
  }
}
