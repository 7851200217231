import axios from "axios";
import Constants from "../context/Constants";

export default async function getProducts(
  ids: [number] | number
): Promise<any> {
  const token = localStorage.getItem("token");
  let idsString;
  if (!ids) return null;
  if (typeof ids === "object") {
    idsString = ids.join(",");
  } else {
    idsString = ids;
  }
  let req = await axios.get(
    `${Constants.paths.ajaxBasePath}prodotti/?token=${token}&ids=${idsString}`
  );
  return typeof ids === "object" ? req.data : req.data[0];
}
