import React from "react";
import {
  Grid,
  List,
  ListItem,
  Popover,
  Typography,
  Button
} from "@mui/material";
import { GeneratePricesPopupContent } from "./PricesPopupContent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import getPricesNr from "../functions/countAvailablePrices";
import Context from "../context/Context";

class TablePriceRender extends React.Component {
  //*PROPS:
  //*acquisto: Boolean
  //*input_props: Object (Rowdata)

  constructor(props) {
    super(props);
    this.handlePopupOpen = this.handlePopupOpen.bind(this);
    this.handlePopupClose = this.handlePopupClose.bind(this);
    this.state = {
      popoverOpen: false
    };
    this.baseDiscounts = [];

  //   for (let i = 1; i < 6; i++) {
  //       if(this.props.input_props[``])
  //       this.baseDiscounts.push(this.pricesJsonToUse.Base.prices[i]);
  //     }
  //   }
  }

  handlePopupOpen(e) {
    this.setState({
      popoverOpen: true,
      popupAnchor: e.target
    });
  }
  handlePopupClose() {
    this.setState({
      popoverOpen: false
    });
  }

  render() {
    return (
      <Context.Consumer>
        {consumer_data => (
          <React.Fragment>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Typography variant="subtitle1">{this.props.input_props.prezzo_l1}€</Typography>
              </Grid>
              <Grid item>
                <Typography>
                  {this.baseDiscounts.map(d => "-" + d + "% ")}
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  // style={{position:"absolute"}}
                  onClick={this.handlePopupOpen}
                >
                  Listini ({getPricesNr(this.props.input_props)})
                  <FontAwesomeIcon
                    style={{
                      marginTop: consumer_data.theme.spacing(0.25) * -1,
                      marginLeft: consumer_data.theme.spacing(0.5)
                    }}
                    icon={faCaretDown}
                  />
                </Button>
              </Grid>
            </Grid>
            <Popover
              open={this.state.popoverOpen}
              onClose={this.handlePopupClose}
              anchorEl={this.state.popupAnchor}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center"
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center"
              }}
            >
              <GeneratePricesPopupContent
                productObj={this.props.input_props}
                acquisto={this.props.acquisto || false}
              />
            </Popover>
          </React.Fragment>
        )}
      </Context.Consumer>
    );
  }
}

class TablePriceEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return "hi";
  }
}


export { TablePriceRender, TablePriceEdit };
