
function calcDiscount(productObj,decimals=2) {
    const thisBasePrice = productObj.v_prezzo;
    const thisDiscount = productObj.user_sconto;
    if (thisDiscount > 0) {
      return parseFloat(
        thisBasePrice - (thisBasePrice * thisDiscount) / 100
      ).toFixed(2);
    } else {
      return parseFloat(thisBasePrice).toFixed(decimals); //Return base price
    }
}
export { calcDiscount };