import React, {useContext} from "react";
import {
    Grid,
    Divider,
    Button,
    Box,
    Fab,
    Typography,
    Card
} from "@mui/material";
import moment from "moment";
import localization from "moment/locale/it";
import Context from "../../context/Context";
// import {withSnackbar} from "notistack";
import {faCloud, faSyncAlt} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

moment.locale("it", localization);

class BozzePanel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <Context.Consumer>
                {(consumer_data) => (
                    <React.Fragment>
                        {this.props.activeTab === 2 ? (
                            <React.Fragment>
                                <Fab
                                    variant={this.props.syncing ? "extended" : "circular"}
                                    style={
                                        {
                                            zIndex: 99,
                                            position: "fixed",
                                            right: consumer_data.theme.spacing(2),
                                            bottom: consumer_data.theme.spacing(2)
                                        }
                                    }
                                    onClick={(e) => this.props.syncDrafts()}
                                >
                                    {this.props.syncing ? "Sincronizzo... " : ""}
                                    <FontAwesomeIcon
                                        icon={faSyncAlt}
                                        // style={{ marginRight: consumer_data.theme.spacing(1) }}
                                    />
                                    {/* {this.state.unlocked ? "SBLOCCATO" : "BLOCCATO"} */}
                                </Fab>
                                <Grid container justifyContent="space-around">
                                    <Grid item xs={12} sx={{width: '100%', textAlign:'center'}}>
                                        <Typography color={'text.secondary'} variant={'overline'} sx={{align: 'center', width: '100%'}}>
                                            Sincronizzazione automatica attiva</Typography>
                                    </Grid>
                                    <Grid item container xs={12} md={8} lg={7}>
                                        {Object.keys(this.props.drafts).length > 0 ? (
                                            Object.keys(this.props.drafts)
                                                .map((did, i, all) => {
                                                    let thisDraft = this.props.drafts[did];
                                                    return (
                                                        <React.Fragment key={did}>
                                                            <br/>
                                                            <Card
                                                                variant="outlined"
                                                                key={did}
                                                                style={{
                                                                    width: "100%",
                                                                    padding: consumer_data.theme.spacing(3),
                                                                }}
                                                            >
                                                                <Typography
                                                                    style={{fontSize: "70%", opacity: "0.4"}}
                                                                >
                                                                    {did}
                                                                </Typography>
                                                                <Grid item container>
                                                                    <Grid
                                                                        item
                                                                        xs={12}
                                                                        style={{
                                                                            marginBottom: consumer_data.theme.spacing(
                                                                                1
                                                                            ),
                                                                        }}
                                                                    >
                                                                        <Typography variant="overline">
                                                                            {moment(thisDraft.dataCreazione).fromNow() +
                                                                                " • " +
                                                                                moment(thisDraft.dataCreazione).format(
                                                                                    "DD/MM/YYYY HH:mm"
                                                                                )
                                                                                // +
                                                                                // " • "
                                                                            }
                                                                            {/*<RemoteDraftStatusIcon*/}
                                                                            {/*    status={*/}
                                                                            {/*        this.props.draftsCloudSyncStatus[did]*/}
                                                                            {/*    }*/}
                                                                            {/*/>*/}
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item container xs={8} spacing={1}>
                                                                        <Grid
                                                                            item
                                                                            xs={3}
                                                                            style={{textAlign: "right"}}
                                                                        >
                                                                            Offerta:
                                                                        </Grid>
                                                                        <Grid
                                                                            item
                                                                            xs={9}
                                                                            style={{
                                                                                textAlign: "left",
                                                                                fontWeight: "bold",
                                                                            }}
                                                                        >
                                                                            {thisDraft.offerta.titolo ||
                                                                                "Non selezionata"}
                                                                        </Grid>
                                                                        <Grid
                                                                            item
                                                                            xs={3}
                                                                            style={{textAlign: "right"}}
                                                                        >
                                                                            Cliente:
                                                                        </Grid>
                                                                        <Grid
                                                                            item
                                                                            xs={9}
                                                                            style={{
                                                                                textAlign: "left",
                                                                                fontWeight: "bold",
                                                                            }}
                                                                        >
                                                                            {thisDraft.cliente.ragione_sociale ||
                                                                                "Non selezionato"}
                                                                        </Grid>
                                                                        <Grid
                                                                            item
                                                                            xs={3}
                                                                            style={{textAlign: "right"}}
                                                                        >
                                                                            Righe:
                                                                        </Grid>
                                                                        <Grid
                                                                            item
                                                                            xs={9}
                                                                            style={{
                                                                                textAlign: "left",
                                                                                fontWeight: "bold",
                                                                            }}
                                                                        >
                                                                            {thisDraft.articoli.length || 0}
                                                                        </Grid>
                                                                        <Grid
                                                                            item
                                                                            xs={3}
                                                                            style={{textAlign: "right"}}
                                                                        >
                                                                            Totale:
                                                                        </Grid>
                                                                        <Grid
                                                                            item
                                                                            xs={9}
                                                                            style={{
                                                                                textAlign: "left",
                                                                                fontWeight: "bold",
                                                                            }}
                                                                        >
                                                                            {parseFloat(thisDraft.totale_ordine)
                                                                                .toFixed(2)
                                                                                .replace(".", ",") + " €"}
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Grid item container xs={4}>
                                                                        <Grid item xs={12}>
                                                                            <Button
                                                                                fullWidth
                                                                                style={{height: "100%"}}
                                                                                variant="contained"
                                                                                onClick={() => {
                                                                                    this.props.onSetActive(
                                                                                        thisDraft,
                                                                                        thisDraft.id
                                                                                    );
                                                                                }}>
                                                                                Riprendi
                                                                            </Button>{" "}
                                                                        </Grid>
                                                                        <Grid item xs={12}>
                                                                            <Button
                                                                                fullWidth
                                                                                style={{height: "100%"}}
                                                                                onClick={() =>
                                                                                    this.props.onDeleteDraft(did)
                                                                                }
                                                                            >
                                                                                <Typography color="error">
                                                                                    Elimina
                                                                                </Typography>
                                                                            </Button>{" "}
                                                                        </Grid>
                                                                    </Grid>
                                                                    {thisDraft.note && (
                                                                        <Grid item xs={12} spacing="2">
                                                                            <br/>
                                                                            <Divider/>
                                                                            <Typography variant="overline">
                                                                                Note
                                                                            </Typography>
                                                                            <Typography variant="body2">
                                                                                {thisDraft.note}
                                                                            </Typography>
                                                                        </Grid>
                                                                    )}
                                                                </Grid>
                                                            </Card>
                                                            {all.length - 1 === i ? undefined : <Divider/>}
                                                        </React.Fragment>
                                                    );
                                                })
                                        ) : (
                                            <Grid item xs={12} style={{textAlign: "center"}}>
                                                <Box color="text.secondary">
                                                    <i>Nessuna bozza salvata</i>
                                                </Box>
                                                <br/>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                            </React.Fragment>
                        ) : undefined}
                    </React.Fragment>
                )}
            </Context.Consumer>
        );
    }
}

export default BozzePanel;
