import React from "react";
import {
    Box,
    Button,
    ButtonGroup,
    Card,
    CardContent,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Divider,
    Typography,
    Switch,
    FormControl,
    FormGroup,
    FormControlLabel,
    FormHelperText,
    TextField,
    Tooltip,
    CircularProgress,
    InputAdornment,
} from "@mui/material";
import {ToggleButton, ToggleButtonGroup, Pagination} from '@mui/material';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faPlus,
    faPaperPlane,
    faClipboard,
    faCloud,
    faCaretRight,
    faRedoAlt,
    faEuroSign,
    faPercent,
} from "@fortawesome/free-solid-svg-icons";
import {
    CustomerSelector,
    Offerta_ProductSelector,
    OffertaSelector,
    ProductSelector,
    Restock_ProductSelector,
} from "../../../components/DialogSelector";
import {SlideUpTransition} from "../../../components/Transitions";
import Constants from "../../../context/Constants";
import Context from "../../../context/Context";
import SchedaProdotto from "./SchedaProdotto";
import {calcDiscount} from "../../../functions/discounts";
import {v4 as uuidv4} from "uuid";
import {enqueueSnackbar, closeSnackbar} from "notistack";
import axios, {options} from "axios";
import {motion} from "framer-motion";
//import renderHTML from "react-render-html";
import download from "downloadjs";
import moment from "moment";
import localization from "moment/locale/it";
import {roundDown, roundUp} from "../../../functions/round";

moment.locale("it", localization);

class OrdinePanel extends React.Component {
    static contextType = Context;
    static inlineOfferCss =
        "<style>.offer-notes img{max-width:100%;border-radius:4px}</style>";

    constructor(props) {
        super(props);
        this.selectCustomer = this.selectCustomer.bind(this);
        this.selectOfferta = this.selectOfferta.bind(this);
        this.emptyOfferta = this.emptyOfferta.bind(this);
        this.selectProduct = this.selectProduct.bind(this);
        this.selectBranch = this.selectBranch.bind(this);
        this.selectShipping = this.selectShipping.bind(this);
        this.previewOrder = this.previewOrder.bind(this);
        this.handleProductChange = this.handleProductChange.bind(this);
        this.calcOrderTotal = this.calcOrderTotal.bind(this);
        this.handleSearchCustomerDialogClose = this.handleSearchCustomerDialogClose.bind(this);
        this.handleSearchCustomerDialogOpen = this.handleSearchCustomerDialogOpen.bind(this);
        this.handleOffertaDialogClose = this.handleOffertaDialogClose.bind(this);
        this.handleOffertaDialogOpen = this.handleOffertaDialogOpen.bind(this);
        this.handleProductDialogClose = this.handleProductDialogClose.bind(this);
        this.handleProductDialogOpen = this.handleProductDialogOpen.bind(this);
        this.handleProductRestockDialogClose = this.handleProductRestockDialogClose.bind(this);
        this.handleProductRestockDialogOpen = this.handleProductRestockDialogOpen.bind(this);
        this.handleConfirmOrderDialogOpen = this.handleConfirmOrderDialogOpen.bind(this);
        this.removeProduct = this.removeProduct.bind(this);
        this.saveDraft = this.saveDraft.bind(this);
        this.saveActive = this.saveActive.bind(this);
        this.newOrdine = this.newOrdine.bind(this);
        this.handleConfirmOrderDialogOpen = this.handleConfirmOrderDialogOpen.bind(this);
        this.handleOrderNotesChange = this.handleOrderNotesChange.bind(this);
        this.handleOrderDiscountValueChange = this.handleOrderDiscountValueChange.bind(this);
        this.handleOrderDiscountModeChange = this.handleOrderDiscountModeChange.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.isOrderEmpty = this.isOrderEmpty.bind(this);
        this.sendOrder = this.sendOrder.bind(this);
        this.onFetchingChange = this.onFetchingChange.bind(this);
        let thisOrderActiveStorage = {};
        try {
            thisOrderActiveStorage = JSON.parse(localStorage.getItem("orderActive"));
        } catch (error) {
        }
        this.state = {
            isOrderEmpty: this.isOrderEmpty(thisOrderActiveStorage),
            readyToSend: false,
            notReadyToSendReasons: [],
            dataOrdine: thisOrderActiveStorage
                ? thisOrderActiveStorage
                : {},
            fetching: false,
            searchCustomerDialog: {
                open: false,
                searchStr: "",
                results: [],
            },
            searchOffertaDialog: {
                open: false,
                searchStr: "",
                results: [],
            },
            searchProductDialog: {
                open: false,
                searchStr: "",
                results: [],
            },
            searchProductRestockDialog: {
                open: false,
                searchStr: "",
                results: [],
            },
            confirmOrderDialog: {
                open: false,
            },
            pagination: {
                pageSize: 10,
                thisPage: 1,
            },
        };
        if (!thisOrderActiveStorage) this.newOrdine();
        //console.log(this.state.dataOrdine);
    }

    componentWillUnmount() {
    }

    componentDidUpdate(prev) {
        this.checkRequiredFields();
        this.saveActive();
    }

    componentDidMount() {
        this.props.updateParentOfferta(
            this.state.dataOrdine.offerta.id,
            this.state.dataOrdine.offerta.titolo
        );
        this.props.updateParentItemIds(
            this.state.dataOrdine.articoli.map((a) => a.id)
        );
    }


    handleConfirmOrderDialogOpen(e) {
        this.setState((prev) => ({
            confirmOrderDialog: {...prev.confirmOrderDialog, open: true},
        }));
    }

    async newOrdine(returnOnly = false, keepOffer = true) {
        const emptyOfferta = {
            id: null,
            titolo: "",
            descrizione: "",
            note: "",
            stato: "",
        };
        let newDataOrdine = {
            id: uuidv4(),
            offerta: emptyOfferta,
            cliente: {
                id: "",
                nome_cond_pagamento: ""
            },
            id_sede: undefined,
            totale_ordine: 0.0,
            totale_ordine_imponibile: 0.0,
            totale_ordine_merci: 0.0,
            sconto_chiusura: 0.0,
            sconto_chiusura_mod: "abs",
            totale_ordine_sconto_chiusura: 0.0,
            totale_ordine_iva: 0.0,
            spedizione: "4.9",
            sendToCustomer: false,
            articoli: [],
            dataCreazione: Date.now(),
            ultimaModifica: Date.now(),
            note: "",
        };
        if (!returnOnly) {
            this.setState({dataOrdine: {...newDataOrdine}}, () => {
                this.props.setOrderIdFn(newDataOrdine.id);
            });
            localStorage.setItem("orderActive", JSON.stringify(newDataOrdine));
        }
        return newDataOrdine;
    }

    isOrderEmpty(orderObj = false) {
        let toChk;
        const result = () => {
            if (orderObj === false) {
                toChk = {...this.state.dataOrdine};
                if (toChk === null) return true;
            } else if (orderObj === null) {
                return true;
            } else {
                toChk = orderObj;
            }
            if (toChk.cliente.id != "") return false;
            if (toChk.articoli.length > 0) return false;
            if (toChk.note != "") return false;
            if (toChk.offerta.id != "") return false;
            return true;
        };
        // if (setState)
        //   this.setState({
        //     isOrderEmpty: result
        //   });
        return result();
    }

    async saveDraft(e, id = undefined) {
        console.log('id', id);
        id = id || this.state.dataOrdine.id.toString();
        console.log('nId', id);
        let storage = JSON.parse(localStorage.getItem("orderDrafts"));
        if (!storage) {
            storage = {}; //Normalize
        }
        console.log("Storage before set", storage);
        storage[id] = {
            ...this.state.dataOrdine,
            ultimaModifica: Date.now()
        }; //Probabile conflitto con gli id!!
        localStorage.setItem("orderDrafts", JSON.stringify(storage));
        console.log("Storage after set", JSON.parse(localStorage.getItem("orderDrafts")));

        enqueueSnackbar("Bozza salvata", {
            variant: "success",
        });
        this.newOrdine();
        this.props.onRefreshDrafts([id]);
    }

    async saveActive() {
        await new Promise((res, rej) => {
            localStorage.setItem(
                "orderActive",
                JSON.stringify(this.state.dataOrdine)
            );
            res();
        });
    }

    handlePageChange(e, newPage) {
        this.setState((prev) => ({
            pagination: {...prev.pagination, thisPage: newPage},
        }));
    }

    handleProductChangeBlur(
        e,
        aObj,
        property,
        directVal = undefined,
        callback = false,
        recalcOrderTotal = false
    ) {
        let newAObj = aObj;
        let newPropVal;
        if (typeof directVal === "undefined" || directVal === false) {
            newPropVal = parseFloat(String(e.target.value).replace(",", "."));
            // console.log("New val", newPropVal);
        } else {
            newPropVal = directVal;
        }

        switch (property) {
            case "sconto":
                if (!newPropVal || isNaN(parseFloat(newPropVal))) newPropVal = 0; //Failsafe per evitare sconti vuoti
                let thisMaxDiscount = parseFloat(newAObj["max_sconto"]) || 100;
                if (newPropVal > thisMaxDiscount) {
                    newPropVal = thisMaxDiscount;
                }
                newAObj["user_sconto"] = newPropVal.toFixed(2);
                newAObj["user_prezzo"] = calcDiscount(newAObj, 3);
                newAObj['uso_punti'] = 0;
                this.calcOrderTotal();
                break;

            case "qta":
                newPropVal = parseInt(newPropVal) > 0 ? parseInt(newPropVal) : 1;
                newAObj["qta"] = newPropVal;
                break;

            case "prezzo":
                if (!newPropVal || isNaN(parseFloat(newPropVal))) newPropVal = 0; //Failsafe per evitare prezzi vuoti
                if (this.state.dataOrdine.offerta.tipo === 'punti') {
                    if (newAObj["options"].punti.uso) {
                        newAObj['uso_punti'] = roundUp(((parseFloat(newAObj['prezzo_l3']) - newPropVal) * newAObj['qta']), 3);
                    }
                }
                newAObj["user_prezzo"] = newPropVal;
                break;

            case "usoPunti":
                if (!newPropVal || isNaN(parseFloat(newPropVal))) newPropVal = 0; //Parse everything first
                let uso_punti = parseFloat(newPropVal);
                let user_sconto = 0.00;
                let l3_price = parseFloat(newAObj['prezzo_l3'] || 0);
                let user_qta = parseInt(newAObj['qta']);
                let user_prezzo = parseFloat(newAObj['user_prezzo']);
                let fixed_min_price = parseFloat(newAObj['options']?.['min_price'] || 0);
                let priceMinusPoints = 0.000;

                // uso_punti = roundDown(newPropVal, 3); //??
                priceMinusPoints = roundUp((((l3_price * user_qta) - uso_punti) / user_qta), 3);
                user_prezzo = (priceMinusPoints > fixed_min_price)
                    ? priceMinusPoints
                    : fixed_min_price;
                uso_punti = roundDown(((l3_price - user_prezzo) * user_qta), 3); //Forza il ricalcolo dei punti restanti

                newAObj['uso_punti'] = uso_punti;
                newAObj['user_sconto'] = user_sconto;
                newAObj['user_prezzo'] = user_prezzo;
                break;

            default:
                break;
        }

        // console.log("Blurred:", e, aObj, newPropVal, newAObj);

        const index = this.state.dataOrdine.articoli.indexOf(aObj);
        let newArticoli = this.state.dataOrdine.articoli;
        newArticoli[index] = newAObj;

        //Finally
        this.setState(
            (prev) => ({
                dataOrdine: {...prev.dataOrdine, articoli: newArticoli},
            }),
            () => {
                if (callback) callback(newAObj);
                if (recalcOrderTotal) this.calcOrderTotal(); //!PERFORMANCE
            }
            //this.calcOrderTotal //!PERFORMANCE
        );
    }

    handleProductChange(
        e,
        aObj,
        property,
        directVal = undefined,
        callback = false
    ) {
        const start = Date.now();
        let newAObj = aObj;
        let newPropVal;
        if (typeof directVal === "undefined") {
            newPropVal = e.target.value;
        } else {
            newPropVal = directVal;
        }
        switch (property) {
            case "note":
                newAObj["user_note"] = newPropVal;
                break;

            case "prezzoBase":
                newAObj["v_prezzo"] = newPropVal;
                break;

            case "prezzo":
                if (newPropVal) {
                    // newPropVal = e.target.value;
                    //newPropVal = e.target.value.replace(",", ".");
                    // newPropVal = e.target.value.replace(",", ".");
                    // newPropVal = newPropVal.split(".");
                    // newPropVal = newPropVal[0] + (newPropVal[1]? "." + newPropVal[1]:"");
                } else {
                    newPropVal = "";
                }
                newAObj["user_sconto"] = 0;
                newAObj["user_prezzo"] = newPropVal;
                break;

            case "sconto":
                newAObj["user_sconto"] = newPropVal;
                break;

            case "qta":
                // newPropVal = parseInt(newPropVal) > 0 ? parseInt(newPropVal) : 1;
                newAObj["qta"] = newPropVal;
                break;

            case "qtaPlus":
                property = "qta";
                newPropVal =
                    parseInt(aObj.qta) + parseInt(aObj.imballo ? aObj.imballo : 1);
                newAObj["qta"] = newPropVal;
                break;

            case "qtaMinus":
                property = "qta";
                //Focu meu...
                newPropVal = parseInt(aObj.qta) > parseInt(aObj.imballo ? aObj.imballo : 2)
                    ? parseInt(aObj.qta) - parseInt(aObj.imballo ? aObj.imballo : 1)
                    : aObj.imballo
                        ? aObj.imballo
                        : 1;
                newAObj["qta"] = newPropVal;
                break;

            case "desc":
                newAObj["descrizione"] = newPropVal;
                newAObj["v_descrizione"] = newPropVal;
                break;

            case "ufCod":
                newAObj["uf_cod"] = newPropVal.toUpperCase();
                break;

            case "usoPunti":
                newAObj["uso_punti"] = newPropVal;
                break;

            default:
                break;
        }

        // console.log(e, aObj, newPropVal, newAObj);

        const index = this.state.dataOrdine.articoli.indexOf(aObj);
        let newArticoli = this.state.dataOrdine.articoli;
        newArticoli[index] = newAObj;
        const end = Date.now();
        // console.log("HandleProductChange exec:", end - start);

        //Finally
        this.setState(
            (prev) => ({
                dataOrdine: {...prev.dataOrdine, articoli: newArticoli},
            }),
            () => {
                this.calcOrderTotal(); //!PERFORMANCE
                if (callback) callback(newAObj);
            }
        );
    }

    calcOrderTotal(iva = 22) {
        let newOrderTotal = 0.0, //Imponibile
            newOrderIva = 0.0, //Totale IVA
            newOrderAvgIva = 0, // Media in perc dell'IVA
            newOrderTotalIVA = 0.0, //Totale
            appliedOrderDiscount = 0.0,
            newOrderTotalPoints = 0.0,
            newOrderAvailablePoints = 0.0; //Totale sconto applicato

        // Iter ogni prodotto
        for (const a of this.state.dataOrdine.articoli) {
            const thisQta = parseInt(a.qta),
                thisIVA = parseFloat(a.iva),
                thisPrezzo = parseFloat(a.user_prezzo);
            // console.log(thisIVA);
            newOrderTotal += thisQta * thisPrezzo;
            newOrderIva += thisQta * (thisPrezzo * (thisIVA / 100));
        }

        //Calcola i punti se necessario
        if (this.state.dataOrdine.offerta.tipo === 'punti') {
            for (const a of this.state.dataOrdine.articoli) {
                let thisPoints = this.returnPointsByPrice(a.user_prezzo, a.options, a) * a.qta;
                newOrderTotalPoints += thisPoints;
                newOrderAvailablePoints += thisPoints;
                newOrderAvailablePoints -= (a.uso_punti || 0);
            }
            if (newOrderAvailablePoints === -0) newOrderAvailablePoints = 0;
        }

        //Calcola percentuale media IVA SOLTANTO sugli articoli
        newOrderAvgIva = (newOrderIva / newOrderTotal) * 100;
        if (isNaN(newOrderAvgIva)) newOrderAvgIva = 0;

        //Applica sconto sulle merci
        if (
            parseFloat(this.state.dataOrdine.sconto_chiusura) &&
            this.state.dataOrdine.sconto_chiusura_mod
        ) {
            if (this.state.dataOrdine.sconto_chiusura_mod === "abs") {
                //Assoluto
                appliedOrderDiscount = parseFloat(
                    this.state.dataOrdine.sconto_chiusura
                );
                newOrderTotal -= appliedOrderDiscount;
            } else {
                //Percentuale
                appliedOrderDiscount =
                    newOrderTotal *
                    (parseFloat(this.state.dataOrdine.sconto_chiusura) / 100);
                newOrderTotal -= appliedOrderDiscount;
            }
        }

        newOrderIva = newOrderTotal * (newOrderAvgIva / 100);

        newOrderTotal += parseFloat(this.state.dataOrdine.spedizione); //Imponibile sped.
        newOrderIva += parseFloat(this.state.dataOrdine.spedizione * (iva / 100)); //IVA sped.
        newOrderTotalIVA = newOrderTotal + newOrderIva; //TOTALE
        // console.log("TOTALI: ---------------");
        // console.log(newOrderTotal);
        // console.log(newOrderIva);
        // console.log(newOrderAvgIva);
        // console.log(newOrderTotalIVA);
        // console.log(appliedOrderDiscount);
        // console.log("-----------------------");
        this.setState((prev) => ({
            dataOrdine: {
                ...prev.dataOrdine,
                totale_ordine_iva: newOrderIva.toFixed(2),
                totale_ordine_sconto_chiusura: appliedOrderDiscount.toFixed(2),
                totale_ordine_imponibile: newOrderTotal.toFixed(2),
                totale_ordine: newOrderTotalIVA.toFixed(2),
                totale_ordine_merci: (newOrderTotal - parseFloat(this.state.dataOrdine.spedizione)).toFixed(2),
                totale_ordine_punti_accumulati: newOrderTotalPoints,
                totale_ordine_punti_disponibili: newOrderAvailablePoints
            },
        }));
        return newOrderTotal.toFixed(2);
    }

    returnPointsByPrice(price, options, product) {
        // Viene passato un prezzo, e viene deciso i punti da assegnare per prodotto singolo
        let lastValidPointsObj = null;
        if (!options?.punti) return 0;
        if (options.punti.ex) {
            for (let iex = 5; iex > 0; iex--) {
                if (!options.punti.ex[iex]) continue;
                if (!product['prezzo_x' + iex]) continue;
                let thisPointsObj = options.punti.ex[iex];
                let thisListino = product['prezzo_x' + iex];
                if (price >= thisListino) {
                    lastValidPointsObj = thisPointsObj['base'];
                }
            }
        }
        let prezzoEV3Scontato = parseFloat(product['prezzo_l3']) - (parseFloat(product['prezzo_l3']) * parseFloat(product['max_sconto']) / 100);
        if (price >= prezzoEV3Scontato) {
            lastValidPointsObj = options.punti.ev[3]['scontato'];
        }
        if (price >= parseFloat(product['prezzo_l3'])) {
            lastValidPointsObj = options.punti.ev[3]['base'];
        }

        return lastValidPointsObj;
    }

    returnMinPossiblePrice(product, order) {
        let optionsMinPossiblePrice = 0.00;
        let pointsMinPossiblePrice = 0.00;
        let prezzoBase = parseFloat(product.prezzo_l3 || 0);
        let puntiDisponibili = 0.00;
        let usoPunti = 0.00;
        let qta = product.qta || 1;
        if (order.offerta?.tipo === 'punti') {
            if (product.options?.punti?.min_price) {
                optionsMinPossiblePrice = parseFloat(product.options.punti.min_price);
            }
            if (product.options?.punti?.uso) {
                puntiDisponibili = parseFloat(order.totale_ordine_punti_disponibili);
                usoPunti = parseFloat(product.uso_punti || 0);
            }
            pointsMinPossiblePrice = prezzoBase - ((puntiDisponibili + usoPunti) / qta);
            if (pointsMinPossiblePrice > optionsMinPossiblePrice)
                return pointsMinPossiblePrice;
            else return optionsMinPossiblePrice;
        }
        //In futuro può essere inserito un altro filtro qui
        return 0.00;
    }

    selectCustomer(clienteObj) {
        console.log("Cliente selezionato: ", clienteObj);
        this.setState((prev) => ({
            dataOrdine: {...prev.dataOrdine, cliente: clienteObj},
            searchCustomerDialog: {
                ...prev.searchCustomerDialog,
                open: false,
            },
        }));
    }

    selectOfferta(offertaObj) {
        this.setState(
            (prev) => ({
                dataOrdine: {...prev.dataOrdine, offerta: offertaObj},
                searchOffertaDialog: {
                    ...prev.searchOffertaDialog,
                    open: false,
                },
            }),
            () => this.props.updateParentOfferta(offertaObj.id, offertaObj.titolo)
        );
    }

    emptyOfferta() {
        this.setState(
            (prev) => ({
                dataOrdine: {
                    ...prev.dataOrdine,
                    offerta: {
                        id: null,
                        titolo: "",
                        descrizione: "",
                        note: "",
                        stato: "",
                    },
                },
                searchOffertaDialog: {
                    ...prev.searchOffertaDialog,
                    open: false,
                },
            }),
            this.props.updateParentOfferta(undefined, undefined)
        );
        console.log("Offerta svuotata");
    }

    selectProduct(productObj) {
        // console.log("FROM");
        console.log(productObj);
        // console.log("("+typeof productObj+")");
        // console.log("________");
        let newProductObj = [];
        if (!Array.isArray(productObj))
            newProductObj = [Object.assign({}, productObj)];
        else {
            productObj.forEach((e, i) => {
                const thisNewObj = Object.assign({}, e);
                // console.log("LOOP");
                // console.log(thisNewObj);
                // console.log("________");
                newProductObj.push(thisNewObj);
            });
            newProductObj = newProductObj.flat();
        }

        // console.log("TO");
        // console.log(newProductObj);
        // console.log("________");

        let newDataOrdine = {
            ...this.state.dataOrdine,
        };
        newProductObj.forEach((e, i) => {
            if (e.id == 99999)
                e.v_cod =
                    e.v_cod +
                    "-" +
                    (newDataOrdine.articoli.filter((a) => a.id == 99999).length + 1);
            e.qta = e.imballo || 1;
            e.user_sconto = 0.0;
            e.user_prezzo = e.v_prezzo || 0.0;
            e.optionsLabel = null;
            //e.user_note = e.posizione ? "Pos. " + e.posizione : '';
            newDataOrdine = {
                ...newDataOrdine,
                articoli: [...newDataOrdine.articoli, e],
            };
        });
        this.setState(
            (prev) => ({
                dataOrdine: newDataOrdine,
                searchProductDialog: {
                    ...prev.searchProductDialog,
                    open: false,
                },
                searchProductRestockDialog: {
                    ...prev.searchProductRestockDialog,
                    open: false,
                },
                pagination: {
                    ...prev.pagination,
                    thisPage: Math.ceil(
                        (newDataOrdine.articoli.length + 1) / prev.pagination.pageSize
                    ),
                },
            }),
            () => {
                this.calcOrderTotal();
                this.props.updateParentItemIds(
                    this.state.dataOrdine.articoli.map((a) => a.id)
                );
            }
        );
    }

    removeProduct(productObj) {
        const index = this.state.dataOrdine.articoli.indexOf(productObj);
        this.setState((prev) => {
            prev.dataOrdine.articoli.splice(index, 1);
            return {
                dataOrdine: {
                    ...prev.dataOrdine,
                    articoli: prev.dataOrdine.articoli,
                },
            };
        }, this.calcOrderTotal);
    }

    selectBranch(e, newSede) {
        this.setState((prev) => ({
            dataOrdine: {...prev.dataOrdine, id_sede: newSede},
        }));
    }

    selectShipping(e, newSpedizione) {
        if (!newSpedizione) return;
        this.setState(
            (prev) => ({
                dataOrdine: {...prev.dataOrdine, spedizione: newSpedizione},
            }),
            this.calcOrderTotal
        );
    }

    handleOffertaDialogOpen() {
        this.setState((prev) => ({
            searchOffertaDialog: {
                ...prev.searchOffertaDialog,
                open: true,
            },
        }));
    }

    handleOffertaDialogClose() {
        this.setState((prev) => ({
            searchOffertaDialog: {
                ...prev.searchOffertaDialog,
                open: false,
            },
        }));
    }

    handleProductDialogOpen() {
        this.setState((prev) => ({
            searchProductDialog: {
                ...prev.searchProductDialog,
                open: true,
            },
        }));
    }

    handleProductDialogClose() {
        this.setState((prev) => ({
            searchProductDialog: {
                ...prev.searchProductDialog,
                open: false,
            },
        }));
    }

    handleProductRestockDialogOpen() {
        this.setState((prev) => ({
            searchProductRestockDialog: {
                ...prev.searchProductRestockDialog,
                open: true,
            },
        }));
    }

    handleProductRestockDialogClose() {
        this.setState((prev) => ({
            searchProductRestockDialog: {
                ...prev.searchProductRestockDialog,
                open: false,
            },
        }));
    }

    handleSearchCustomerDialogClose() {
        this.setState((prev) => ({
            searchCustomerDialog: {
                ...prev.searchCustomerDialog,
                open: false,
            },
        }));
    }

    handleSearchCustomerDialogOpen() {
        this.setState((prev) => ({
            searchCustomerDialog: {
                ...prev.searchCustomerDialog,
                open: true,
            },
        }));
    }

    handleOrderNotesChange(e) {
        let newVal = e.target.value || "";
        this.setState((prev) => ({
            dataOrdine: {
                ...prev.dataOrdine,
                note: newVal,
            },
        }));
    }

    handleOrderDiscountValueChange(e, directVal = false) {
        let newVal = directVal ? directVal : e.target.value;
        newVal = newVal.replace(",", ".");
        //Se il valore è valido
        this.setState(
            (prev) => ({
                dataOrdine: {
                    ...prev.dataOrdine,
                    sconto_chiusura: newVal,
                },
            }),
            () => this.calcOrderTotal()
        );
    }

    handleOrderDiscountValueChangeBlur(e, directVal = false) {
        let newVal = directVal ? directVal : e.target.value;
        newVal = parseFloat(newVal.replace(",", "."));
        if (!newVal) newVal = 0;
        //Se il valore è valido
        this.setState(
            (prev) => ({
                dataOrdine: {
                    ...prev.dataOrdine,
                    sconto_chiusura: newVal,
                },
            }),
            () => this.calcOrderTotal()
        );
    }

    handleOrderDiscountModeChange(e, value = "abs") {
        let newVal = value;
        if (newVal)
            this.setState(
                (prev) => ({
                    dataOrdine: {
                        ...prev.dataOrdine,
                        sconto_chiusura_mod: newVal,
                    },
                }),
                () => this.calcOrderTotal()
            );
    }

    markRequiredFields(fields) {
        alert("tolasassa");
    }

    checkRequiredFields() {
        let toChk;
        let reasons = [];
        const maxDiscountDefault = 15;
        const maxPriceDifferenceTollerance = 0.001;
        const listiniToCheck = [ //Controlla solo gli aux, altrimenti non sarebbero possibili gli sconti in caso di assenza
            // "prezzo_l1",
            // "prezzo_l2",
            // "prezzo_l3",
            // "prezzo_l4",
            "prezzo_x1",
            "prezzo_x2",
            "prezzo_x3",
            "prezzo_x4"
        ];
        const listinoBase = 'prezzo_l3'

        toChk = {...this.state.dataOrdine};
        if (toChk === null) reasons.push({
            message: 'Ordine non definito.',
            type: 'order',
            id: null
        });

        if (!toChk.cliente.id) { //È presente il cliente?
            reasons.push({
                message: 'Cliente non selezionato.',
                type: 'customer',
                id: null
            });
        }

        if (toChk.articoli.length < 1) { //C'è almeno un articolo
            reasons.push(
                {
                    message: 'Ordine vuoto.',
                    type: 'order',
                    id: null
                }
            );
        }

        if (toChk.id_sede != "1" && toChk.id_sede != "2") { //È selezionata la sede
            reasons.push(
                {
                    message: 'Sede non selezionata.',
                    type: 'branch',
                    id: null
                }
            );
        }

        for (const art of toChk.articoli) { //I prezzi degli articoli sono validi?
            let thisUserPrice = parseFloat(art.user_prezzo);
            let thisBasePrice = parseFloat(art[listinoBase]);
            let thisMaxDiscount = parseFloat(art.max_sconto > 0 ? art.max_sconto : maxDiscountDefault);
            // if (thisMaxDiscount == 100) thisMaxDiscount = maxDiscountDefault; //Serve per le offerte che hanno max discount = 100%
            let prices = listiniToCheck.map(lis => parseFloat(art[lis])).filter(p => p > 0);
            let lowest = prices.sort((a, b) => (a - b))[0];
            // console.log('user', thisUserPrice);
            // console.log('base', thisBasePrice);
            // console.log('lowest', lowest);
            // console.log('prices', prices);
            if (lowest) {
                if (thisUserPrice < lowest) {
                    reasons.push(
                        {
                            message: 'Prezzo più basso dell\'ultimo AUX sull\'articolo #' + (this.state.dataOrdine.articoli.findIndex(ae => ae.id === art.id) + 1),
                            type: 'product.price',
                            id: art.id
                        }
                    );
                }
            } else {
                if (thisBasePrice > 0) {
                    let roundedUserPrice = parseFloat((parseFloat(thisUserPrice) + maxPriceDifferenceTollerance).toFixed(2));
                    let roundedBasePrice = parseFloat(parseFloat(thisBasePrice).toFixed(2));
                    let roundedLowestPossiblePrice =
                        parseFloat(
                            parseFloat(
                                (roundedBasePrice - (roundedBasePrice / 100) * thisMaxDiscount) - maxPriceDifferenceTollerance
                            ).toFixed(2)
                        );
                    if (
                        roundedUserPrice < roundedLowestPossiblePrice) reasons.push(
                        {
                            message: 'Prezzo troppo basso per articolo #' + (this.state.dataOrdine.articoli.findIndex(ae => ae.id === art.id) + 1),
                            type: 'product.price',
                            id: art.id
                        }
                    );
                }
            }
        }
        // console.log(reasons);
        // console.log(reasons.length);
        // console.log(reasons.length === 0);
        let thisRTS = reasons.length === 0;
        // console.log("Required fields", reasons, thisRTS);
        if (this.state.readyToSend != thisRTS
            || JSON.stringify(this.state.notReadyToSendReasons) != JSON.stringify(reasons)) {
            this.setState({
                readyToSend: thisRTS,
                notReadyToSendReasons: reasons
            }, () => console.log(this.state.notReadyToSendReasons));
        }
    }

    sendOrder() {
        new Promise((resolve, reject) => {
            this.setState({fetching: true});
            let data = {...this.state.dataOrdine};
            try {
                //Elimina trash
                delete data.offerta.optionsLabel;
                delete data.offerta.match;
                delete data.offerta.score;
                delete data.offerta.terms;
                delete data.cliente.optionsLabel;
                delete data.cliente.match;
                delete data.cliente.score;
                delete data.cliente.terms;
                for (let i in data.articoli) {
                    delete data.articoli[i].optionsLabel;
                    delete data.articoli[i].match;
                    delete data.articoli[i].score;
                    delete data.articoli[i].terms;
                }
            } catch (error) {
                console.log(error);
            }

            data.costi_spedizione = parseFloat(data.spedizione) || "0";
            delete data.spedizione;
            //
            const toPost = JSON.stringify({
                ordine: data,
                token: this.context.user.token,
                action: "create",
            });
            console.log(toPost);
            axios
                .post(
                    Constants.paths.ajaxBasePath + "ordini-agenti/invia-ordine/",
                    toPost
                )
                .then((res) => {
                    if (res.status >= 200 && res.status < 300) {
                        this.newOrdine(false, true);
                        enqueueSnackbar("Ordine inviato", {
                            variant: "success",
                        });
                        resolve();
                    } else {
                        reject();
                        throw new Error(res);
                    }
                })
                .catch((e) => {
                    reject();
                    alert("Errore nel form:" + e.response.status);
                    console.log(e);
                })
                .finally((_) => {
                    this.setState({fetching: false});
                });
        });
    }

    previewOrder() {
        new Promise((resolve, reject) => {
            let data = {...this.state.dataOrdine};
            try {
                //Elimina trash
                delete data.offerta.optionsLabel;
                delete data.offerta.match;
                delete data.offerta.score;
                delete data.offerta.terms;
                delete data.cliente.optionsLabel;
                delete data.cliente.match;
                delete data.cliente.score;
                delete data.cliente.terms;
                for (let i in data.articoli) {
                    delete data.articoli[i].optionsLabel;
                    delete data.articoli[i].match;
                    delete data.articoli[i].score;
                    delete data.articoli[i].terms;
                }
            } catch (error) {
                console.log(error);
            }

            data.costi_spedizione = parseFloat(data.spedizione) || "0";
            //data.sendToCustomer = true;
            delete data.spedizione;
            //
            const toPost = JSON.stringify({
                ordine: data,
                token: this.context.user.token,
            });
            console.log(toPost);
            axios
                .post(
                    Constants.paths.ajaxBasePath + "ordini-agenti/anteprima/",
                    toPost,
                    {responseType: "blob"}
                )
                .then((res) => {
                    if (res.status >= 200 && res.status < 300) {
                        //console.log(res.data);
                        download(
                            res.data,
                            "Anteprima del " + moment().format("DD-MM-YYYY_hh:mm:ss"),
                            data.type
                        );
                        console.log("TYPE:" + data.type);
                        resolve();
                    } else {
                        reject();
                        throw new Error(res);
                    }
                })
                .catch((e) => {
                    reject();
                    alert("Errore nel form:" + e.response.status);
                    console.log(e.response.data.text());
                });
        });
    }

    onFetchingChange(isFetching) {
        this.setState({fetching: isFetching});
    }

    render() {
        // const {enqueueSnackbar} = this.props;
        return (
            <>
                {
                    (this.props.activeTab === 0 &&
                        <Grid container justifyContent="center" sx={{
                            maxWidth:
                                this.context.matches.xl
                                    ? "80%"
                                    : this.context.matches.lg
                                        ? "90%"
                                        : "100%",
                            left: 0,
                            right: 0,
                            marginX: 'auto'
                        }}>
                            <Grid item justifyContent={'center'} xs={12}>
                                <Card
                                    style={{
                                        textAlign: "center",
                                        margin: !this.context.matches.sm
                                            ? this.context.theme.spacing(2) //XS
                                            : !this.context.matches.md
                                                ? this.context.theme.spacing(3) //SM
                                                : this.context.theme.spacing(4), //MD+
                                        padding: !this.context.matches.sm
                                            ? this.context.theme.spacing(1) //XS
                                            : !this.context.matches.md
                                                ? this.context.theme.spacing(2) //SM
                                                : this.context.theme.spacing(3), //MD+
                                    }}
                                    elevation={10}
                                    variant={
                                        this.context.matches.md ? "elevation" : "outlined"
                                    }
                                >
                                    <Chip
                                        label={
                                            this.isOrderEmpty()
                                                ? "Creato ora"
                                                : moment().diff(
                                                    this.state.dataOrdine.dataCreazione,
                                                    "days"
                                                ) < 7
                                                    ? "Creato " +
                                                    moment(
                                                        this.state.dataOrdine.dataCreazione
                                                    ).fromNow()
                                                    : "Creato il " +
                                                    moment(
                                                        this.state.dataOrdine.dataCreazione
                                                    ).format("L")
                                        }
                                    />
                                    <br/>
                                    <br/>
                                    <Typography variant="h4">Ordine</Typography>
                                    <br/>
                                    <Typography style={{fontSize: "70%", opacity: "0.4"}}>
                                        {this.state.dataOrdine.id}
                                    </Typography>
                                    <CardContent>
                                        <Grid spacing={2} container justifyContent="center">
                                            <Grid item xs={12} md={4}>
                                                <Typography variant="overline">Offerta</Typography>
                                                <br/>
                                                <Button
                                                    //! DISATTIVATO PER EVITARE DISAGI
                                                    //disabled
                                                    //! DISATTIVATO PER EVITARE DISAGI
                                                    fullWidth
                                                    style={{
                                                        padding: 0,
                                                        height: Constants.sizes.bigButtonHeight,
                                                    }}
                                                    id="offerta"
                                                    // startIcon={
                                                    //   <FontAwesomeIcon icon={faUser} size="sm" />
                                                    // }
                                                    variant="contained"
                                                    size="large"
                                                    // color={
                                                    //   this.state.dataOrdine.cliente.ragione_sociale
                                                    //     ? "textPrimary"
                                                    //     : "auto"
                                                    // }
                                                    onClick={
                                                        this.state.dataOrdine.articoli &&
                                                        this.state.dataOrdine.articoli.length > 0
                                                            ? () =>
                                                                this.props.onClearOrder(
                                                                    undefined,
                                                                    "Cambiare offerta?",
                                                                    "Così facendo l'intero ordine verrà annullato",
                                                                    () => {
                                                                        this.calcOrderTotal();
                                                                        this.newOrdine();
                                                                        this.handleOffertaDialogOpen();
                                                                    },
                                                                    this.saveDraft
                                                                )
                                                            : this.handleOffertaDialogOpen
                                                    }
                                                >
                                                    {this.state.dataOrdine.offerta.id ||
                                                        "Ordine generico"}
                                                </Button>
                                                {/* <Typography variant="caption">
                              Disattivato per ristrutturazione DB
                            </Typography> */}
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <Typography variant="overline">Sede</Typography>
                                                <br/>
                                                <ToggleButtonGroup
                                                    value={this.state.dataOrdine.id_sede}
                                                    exclusive
                                                    onChange={this.selectBranch}
                                                    style={{width: "100%"}}
                                                >
                                                    <ToggleButton
                                                        style={{
                                                            width: "100%",
                                                            height: Constants.sizes.bigButtonHeight,
                                                            padding: 0,
                                                        }}
                                                        value="1"
                                                    >
                                                        Feroleto A.
                                                    </ToggleButton>

                                                    <ToggleButton
                                                        style={{
                                                            width: "100%",
                                                            height: Constants.sizes.bigButtonHeight,
                                                            padding: 0,
                                                        }}
                                                        value="2"
                                                    >
                                                        Rende
                                                    </ToggleButton>
                                                </ToggleButtonGroup>
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <Typography variant="overline">Cliente</Typography>
                                                <br/>
                                                <Button
                                                    fullWidth
                                                    style={{
                                                        padding: 0,
                                                        height: Constants.sizes.bigButtonHeight,
                                                    }}
                                                    id="cliente-offerta"
                                                    // startIcon={
                                                    //   <FontAwesomeIcon icon={faUser} size="sm" />
                                                    // }
                                                    variant="contained"
                                                    size="large"
                                                    // color={
                                                    //   this.state.dataOrdine.cliente.ragione_sociale
                                                    //     ? "primary"
                                                    //     : "auto"
                                                    // }
                                                    onClick={this.handleSearchCustomerDialogOpen}
                                                >
                                                    {this.state.dataOrdine.cliente.ragione_sociale ||
                                                        "Seleziona cliente"}
                                                </Button>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                md={5}
                                                style={{textAlign: "center"}}
                                            >
                                                <Typography variant="overline">
                                                    Costi spedizione
                                                </Typography>
                                                <br/>
                                                <ToggleButtonGroup
                                                    value={this.state.dataOrdine.spedizione}
                                                    exclusive
                                                    onChange={this.selectShipping}
                                                    style={{width: "100%"}}
                                                >
                                                    <ToggleButton
                                                        style={{
                                                            width: "100%",
                                                        }}
                                                        value="0"
                                                    >
                                                        {this.context.matches.md ? "Gratis" : "Grat."}
                                                    </ToggleButton>

                                                    <ToggleButton
                                                        style={{
                                                            width: "100%",
                                                        }}
                                                        value="3.5"
                                                    >
                                                        3,50 €
                                                    </ToggleButton>
                                                    <ToggleButton
                                                        style={{
                                                            width: "100%",
                                                        }}
                                                        value="3.9"
                                                    >
                                                        3,90 €
                                                    </ToggleButton>
                                                    <ToggleButton
                                                        style={{
                                                            width: "100%",
                                                        }}
                                                        value="4.9"
                                                    >
                                                        4,90 €
                                                    </ToggleButton>
                                                </ToggleButtonGroup>
                                            </Grid>
                                            <Grid item xs={12} md={7}>
                                                <TextField
                                                    fullWidth
                                                    label="Note"
                                                    multiline
                                                    rows="3"
                                                    value={this.state.dataOrdine.note}
                                                    onChange={this.handleOrderNotesChange}
                                                    variant="outlined"
                                                />
                                            </Grid>
                                        </Grid>
                                        {
                                            this.state.dataOrdine?.offerta?.tipo !== 'semplice'
                                                ?
                                                <>
                                                    <br/>
                                                    <Divider/>
                                                    <br/>
                                                    <Typography variant="overline"
                                                                color={'text.secondary'}>
                                                        Tipo
                                                        offerta: <strong>{this.state.dataOrdine.offerta.tipo}</strong>
                                                    </Typography>
                                                </>
                                                : null
                                        }
                                        {
                                            this.state.dataOrdine?.offerta?.note?.length > 1 ? (
                                                <>
                                                    <br/>
                                                    <Divider/>
                                                    <br/>
                                                    <Typography variant="overline">
                                                        Note per questa offerta
                                                    </Typography>
                                                    <Typography
                                                        className="offer-notes"
                                                        variant="body2"
                                                        align="left"
                                                        style={{whiteSpace: "pre-line"}}
                                                    >{this.state.dataOrdine.offerta.note}
                                                        {/*{renderHTML(OrdinePanel.inlineOfferCss)}*/}
                                                        {/*{renderHTML(this.state.dataOrdine.offerta.note)}*/}
                                                    </Typography>
                                                </>
                                            ) : null}
                                    </CardContent>
                                </Card>
                                {/*PRODOTTI*/}
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant={"h2"} align={"center"}
                                            style={{marginBottom: this.context.theme.spacing(2)}}>
                                    Articoli
                                </Typography>
                                {this.state.dataOrdine.articoli
                                    .slice(
                                        (this.state.pagination.thisPage - 1) *
                                        this.state.pagination.pageSize,
                                        this.state.pagination.thisPage *
                                        this.state.pagination.pageSize
                                    ).map((a) => {
                                            // const theseErrors = this.state.notReadyToSendReasons.filter(e => e.id === a.id);
                                            // console.log(theseErrors);
                                            const theseErrors = this.state.notReadyToSendReasons
                                                ? this.state.notReadyToSendReasons.filter(e => e.id === a.id && e.type?.includes('product'))
                                                : [];
                                            return (<SchedaProdotto
                                                index={this.state.dataOrdine.articoli.findIndex(ae => ae.id === a.id) + 1}
                                                key={a.v_cod}
                                                offer={this.state.dataOrdine.offerta}
                                                product={a}
                                                customer={this.state.dataOrdine.cliente}
                                                removeThisProduct={() => {
                                                    this.removeProduct(a);
                                                }}
                                                isPointsOffer={this.state.dataOrdine.offerta?.tipo === 'punti'}
                                                usesPoints={
                                                    this.state.dataOrdine.offerta?.tipo === 'punti'
                                                    && a.options?.punti?.uso
                                                }
                                                points={this.state.dataOrdine.offerta?.tipo === 'punti'
                                                    ? this.returnPointsByPrice(a.user_prezzo, a.options, a)
                                                    : 0}
                                                availablePoints={
                                                    this.state.dataOrdine.offerta?.tipo === 'punti'
                                                        ? this.state.dataOrdine.totale_ordine_punti_disponibili
                                                        : 0
                                                }
                                                totalPoints={
                                                    this.state.dataOrdine.offerta?.tipo === 'punti'
                                                        ? this.state.dataOrdine.totale_ordine_punti_accumulati
                                                        : 0
                                                }
                                                minPossibilePrice={
                                                    this.returnMinPossiblePrice(a, this.state.dataOrdine)
                                                }
                                                handleProductChange={this.handleProductChange.bind(this)}
                                                handleProductChangeBlur={this.handleProductChangeBlur.bind(this)}
                                                hasQuantityError={false}
                                                hasDiscountError={theseErrors.some(e => e.type.includes('discount'))}
                                                hasPriceError={theseErrors.some(e => e.type.includes('price'))}
                                                errorMessages={theseErrors.map(e => e.message)}
                                            />)
                                        }
                                    )
                                }
                                {this.state.dataOrdine.articoli.length >
                                    this.state.pagination.pageSize && (
                                        <Grid item xs={12}>
                                            <Pagination
                                                style={{display: "flex", justifyContent: "center"}}
                                                count={Math.ceil(
                                                    this.state.dataOrdine.articoli.length /
                                                    this.state.pagination.pageSize
                                                )}
                                                page={this.state.pagination.thisPage}
                                                onChange={this.handlePageChange}
                                            />
                                        </Grid>
                                    )}
                                <Grid item container xs={12}>
                                    <ButtonGroup
                                        fullWidth
                                        disableElevation
                                        style={{
                                            margin: !this.context.matches.sm
                                                ? this.context.theme.spacing(1) //XS
                                                : !this.context.matches.md
                                                    ? this.context.theme.spacing(2) //SM
                                                    : this.context.theme.spacing(4), //MD+
                                        }}
                                    >
                                        <Grid item xs={8}>
                                            <Button
                                                disableElevation
                                                size="large"
                                                variant="contained"
                                                fullWidth
                                                style={{
                                                    borderTopRightRadius: 0,
                                                    borderBottomRightRadius: 0,
                                                }}
                                                onClick={this.handleProductDialogOpen}
                                                startIcon={
                                                    <Box color="success.main">
                                                        <FontAwesomeIcon icon={faPlus}/>
                                                    </Box>
                                                }>
                                                Aggiungi
                                            </Button>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Button
                                                disabled={!this.state.dataOrdine.cliente.id}
                                                disableElevation
                                                size="large"
                                                variant="contained"
                                                fullWidth
                                                onClick={this.handleProductRestockDialogOpen}
                                                style={{
                                                    borderTopLeftRadius: 0,
                                                    borderBottomLeftRadius: 0,
                                                    borderLeft: "solid 1px",
                                                    borderColor:
                                                        this.context.theme.palette.grey[400],
                                                }}
                                                startIcon={
                                                    <Box color="warning.main">
                                                        <FontAwesomeIcon icon={faRedoAlt}/>
                                                    </Box>
                                                }>
                                                Riordina
                                            </Button>
                                        </Grid>
                                    </ButtonGroup>
                                </Grid>
                                <Grid
                                    container
                                    item
                                    alignItems="center"
                                    justifyContent="space-around"
                                >
                                    <Grid item>
                                        <Card
                                            elevation={10}
                                            style={{
                                                marginTop: this.context.theme.spacing(4),
                                                marginBottom: this.context.theme.spacing(4),
                                                maxWidth: "24em",
                                                marginRight: "auto",
                                                marginLeft: "auto",
                                            }}
                                        >
                                            <CardContent>
                                                <Typography variant="h6">
                                                    Sconto chiusura
                                                </Typography>
                                                <Typography variant="caption">
                                                    Valore merce:{" "}
                                                    <strong>
                                                        {(
                                                            parseFloat(
                                                                this.state.dataOrdine.totale_ordine_merci
                                                            ) +
                                                            parseFloat(
                                                                this.state.dataOrdine
                                                                    .totale_ordine_sconto_chiusura || 0
                                                            )
                                                        )
                                                            .toFixed(2)
                                                            .replace(".", ",")}{" "}
                                                        <FontAwesomeIcon icon={faEuroSign}/>
                                                    </strong>
                                                </Typography>
                                                <br/>
                                                <br/>
                                                <Grid container justifyContent="space-between" spacing={2}>
                                                    <Grid item xs={8}>
                                                        <ToggleButtonGroup
                                                            orientation="vertical"
                                                            size="small"
                                                            value={
                                                                this.state.dataOrdine.sconto_chiusura_mod ||
                                                                "abs"
                                                            }
                                                            exclusive
                                                            onChange={this.handleOrderDiscountModeChange}
                                                            style={{width: "100%"}}
                                                        >
                                                            <ToggleButton
                                                                size="small"
                                                                style={{
                                                                    width: "100%",
                                                                    height: Constants.sizes.bigButtonHeight,
                                                                    padding: 0,
                                                                }}
                                                                value="abs"
                                                            >
                                                                Assoluto
                                                            </ToggleButton>

                                                            <ToggleButton
                                                                size="small"
                                                                style={{
                                                                    width: "100%",
                                                                    height: Constants.sizes.bigButtonHeight,
                                                                    padding: 0,
                                                                }}
                                                                value="per"
                                                            >
                                                                Percentuale
                                                            </ToggleButton>
                                                        </ToggleButtonGroup>
                                                    </Grid>
                                                    <Grid item container xs={4}>
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                variant="outlined"
                                                                className="input-align-right"
                                                                type="number"
                                                                placeholder="0,00"
                                                                //variant="outlined"
                                                                // style={{ maxWidth: "7rem" }}
                                                                value={
                                                                    this.state.dataOrdine.sconto_chiusura
                                                                }
                                                                onChange={
                                                                    this.handleOrderDiscountValueChange
                                                                }
                                                                InputProps={{
                                                                    startAdornment: (
                                                                        <InputAdornment position="start">
                                                                            <FontAwesomeIcon
                                                                                icon={
                                                                                    this.state.dataOrdine
                                                                                        .sconto_chiusura_mod
                                                                                        ? this.state.dataOrdine
                                                                                            .sconto_chiusura_mod ==
                                                                                        "abs"
                                                                                            ? faEuroSign
                                                                                            : faPercent
                                                                                        : faEuroSign
                                                                                }
                                                                            />
                                                                        </InputAdornment>
                                                                    ),
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid xs={12} sstyle={{alignSelf: "center"}}>
                                                            <Typography align="right">
                                                                -{" "}
                                                                {this.state.dataOrdine
                                                                    .sconto_chiusura_mod === "per"
                                                                    ? this.state.dataOrdine
                                                                        .totale_ordine_sconto_chiusura
                                                                    : parseFloat(
                                                                        (parseFloat(
                                                                                this.state.dataOrdine
                                                                                    .sconto_chiusura
                                                                            ) /
                                                                            (parseFloat(
                                                                                    this.state.dataOrdine
                                                                                        .totale_ordine_merci
                                                                                ) +
                                                                                parseFloat(
                                                                                    this.state.dataOrdine
                                                                                        .totale_ordine_sconto_chiusura
                                                                                ))) *
                                                                        100 || 0
                                                                    ).toFixed(2)}{" "}
                                                                <FontAwesomeIcon
                                                                    size="sm"
                                                                    icon={
                                                                        this.state.dataOrdine
                                                                            .sconto_chiusura_mod === "per"
                                                                            ? faEuroSign
                                                                            : faPercent
                                                                    }
                                                                />
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography variant="caption">
                                                            Calcolato sull'imponibile delle merci
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    <Grid item>
                                        <Card
                                            variant="outlined"
                                            style={{
                                                marginTop: this.context.theme.spacing(4),
                                                marginBottom: this.context.theme.spacing(4),
                                                maxWidth: "16em",
                                                marginRight: "auto",
                                                marginLeft: "auto",
                                                textAlign: 'right'
                                            }}
                                        >
                                            <CardContent>
                                                <Grid container>
                                                    <Grid
                                                        container
                                                        item
                                                        xs={12}
                                                        justifyContent="space-between"
                                                    >
                                                        <Grid item xs={5}>
                                                            <Typography
                                                                variant="overline"
                                                                style={{
                                                                    color:
                                                                    this.context.theme.palette.text
                                                                        .disabled,
                                                                }}
                                                            >
                                                                Imponibile
                                                            </Typography>
                                                            <Typography
                                                                variant="h6"
                                                                style={{
                                                                    color:
                                                                    this.context.theme.palette.text
                                                                        .disabled,
                                                                }}
                                                            >
                                                                {parseFloat(
                                                                    this.state.dataOrdine
                                                                        .totale_ordine_imponibile
                                                                )
                                                                    .toFixed(2)
                                                                    .replace(".", ",") + "€"}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={"auto"}>
                                                            <Divider orientation="vertical"/>
                                                        </Grid>

                                                        <Grid item xs={5}>
                                                            <Typography
                                                                variant="overline"
                                                                style={{
                                                                    color:
                                                                    this.context.theme.palette.text
                                                                        .disabled,
                                                                }}
                                                            >
                                                                IVA
                                                            </Typography>
                                                            <Typography
                                                                variant="h6"
                                                                style={{
                                                                    color:
                                                                    this.context.theme.palette.text
                                                                        .disabled,
                                                                }}
                                                            >
                                                                {parseFloat(
                                                                    this.state.dataOrdine.totale_ordine_iva
                                                                )
                                                                    .toFixed(2)
                                                                    .replace(".", ",") + "€"}
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={12}>
                                                            <br/>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        container
                                                        xs={12}
                                                        justifyContent="space-between"
                                                    >
                                                        <Grid item xs={12}>
                                                            <Typography variant="overline">
                                                                Totale
                                                            </Typography>
                                                            <Typography variant="h4" sx={{fontWeight: "bold"}}>
                                                                {parseFloat(
                                                                    this.state.dataOrdine.totale_ordine
                                                                )
                                                                    .toFixed(2)
                                                                    .replace(".", ",") + "€"}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12}
                                                              sx={{padding: this.context.theme.spacing(1)}}>
                                                            <Divider/>
                                                        </Grid>
                                                        <Grid item xs={12}
                                                              sx={{textAlign: 'center', width: '100%'}}>
                                                            <Typography color={'text.secondary'}
                                                                        variant={'caption'} sx={{
                                                                textAlign: 'center',
                                                                width: '100%'
                                                            }}>
                                                                {this.state.dataOrdine.cliente.id_cond_pagamento ? `${this.state.dataOrdine.cliente.nome_cond_pagamento}` : ""}
                                                            </Typography>
                                                        </Grid>
                                                        {this.state.dataOrdine.offerta?.tipo === 'punti' &&
                                                            <>
                                                                <Grid item xs={12}
                                                                      sx={{padding: this.context.theme.spacing(1)}}>
                                                                    <Divider/>
                                                                </Grid>
                                                                <Grid item xs={12}
                                                                      sx={{textAlign: 'right', width: '100%'}}>
                                                                    <Typography
                                                                        color={'text.secondary'}
                                                                        variant={'overline'} sx={{
                                                                        textAlign: 'right',
                                                                        width: '100%'
                                                                    }}>
                                                                        Punti accumulati
                                                                    </Typography>
                                                                    <Typography variant="h6" sx={{
                                                                        fontWeight: "bold",
                                                                        color: this.context.theme.palette.success.main
                                                                    }}>
                                                                        {parseFloat(
                                                                            this.state.dataOrdine.totale_ordine_punti_accumulati
                                                                        )
                                                                            .toFixed(2)
                                                                            .replace(".", ",") + "€"}
                                                                    </Typography>
                                                                </Grid>
                                                            </>}
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                style={{
                                    // margin: !this.context.matches.sm
                                    //   ? this.context.theme.spacing(1) //XS
                                    //   : !this.context.matches.md
                                    //   ? this.context.theme.spacing(1) //SM
                                    //   : this.context.theme.spacing(4), //MD+
                                    margintTop: "0",
                                }}
                            >
                                <Grid
                                    container
                                    spacing={2}
                                    justifyContent="center"
                                    style={{
                                        padding: !this.context.matches.sm
                                            ? this.context.theme.spacing(1) //XS
                                            : !this.context.matches.md
                                                ? this.context.theme.spacing(2) //SM
                                                : this.context.theme.spacing(4), //MD+
                                        paddingTop: 0,
                                    }}
                                >
                                    <Grid item xs={12} md={3}>
                                        <Button
                                            fullWidth
                                            onClick={() => {
                                                this.calcOrderTotal();
                                                this.props.onClearOrder(
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    this.newOrdine,
                                                    this.saveDraft
                                                );
                                            }}
                                            size="large"
                                            variant="outlined"
                                        >
                                            Annulla
                                        </Button>
                                    </Grid>

                                    <Grid item xs={12} md={3}>
                                        <Button
                                            disabled={this.isOrderEmpty()}
                                            fullWidth
                                            size="large"
                                            variant="outlined"
                                            onClick={this.previewOrder}>
                                            Anteprima PDF
                                        </Button>
                                    </Grid>

                                    <Grid item xs={12} md={3}>
                                        <Button
                                            disabled={this.isOrderEmpty()}
                                            fullWidth
                                            size="large"
                                            variant="outlined"
                                            color="primary"
                                            onClick={this.saveDraft}
                                        >
                                            Salva bozza
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <Tooltip
                                            title={
                                                !this.state.readyToSend
                                                    ? "Compila tutti i campi richiesti"
                                                    : ""
                                            }
                                            arrow={true}
                                            // disableFocusListener={!this.state.readyToSend}
                                            // disableHoverListener={!this.state.readyToSend}
                                            // disableTouchListener={!this.state.readyToSend}
                                        >
                                                <span style={{display: "flex", height: "100%"}}>
                                                  <Button
                                                      fullWidth
                                                      disabled={!this.state.readyToSend}
                                                      size="large"
                                                      variant="contained"
                                                      color="primary"
                                                      onClick={
                                                          this.state.readyToSend
                                                              ? () => {
                                                                  this.calcOrderTotal();
                                                                  this.handleConfirmOrderDialogOpen();
                                                              }
                                                              : this.markRequiredFields
                                                      }
                                                      endIcon={<FontAwesomeIcon icon={faPaperPlane}/>}
                                                  >
                                                    Invia
                                                  </Button>
                                                </span>
                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs={12} sx={{textAlign: "center"}}>
                                        {this.state.readyToSend
                                            ? (<Typography variant="caption">
                                                Ti verrà chiesta conferma prima dell'invio.
                                            </Typography>)
                                            : <>{this.state.notReadyToSendReasons.map(r =>
                                                <Typography color={'error'}>
                                                    {r.message}
                                                </Typography>)}
                                            </>
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                            {/*</Grid>*/}
                        </Grid>)}
                {/* DIALOG */
                }
                <CustomerSelector
                    open={this.state.searchCustomerDialog.open}
                    onClose={this.handleSearchCustomerDialogClose}
                    onOpen={this.handleSearchCustomerDialogOpen}
                    onSelect={this.selectCustomer}
                />
                <OffertaSelector
                    open={this.state.searchOffertaDialog.open}
                    onClose={this.handleOffertaDialogClose}
                    onOpen={this.handleOffertaDialogOpen}
                    onSelect={this.selectOfferta}
                    onEmpty={this.emptyOfferta}
                    emptyText={"Default"}
                    emptyButtonVariant="contained"
                    disableLoadingBackdrop
                    maxResults={1000}
                />
                {
                    this.state.dataOrdine.offerta.id != null &&
                    this.state.dataOrdine.offerta.id != "DEFAULT" ? (
                        <Offerta_ProductSelector
                            showInventory
                            showProdImg
                            fullScreen
                            multiple
                            key={this.state.dataOrdine.offerta.id}
                            open={this.state.searchProductDialog.open}
                            onClose={this.handleProductDialogClose}
                            onOpen={this.handleProductDialogOpen}
                            onSelect={this.selectProduct}
                            idOfferta={this.state.dataOrdine.offerta.id}
                            onFetchingChange={this.onFetchingChange}
                            selectedIds={
                                (this.state.dataOrdine.articoli &&
                                    this.state.dataOrdine.articoli.map((a) => a.id)) ||
                                []
                            }
                        />
                    ) : (
                        <ProductSelector
                            showInventory
                            showProdImg={!this.context.device.lowSpec}
                            fullScreen
                            multiple
                            open={this.state.searchProductDialog.open}
                            onClose={this.handleProductDialogClose}
                            onOpen={this.handleProductDialogOpen}
                            onSelect={this.selectProduct}
                            onFetchingChange={this.onFetchingChange}
                            filter={"vendita"}
                            selectedIds={
                                (this.state.dataOrdine.articoli &&
                                    this.state.dataOrdine.articoli.map((a) => a.id)) ||
                                []
                            }
                        />
                    )
                }
                <Restock_ProductSelector
                    showInventory
                    showProdImg={!this.context.device.lowSpec}
                    fullScreen
                    showTitle
                    multiple
                    returnHistory={false}
                    id_cliente={this.state.dataOrdine.cliente.id}
                    open={this.state.searchProductRestockDialog.open}
                    onClose={this.handleProductRestockDialogClose}
                    onOpen={this.handleProductRestockDialogOpen}
                    onSelect={this.selectProduct}
                    onFetchingChange={this.onFetchingChange}
                    filter={"vendita"}
                    selectedIds={
                        (this.state.dataOrdine.articoli &&
                            this.state.dataOrdine.articoli.map((a) => a.id)) ||
                        []
                    }
                />
                <Dialog
                    scroll="body"
                    fullWidth
                    maxWidth="sm"
                    TransitionComponent={SlideUpTransition}
                    onClose={() =>
                        this.setState((prev) => ({
                            confirmOrderDialog: {
                                ...prev.confirmOrderDialog,
                                open: false,
                            },
                        }))
                    }
                    open={this.state.confirmOrderDialog.open}
                >
                    <DialogTitle>Inviare l'ordine?</DialogTitle>
                    <DialogContent
                        style={{
                            textAlign: "center",
                            overflowY: "hidden"
                        }}
                    >
                        <Grid
                            container
                            style={{
                                textAlign: "center",
                                fontSize: 80,
                                marginBottom: this.context.theme.spacing(2),
                            }}
                        >
                            <Grid item xs={4}>
                                <FontAwesomeIcon icon={faClipboard}/>
                            </Grid>
                            <Grid item xs={4}>
                                <motion.div
                                    animate={{x: [-100, 0, 100], opacity: [0, 1, 0]}}
                                    transition={{
                                        ease: "linear",
                                        duration: 4,
                                        loop: "Infinity",
                                    }}
                                >
                                    <FontAwesomeIcon icon={faCaretRight}/>
                                </motion.div>
                            </Grid>
                            <Grid item xs={4}>
                                <FontAwesomeIcon icon={faCloud}/>
                            </Grid>
                        </Grid>
                        <Divider/>
                        <Typography
                            style={{width: "100%"}}
                            variant="overline"
                            align="center"
                        >
                            Riassunto
                        </Typography>
                        <Grid item container spacing={2}>
                            <Grid item xs={4} style={{textAlign: "right"}}>
                                <Typography style={{fontWeight: "bold"}}>
                                    Agente:
                                </Typography>
                                <Typography style={{fontWeight: "bold"}}>
                                    Sede:
                                </Typography>
                                <Typography style={{fontWeight: "bold"}}>
                                    Cliente:
                                </Typography>
                                <Typography style={{fontWeight: "bold"}}>
                                    Costi spedizione:
                                </Typography>
                                <Typography style={{fontWeight: "bold"}}>
                                    Offerta:
                                </Typography>
                                <Typography style={{fontWeight: "bold"}}>
                                    Totale ordine:
                                </Typography>
                            </Grid>
                            <Grid item xs={8} style={{textAlign: "left"}}>
                                <Typography>
                                    {this.context.user.firstName +
                                        " " +
                                        this.context.user.lastName}
                                </Typography>
                                <Typography>
                                    {this.state.dataOrdine.id_sede == 1
                                        ? "Feroleto Antico"
                                        : "Rende"}
                                </Typography>
                                <Typography>
                                    {this.state.dataOrdine.cliente.ragione_sociale +
                                        " (" +
                                        this.state.dataOrdine.cliente.id +
                                        ")"}
                                </Typography>
                                <Typography>
                                    {parseFloat(this.state.dataOrdine.spedizione)
                                        ? parseFloat(this.state.dataOrdine.spedizione)
                                        .toFixed(2)
                                        .replace(".", ",") + " €"
                                        : "No"}
                                </Typography>
                                <Typography>
                                    {this.state.dataOrdine.offerta &&
                                    this.state.dataOrdine.offerta.id != null &&
                                    this.state.dataOrdine.offerta.id != "DEFAULT"
                                        ? this.state.dataOrdine.offerta.titolo +
                                        " (" +
                                        this.state.dataOrdine.offerta.id +
                                        ")"
                                        : "No"}
                                </Typography>
                                <Typography>
                                    {this.state.dataOrdine.totale_ordine + "€ "}
                                    <Typography variant="caption">IVA incl.</Typography>
                                </Typography>
                            </Grid>
                        </Grid>
                        <br/>
                        <Divider/>
                        <FormControl
                            disabled={!this.state.dataOrdine.cliente.email}
                            style={{width: "100%"}}
                            component="fieldset"
                        >
                            <FormGroup>
                                <FormControlLabel
                                    style={{alignSelf: "end"}}
                                    control={<Switch color="primary"/>}
                                    onChange={(e) => {
                                        this.setState((prev) => ({
                                            dataOrdine: {
                                                ...prev.dataOrdine,
                                                sendToCustomer: e.target.checked,
                                            },
                                        }));
                                    }}
                                    label={
                                        "Inviare al cliente? (" +
                                        (this.state.dataOrdine.cliente.email || "N/D") +
                                        ")"
                                    }
                                    labelPlacement="start"
                                    checked={this.state.dataOrdine.sendToCustomer}
                                />
                            </FormGroup>
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            color={"error"}
                            onClick={() => {
                                this.calcOrderTotal();
                                this.setState((prev) => ({
                                    confirmOrderDialog: {
                                        ...prev.confirmOrderDialog,
                                        open: false,
                                    },
                                }));
                            }}
                            // sx={{color: this.context.theme.palette.error.main}}
                            // autoFocus
                        >
                            Annulla
                        </Button>
                        <Button
                            disabled={this.state.fetching}
                            variant="contained"
                            onClick={() => {
                                this.setState({fetching: true}, this.sendOrder);
                            }}
                            color="primary"
                        >
                            Conferma
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        )
    }
}

export default OrdinePanel;
