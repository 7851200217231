import React from "react";
import ProfileCard from "../../components/ProfileCard";
import MaterialTable from "material-table";
import materialTableLocalisationIT from "../../support/materialTableLocalisationIT";
import ProductImage from "../../components/ProductImage";
import materialTableIcons from "../../support/materialTableIcons";
import axios from "axios";
import { Typography, Chip } from "@mui/material";
import Constants from "../../context/Constants";
import Context from "../../context/Context";
import {
  TablePriceRender,
  TablePriceEdit,
  TableCodRender,
} from "../../components/TableProductPrice";

class ProdottiPanel extends React.Component {
  static contextType = Context;
  constructor(props) {
    super(props);
    this.getData = this.getData.bind(this);
    this.state = {
      dataProdotti: {},
    };
  }
  componentDidMount() {
    //this.getData();
  }

  componentDidUpdate(prev) {
    //if (prev.activeOfferta.id != this.props.activeOfferta.id) {
    /*        this.getData();
    } */
  }

  getData() {
    let getPath;
    if (
      this.props.activeOfferta.id == undefined ||
      this.props.activeOfferta.id == "DEFAULT"
    ) {
      getPath =
        Constants.paths.ajaxBasePath +
        `prodotti/?filter=attivi&token=` +
        this.context.user.token;
    } else {
      getPath =
        Constants.paths.ajaxBasePath +
        `offerte-agenti/prodotti/?id_offerta=${this.props.activeOfferta.id}&filter=attivi&token=` +
        this.context.user.token;
    }
    this.setState({ fetching: true });
    let finalData = [];
    axios
      .get(getPath)
      .then((res) => {
        console.log("Prodotti offerta TABELLA:", res);

        finalData = res.data.map((d) => {
          return {
            ...d,
          };
        });
        this.setState({
          allData: finalData,
          fetching: false,
        });
      })
      .catch((e) => {
        console.log(e);
      });
  }

  render() {
    return (
      <div
        className="data-table prodotti"
        style={{
          display: this.props.activeTab === 1 ? "inherit" : "none",
        }}
      >
        <Typography variant='h4' color='secondary'>In sviluppo</Typography>
      </div>
    );
    /*     return (
      <React.Fragment>
          <Context.Consumer>
            {(consumer_data) => (
              <div className="data-table prodotti" style={{
                display:this.props.activeTab === 1 ? 'inherit':'none'
              }}>
                <MaterialTable
                  isLoading={this.state.isFetching}
                  options={{
                    tableLayout: "auto",
                    pageSize: 15,
                    pageSizeOptions: [15, 30, 40, 50, 100, 200],
                    addRowPosition: "first",
                    detailPanelColumnAlignment: "right",
                    actionsColumnIndex: 100,
                    actionsCellStyle: {
                      textAlign: "center",
                    },
                    filtering: true,
                  }}
                  columns={[
                    {
                      title: "COD",
                      field: "cod",
                      type: "string",
                      editable: "onAdd",
                      render: (props) => <TableCodRender input_props={props} />,
                      headerStyle: {},
                      cellStyle: {},
                    },
                    {
                      title: "Descrizione",
                      field: "descrizione",
                      type: "string",
                      editable: "always",
                      initialEditValue: "",
                      headerStyle: {
                        minWidth: "300px",
                      },
                    },
                    {
                      title: "Vendita",
                      field: "prezzo_l1",
                      editable: "always",
                      render: (props) => (
                        <TablePriceRender input_props={props} />
                      ),
                      editComponent: (props) => (
                        <TablePriceEdit input_props={props} />
                      ),
                      headerStyle: {},
                      cellStyle: { verticalAlign: "middle" },
                    },
                  ]}
                  data={this.state.allData}
                  title={
                    this.props.activeOfferta.id &&
                    this.props.activeOfferta.id != "DEFAULT"
                      ? `Prodotti per offerta ${this.props.activeOfferta.title } (${this.props.activeOfferta.id})`
                      : "Prodotti"
                  }
                  icons={materialTableIcons}
                  localization={materialTableLocalisationIT}
                  // editable={{
                  //   onRowAdd: newData => this.props.setDataOnAdd,
                  //   onRowUpdate: (newData, oldData) => this.props.setDataOnUpdate,
                  //   onRowDelete: oldData => this.props.setDataOnDelete
                  // }}
                />
              </div>
            )}
          </Context.Consumer>
      </React.Fragment>
    ); */
  }
}
export default ProdottiPanel;
