import React from "react";
import {faCopyright, faHeart} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Typography, Paper, Card, Box} from "@mui/material";
import Context from "../context/Context";
import logo from "../media/k-odin.png";
import logoBright from "../media/k-odin-alt.png";
import moment from "moment";

export default class Footer extends React.Component {
    render() {
        return (
            <Context.Consumer>
                {
                    consumer_data =>
                        <React.Fragment>
                            <Box
                                style={{
                                    padding: consumer_data.theme.spacing(2),
                                    maxWidth: "20em",
                                    margin: "auto",
                                    color: consumer_data.theme.palette.text.secondary,
                                    textAlign: 'center'
                                }}
                                variant="outlined"
                                elevation={0}
                                className=""
                            >
                                <img
                                    src={consumer_data.ui.dark ? logo : logoBright}
                                    style={{
                                        maxHeight: "50px",
                                        margin: consumer_data.theme.spacing(1)
                                    }}
                                    alt={'Logo'}/>
                                <br/>
                                <Typography variant="subtitle2">
                                    <FontAwesomeIcon icon={faCopyright}/> Copyright {moment().year()} •
                                    Kartiell Verona S.r.l.
                                </Typography>
                                <Typography variant="subtitle2">
                                    Applicazione ad uso interno. Tutti i diritti riservati.
                                </Typography>
                                <Typography variant={"subtitle2"}
                                            sx={{color: consumer_data.theme.palette.background.default}}>
                                    {"</> with love by Samuele Mancuso"}
                                </Typography>
                            </Box>
                            <br/>
                        </React.Fragment>
                }
            </Context.Consumer>
        );
    }
}
