import {
    Button,
    Popover,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import axios from "axios";

import Barcode from "react-barcode";
import {useContext, useRef, useState} from "react";
import Constants from "../context/Constants";
import Context from "../context/Context";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons";

export default function ProductExtendedDescriptionPopup({
                                                            id,
                                                            useSku = false,
                                                            withButton = true,
                                                            open = false,
                                                            anchorEl = undefined,
                                                            buttonVariant = undefined,
                                                            buttonStyle = undefined,
                                                            buttonProps = {},
                                                            buttonChildren = undefined
                                                        }) {
    const token = localStorage.getItem("token");
    const [text, setText] = useState("");
    const [isOpen, setIsOpen] = useState(open);

    const buttonRef = useRef();
    const context = useContext(Context);

    const handlePopoverOpen = async () => {
        let stato = await axios.get(
            `${Constants.paths.ajaxBasePath}prodotti/utils/get-single-value/stato/?token=${token}&ids=${id}` +
            (useSku ? `&useSku=${useSku}` : "")
        );
        stato = parseInt(stato.data[id]);
        const useTable = [10].includes(stato);
        if (useTable) {
            let thisMeta = await axios.get(
                `${Constants.paths.ajaxBasePath}prodotti/utils/meta-descrizioni/?token=${token}&ids=${id}` +
                (useSku ? `&useSku=${useSku}` : "")
            );
            thisMeta = thisMeta.data[0];
            //? Genera tabella attributi se il prodotto è 10
            // console.log(thisMeta);
            let table = (
                <>
                    <Typography variant="body2">{thisMeta.brand.nome}</Typography>
                    <Typography variant="overline">
                        {thisMeta.nome_tipico.nome}
                    </Typography>
                    <Typography variant="h6">{thisMeta.nome}</Typography>
                    <Typography variant="caption">{thisMeta.cod_produttore}</Typography>
                    <Table size="small">
                        {/* <TableHead>
          <TableRow>
            <TableCell></TableCell>
          </TableRow>
        </TableHead> */}
                        <TableBody>
                            {thisMeta.attrs.map((a) => (
                                <TableRow>
                                    <TableCell align="right">{a.nome}:</TableCell>
                                    <TableCell align="left">
                                        {a.tipo === "bool"
                                            ? a.valore == 1
                                                ? "Sì"
                                                : "No"
                                            : a.valore}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    {thisMeta.cod_barre && (
                        <div
                            style={{display: "block", left: 0, right: 0, margin: "auto"}}
                        >
                            <Barcode
                                style={{display: "block", left: 0, right: 0, margin: "auto"}}
                                value={thisMeta.cod_barre}
                                //margin='auto'
                                height={25}
                                width={2}
                                fontSize={14}
                            />
                        </div>
                    )}
                </>
            );
            setText(table);
            setIsOpen(true);
        } else {
            axios
                .get(
                    `${Constants.paths.ajaxBasePath}prodotti/utils/get-single-value/descrizione_estesa/?token=${token}&ids=${id}` +
                    (useSku ? `&useSku=${useSku}` : "")
                )
                .then((res) => {
                    //? Noiosa descrizione estesa di Lofaro
                    setText(res.data[id]);
                    setIsOpen(true);
                });
        }
    };

    return withButton ? (
        <>
            <Button
                variant={buttonVariant || "contained"}
                size="small"
                ref={buttonRef}
                onClick={(e) => handlePopoverOpen()}
                style={
                    {
                        marginLeft: context.theme.spacing(1),
                        ...(buttonStyle || {})
                    }
                }
                {...buttonProps}
            >
                {buttonChildren || <FontAwesomeIcon icon={faSearch}/>}
            </Button>
            <Popover
                anchorEl={buttonRef.current}
                onClose={(e) => setIsOpen(false)}
                open={isOpen}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
            >
                <Typography
                    style={{padding: context.theme.spacing(2)}}
                    variant="body1"
                >
                    {text}
                </Typography>
            </Popover>
        </>
    ) : (
        <>
            <Popover
                anchorEl={anchorEl}
                open={isOpen}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
            >
                <Typography
                    style={{padding: context.theme.spacing(2)}}
                    variant="body1"
                >
                    {text}
                </Typography>
            </Popover>
        </>
    );
}
