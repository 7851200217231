import React from "react";
import {
  Avatar,
  Dialog,
  DialogContent,
  Button,
} from "@mui/material";
import Constants from "../context/Constants";
import { ZoomTransition } from "../components/Transitions";
import axios from "axios";
import Context from "../context/Context";

class ProductImage extends React.Component {
  //PROPS:
  //  cod: Prod COD
  //  size: Thumbnail Size
  static contextType = Context;
  constructor(props) {
    super(props);
    this.openDialog = this.openDialog.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
    this.state = {
      dialogOpen: false,
      hasError: false,
      imgCount: 0,
    };
    // if (!this.props.img) {
    //   console.error('Quantità immagini non fornite per Cod:'+this.props.cod)
    // }
  }

  componentDidMount() {
    if (typeof this.props.img === "undefined") {
      if (this.props.cod) {
        if (this.props.cod) {
          this.getImgNr(this.props.cod);
        }
      }
    } else this.setState({ imgCount: this.props.img });
  }

  componentDidUpdate(prevProps) {
    if (typeof this.props.img === "undefined") {
      if (this.props.cod) {
        if (this.props.cod != prevProps.cod) {
          this.getImgNr(this.props.cod);
        }
      }
    } else {
      if (this.props.img != prevProps.img)
        this.setState({ imgCount: this.props.img });
    }
  }

  getImgNr() {
    axios
      .get(
        `${Constants.paths.ajaxBasePath}prodotti/img/utils/count/?token=${this.context.user.token}&skus=${this.props.cod}&simple`
      )
      .then((res) => {
        this.setState({ imgCount: res.data });
      });
  }

  getImg(full = true) {
    let size = `-${this.props.size}x${this.props.size}`;
    if (full || !this.props.size) {
      size = "";
    }
    return `${Constants.paths.productImgBasePath}${
      parseInt(this.state.imgCount) ? this.props.cod || "" : "support/no-img"
    }${size}.jpg`;
  }

  onError(to = true) {
    this.setState({
      hasError: true,
    });
  }

  openDialog() {
    this.setState({
      dialogOpen: true,
    });
  }

  closeDialog() {
    this.setState({
      dialogOpen: false,
    });
  }

  render() {
    return (
      <React.Fragment>
        <Button
          onClick={this.openDialog}
          style={{ padding: 0, ...this.props.buttonStyle }}
        >
          <Avatar
            style={{
              height: this.props.containerSize || "4rem",
              width: this.props.containerSize || "4rem",
              ...this.props.avatarStyle,
            }}
            variant="rounded"
            src={this.getImg()} //TODO: Add srcset functionality
            imgProps={{ onError: () => this.onError(true) }}
          />
        </Button>
        <Dialog
          onClose={this.closeDialog}
          open={this.state.dialogOpen}
          TransitionComponent={ZoomTransition}
          style={{
            padding: 0,
          }}
        >
          <DialogContent
            style={{
              padding: 0,
            }}
          >
            <img
                alt={'Immagine prodotto'}
              style={{
                maxHeight: "100%",
                maxWidth: "100%",
              }}
              src={this.getImg(true)}
            />
          </DialogContent>
        </Dialog>
      </React.Fragment>
    );
  }
}

export default ProductImage;
