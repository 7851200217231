//Core
import React from "react";
// import {HashRouter as Router, Switch, Route} from "react-router-dom";
// import {
//     createBrowserRouter,
//     RouterProvider,
//     Route,
// } from "react-router-dom";
// import RouterTitle from "react-router-title";
import axios from "axios";

//Globals
import Context from "./context/Context";
import Constants from "./context/Constants";
// import {
//     // flogin,
//     flogout,
//     ftoggleSidebar,
//     fsetDarkTheme,
//     fsetActiveRouteName,
//     fCapitalizeFirstLetter,
//     fSetLowSpecDevice,
//     fSetuseElasticSearch,
// } from "./context/GlobalFunctions";
import Media from "react-media";

//Components
import Header from "./components/Header";
import Footer from "./components/Footer";

//Routes
import Login from "./routes/Login";

//CSS
import "./App.css";
import "./Style.scss";
// import {darkTheme, lightTheme} from "./context/Theme";
import {Box, Button, StyledEngineProvider} from "@mui/material";
import routes from "./context/Routes";
import {SnackbarProvider} from "notistack";
import {Route, Routes} from "react-router-dom";

import {ThemeProvider} from '@mui/material/styles';
import {brandingDarkTheme, brandingLightTheme} from "./context/Theme";
import {ConfirmProvider} from "material-ui-confirm";
import {SlideUpTransition} from "./components/Transitions";

// import NetworkWatcher from "./components/NetworkWatcher";
//Extras

// const theme = createTheme({});

const mediaQueries = {
    xs: "(min-width:1px)",
    sm: "(min-width:600px)",
    md: "(min-width:960px)",
    lg: "(min-width:1280px)",
    xl: "(min-width:1500px)",
    xxl: "(min-width:1920px)",
};

function prepareRoutes() {
    let idCount = 0;
    let returnArray = [];
    const returnRoute = r => <Route key={idCount} path={r.link} element={r.component}/>;
    const returnRoutes = (r) => {
        // if (roles.includes(r.role)||true) {
        if (r.type === "link") {
            if (r.isRoute) {
                idCount++;
                returnArray.push(returnRoute(r));
                // console.log("DONE", r);
            }
        } else if (r.type === "nested") {
            r.children.map(c => returnRoutes(c));
        }
        // }
    };
    routes.forEach(r => returnRoutes(r)); //Launch
    return returnArray
}

// const router = createBrowserRouter(prepareRoutes());

export default class App extends React.Component {
    state = {};

    constructor(props) {
        super(props);
        this.rootStyle = {minHeight: "100vh"};
        // console.log(theme.breakpoints);

        // this.darkRootStyle = { Obsoleto
        //   ...this.rootStyle
        // };
        let defState = {};
        try {
            defState = {
                // setState: this.setState.bind(this),
                user: {
                    id: localStorage.getItem('userId'),
                    loggedIn: localStorage.getItem("loggedIn"),
                    username: localStorage.getItem("username"),
                    firstName: localStorage.getItem("firstName"),
                    lastName: localStorage.getItem("lastName"),
                    token: localStorage.getItem("token"),
                    email: localStorage.getItem("email"),
                    roles: JSON.parse(localStorage.getItem("roles")),
                    assignedZones: JSON.parse(localStorage.getItem("assignedZones")),
                    branchId: localStorage.getItem("branchId")
                },
                // functions: {
                //     login: flogin.bind(this),
                //     logout: flogout.bind(this),
                //     // toggleSidebar: ftoggleSidebar.bind(this),
                //     setDarkTheme: fsetDarkTheme.bind(this),
                //     capitalize: fCapitalizeFirstLetter.bind(this),
                //     setLowSpecDevice: fSetLowSpecDevice.bind(this),
                //     setuseElasticSearch: fSetuseElasticSearch.bind(this),
                // },
                ui: {
                    sidebarOpen: localStorage.getItem("sidebarOpen") === "true",
                    dark: localStorage.getItem("darkUi") === "true",
                },
                network: {
                    showSnackbar: false,
                },
                device: {
                    lowSpec: localStorage.getItem("lowSpecDevice") === "true",
                    useElasticSearch:
                        (localStorage.getItem("useElasticSearch") || "true") === "true",
                }
            };
        } catch {
            defState = {
                user: undefined,
                ui: {
                    sidebarOpen: false,
                    dark: true
                },
                activeRouteName: "Dashboard",
                network: {
                    showSnackbar: false,
                },
                device: {
                    lowSpec: false,
                    useElasticSearch: true
                },

            }
            localStorage.clear();
        }
        this.state = {
            setContext: newState => this.setState(newState),
            theme: this.state?.ui?.dark ? brandingDarkTheme : brandingLightTheme,
            // env: process?.env?.NODE_ENV === "development" ? "dev" : "prod",
            ...defState
        }
        this.notistackRef = React.createRef();
    }

    snackbarOnClickDismiss = (key) => () => {
        this.notistackRef.current.closeSnackbar(key);
    };

    componentDidMount() {
        setInterval(() => {
            if (this.state?.user?.loggedIn) {
                axios
                    .get(
                        Constants.paths.ajaxBasePath +
                        "user/?action=isAuthorized&token=" +
                        localStorage.getItem("token")
                    )
                    .catch((e) => {
                        try {
                            console.log(e.response.status);
                            if (e.response.status === 403) {
                                this.state.functions.logout();
                            }
                        } catch (error) {
                            console.warn("Cannot check user's permission (Network down?)");
                        }
                    });
            }
        }, Constants.heartbeat.main);
        if (this.state?.device?.lowSpec) document.body.classList.add('low-spec-device');
        else document.body.classList.remove('low-spec-device');
    }

    render() {
        return (
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={this.state.ui.dark ? brandingDarkTheme : brandingLightTheme}>
                    <ConfirmProvider
                        defaultOptions={{
                            title: "Confermi?",
                            description: "",
                            confirmationText: "Conferma",
                            cancellationText: "Annulla",
                            dialogProps: {
                                TransitionComponent: SlideUpTransition
                            }
                        }}
                    >
                        <Media queries={mediaQueries}>
                            {matches => (
                                <Context.Provider
                                    value={{
                                        ...this.state,
                                        theme: this.state.ui.dark ? brandingDarkTheme : brandingLightTheme,
                                        matches: matches,
                                    }}
                                >
                                    {/*<StyledEngineProvider injectFirst>*/}
                                    {/*    <ThemeProvider theme={this.state?.ui?.dark ? darkTheme : lightTheme}>*/}
                                    <SnackbarProvider
                                        ref={this.notistackRef}
                                        action={(key) => (
                                            <Button onClick={this.snackbarOnClickDismiss(key)}>
                                                {/* <Box color='text.primary'>
                        <FontAwesomeIcon size='xs' icon={faTimes}/></Box> */}
                                                Chiudi
                                            </Button>
                                        )}
                                    ><Box id={'App'} className={[
                                        this.state?.ui?.dark ? "dark-theme" : "light-theme",
                                        this.state?.device?.lowSpec ? "low-spec-device" : ""

                                    ].join(" ")
                                    }>
                                        {this.state?.user?.loggedIn ? (
                                            <>
                                                <Header/>
                                                {/*<React.Fragment>*/}
                                                {/*<Box*/}
                                                {/*    className={*/}
                                                {/*        "App " + (this.state.ui?.dark ? "dark-theme" : "")*/}
                                                {/*    }*/}
                                                {/*    style={this.rootStyle}*/}
                                                {/*>*/}

                                                {/* <Sidebar className="" style={{ minHeight: "80vh" }} /> */}
                                                {/* <div onClick={(e)=>{this.state.functions.toggleSidebar()}} id="app-darkener" className={this.state.ui.sidebarOpen?"on":"off"} /> */}
                                                <Box
                                                    className="page-content-wrapper"
                                                    style={{
                                                        margin: !matches.md
                                                            ? 0
                                                            : !matches.lg
                                                                ? this.state.theme.spacing(2)
                                                                : !matches.xl
                                                                    ? this.state.theme.spacing(2.5)
                                                                    : !matches.xxl
                                                                        ? this.state.theme.spacing(3)
                                                                        : this.state.theme.spacing(5),
                                                        marginTop: this.state.theme.spacing(3),
                                                        marginBottom: this.state.theme.spacing(3),
                                                    }}
                                                >
                                                    <Routes>
                                                        {prepareRoutes(this.state.user.roles)}
                                                    </Routes>
                                                    {/*<Routes>*/}

                                                    {/*</Routes>*/}
                                                </Box>
                                                <Footer/>

                                                {/*</Box>*/}
                                            </>
                                        ) : (
                                            <React.Fragment>
                                                <Box
                                                    className={
                                                        "App " + (this.state?.ui?.dark ? "dark-theme" : "")
                                                    }
                                                    style={this.rootStyle}
                                                >
                                                    {/* <Header /> */}
                                                    <Login/>
                                                    {/* <Footer /> */}
                                                </Box>
                                            </React.Fragment>
                                        )}
                                    </Box>
                                    </SnackbarProvider>
                                    {/*    </ThemeProvider>*/}
                                    {/*</StyledEngineProvider>*/}
                                </Context.Provider>
                            )}
                        </Media>
                    </ConfirmProvider>
                </ThemeProvider>
            </StyledEngineProvider>);
    }
}
