import {TextField, Typography, Divider} from "@mui/material";
import {Autocomplete} from '@mui/material';
import axios from "axios";
import React from "react";
import Constants from "../context/Constants";
import Context from "../context/Context";

export default class ClassificationsDropdown extends React.Component {
    static contextType = Context;

    constructor(props) {
        super(props);
        this.getData = this.getData.bind(this);
        this.state = {
            data: [],
        };
    }

    getData() {
        axios
            .get(
                `${Constants.paths.ajaxBasePath}prodotti/classificazioni/?token=${this.context.user.token}`
            )
            .then((res) => {
                console.log(res.data);
                this.setState({data: res.data});
            });
    }

    componentDidMount() {
        if (this.props.classData) {
            this.setState({
                data: this.props.classData,
            });
        } else {
            this.getData();
        }
    }

    render() {
        return (
            <Autocomplete
                autoSelect
                // defaultValue={}
                disabled={this.props.disabled}
                getOptionLabel={(option) => option.nome||""}
                options={this.state.data}
                // getOptionLabel={(option) => option.nome || ""}
                isOptionEqualToValue={(o, v) => o.id === v.id}
                style={{width: "100%"}}
                value={this.state.data?.find(c => c.id == this.props.value) || {}}
                onChange={(e, val) => this.props.onChange(val)}
                renderInput={(params) => (
                    <TextField {...params} label="Classe" variant="outlined"/>
                )}
            />
        );
    }
}
