import React, {ReactElement, useContext, useEffect, useMemo, useState} from 'react';
import ProfileCard from "../../components/ProfileCard";
import {
    Box,
    Button,
    ButtonGroup,
    Card,
    CardHeader,
    Checkbox,
    Collapse,
    Dialog,
    DialogActions,
    Divider,
    FormControlLabel,
    IconButton,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Paper,
    Select,
    Stack,
    Switch,
    Tab,
    Tabs,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Typography
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import {CustomerSelector} from "../../components/DialogSelector";
import Cliente from "../../interfaces/clienti/Cliente";
import Context from "../../context/Context";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faBox,
    faBoxesPacking,
    faCaretLeft,
    faCaretRight,
    faMinus,
    faPlus,
    faRefresh,
    faTag
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import Constants from "../../context/Constants";
import download from "downloadjs";
import moment, {Moment} from "moment/moment";
import Sede from "../../interfaces/sedi/Sede";
import MaterialTable from "material-table";
import MaterialTableLocalisationIT from "../../support/materialTableLocalisationIT";
import MaterialTableIcons from "../../support/materialTableIcons";
import {Add, Delete, Download, Refresh} from "@mui/icons-material";
import {SlideUpTransition} from "../../components/Transitions";
import {useSnackbar} from 'notistack'
import {confirm, useConfirm} from "material-ui-confirm";
import {faPalletBoxes} from "@fortawesome/pro-solid-svg-icons";
import {titoloMessaggioRandom} from "../../functions/easterEggs";

interface Label {
    id?: number,
    via: string,
    comune: string,
    prov: string,
    cap: number | "",
    tel: number | "",
    cell: number | "",
    nColli: number,
    note: string,
    idCliente: Cliente['id'],
    nomeCliente: Cliente['ragione_sociale'],
    idSede: Sede['id'],
    contTipo: 'Contanti' | 'Assegno' | 'Assegno PD' | null,
    contImporto: number | null,
    contConEstratto: boolean,
    idBordero: number | null,
    dataCreazione?: Moment | null,
    documentoRif?: string,
    tipoCollo: "collo" | "pallet"
}

type TabType = "etichette" | "bordero";

const GeneraEtichette = ({open, onClose, downloadFn}: {
    open: boolean,
    onClose: () => void,
    downloadFn: (id: number) =>
        void
}) => {
    const context = useContext(Context);

    //region State
    const [customerSelectorOpen, setCustomerSelectorOpen] = useState<boolean>(false);
    const [customer, setCustomer] = useState<Cliente | null>(null);

    const [via, setVia] = useState<string>('');
    const [comune, setComune] = useState<string>('');
    const [prov, setProv] = useState<string>('');
    const [cap, setCap] = useState<number | "">(0);
    const [nColli, setNColli] = useState<number>(1);
    const [note, setNote] = useState<string>('');

    const [cell, setCell] = useState<"" | number>('');
    const [tel, setTel] = useState<"" | number>('');
    const [idSede, setIdSede] = useState<number>(1); //1=Feroleto | 2=Rende

    const [documentoRif, setDocumentoRif] = useState<string>("");

    const [tipoCollo, setTipoCollo] = useState<Label['tipoCollo']>("collo");

    const [contTipo, setContTipo] = useState<Label['contTipo']>(undefined);
    const [contImporto, setContImporto] = useState<Label['contImporto'] | string>(0);
    const [contConEstratto, setContConEstratto] = useState<Label['contConEstratto']>(false);

    const [docRifIdSede, setDocRifIdSede] = useState<number>(1);
    //endregion

    //region Hooks
    useEffect(() => {
        setDocRifIdSede(idSede);
    }, [idSede]);

    useEffect(() => {
        if (open == false) {
            setCustomer(null);
            setVia('');
            setComune('');
            setProv('');
            setCap(0);
            setNColli(1);
            setNote('');
            setCell('');
            setTel('');
            setIdSede(1);
            setDocumentoRif('');
            setContTipo(null);
            setContImporto(0);
            setContConEstratto(false);
            setDocRifIdSede(1);
            setTipoCollo('collo');
        }
    }, [open]);

    useEffect(() => {
        if (customer && customer.id) {
            axios.get(Constants.paths.ajaxBasePath + "spedizioni/etichette/numero-spedizioni-cliente-giornaliero/?token=" + context.user.token + "&id_cliente=" + customer.id)
                .then(res => {
                    if (parseInt(res.data) > 0) {
                        confirm({
                            title: (context.user.username === "g.bellantonio" ? titoloMessaggioRandom("Peppi", 'errore') : "Attenzione"),
                            description: `Oggi è già stata assegnata un'etichetta per il cliente (${customer.id}) ${customer.ragione_sociale}!`,
                            hideCancelButton: true,
                            confirmationText: "OK"
                        }).then();
                    }
                });
        }
    }, [customer]);
    //endregion

    //region Functions
    const canSend = (): boolean => {
        if (!via) return false;
        if (!comune) return false;
        if (!prov) return false;
        if (!cap) return false;
        if (!cell && !tel) return false;
        if (!idSede) return false;
        if (parseFloat(contImporto as string) > 0) {
            if (!contTipo) return false;
        }
        return true;
    }

    const composeLabelFromState = (): Label => {
        return {
            via: via,
            comune: comune,
            prov: prov,
            cap: cap,
            tel: tel,
            cell: cell,
            nColli: nColli,
            note: note,
            idCliente: customer?.id ?? null,
            nomeCliente: customer?.ragione_sociale ?? null,
            idSede: idSede,
            contTipo: contTipo,
            contImporto: parseFloat(contImporto as string),
            contConEstratto: contConEstratto,
            idBordero: null,
            documentoRif: documentoRif ? (documentoRif + "/" + (docRifIdSede === 1 ? "FE" : "RE")) : null,
            tipoCollo: tipoCollo
        }
    }

    const postLabel = async (): Promise<number> => {
        const body = {
            token: context.user.token, labels: [composeLabelFromState()]
        };
        let res = await axios.post(Constants.paths.ajaxBasePath + "spedizioni/etichette/", body);
        if (res.data[0])
            return parseInt(res.data[0]);
        else throw new Error(`${res.statusText}`);
        // download(res.data, "Etichette del " + moment().format("DD-MM-YYYY_hh:mm:ss") + ".pdf");
    }

    const selectCustomer = (customer: Cliente | null) => {
        setCustomer(customer);
        setCustomerSelectorOpen(false);

        setVia(customer.sped_via ?? customer.via);
        setComune(customer.sped_comune ?? customer.comune);
        setProv(customer.sped_prov ?? customer.prov);
        setCap(customer.sped_cap ?? customer.cap);

        setCell(customer.cell);
        setTel(customer.tel);
    }


    //endregion
    return <Dialog fullScreen={!context.matches.md} scroll={'body'} maxWidth={'lg'} open={open}
                   onClose={() => onClose()}
                   TransitionComponent={SlideUpTransition as React.JSXElementConstructor<any>}>
        <Grid2 container spacing={2} sx={{textAlign: 'center', margin: 'auto', p: 2}}>
            <Grid2 xs={12}>
                <Typography
                    variant={'h2'}
                    textAlign={'center'}
                    color={context.theme.palette.text.primary}>
                    Genera Etichette
                </Typography>
            </Grid2>
            <Grid2 xs={12} textAlign={"right"} sx={{alignContent: 'center', textAlign: 'center'}}>
                <Typography textAlign={'left'} variant={'h4'}>Sede</Typography>
            </Grid2>
            <Grid2 xs={12}>
                <ToggleButtonGroup fullWidth size={'small'} exclusive value={idSede}
                                   onChange={(_, v) => v && setIdSede(v)}>
                    <ToggleButton value={1}>
                        Feroleto Antico
                    </ToggleButton>
                    <ToggleButton value={2}>
                        Rende
                    </ToggleButton>
                </ToggleButtonGroup>
            </Grid2>
            <Grid2 container xs={12}>
                <Grid2 xs={12}>
                    <Typography textAlign={'left'} variant={'h4'}>Indirizzo</Typography>
                </Grid2>
                <Grid2 xs={12}>
                    <Button
                        fullWidth
                        variant={'contained'}
                        onClick={() => setCustomerSelectorOpen(true)}>
                        {customer ? `(${customer.id}) ${customer.ragione_sociale}` : "Seleziona cliente"}
                    </Button>
                </Grid2>
                <Grid2 xs={6} textAlign={'left'}>
                    <TextField value={via} onChange={e => setVia(e.target.value)}
                               fullWidth
                               label={'Via'}
                               size={'small'}
                               key={'via'}/>
                </Grid2>
                <Grid2 xs={6} textAlign={'left'}>
                    <TextField value={comune} onChange={e => setComune(e.target.value)}
                               fullWidth
                               label={'Comune'}
                               size={'small'}/>
                </Grid2>
                <Grid2 xs={6} textAlign={'left'}>
                    <TextField value={prov} onChange={e => setProv(e.target.value)}
                               fullWidth
                               label={'Provincia'}
                               size={'small'}/>
                </Grid2>
                <Grid2 xs={6} textAlign={'left'}>
                    <TextField value={cap} label={'CAP'}
                               onChange={e => setCap(e.target.value === "" ? "" : parseInt(e.target.value))}
                               fullWidth
                               size={'small'}/>
                </Grid2>
                <Grid2 xs={6}>
                    <TextField fullWidth value={cell}
                               label={'Cellulare'}
                               sx={{alignContent: 'center'}}
                               onChange={e => setCell(e.target.value as "" | number)} size={"small"}/>
                </Grid2>
                <Grid2 xs={6}>
                    <TextField fullWidth value={tel}
                               label={'Telefono'}
                               sx={{alignContent: 'center'}}
                               onChange={e => setTel(e.target.value as "" | number)} size={"small"}/>
                </Grid2>
                <Grid2 xs={12}>
                    <Typography textAlign={'left'}
                                variant={'h4'}>{tipoCollo === "collo" ? "Collo" : "Pallet"}</Typography>
                </Grid2>

                <Grid2 xs={4} sx={{alignContent: 'center'}}>
                    <TextField fullWidth value={nColli} type={'number'}
                               label={'# ' + (tipoCollo === "collo" ? "Collo" : "Pallet")}
                               onChange={e => setNColli(parseInt(e.target.value))}
                               size={"small"}/>
                </Grid2>
                <Grid2 xs={4}>
                    <ButtonGroup fullWidth sx={{height: '100%'}}>
                        <Button onClick={() => setNColli(nColli > 1 ? nColli - 1 : 1)}>
                            <FontAwesomeIcon icon={faMinus}/>
                        </Button>
                        <Button onClick={() => setNColli(nColli + 1)}><FontAwesomeIcon
                            icon={faPlus}/>
                        </Button>
                    </ButtonGroup>
                </Grid2>
                <Grid2 xs={4}>
                    <ToggleButtonGroup size={'small'} value={tipoCollo} exclusive fullWidth sx={{height: '100%'}}
                                       onChange={(_, val) => val && setTipoCollo(val)}>
                        <ToggleButton value={'collo'}>Collo</ToggleButton>
                        <ToggleButton value={'pallet'}>Pallet</ToggleButton>
                    </ToggleButtonGroup>
                </Grid2>
                <Grid2 xs={12} textAlign={"right"} sx={{alignContent: 'center', textAlign: 'center'}}>
                    <Typography textAlign={'left'} variant={'h4'}>Documento</Typography>
                </Grid2>
                <Grid2 xs={4}>
                    <TextField
                        label={'N. Documento'}
                        fullWidth
                        type={'number'}
                        value={documentoRif}
                        onChange={e => setDocumentoRif(e.target.value)}
                        size={'small'}
                    />
                </Grid2>
                <Grid2 xs={4}>
                    <ToggleButtonGroup fullWidth size={'small'} exclusive value={docRifIdSede}
                                       onChange={(_, v) => v && setDocRifIdSede(v)}>
                        <ToggleButton value={1}>
                            Feroleto Antico
                        </ToggleButton>
                        <ToggleButton value={2}>
                            Rende
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Grid2>
                <Grid2 xs={4} style={{alignContent: 'center', textAlign: 'center'}}>
                    <TextField
                        disabled
                        variant={'standard'}
                        size={'small'}
                        fullWidth
                        InputProps={{
                            style: {
                                textAlign: 'right'
                            }
                        }}
                        value={documentoRif ? documentoRif + "/" + (docRifIdSede === 1 ? "FE" : "RE") : ""}
                    />
                </Grid2>
                <Grid2 xs={12}>
                    <Typography textAlign={'left'} variant={'h4'}>
                        Contrassegno
                    </Typography>
                </Grid2>
                <Grid2 container xs={12}>
                    <Grid2 xs={4}>
                        <TextField fullWidth value={contImporto}
                                   inputMode={'numeric'}
                                   label={'Importo'}
                                   onChange={e => setContImporto(e.target.value)}
                                   onBlur={() => setContImporto(parseFloat(contImporto.toString().replace(",", ".")).toFixed(2))}
                                   size={'small'}
                            // inputProps={{
                            //     endAdornment: <InputAdornment position={'end'}>€</InputAdornment>
                            // }}
                        />
                    </Grid2>
                    <Grid2 xs={4}>
                        <Select value={contTipo}
                                placeholder={'Tipo'}
                            // label={'Tipo'}
                            // disabled={!(parseFloat(contImporto as string) > 0)}
                                onChange={e => setContTipo(e.target.value as Label['contTipo'])}
                                fullWidth
                                size={'small'}>
                            <MenuItem value={'Contanti'}>Contanti</MenuItem>
                            <MenuItem value={'Assegno'}>Assegno</MenuItem>
                            <MenuItem value={'Assegno PD'}>Assegno PD</MenuItem>
                            <MenuItem value={undefined}><i>Nessuno</i></MenuItem>

                        </Select>
                    </Grid2>
                    <Grid2 xs={4} sx={{alignContent: 'center'}}>
                        <FormControlLabel
                            labelPlacement={'start'}
                            control={<Switch
                                sx={{alignContent: 'center'}}
                                checked={contConEstratto}
                                onChange={e => setContConEstratto(e.target.checked)}/>}
                            label="Con estratto?"
                        />
                    </Grid2>
                </Grid2>
                <Grid2 xs={12}>
                    <TextField fullWidth value={note} onChange={e => setNote(e.target.value)} multiline
                               size={'small'}
                               label={"Note"}/>
                </Grid2>
            </Grid2>
        </Grid2>
        <DialogActions>
            <Button sx={{color: context.theme.palette.text.primary}} onClick={() => onClose()}>Annulla</Button>
            <Button disabled={!canSend()} onClick={() => postLabel().then(() => onClose())}>
                Salva
            </Button>
            <Button disabled={!canSend()} onClick={() => postLabel().then((insertId) => {
                downloadFn(insertId);
                onClose();
            })}>
                Salva e Scarica
            </Button>
        </DialogActions>
        <CustomerSelector
            open={customerSelectorOpen}
            onClose={() => setCustomerSelectorOpen(false)}
            onSelect={(c: any) => selectCustomer(c)}
            onEmpty={() => selectCustomer(null)}
        />
    </Dialog>
};

const LabelListItem = ({label, checked, onClick}: {
    label: Label,
    checked: boolean,
    onClick: () => void
}): ReactElement => {
    return <ListItemButton onClick={() => onClick()}>
        <ListItemIcon>
            <Checkbox
                checked={checked}
                tabIndex={-1}
                disableRipple
            />
        </ListItemIcon>
        <ListItemText primary={`(${label.idCliente}) ${label.nomeCliente}`}
                      secondary={<>{label.nColli} <FontAwesomeIcon
                          icon={label.tipoCollo === 'collo' ? (label.nColli > 1 ? faBoxesPacking : faBox)
                              : (faPalletBoxes)}
                      />
                          {` ${label.comune} (${label.prov}) • ${label.dataCreazione.fromNow()} •
                                          ${label.dataCreazione.format("DD/MM/yyyy")} ${label.contImporto ? label.contImporto + "€" : ""}`}
                      </>}/>
    </ListItemButton>
}

const BorderoTransferList = ({open, onClose}: {
    open: boolean,
    onClose: () => void
}) => {
    interface LabelCheckable extends Label {
        checked: boolean;
    }

    const context = useContext(Context);
    const {enqueueSnackbar} = useSnackbar();
    //region States
    const [branchId, setBranchId] = useState<number>(0);
    // const [search, setSearch] = useState<string>('');
    // const [labelsData, setLabelsData] = useState<Label[]>([]);

    // const [allLabelsData, setAllLabelsData] = useState<LabelCheckable[]>([]);

    const [unassignedLabels, setUnassignedLabels] = useState<LabelCheckable[]>([]);
    const [unassignedLabelsCount, setUnassignedLabelsCount] = useState<number>(0);
    const [unassignedLabelsPage, setUnassignedLabelsPage] = useState<number>(0);

    const [note, setNote] = useState<string>("");
    // const [checkedAvailableLabels, setCheckedAvailableLabels] = useState<number[]>([]);

    const [addedLabels, setAddedLabels] = useState<LabelCheckable[]>([]);
    // const [checkedAddedLabels, setCheckedAddedLabels] = useState<number[]>([]);
    //endregion

    //region Effects
    useEffect(() => { //OnLoad
        setBranchId(parseInt(context.user.branchId as string));
    }, []);

    useEffect(() => {
        if (open) getLabels(unassignedLabelsPage).then();
    }, [open, unassignedLabelsPage]);

    useEffect(() => {
        if (!open) {
            setAddedLabels([]);
            setUnassignedLabels([]);
            setUnassignedLabelsPage(0);
            setNote('');
        }
    }, [open]);

    useEffect(() => {
        setAddedLabels([]);
        getLabels(0).then();
    }, [branchId]);
    //endregion

    //region Functions
    const transferLabels = (direction: "toLeft" | "toRight") => {
        let srcLabels: LabelCheckable[] = direction === "toRight" ? unassignedLabels : addedLabels;
        let destLabels: LabelCheckable[] = direction === "toRight" ? addedLabels : unassignedLabels;

        let checkedSrcLabel: LabelCheckable[] = srcLabels.filter(l => l.checked);
        srcLabels = srcLabels.filter(l => !l.checked);

        destLabels = [...checkedSrcLabel.map(cl => ({
            ...cl,
            checked: false
        })),
            ...destLabels] as LabelCheckable[];

        if (direction === "toRight") {
            setUnassignedLabels(srcLabels);
            setAddedLabels(destLabels);
        } else {
            setUnassignedLabels(destLabels);
            setAddedLabels(srcLabels);
        }
    };

    const getAssignableLabelsTotalCount = useMemo<number>(() => unassignedLabelsCount - addedLabels.length,
        [unassignedLabelsCount, addedLabels]);
    const getLabels = (page = 0) => axios.get(Constants.paths.ajaxBasePath + "spedizioni/etichette/?" + "token=" + context.user.token + "&noAssoc" + "&page=" + page + "&limit=" + 100 + "&branch=" + branchId)
        .then(resp => {
            if (resp.data.labels) {
                let resArray = resp.data.labels.map((l: any) => ({
                    id: l.id,
                    idCliente: l.id_cliente,
                    nomeCliente: l.ragione_sociale,
                    via: l.via,
                    comune: l.comune,
                    prov: l.prov,
                    cap: l.cap,
                    cell: l.cell,
                    tel: l.tel,
                    nColli: l.n_colli,
                    note: l.note,
                    idSede: l.id_sede,
                    contConEstratto: l.contrassegno_con_estratto,
                    contTipo: l.contrassegno_con_tipo,
                    contImporto: l.contrassegno_con_importo,
                    idBordero: l.id_bordero,
                    dataCreazione: moment(l.data_creazione),
                    tipoCollo: l.tipo_collo,
                    checked: false
                })) as Label[];

                if (page > 0) resArray = [...unassignedLabels, ...resArray];
                // let unassignedIds: number[] = unassignedLabels.map(l => l.id);
                let addedIds: number[] = addedLabels.map(l => l.id);
                let newUnassigned: LabelCheckable[] = [];
                // let newAdded: LabelCheckable[] = [];
                resArray.forEach((l) => {
                    if (addedIds.includes(l.id)) {
                        //non fare nulla
                    } else {
                        newUnassigned.push({
                            ...l,
                            checked: unassignedLabels.find(src => src.id === l.id)?.checked || false
                        } as LabelCheckable);
                    }
                });
                setUnassignedLabels(newUnassigned);
                // setAddedLabels(newAdded);
                setUnassignedLabelsCount(resp.data.total as number);
            }
        });

    const send = (
        closeAfter: boolean = false,
        downloadAfter: boolean = false
    ) => axios.post(Constants.paths.ajaxBasePath + "spedizioni/bordero/", {
        token: context.user.token,
        branch: branchId,
        note: note,
        labelIds: addedLabels.map(l => l.id),
        generate: downloadAfter
        //@ts-ignore
    }, {responseType: "blob"}).then((resp) => {
        enqueueSnackbar(
            context.user.username === "g.bellantonio" ? "BRAVO PEPPE! 👍👍👍" : //Easter egg
                'Borderò generato con successo!', {variant: "success"});
        if (downloadAfter) download(resp.data, "Borderò del " + moment().format("DD-MM-YYYY"));
        if (closeAfter) onClose();
    }).catch((err) => {
        enqueueSnackbar('Errore durante la creazione del borderò', {variant: "error"});
        console.error(err)
    });
//endregion

    return <Dialog
        fullWidth
        maxWidth={"lg"}
        open={open}
        onClose={() => {
            onClose();
            setUnassignedLabelsPage(0);
            setUnassignedLabels([]);
            setAddedLabels([]);
        }}
        TransitionComponent={SlideUpTransition as React.JSXElementConstructor<any>}
    >
        <Grid2 container spacing={0}>
            <Grid2 xs={12} sx={{textAlign: 'center'}}>
                <Typography sx={{p: 2, pb: 0}} align={'center'} variant={'h2'}>Crea Borderò</Typography>
                <Typography sx={{pt: 1, pb: 3}} align={'center'} variant={'caption'}>
                    Inserisci le etichette non ancora assegnate ad alcun borderò
                </Typography>
            </Grid2>
            <Grid2 xs={12} sx={{px: 2}}>
                <ToggleButtonGroup value={branchId} onChange={(_, v) => v && setBranchId(v)} exclusive
                                   fullWidth
                                   size={'small'}>
                    <ToggleButton value={1}>Feroleto A.</ToggleButton>
                    <ToggleButton value={2}>Rende</ToggleButton>
                </ToggleButtonGroup>
            </Grid2>
            <Collapse in={!!branchId} sx={{width: '100%'}}>
                <Grid2 container xs={12}>
                    <Grid2 xs={5}>
                        <Card elevation={0}>
                            <CardHeader
                                sx={{px: 1, py: 2}}
                                avatar={<Checkbox onClick={() => {
                                    if (!unassignedLabels.find(l => l.checked))
                                        setUnassignedLabels(unassignedLabels.map(l => ({
                                            ...l,
                                            checked: true
                                        }) as LabelCheckable));
                                    else
                                        setUnassignedLabels(unassignedLabels.map(l => ({
                                            ...l,
                                            checked: false
                                        }) as LabelCheckable));
                                }}
                                                  checked={(unassignedLabelsCount - addedLabels.length) === unassignedLabels.filter(l => l.checked).length}
                                                  indeterminate={!!(unassignedLabels.filter(l => l.checked).length && unassignedLabels.filter(l => l.checked).length < (unassignedLabelsCount - addedLabels.length))}
                                                  disabled={unassignedLabelsCount < 1}
                                />}
                                title={"Etichette"}
                                subheader={`${unassignedLabels.filter(l => l.checked).length}/${getAssignableLabelsTotalCount} Selezionate`}
                            />
                            <Divider/>
                            <List sx={{
                                minHeight: '50vh', maxHeight: '50vh', overflowY: 'auto'
                            }}>
                                {unassignedLabels.map(l => <LabelListItem
                                    label={l}
                                    key={l.id}
                                    checked={l.checked}
                                    onClick={() => setUnassignedLabels(() => unassignedLabels.map(
                                        l2 => ({
                                            ...l2,
                                            checked: l2.id === l.id ? !l2.checked : l2.checked
                                        })))
                                    }
                                />)}
                                {unassignedLabelsCount > unassignedLabels.length &&
                                    <ListItemButton key={'loadMore'}
                                                    onClick={() => setUnassignedLabelsPage(unassignedLabelsPage + 1)}
                                                    sx={{textAlign: 'center', placeContent: 'center'}}>Carica
                                        altro..</ListItemButton>}
                            </List>
                        </Card>
                    </Grid2>
                    <Grid2 xs={2} alignContent={'center'}>
                        <Stack>
                            <Button
                                sx={{my: 0.5, mx: 2}}
                                variant="outlined"
                                size="medium"
                                onClick={() => transferLabels('toRight')}
                                // disabled={rightChecked.length === 0}
                            >
                                Aggiungi<FontAwesomeIcon size={'lg'} style={{marginLeft: context.theme.spacing(1)}}
                                                         icon={faCaretRight}/>
                            </Button>
                            <Button
                                sx={{my: 0.5, mx: 2}}
                                variant="outlined"
                                size="medium"
                                onClick={() => transferLabels('toLeft')}
                                // disabled={leftChecked.length === 0}
                            >
                                <FontAwesomeIcon size={'lg'} style={{marginRight: context.theme.spacing(1)}}
                                                 icon={faCaretLeft}
                                /> Rimuovi
                            </Button>
                        </Stack>
                    </Grid2>
                    <Grid2 xs={5}>
                        <Card elevation={0}>
                            <CardHeader
                                sx={{px: 1, py: 2}}
                                avatar={<Checkbox onClick={() => {
                                    if (!addedLabels.find(l => l.checked))
                                        setAddedLabels(addedLabels.map(l => ({
                                            ...l,
                                            checked: true
                                        }) as LabelCheckable));
                                    else
                                        setAddedLabels(addedLabels.map(l => ({
                                            ...l,
                                            checked: false
                                        }) as LabelCheckable));
                                }}
                                                  checked={!addedLabels.find(l => !l.checked)}
                                                  indeterminate={!!(addedLabels.filter(l => l.checked).length && (addedLabels.length > (addedLabels.filter(l => l.checked).length)))}
                                                  disabled={addedLabels.length < 1}
                                />}
                                title={"Etichette"}
                                subheader={`${addedLabels.filter(l => l.checked).length}/${addedLabels.length} Selezionate`}
                            />
                            <Divider/>
                            <List sx={{minHeight: '50vh', maxHeight: '50vh', overflowY: 'auto'}}>
                                {addedLabels.map(l =>
                                    <LabelListItem
                                        key={l.id}
                                        label={l}
                                        checked={l.checked}
                                        onClick={() => setAddedLabels(addedLabels.map(l2 => ({
                                            ...l2,
                                            checked: (l.id === l2.id) ? !l2.checked : l2.checked
                                        } as LabelCheckable)))}
                                    />)}
                            </List>
                        </Card>
                    </Grid2>
                </Grid2>
                <Grid2 xs={12} sx={{p: 2}}>
                    <TextField fullWidth label={"Note"} value={note} onChange={(e) => setNote(e.target.value)}/>
                </Grid2>
            </Collapse>
        </Grid2>

        <DialogActions>
            <Grid2 container justifyContent={'space-between'}>
                <Grid2 alignContent={'center'}>
                    <IconButton onClick={() => getLabels(0)}><FontAwesomeIcon size={'sm'}
                                                                              icon={faRefresh}/></IconButton>
                </Grid2>
                <Grid2>
                    <Button onClick={() => onClose()}
                            sx={{color: context.theme.palette.text.primary}}>Annulla</Button>
                    <Button onClick={() => {
                        send(true).then();
                    }}>Salva</Button>
                    <Button onClick={() => {
                        send(true, true).then();
                    }}>Salva e Scarica</Button>
                </Grid2>
            </Grid2>
        </DialogActions>
    </Dialog>
}

const Bordero = () => {
    const context = useContext(Context);
    const confirm = useConfirm();
    const snackbar = useSnackbar();

    //region Const
    const tableRef = React.createRef<any>();
    //endregion

    //region States
    const [transferListOpen, setTransferListOpen] = useState(false);
    const [filterBranch, setFilterBranch] = useState(0);
    //endregion

    //region Effects
    useEffect(() => {
        setFilterBranch(parseInt(context.user.branchId));
    }, [context.user.branchId]);

    useEffect(() => { //Trigger update on filterBranch change
        tableRef.current && tableRef.current.onQueryChange();
    }, [filterBranch]);
    //endregion

    //region Functions

    const downloadBordero = (id: number) => {
        axios.get(Constants.paths.ajaxBasePath + "spedizioni/bordero/download/?token=" + context.user.token + "&id=" + id, {responseType: "blob"})
            .then((res) => download(res.data, "Borderò #" + id + ".pdf"));
    }

    const deleteBordero = async (id: number) => {
        await axios.delete(Constants.paths.ajaxBasePath + "spedizioni/bordero/?token=" + context.user.token + "&id=" + id)
            .then(
                () => snackbar.enqueueSnackbar("Borderò #" + id + " eliminato.", {variant: 'info'})
            );
    }
    //endregion

    return <Box>
        <Grid2 container spacing={2} xs={12} lg={12}
               sx={{textAlign: 'center', margin: 'auto', placeContent: 'center'}}>
            <Grid2 xs={12}>
                <Typography
                    variant={'h1'}
                    textAlign={'center'}
                    color={context.theme.palette.text.primary}>
                    Borderò
                </Typography>
            </Grid2>
            <Grid2 xs={12} md={6} alignContent={'center'}>
                <Typography variant={'overline'}>Filtra per sede</Typography>
                <ToggleButtonGroup exclusive fullWidth value={filterBranch}
                                   onChange={(_, value) => setFilterBranch(value)}>
                    <ToggleButton value={0}>Tutte</ToggleButton>
                    <ToggleButton value={1}>Feroleto A.</ToggleButton>
                    <ToggleButton value={2}>Rende</ToggleButton>
                </ToggleButtonGroup>
            </Grid2>
            <Grid2 xs={12}>
                <MaterialTable
                    title={''}
                    tableRef={tableRef}
                    localization={MaterialTableLocalisationIT}
                    icons={MaterialTableIcons}
                    options={{
                        actionsColumnIndex: -1,
                        pageSize: 15,
                        pageSizeOptions: [15, 30, 50, 100],
                        filtering: false
                    }}
                    actions={[{
                        icon: Refresh,
                        tooltip: "Aggiorna",
                        onClick: () => tableRef.current && tableRef.current.onQueryChange(),
                        isFreeAction: true
                    }, {
                        icon: Add, tooltip: "Crea", onClick: () => setTransferListOpen(true), isFreeAction: true
                    }, {
                        icon: Download, tooltip: "Apri", onClick: (_, data) => {

                            // @ts-ignore
                            downloadBordero(data.id as number);
                        }
                    }, {
                        icon: Delete, tooltip: "Elimina", onClick: (_, data) => {
                            confirm(
                                {
                                    title: "Confermi l'eliminazione?",
                                    // @ts-ignore
                                    description: "Borderò #" + data.id
                                }
                            )
                                .then(() => deleteBordero(
                                    // @ts-ignore
                                    data.id as number
                                )).then(() => tableRef && tableRef.current.onQueryChange())
                                .catch(() => {
                                });
                        }
                    }]}
                    columns={[{
                        title: 'ID', field: 'id', sorting: false
                    }, {
                        title: "N. Spedizioni", field: 'n_spedizioni', sorting: false
                    }, {
                        title: "N. Colli", field: 'n_colli', sorting: false
                    }, {
                        title: "Sede", field: 'nome_sede', sorting: false
                    }, {
                        title: "Note", field: 'note', sorting: false
                    }, {
                        title: "Totale contrassegni",
                        field: 'totale_contrassegni',
                        sorting: false,
                        render: rd => rd.totale_contrassegni + " €"
                    }, {
                        title: "Data creazione",
                        field: 'data_creazione',
                        sorting: false,
                        render: rd => moment(rd.data_creazione).format("DD/MM/YYYY HH:mm")
                    }]}
                    data={query => new Promise((res) => {
                        axios.get(Constants.paths.ajaxBasePath + "spedizioni/bordero/?" + "token=" + context.user.token + "&page=" + query.page + "&limit=" + query.pageSize + "&branch=" + filterBranch + "&search=" + query.search)
                            .then(resp => {
                                let page = query.page;
                                if (resp.data['bordero'] && resp.data['bordero'].length < 1) page = 0; //Elimina il bug del maximum depth
                                res({
                                    data: resp.data['bordero'],
                                    page: page,
                                    totalCount: parseInt(resp.data['total'])
                                });
                            });
                    })}
                />
            </Grid2>
        </Grid2>
        <BorderoTransferList open={transferListOpen} onClose={() => {
            setTransferListOpen(false);
            tableRef.current && tableRef.current.onQueryChange()
        }}/>
    </Box>;
};

const Etichette = () => {
    const context = useContext(Context);
    const confirm = useConfirm();
    const snackbar = useSnackbar();

    //region Const
    const tableRef = React.createRef<any>();
    //endregion

    //region States
    const [labelGeneratorOpen, setLabelGeneratorOpen] = useState<boolean>(false);
    const [filterBranch, setFilterBranch] = useState<number>(0);
    //endregion

    //region Effects
    useEffect(() => {
        setFilterBranch(parseInt(context.user.branchId));
    }, [context.user.branchId]);

    useEffect(() => { //Trigger update on filterBranch change
        tableRef.current && tableRef.current.onQueryChange();
    }, [filterBranch]);
    //endregion

    //region Functions
    const downloadLabel = (id: number) => {
        axios.get(Constants.paths.ajaxBasePath + "spedizioni/etichette/download/?token=" + context.user.token + "&id=" + id, {responseType: "blob"})
            .then((res) => download(res.data, "Etichetta #" + id + ".pdf"));
    }

    const deleteLabel = async (id: number) => {
        await axios.delete(Constants.paths.ajaxBasePath + "spedizioni/etichette/?token=" + context.user.token + "&id=" + id)
            .then(
                () => snackbar.enqueueSnackbar("Etichetta #" + id + " eliminata.", {variant: 'info'})
            );
    }
    //endregion

    return <Box>
        <Grid2 container spacing={2} xs={12} lg={12}
               sx={{textAlign: 'center', margin: 'auto', placeContent: 'center'}}>
            <Grid2 xs={12}>
                <Typography
                    variant={'h1'}
                    textAlign={'center'}
                    color={context.theme.palette.text.primary}>
                    Etichette
                </Typography>
            </Grid2>
            <Grid2 xs={12} md={6} alignContent={'center'}>
                <Typography variant={'overline'}>Filtra per sede</Typography>
                <ToggleButtonGroup exclusive fullWidth value={filterBranch}
                                   onChange={(_, value) => setFilterBranch(value)}>
                    <ToggleButton value={0}>Tutte</ToggleButton>
                    <ToggleButton value={1}>Feroleto A.</ToggleButton>
                    <ToggleButton value={2}>Rende</ToggleButton>
                </ToggleButtonGroup>
            </Grid2>
            <Grid2 xs={12}>
                <MaterialTable
                    title={''}
                    tableRef={tableRef}
                    localization={MaterialTableLocalisationIT}
                    icons={MaterialTableIcons}
                    options={{
                        actionsColumnIndex: -1,
                        pageSize: 15,
                        pageSizeOptions: [15, 30, 50, 100],
                        filtering: false
                    }}
                    actions={[{
                        icon: Refresh,
                        tooltip: "Aggiorna",
                        onClick: () => tableRef.current && tableRef.current.onQueryChange(),
                        isFreeAction: true
                    }, {
                        icon: Add, tooltip: "Crea", onClick: () => setLabelGeneratorOpen(true), isFreeAction: true
                    }, {
                        icon: Download, tooltip: "Apri", onClick: (_, data) => {
                            // @ts-ignore
                            downloadLabel(data.id as number);
                        }
                    }, {
                        icon: Delete, tooltip: "Elimina", onClick: (_, data) => {
                            confirm(
                                {
                                    title: "Confermi l'eliminazione?",
                                    // @ts-ignore
                                    description: "Etichetta #" + data.id
                                }
                            )
                                .then(() => deleteLabel(
                                    // @ts-ignore
                                    data.id as number
                                )).then(() => tableRef && tableRef.current.onQueryChange())
                                .catch(() => {
                                    confirm({
                                        title: "Eliminazione fallita!",
                                        description: "Controlla borderò corrispondente.",
                                        hideCancelButton: true,
                                        confirmationText: "OK"
                                        // variant: 'danger'
                                    }).then(r => {
                                    })
                                });
                        }
                    }]}
                    columns={[{
                        title: 'ID',
                        field: 'id',
                        sorting: false
                    },
                        {
                            title: "Doc.",
                            field: "documento_rif",
                            sorting: false
                        },
                        {
                            title: "Cliente",
                            field: 'id_cliente',
                            render: (row) => "(" + row.id_cliente + ") " + row.ragione_sociale,
                            sorting: false
                        },
                        {
                            title: "ragione_sociale", field: 'ragione_sociale', sorting: false, hidden: true
                        },
                        {
                            title: "N. Colli",
                            field: 'n_colli',
                            sorting: false,
                            render: rd => <><FontAwesomeIcon
                                icon={rd.tipo_collo === "collo" ? (rd.n_colli > 1 ? faBoxesPacking : faBox) : faPalletBoxes}/> {rd.n_colli}</>
                        },
                        {
                            title: "Tipo", field: 'tipo_collo', hidden: true
                        },
                        {
                            title: "Sede", field: 'nome_sede', sorting: false
                        },
                        {
                            title: "Comune",
                            field: 'comune',
                            sorting: false,
                            render: rd => rd.comune + " (" + rd.prov + ")"
                        },
                        {
                            title: "Prov", field: 'prov', sorting: false, hidden: true
                        },
                        {
                            title: "Contrassegno",
                            field: 'contrassegno_importo',
                            sorting: false,
                            render: rd => rd.contrassegno_tipo ? (rd.contrassegno_tipo + " " + rd.contrassegno_importo + "€" + (parseInt(rd.contrassegno_con_estratto) ? " +Est." : "")) : "No"
                        },
                        {
                            title: "Contrassegno Tipo",
                            field: 'contrassegno_tipo',
                            hidden: true
                        },
                        {
                            title: "Estratto Conto",
                            field: 'contrassegno_con_estratto',
                            hidden: true
                        },
                        {
                            title: "Note", field: 'note', sorting: false
                        },
                        {
                            title: "Data creazione",
                            field: 'data_creazione',
                            render: rd => moment(rd.data_creazione).format("DD/MM/YYYY HH:mm"),
                            sorting: false
                        }]}
                    data={query => new Promise((res) => {
                        axios.get(Constants.paths.ajaxBasePath + "spedizioni/etichette/?" + "token=" + context.user.token + "&page=" + query.page + "&limit=" + query.pageSize + "&branch=" + filterBranch + "&search=" + query.search)
                            .then(resp => {
                                let page = query.page;
                                if (resp.data['bordero'] && resp.data['bordero'].length < 1) page = 0; //Elimina il bug del maximum depth
                                res({
                                    // @ts-ignore
                                    data: resp.data['labels'] as Label[],
                                    page: page,
                                    totalCount: parseInt(resp.data['total'])
                                });
                            });
                    })}
                />
            </Grid2>
        </Grid2>
        <GeneraEtichette open={labelGeneratorOpen} downloadFn={id => downloadLabel(id)} onClose={() => {
            setLabelGeneratorOpen(false);
            tableRef && tableRef.current.onQueryChange();
        }}/>
    </Box>;
}

const StoricoEtichette = () => {
    const context = useContext(Context);
    return <Box>
        <Grid2 container spacing={2} xs={12} lg={6} sx={{textAlign: 'center', margin: 'auto'}}>
            <Grid2 xs={12}>
                <Typography
                    variant={'h1'}
                    textAlign={'center'}
                    color={context.theme.palette.text.primary}>
                    Storico
                </Typography>
            </Grid2>
        </Grid2>
    </Box>;
}
export default function SpedizioniEtichette() {
    const context = useContext(Context);
    //region States
    // const [labelGeneratorOpen, setLabelGeneratorOpen] = useState<boolean>(false);
    const [activeTab, setActiveTab] = useState<TabType>("etichette");
    //endregion
    //region Functions
    const handleTabChange = (tab: TabType): void => {
        setActiveTab(tab);
    }
    //endregion


    return <>
        <ProfileCard/>
        <Paper
            style={{
                margin: !context.matches.sm ? context.theme.spacing(1) //XS
                    : !context.matches.md ? context.theme.spacing(2) //SM
                        : context.theme.spacing(4) //MD
            }}
        >
            <Tabs
                indicatorColor="primary"
                textColor="primary"
                value={activeTab}
                onChange={(_, v) => handleTabChange(v)}
                centered
            >
                <Tab
                    label="Etichette"
                    value={'etichette'}
                    icon={<FontAwesomeIcon icon={faTag}/>}
                />
                <Tab
                    label="Borderò"
                    value={'bordero'}
                    icon={<FontAwesomeIcon icon={faBoxesPacking}/>}
                />
            </Tabs>
        </Paper>
        {activeTab === 'etichette' ? <Etichette/> : activeTab === 'bordero' ?
            <Bordero/> : activeTab === 'storico' ?
                <StoricoEtichette/> : ""}
    </>;
}