import React from "react";
import {Box} from "@mui/material";

class Agenti extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <React.Fragment>
                <Box>Schede de che?</Box>
            </React.Fragment>
        )
    }
}

export default Agenti;
