import React from "react";
import ProfileCard from "../../../components/ProfileCard";
import axios from "axios";
import Context from "../../../context/Context";
import Constants from "../../../context/Constants";
import {
    Paper,
    Grid,
    Typography,
    Button,
    TextField,
    Card,
    ButtonBase,
} from "@mui/material";
import {enqueueSnackbar} from "notistack";
// import {withSnackbar} from "notistack";

class GeneraSku extends React.Component {
    static contextType = Context;

    constructor(props) {
        super();
        this.launchGeneration = this.launchGeneration.bind(this);
        this.generateSkuTable = this.generateSkuTable.bind(this);
        this.copyToClipboard = this.copyToClipboard.bind(this);
        this.state = {
            quantity: 10,
            collapsed: false,
            newSkus: [],
        };
    }

    launchGeneration(e) {
        axios
            .get(
                Constants.paths.ajaxBasePath + `prodotti/utils/generate-sku/?token=${this.context.user.token}&n=${this.state.quantity}`
            )
            .then((res) => {
                this.setState({
                    newSkus: res.data,
                });
            });
    }

    generateSkuTable() {
        return (
            <table style={{width: "100%"}}>
                <tbody>
                {this.state.newSkus.map((s) => (
                    <tr key={s}>
                        <td>
                            {s}
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        );
    }

    copyToClipboard() {
        navigator.clipboard.writeText(this.state.newSkus.join("\n"));
        enqueueSnackbar("Codici copiati!", {
            variant: "info",
        });
    }

    render() {
        // const {enqueueSnackbar, closeSnackbar} = this.props
        return (
            <>
                <ProfileCard/>
                <Paper
                    style={{
                        maxWidth: this.context.theme.breakpoints.values.md,
                        margin: 'auto',
                        marginTop: this.context.theme.spacing(6),
                        marginBotton: this.context.theme.spacing(6),
                    }}
                >
                    <Grid container spacing={3} justifyContent="center" alignItems="center" sx={{textAlign: 'center'}}>
                        <Grid xs={12} item>
                            <Typography variant="h2">Genera SKU</Typography>
                        </Grid>
                        <Grid xs={"auto"} item style={{textAlign: "left"}}>
                            <TextField
                                value={this.state.quantity}
                                label="Quantità"
                                variant="outlined"
                                onChange={(e) => this.setState({quantity: e.target.value})}
                            />
                        </Grid>
                        <Grid xs={12} item>
                            <Button
                                disabled={!(this.state.quantity)}
                                onClick={this.launchGeneration}
                                variant="contained"
                                style={{
                                    width: "60%",
                                    marginBottom: this.context.theme.spacing(4)
                                }}
                            >
                                Genera
                            </Button>

                        </Grid>
                    </Grid>
                </Paper>
                <br/>
                <br/>
                <Grid container alignItems={'center'} justifyContent={'center'}>
                    <Grid item xs={4}>
                        <ButtonBase
                            disabled={this.state.newSkus.length < 1}
                            onClick={this.copyToClipboard}
                            sx={{width:'100%'}}
                        >
                            <Card
                                variant="outlined"
                                style={{
                                    // maxWidth: this.context.theme.breakpoints.values.sm,
                                    padding: this.context.theme.spacing(2),
                                    margin: "auto",
                                }}
                            >
                                <Typography variant="overline">
                                    {this.state.newSkus.length > 0
                                        ? "SKU generati"
                                        : "Pronto per la generazione"}
                                </Typography>
                                {this.generateSkuTable()}

                                {this.state.newSkus.length > 0 &&
                                    <Typography variant="caption"
                                                style={{display: "block", marginTop: this.context.theme.spacing(1)}}>
                                        Clicca per copiare nella clipboard
                                    </Typography>
                                }
                            </Card>
                        </ButtonBase>
                    </Grid>
                </Grid>

            </>
        );
    }
}

export default GeneraSku;
