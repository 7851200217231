import React from "react";
import {
  Select,
  Input,
  MenuItem,
  Checkbox,
  ListItemText,
  FormControl,
  InputLabel,
  Chip,
  Typography,
  Button,
  Popover,
  List,
  ListItem,
  IconButton
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

class Render extends React.Component {
  constructor(props) {
    super(props);
    this.handlePopoverClick = this.handlePopoverClick.bind(this);
    this.handlePopoverClose = this.handlePopoverClose.bind(this);
    this.state = {
      popoverOpen: false,
      popoverAnchor: undefined
    };
    console.log(props);
  }
  handlePopoverClick(e) {
    this.setState({ popoverOpen: true, popoverAnchor: e.currentTarget });
  }
  handlePopoverClose() {
    this.setState({ popoverOpen: false });
  }
  render() {
    return (
      <div>
        <Button
          style={{ width: "100%" }}
          aria-describedby={"Mostra assegnazioni"}
          variant="contained"
          color="primary"
          onClick={this.handlePopoverClick}
          size="small"
          startIcon={<FontAwesomeIcon icon={faEye} />}
          disabled={this.props.value.assegnazioniArray.length===0}
        >
          {this.props.value.assegnazioniArray.length}
        </Button>
        <Popover
          id={this.props.value.id}
          open={this.state.popoverOpen}
          anchorEl={this.state.popoverAnchor}
          onClose={this.handlePopoverClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center"
          }}
        >
          {this.props.value.assegnazioniArray.length > 0 ? (
            <List dense={false}>
              {this.props.value.assegnazioniArray.map(v => (
                <ListItem key={v}>
                  <ListItemText
                    primary={v}
                    //secondary={"Venduto:"}
                  />
                </ListItem>
              ))}
            </List>
          ) : (
            ""
          )}
        </Popover>
      </div>
    );
  }
}

class Edit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    console.log("====================================");
    console.log(this.props.input_props);
    console.log("====================================");
  }
  render() {
    return (
      <FormControl>
        <InputLabel id="assegnazioni-label">Tag</InputLabel>
        <Select
          labelId="assegnazioni-label"
          id="assegnazioni"
          multiple
          value={this.props.input_props.value} //{this.props.input_props.value.split(",")||""}
          onChange={e => this.props.input_props.onChange(e.target.value)}
          input={<Input />}
          renderValue={selected => "Selezionati: " + selected.length}
          MenuProps={MenuProps}
        >
          {this.props.selects.map(name => (
            <MenuItem key={name} value={name}>
              <Checkbox checked={this.props.input_props.value.includes(name)} />
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }
}

export { Render as TableSelectRender, Edit as TableSelectEdit };
