import { faInfo, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Backdrop, Button, Chip, Collapse } from "@mui/material";
import axios from "axios";
import MaterialTable from "material-table";
import React from "react";
import { ProductAttributesSelector } from "../../components/DialogSelector";
import Constants from "../../context/Constants";
import Context from "../../context/Context";
import materialTableIcons from "../../support/materialTableIcons";
import materialTableLocalisationIT from "../../support/materialTableLocalisationIT";

export default class Attributi extends React.Component {
  static contextType = Context;
  constructor(props) {
    super(props);
    this.getData = this.getData.bind(this);
    this.handleRowAdd = this.handleRowAdd.bind(this);
    this.handleDisambDialogOpen = this.handleDisambDialogOpen.bind(this);
    this.handleDisambDialogClose = this.handleDisambDialogClose.bind(this);
    this.handleDisambSelect = this.handleDisambSelect.bind(this);
    this.handleDisambDelete = this.handleDisambDelete.bind(this);
    this.state = {
      disambSelector: {
        attrId: 0,
        selectedIds: [],
        open: false,
      },
    };
  }

  componentDidMount() {
    this.getData();
  }
  getData(returnOnly = false) {
    axios
      .get(
        `${Constants.paths.ajaxBasePath}prodotti/attributi/?token=${this.context.user.token}`
      )
      .then((res) => {
        console.log(res.data);
        let newData = res.data.map((d) => ({
          ...d,
        }));
        this.setState({ attrData: newData });
        console.log(newData);
      });
  }

  handleDisambDialogOpen(attrId) {
    console.log(attrId);
    this.setState((prev) => ({
      disambSelector: {
        ...prev.disambSelector,
        open: true,
        attrId: attrId,
        selectedIds: this.state.attrData
          .find((a) => a.id == attrId)
          .disambiguazione.map((d) => d.id),
      },
    }));
  }

  handleDisambDialogClose() {
    this.setState((prev) => ({
      disambSelector: { ...prev.disambSelector, open: false },
    }));
  }

  async handleDisambSelect(attrId, disambAttrs) {
    let promises = [];
    for (let i = 0; i < disambAttrs.length; i++) {
      const d = disambAttrs[i];
      const toPost = {
        token: this.context.user.token,
        ids: [attrId, d.id],
      };
      promises.push(
        new Promise((res, rej) =>
          axios
            .post(
              `${Constants.paths.ajaxBasePath}prodotti/attributi/disambiguazione/`,
              toPost
            )
            .then((response) => res())
        )
      );
    }
    await Promise.all(promises);
    this.getData();
    this.handleDisambDialogClose();
  }

  async handleDisambDelete(attrId, disambIds) {
    let promises = [];
    for (let i = 0; i < disambIds.length; i++) {
      const d = disambIds[i];
      const toPost = {
        token: this.context.user.token,
        ids: [attrId, d],
      };
      promises.push(
        new Promise((res, rej) =>
          axios
            .delete(
              `${Constants.paths.ajaxBasePath}prodotti/attributi/disambiguazione/`,
              { data: toPost }
            )
            .then((response) => res())
        )
      );
    }
    await Promise.all(promises);
    this.getData();
  }

  handleRowAdd(newData, onSuccess = false, onReject = false) {
    const toPost = {
      token: this.context.user.token,
      row: {
        nome: newData.nome,
        abbreviazione: newData.abbreviazione,
        prefisso: newData.prefisso,
        legacy_prefisso: newData.legacy_prefisso,
        suffisso: newData.suffisso,
        legacy_suffisso: newData.legacy_suffisso,
        tipo: newData.tipo,
        regola: newData.regola,
        legacy_regola: newData.legacy_regola,
        note: newData.note,
      },
    };
    axios
      .post(`${Constants.paths.ajaxBasePath}prodotti/attributi/`, toPost)
      .then((res) => {
        if (onSuccess) onSuccess();
        this.getData();
      })
      .catch((err) => {
        alert("Attributo non valido o duplicato");
        if (onReject) onReject();
      });
  }
  handleRowUpdate(newData, oldData, onSuccess = false, onReject = false) {
    const toPost = {
      token: this.context.user.token,
      row: {
        id: newData.id,
        nome: newData.nome,
        abbreviazione: newData.abbreviazione,
        prefisso: newData.prefisso,
        legacy_prefisso: newData.legacy_prefisso,
        suffisso: newData.suffisso,
        legacy_suffisso: newData.legacy_suffisso,
        tipo: newData.tipo,
        regola: newData.regola,
        legacy_regola: newData.legacy_regola,
        disambiguazione: newData.disambiguazione.map((d) => d.id),
        note: newData.note,
      },
    };
    axios
      .patch(`${Constants.paths.ajaxBasePath}prodotti/attributi/`, toPost)
      .then((res) => {
        if (onSuccess) onSuccess();
        this.getData();
      })
      .catch((err) => {
        alert("Attributo non valido o duplicato");
        if (onReject) onReject();
      });
  }

  render() {
    return (
      <React.Fragment>
        <MaterialTable
          title="Attributi"
          icons={materialTableIcons}
          localization={materialTableLocalisationIT}
          isLoading={this.state.fetching}
          columns={[
            {
              title: "ID",
              field: "id",
              editable: "never",
            },
            {
              title: "LG Prefisso",
              field: "legacy_prefisso",
              editable: "always",
              width: "5%",
            },
            {
              title: "Prefisso",
              field: "prefisso",
              editable: "always",
              width: "5%",
            },

            {
              title: "Nome",
              field: "nome",
              editable: "always",
              width: "70%",
            },
            {
              title: "Abbrev.",
              field: "abbreviazione",
              editable: "always",
              width: "40%",
            },
            {
              title: "Suffisso",
              field: "suffisso",
              editable: "always",
              width: "5%",
            },
            {
              title: "LG Suffisso",
              field: "legacy_suffisso",
              editable: "always",
              width: "5%",
            },
            {
              title: "Tipo",
              field: "tipo",
              editable: "always",
              width: "5%",
              lookup: {
                numerico: "Numerico",
                libero: "Libero",
                selezione: "Selezione",
                colore: "Colore",
                bool: "Bool",
                dimensione: "Dimensione",
                dimensione1d: "Dimensione 1D",
                dimensione2d: "Dimensione 2D",
                dimensione3d: "Dimensione 3D",
                quantita: "Quantità",
                regex: "Regex",
                peso: "Peso",
              },
            },
            {
              title: "Regola",
              field: "regola",
              editable: "always",
              width: "10%",
            },
            {
              title: "LG Regola",
              field: "legacy_regola",
              editable: "always",
              width: "10%",
            },
            {
              title: "Disambig.",
              field: "disambiguazione",
              editable: "always",
              editComponent: (row_data) => (
                <React.Fragment>
                  {row_data.rowData.disambiguazione?.map((d) => (
                    <Chip
                      onDelete={(e) =>
                        this.handleDisambDelete(row_data.rowData.id, [d.id])
                      }
                      size="small"
                      label={d.nome}
                    />
                  ))}
                  <Button
                    onClick={(e) =>
                      this.handleDisambDialogOpen(row_data.rowData.id)
                    }
                    size="small"
                    fullWidth
                    variant="outlined"
                  >
                    Aggiungi
                  </Button>
                </React.Fragment>
              ),
              render: (row_data) =>
                row_data.disambiguazione.map((d) => (
                  <Chip size="small" label={d.nome} />
                )),
            },
            {
              title: "Note",
              field: "note",
              editable: "always",
            },
          ]}
          options={{
            tableLayout: "auto",
            search: true,
            pageSize: 50,
            pageSizeOptions: [25, 50, 100, 150, 200],
            //   filtering: true,
            //   sorting: true,
            addRowPosition: "first",
            actionsColumnIndex: -1,
          }}
          data={this.state.attrData}
          editable={{
            onRowAdd: (newData) =>
              new Promise((res, rej) => {
                this.handleRowAdd(newData, res, rej);
              }),
            onRowUpdate: (newData, oldData) =>
              new Promise((res, rej) => {
                this.handleRowUpdate(newData, oldData, res, rej);
              }),
          }}
        />
        <ProductAttributesSelector
          multiple
          open={this.state.disambSelector.open}
          onClose={(e) => this.handleDisambDialogClose()}
          onSelect={(attr) =>
            this.handleDisambSelect(this.state.disambSelector.attrId, attr)
          }
          selectedIds={[
            ...this.state.disambSelector.selectedIds,
            this.state.disambSelector.attrId,
          ]}
          enableSelected
        />
      </React.Fragment>
    );
  }
}
