import React from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Chip,
    Typography,
    List,
    ListItem,
    Button,
    FormControlLabel,
    Divider,
    LinearProgress,
    Switch,
    Box,
    Checkbox,
    Backdrop,
    CircularProgress,
    Grid, ListItemButton,
} from "@mui/material";
import axios from "axios";
import Constants from "../context/Constants";
import Context from "../context/Context";
import {SlideUpTransition} from "./Transitions";
import MiniSearch from "minisearch";
import {BottomScrollListener} from "react-bottom-scroll-listener";
import ProductImage from "./ProductImage";
import ListInventoryDisplay from "./ListInventoryDisplay";
import {
    faArrowDown,
    faArrowUp,
    faHistory,
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ProductExtendedDescriptionPopup from "./ProductExtendedDescriptionPopup";

const defaultMinisearchOptions = {
    boost: {
        id: 10,
    },
    fuzzy: 1,
    combineWith: "AND",
    prefix: true,
};

class DialogSelector extends React.Component {
    static contextType = Context;

    constructor(props) {
        super(props);
        this.getMinisearchData = this.getMinisearchData.bind(this);
        this.elasticSearch = this.elasticSearch.bind(this);
        this.filterElasticSearchResult = this.filterElasticSearchResult.bind(this);
        this.elasticSearchAvailable = false;
        this.elasticSearchFilter = "";
        this.elasticSearchCancelToken = null;
        this.elasticSearchUrl = "";
        this.miniSearch = this.miniSearch.bind(this);
        this.initMinisearch = this.initMinisearch.bind(this);
        this.handleCheckedChange = this.handleCheckedChange.bind(this);
        this.state = {
            fetching: false,
            results: [],
            srcData: [],
            searchStr: "",
            selectedIds: new Set(),
            lastSearch: "",
            storedResults: [], //Usato per salvare alcuni prodotti (ElasticSearch)
        };
        this.searchTitle = "Seleziona";
        this.minisearchIdField = "id";
        this.minisearchFields = ["id"]; //* Should be edited for each class extension
        this.minisearchBoost = {id: 10}; //* Should be edited for each class extension
        this.minCharsToSearch = 3;
        this.maxResults = parseInt(this.props.maxResults) || 20;
        this.fetchingUpdates = 0;
        this.useElasticSearch = false;
    }

    shouldComponentUpdate(nextProps) {
        if (this.state.fetching) {
            this.fetchingUpdates = 0;
            return true;
        } else if (this.fetchingUpdates < 1) {
            this.fetchingUpdates++;
            return true;
        }
        return nextProps.open || (this.props.open && !nextProps.open) || false;
        //*Rerender only if the dialog is open, or if it's closing
    }

    componentDidMount() {
        this.useElasticSearch =
            !!(this.elasticSearchAvailable && this.context.device.useElasticSearch);
        if (!this.useElasticSearch) this.getMinisearchData(true);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.open === false && this.props.open === true) {
            // ? Si sta aprendo
            if (this.useElasticSearch) this.elasticSearch(this.props.initialSearch);
            else this.miniSearch(this.props.initialSearch);
            this.setState({selectedIds: new Set(), storedResults: []});
        } else if (prevProps.open === true && this.props.open === false) {
            // ? Si sta chiudendo
            if (this.elasticSearchCancelToken)
                this.elasticSearchCancelToken.cancel("Annullo ricerca per chiusura");
            this.setState({fetching: false});
        }
    }

    getMinisearchData(isInit = false) {
        return; //* This method has to be redefined for each class extension
    }

    initMinisearch(finalData, isInit = false) {
        // console.log("Init called", finalData);
        if (finalData.length > 0) {
            this.minisearch = new MiniSearch({
                idField: this.minisearchIdField,
                fields: this.minisearchFields,
                storeFields: Object.keys(finalData[0]),
                searchOptions: {
                    ...defaultMinisearchOptions,
                    boost: this.minisearchBoost,
                },
            });
            this.minisearch.addAllAsync(finalData).then(() => {
                console.log("minisearch finished");
                this.setState({fetching: false}, () => {
                    this.miniSearch((isInit && this.props.initialSearch) || "");
                });
            });
        } else {
            this.setState({fetching: false});
        }
    }

    filterElasticSearchResult(result) {
        return result;
    }

    elasticSearch(e = undefined, loadMore = false) {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        let finalData = [];
        let hasMoreResults = false;
        if (this.elasticSearchCancelToken)
            this.elasticSearchCancelToken.cancel("Annullo ricerca precedente");
        this.elasticSearchCancelToken = source;
        // let promise = new Promise((res, rej)=>{

        // });
        const thisSearch = loadMore
            ? this.state.lastSearch
            : typeof e == "undefined"
                ? ""
                : typeof e == "string"
                    ? e
                    : e.target.value.toLowerCase();
        axios
            .get(
                Constants.paths.ajaxBasePath +
                this.elasticSearchUrl +
                "&filter=" +
                (this.props.filter || this.elasticSearchFilter) +
                "&token=" +
                this.context.user.token +
                "&query=" +
                encodeURIComponent(thisSearch) +
                "&from=" +
                (loadMore ? this.state.nResults : "0") +
                "&size=" +
                (this.maxResults + 1),
                {
                    cancelToken: source.token,
                }
            )
            .then((res) => {
                hasMoreResults = res.data.length > this.maxResults; //Verifica se sono disponibili altri risultati
                res.data.slice(0, this.maxResults);
                finalData = this.filterElasticSearchResult(res.data);
                this.setState((prev) => ({
                    lastSearch: thisSearch,
                    results: loadMore ? [...prev.results, ...finalData] : finalData,
                    nResults: prev.results.length + finalData.length,
                    fetching: false,
                    hasMoreResults: hasMoreResults,
                }));
            })
            .catch((e) => {
                if (axios.isCancel(e)) {
                    console.log("Annullata ricerca: " + thisSearch);
                } else {
                    console.warn("Errore durante la ricerca: ", e);
                }
                this.setState({fetching: false});
            });
    }

    miniSearch(e = undefined, loadMore = false) {
        this.setState({fetching: true}, () => {
            let promise = new Promise((res, rej) => {
                const minChars = this.minCharsToSearch;
                const thisSearch = loadMore
                    ? this.state.lastSearch
                    : typeof e == "undefined"
                        ? ""
                        : typeof e == "string"
                            ? e
                            : e.target.value.toLowerCase();
                const srcData = this.state.srcData;
                let results = [];
                if (thisSearch == "" || thisSearch.length < minChars) {
                    results = srcData;
                } else {
                    results = this.minisearch.search(thisSearch);
                    //console.log(results);
                }

                const SetState = () =>
                    this.setState(
                        (prev) => ({
                            lastSearch: thisSearch,
                            results: results.slice(
                                0,
                                loadMore
                                    ? (prev.nResults || 0) + this.maxResults
                                    : this.maxResults
                            ),
                            fetching: false,
                            hasMoreResults:
                                results.length > (prev.nResults || 0) + this.maxResults, //Avrebbe altri risultati da mostrare?
                        }),
                        (_) => {
                            // console.log(results);
                            res();
                        }
                    );

                if (!loadMore)
                    this.setState(
                        (prev) => ({results: [], nResults: prev.results?.length || 0}),
                        () => SetState()
                    );
                else
                    this.setState(
                        (prev) => ({nResults: prev.results?.length || 0}),
                        SetState()
                    );
            });
        });
    }

    onChildFilterChange() {
        return; //Define in children
    }

    handleCheckedChange(checked, id) {
        this.setState((prev) => {
            let newSelectedIds = prev.selectedIds;
            let newStoredResults = prev.storedResults;
            if (checked)
                newStoredResults.push(this.state.results.find((r) => r.id == id));
            else newStoredResults = newStoredResults.filter((r) => r.id != id);
            checked ? newSelectedIds.add(id) : newSelectedIds.delete(id);
            return {
                selectedIds: newSelectedIds,
                storedResults: newStoredResults,
            };
        });
    }

    render() {
        return (
            <Context.Consumer>
                {(consumer_data) => (
                    <React.Fragment>
                        {this.props.disableLoadingBackdrop || (
                            <Backdrop open={this.state.fetching} style={{zIndex: 1000}}>
                                <CircularProgress
                                    disableShrink
                                    style={{position: "absolute"}}
                                />
                                <Box
                                    color={consumer_data.theme.palette.text.primary}
                                    style={{
                                        position: "absolute",
                                        marginTop: consumer_data.theme.spacing(10),
                                    }}
                                >
                                    <Typography variant="h5">Caricamento...</Typography>
                                </Box>
                            </Backdrop>
                        )}
                        <Dialog
                            fullWidth={true}
                            fullScreen={this.props.fullScreen || !consumer_data.matches.sm}
                            maxWidth={this.props.maxWidth || "md"}
                            //style={{ top: 0, bottom: "auto" }}
                            open={this.props.open}
                            TransitionComponent={SlideUpTransition}
                            onClose={() => {
                                this.setState({selectedIds: new Set()});
                                this.props.onClose();
                            }}
                            //onOpen={this.props.onOpen}
                            aria-labelledby="alert-dialog-slide-title"
                            aria-describedby="alert-dialog-slide-description"
                        >
                            {this.state.fetching && <LinearProgress variant="query"/>}
                            <DialogTitle id="alert-dialog-slide-title">
                                {this.props.showTitle ? this.searchTitle : undefined}
                                {this.useElasticSearch || this.state.srcData.length > 0 ? (
                                    <TextField
                                        style={{
                                            width: "100%",
                                            marginTop: consumer_data.theme.spacing(2),
                                        }}
                                        //id="search-customer-dialog-input"
                                        variant="outlined"
                                        label="Cerca"
                                        defaultValue={this.props.initialSearch || ""}
                                        placeholder="Cerca..."
                                        onChange={
                                            this.useElasticSearch
                                                ? this.elasticSearch
                                                : consumer_data.device.lowSpec
                                                    ? null
                                                    : this.miniSearch
                                        }
                                        //onBlur={consumer_data.device.lowSpec?this.search:null}
                                        autoComplete="false"
                                        autoFocus
                                        onKeyDown={
                                            this.useElasticSearch
                                                ? (e) => e.key == "Enter" && this.elasticSearch(e)
                                                : consumer_data.device.lowSpec
                                                    ? (e) => e.key == "Enter" && this.miniSearch(e)
                                                    : null
                                        }
                                        //inputRef={(input) => input && input.focus()} //!Triggers ugly jumps, fix it
                                    />
                                ) : (
                                    ""
                                )}
                            </DialogTitle>
                            <BottomScrollListener
                                onBottom={() => this.setState({showLoadMoreBtn: true})}
                                offset={0}
                            >
                                {(scrollRef) => (
                                    <DialogContent ref={scrollRef} style={{height: 9999}}>
                                        <List>
                                            {this.state.results.length > 0 ? (
                                                this.state.results.map((r) => {
                                                    return (
                                                        <React.Fragment key={r.id}>
                                                            <ListItemButton
                                                                disabled={
                                                                    r.id == "99999" //Il prodotto generico può essere duplicato nell'ordine
                                                                        ? false
                                                                        : this.props.selectedIds
                                                                            ? this.props.selectedIds instanceof Set
                                                                                ? this.props.selectedIds.has(r.id) //Set
                                                                                : typeof this.props.selectedIds == "String"
                                                                                    ? this.props.selectedIds == r.id //String | Int
                                                                                    : this.props.selectedIds.includes(r.id) //Array
                                                                            : false
                                                                }
                                                                disableGutters={!consumer_data.matches.sm}
                                                                button={!this.props.multiple}
                                                                onClick={
                                                                    !this.props.multiple
                                                                        ? (e) => {
                                                                            this.props.onSelect({...r});
                                                                        }
                                                                        : null
                                                                }
                                                            >
                                                                {this.props.multiple && (
                                                                    <Checkbox
                                                                        checked={this.state.selectedIds.has(r.id)}
                                                                        disabled={
                                                                            r.id == "99999" //Il prodotto generico può essere duplicato nell'ordine
                                                                                ? false
                                                                                : this.props.selectedIds
                                                                                    ? this.props.selectedIds instanceof Set
                                                                                        ? this.props.selectedIds.has(r.id)
                                                                                        : typeof this.props.selectedIds ==
                                                                                        "String"
                                                                                            ? this.props.selectedIds == r.id
                                                                                            : this.props.selectedIds.includes(
                                                                                                r.id
                                                                                            )
                                                                                    : false
                                                                        }
                                                                        color="primary"
                                                                        size="large"
                                                                        onChange={(e) =>
                                                                            this.handleCheckedChange(
                                                                                e.target.checked,
                                                                                r.id
                                                                            )
                                                                        }
                                                                    />
                                                                )}
                                                                {this.props.showProdImg && (
                                                                    <ProductImage
                                                                        containerSize="3.5rem"
                                                                        buttonStyle={{
                                                                            marginRight:
                                                                                consumer_data.theme.spacing(1),
                                                                        }}
                                                                        style={{
                                                                            textAlign: "center",
                                                                        }}
                                                                        img={r.img}
                                                                        cod={r.cod}
                                                                    />
                                                                )}
                                                                {r.optionsLabel}
                                                                {this.props.showInventory && (
                                                                    <ListInventoryDisplay
                                                                        useDirectStocks
                                                                        directFeroleto={r.stock_feroleto}
                                                                        directRende={r.stock_rende}
                                                                        directOfferte={r.stock_offerte}
                                                                        directUseOnlyOfferte={(r.stato_prod == 6)}
                                                                        id_prod={r.id}
                                                                        style={{
                                                                            marginLeft:
                                                                                this.context.theme.spacing(1.5),
                                                                            maxWidth: "4rem",
                                                                        }}
                                                                    />
                                                                )}
                                                            </ListItemButton>
                                                            <Divider/>
                                                        </React.Fragment>
                                                    );
                                                })
                                            ) : (
                                                <React.Fragment>
                                                    <ListItem alignItems="center">
                                                        <Typography variant="subtitle1">
                                                            Nessun risultato
                                                        </Typography>
                                                    </ListItem>
                                                </React.Fragment>
                                            )}
                                        </List>
                                    </DialogContent>
                                )}
                            </BottomScrollListener>
                            <DialogActions>
                                {this.state.hasMoreResults && (
                                    <Button
                                        // disabled={!this.state.showLoadMoreBtn}
                                        onClick={() => {
                                            this.setState({showLoadMoreBtn: false}, () => {
                                                if (this.useElasticSearch)
                                                    this.elasticSearch(false, true);
                                                else this.miniSearch(false, true);
                                            });
                                        }}
                                    >
                                        Carica altro
                                    </Button>
                                )}
                                {this.props.multiple && (
                                    <Typography color="secondary">
                                        {this.state.selectedIds.size} selezionati
                                    </Typography>
                                )}
                                {this.state.hasChildAction ? (
                                    <FormControlLabel
                                        // style={{display:'none'}}
                                        control={
                                            <Switch
                                                disabled={this.state.fetching}
                                                checked={this.state.isChildFilterActive}
                                                onChange={(e) => {
                                                    this.setState(
                                                        (prev) => ({
                                                            isChildFilterActive: !prev.isChildFilterActive,
                                                        }),
                                                        this.onChildFilterChange
                                                    );
                                                }}
                                                name="filtroClienti"
                                                color="primary"
                                            />
                                        }
                                        labelPlacement="start"
                                        label="Mostra solo clienti assegnati"
                                    />
                                ) : null}
                                {this.props.onEmpty ? (
                                    <Button
                                        onClick={this.props.onEmpty}
                                        variant={this.props.emptyButtonVariant || "text"}
                                    >
                                        {this.props.emptyText || "Svuota"}
                                    </Button>
                                ) : (
                                    ""
                                )}
                                <Button onClick={this.props.onClose}>Annulla</Button>
                                {this.props.multiple && (
                                    <Button
                                        onClick={() => {
                                            if (this.useElasticSearch)
                                                this.props.onSelect([
                                                    ...this.state.storedResults.filter((p) =>
                                                        this.state.selectedIds.has(p.id)
                                                    ),
                                                ]);
                                            else
                                                this.props.onSelect([
                                                    ...this.state.srcData.filter((p) =>
                                                        this.state.selectedIds.has(p.id)
                                                    ),
                                                ]);
                                        }}
                                    >
                                        Conferma
                                    </Button>
                                )}
                            </DialogActions>
                        </Dialog>
                    </React.Fragment>
                )}
            </Context.Consumer>
        );
    }
}

class CustomerSelector extends DialogSelector {
    constructor(props) {
        super(props);
        this.elasticSearchAvailable = true;
        this.minisearchFields = ["id", "ragione_sociale", "comune"];
        this.minisearchBoost = {id: 10};
        this.searchTitle = "Seleziona cliente";
        this.state.isChildFilterActive = true; //Set default to true
        this.filterData = this.filterData.bind(this);
        this.onChildFilterChange = this.filterData;
        this.elasticSearchFilter = "attivi";
        this.elasticSearchUrl = "clienti/elastic/search/?";
    }

    componentDidMount() {
        super.componentDidMount();
        this.setState({
            hasChildAction:
                this.context.user.assignedZones &&
                this.context.user.assignedZones.length > 0,
        });
    }

    filterData(data, isInit = false) {
        console.log(data);
        if (!data) data = this.state.srcData;
        let finalData = data;
        if (this.state.isChildFilterActive) {
            if (this.context.user.assignedZones.length > 0) {
                let assignedZones = this.context.user.assignedZones;
                let newFinalData = [];
                finalData.forEach((d) => {
                    if (assignedZones.includes(d.prov)) newFinalData.push(d);
                });
                finalData = newFinalData;
            }
        }
        this.setState(
            {
                srcData: finalData,
                fetching: false,
            },
            () => {
                this.initMinisearch(finalData, isInit);
            }
        );
    }

    filterElasticSearchResult(result) {
        return result.map((d) => ({
            ...d,
            optionsLabel: (
                <Grid item container wrap={"nowrap"}>
                    <Grid item xs={2}>
                        <Chip
                            size="small"
                            label={d.id}
                            style={{marginRight: this.context.theme.spacing(1)}}
                        />
                    </Grid>
                    <Grid item xs={7}>
                        <Typography style={{width: "100%"}}>{d.ragione_sociale}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography
                            disabled
                            variant="caption"
                            style={{
                                textAlign: "right",
                                float: "right",
                                justifySelf: "end",
                                width: "100%",
                                // marginLeft: this.context.theme.spacing(1),
                            }}
                            fontWeight="light"
                            fontStyle="italic"
                            align="right"
                        >

                            {d.comune}
                        </Typography>
                    </Grid>
                </Grid>
            ),
        }));
    }

    getMinisearchData(isInit = false) {
        this.setState({fetching: true});
        let finalData = [];
        axios
            .get(
                Constants.paths.ajaxBasePath +
                "clienti/?filter=attivi&token=" +
                this.context.user.token
            )
            .then((res) => {
                finalData = res.data.map((d) => {
                    return {
                        ...d,
                        optionsLabel: (
                            <React.Fragment>
                                <Chip
                                    size="small"
                                    label={d.id}
                                    style={{marginRight: this.context.theme.spacing(1)}}
                                />
                                <Typography style={{width: "80%"}}>
                                    {d.ragione_sociale}
                                </Typography>
                                <Typography
                                    disabled
                                    variant="caption"
                                    style={{
                                        textAlign: "right",
                                        float: "right",
                                        justifySelf: "end",
                                        width: "100%",
                                        // marginLeft: this.context.theme.spacing(1),
                                    }}
                                    fontWeight="light"
                                    fontStyle="italic"
                                    align="right"
                                >
                                    {d.comune}
                                </Typography>
                            </React.Fragment>
                        ),
                    };
                });
                this.setState({srcData: finalData}, () =>
                    this.filterData(finalData, isInit)
                );
            });
        //console.log(finalData);
    }
}

class ProductSelector extends DialogSelector {
    constructor(props) {
        super(props);
        this.elasticSearchAvailable = true;
        this.searchTitle = "Seleziona";
        this.elasticSearchUrl = "prodotti/elastic/search/?with_stock";
        this.elasticSearchFilter = "vendita";
        this.minisearchFields = [
            "cod",
            "brand_nome",
            "old_cod",
            "uf_cod",
            "descrizione",
            "descrizione_estesa",
            "cod_barre",
        ]; //* Should be edited for each class extension
        this.minisearchBoost = {
            cod: 10,
            uf_cod: 8,
            old_cod: 7,
            descrizione: 5,
            descrizione_estesa: 5,
            brand_nome: 20,
        }; //* Should be edited for each class extension
        this.searchTitle = "Seleziona prodotto";
    }

    filterElasticSearchResult(result) {
        return result.map((d) => {
            return {
                ...d,
                optionsLabel: (
                    <React.Fragment>
                        {/* <Typography variant="caption" color="textSecondary" style={{}}>SKU: </Typography>
  <Typography variant="caption">{d.cod}</Typography> */}
                        <Chip
                            size="small"
                            label={d.uf_cod || d.v_cod}
                            style={{marginRight: this.context.theme.spacing(1)}}
                        />
                        {d.note_commerciali &&
                            <Typography color={'secondary'} variant={"caption"}
                                        style={{marginRight: this.context.theme.spacing(1)}}>
                                <i>[{d.note_commerciali}]</i>
                            </Typography>
                        }
                        <Typography variant="body1" style={{width: "100%"}}>
                            {d.descrizione}
                            {d.descrizione_estesa && (
                                <ProductExtendedDescriptionPopup withButton id={d.id}/>
                            )}
                        </Typography>
                        <Typography
                            style={{
                                alignSelf: "center",
                            }}
                        >
                            {parseFloat(d.v_prezzo).toFixed(2).toString().replace(".", ",") +
                                "€" || "N/D"}
                        </Typography>
                    </React.Fragment>
                ),
            };
        });
    }

    getMinisearchData(isInit = false) {
        this.setState({fetching: true});
        let finalData = [];
        const thisFilter = this.props.filter || "attivi";
        axios
            .get(
                Constants.paths.ajaxBasePath +
                `prodotti/?filter=${thisFilter}&token=` +
                this.context.user.token
            )
            .then((res) => {
                finalData = res.data.map((d) => {
                    return {
                        ...d,
                        optionsLabel: (
                            <React.Fragment>
                                <Chip
                                    size="small"
                                    label={d.uf_cod || d.v_cod}
                                    style={{marginRight: this.context.theme.spacing(1)}}
                                />
                                <Typography variant="body1" style={{width: "100%"}}>
                                    {d.descrizione}
                                </Typography>
                                <ProductExtendedDescriptionPopup withButton id={d.id_prod}/>
                                <Typography
                                    style={{
                                        alignSelf: "center",
                                    }}
                                >
                                    {parseFloat(d.v_prezzo)
                                        .toFixed(2)
                                        .toString()
                                        .replace(".", ",") + "€" || "N/D"}
                                </Typography>
                            </React.Fragment>
                        ),
                    };
                });
                this.setState(
                    {
                        srcData: finalData,
                        fetching: false,
                    },
                    () => {
                        this.initMinisearch(finalData, isInit);
                    }
                );
            });
    }
}

class Restock_ProductSelector extends DialogSelector {
    constructor(props) {
        super(props);
        this.elasticSearchAvailable = false;
        this.elasticSearchUrl = "";
        this.elasticSearchFilter = "";
        this.minisearchFields = [
            "cod",
            "brand_nome",
            "old_cod",
            "uf_cod",
            "descrizione",
            "descrizione_estesa",
            "cod_barre",
        ];
        this.minisearchBoost = {
            cod: 10,
            uf_cod: 8,
            old_cod: 7,
            descrizione: 5,
            descrizione_estesa: 5,
            brand_nome: 20,
        };
        this.searchTitle = "Prodotti presenti tra gli ultimi 1000 ordini";
    }

    shouldComponentUpdate(nextProps) {
        let shouldUpdate = false;
        if (this.state.fetching) {
            this.fetchingUpdates = 0;
            shouldUpdate = true;
        } else if (this.fetchingUpdates < 1) {
            this.fetchingUpdates++;
            shouldUpdate = true;
        }
        if (nextProps.open || (this.props.open && !nextProps.open))
            shouldUpdate = true;
        if (this.props.id_cliente != nextProps.id_cliente) {
            shouldUpdate = true;
            this.setState({fetching: true}, () => this.getMinisearchData(true));
        }

        return shouldUpdate;
    }

    getMinisearchData(isInit = false) {
        if (!this.props.id_cliente) return;
        this.setState({fetching: true});
        let finalData = [];
        const thisFilter = this.props.filter || "attivi";
        const thisCustomer = this.props.id_cliente;
        axios
            .get(
                Constants.paths.ajaxBasePath +
                `prodotti/storici/ordini/clienti/?id_cliente=${thisCustomer}&filter=${thisFilter}&token=` +
                this.context.user.token
            )
            .then((res) => {
                if (res.data) {
                    //Processa solo se esistono risultati
                    let dataEntries = [];
                    Object.entries(res.data).forEach(([k, v]) => dataEntries.push(v));
                    finalData = dataEntries.map((d) => {
                        let thisProd = d._source;
                        let thisHistory = {};
                        if (this.props.returnHistory) {
                            thisHistory = {...d};
                            delete thisHistory._source;
                        }
                        return {
                            ...thisProd,
                            v_prezzo: d._last.v_prezzo,
                            user_prezzo: d._last.user_prezzo,
                            user_sconto: d._user_sconto,
                            history: thisHistory,
                            optionsLabel: (
                                <React.Fragment>
                                    {/* <Typography variant="caption" color="textSecondary" style={{}}>SKU: </Typography>
      <Typography variant="caption">{d.cod}</Typography> */}
                                    <Chip
                                        size="small"
                                        label={thisProd.uf_cod || thisProd.v_cod}
                                        style={{marginRight: this.context.theme.spacing(1)}}
                                    />
                                    <Typography variant="body1" style={{width: "140vw"}}>
                                        {thisProd.descrizione}
                                        {thisProd.descrizione_estesa && (
                                            <ProductExtendedDescriptionPopup
                                                withButton
                                                id={thisProd.id}
                                            />
                                        )}
                                    </Typography>
                                    <Grid
                                        container
                                        spacing={0}
                                        justifyContent="center"
                                        style={{textAlign: "center", width: "auto"}}
                                    >
                                        <Grid item xs={12}>
                                            <Typography variant="caption">
                                                <FontAwesomeIcon icon={faHistory}/>{" "}
                                                {parseFloat(d._last.user_prezzo)
                                                    .toFixed(2)
                                                    .toString()
                                                    .replace(".", ",") + "€" || "N/D"}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="caption">
                                                <FontAwesomeIcon
                                                    style={{
                                                        color: this.context.theme.palette.error.main,
                                                    }}
                                                    icon={faArrowUp}
                                                />{" "}
                                                {parseFloat(d._max.user_prezzo)
                                                    .toFixed(2)
                                                    .toString()
                                                    .replace(".", ",") + "€" || "N/D"}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="caption">
                                                <FontAwesomeIcon
                                                    style={{
                                                        color: this.context.theme.palette.success.main,
                                                    }}
                                                    icon={faArrowDown}
                                                />{" "}
                                                {parseFloat(d._min.user_prezzo)
                                                    .toFixed(2)
                                                    .toString()
                                                    .replace(".", ",") + "€" || "N/D"}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </React.Fragment>
                            ),
                        };
                    });
                }
                this.setState(
                    {
                        srcData: finalData,
                        fetching: false,
                    },
                    () => {
                        this.initMinisearch(finalData, isInit);
                    }
                );
            })
            .catch((err) =>
                this.setState({fetching: false}, () => {
                    alert("Non riesco a scaricare lo storico cliente.");
                    console.error(err);
                })
            );
    }
}

class Offerta_ProductSelector extends DialogSelector {
    constructor(props) {
        super(props);
        this.elasticSearchAvailable = false;
        // this.minisearchIdField = 'id_prod';
        this.minisearchFields = [
            "cod",
            "uf_cod",
            "old_cod",
            "brand_nome",
            "descrizione",
            "descrizione_estesa",
            "posizione",
        ];
        this.minisearchBoost = {
            cod: 10,
            uf_cod: 8,
            old_cod: 5,
            brand_nome: 20,
            descrizione: 4,
            descrizione_estesa: 4,
            posizione: 30,
        };
        this.minCharsToSearch = 1;
    }

    getMinisearchData(isInit = false) {
        this.setState({fetching: true});
        let finalData = [];
        axios
            .get(
                Constants.paths.ajaxBasePath +
                `offerte-agenti/prodotti/?id_offerta=${this.props.idOfferta}&token=` +
                this.context.user.token
            )
            .then((res) => {
                console.log("Prodotti offerta (MiniSearch):", res);
                finalData = res.data.map((d) => {
                    return {
                        ...d,
                        //prezzi_acquisto: JSON.parse(d.prezzi_acquisto),
                        optionsLabel: (
                            <>
                                <Typography
                                    style={{
                                        whiteSpace: "nowrap",
                                        marginRight: this.context.theme.spacing(1),
                                    }}
                                >
                                    {d.posizione}
                                </Typography>
                                <Chip
                                    size="small"
                                    label={d.uf_cod || d.v_cod}
                                    style={{marginRight: this.context.theme.spacing(1)}}
                                />
                                <Typography style={{width: "100%"}}>
                                    {d.descrizione}
                                    {d.descrizione_estesa && (
                                        <ProductExtendedDescriptionPopup withButton id={d.id}/>
                                    )}
                                </Typography>
                                <Typography
                                    style={{
                                        fontWeight: "bold",
                                    }}
                                >
                                    {parseFloat(d.v_prezzo)
                                        .toFixed(2)
                                        .toString()
                                        .replace(".", ",") + "€" || "N/D"}
                                </Typography>
                            </>
                        ),
                    };
                });
                this.setState(
                    {
                        srcData: finalData,
                        fetching: false,
                    },
                    () => this.initMinisearch(finalData, isInit)
                );
            })
            .catch((e) => {
                console.log(e);
            });
    }
}

class OffertaSelector extends DialogSelector {
    static minisearchFields = ["id", "titolo", "descrizione", "note"];
    static minisearchBoost = {id: 10, titolo: 8, descrizione: 4};

    constructor(props) {
        super(props);
        this.elasticSearchAvailable = false;
    }

    getMinisearchData(isInit = false) {
        this.setState({fetching: true});
        let finalData = [];
        axios
            .get(
                Constants.paths.ajaxBasePath +
                "offerte-agenti/?action=get_offerte_list&filter=attivi&token=" +
                this.context.user.token
            )
            .then((res) => {
                console.log("Offerte Agenti Data:", res.data);
                finalData = res.data.map((d) => ({
                    ...d,
                    optionsLabel: (
                        <Grid container>
                            <Grid item xs={3}>
                                <Chip
                                    size="small"
                                    label={d.id}
                                    style={{
                                        marginRight: this.context.theme.spacing(1),
                                        ...(d.id == "DEFAULT"
                                            ? {
                                                backgroundColor:
                                                this.context.theme.palette.primary.main,
                                                color: this.context.theme.palette.primary.contrastText,
                                            }
                                            : {}),
                                    }}
                                />
                            </Grid>

                            <Grid item xs={8}>
                                {d.titolo}
                                <Typography
                                    disabled
                                    variant="caption"
                                    style={{marginLeft: this.context.theme.spacing(1)}}
                                    fontWeight="light"
                                    fontStyle="italic"
                                >
                                    {d.descrizione}
                                </Typography>
                            </Grid>
                            <Grid item xs={1} sx={{textAlign: 'right'}}>
                                <Typography variant={"caption"}
                                            color={'text.secondary'}>
                                    {d.tipo}
                                </Typography>
                            </Grid>
                        </Grid>
                    ),
                }));
                this.setState(
                    {
                        srcData: finalData,
                        fetching: false,
                    },
                    () => this.initMinisearch(finalData, isInit)
                );
            });
    }
}

class ProductAttributesSelector extends DialogSelector {
    constructor(props) {
        super(props);
        this.elasticSearchAvailable = false;
        this.minisearchFields = ["id", "nome", "abbreviazione"];
        this.minisearchBoost = {id: 10, nome: 8, abbreviazione: 4};
    }

    //   componentDidUpdate(arg1, arg2) {
    //     super.componentDidUpdate(arg1, arg2);
    //     console.log(this.props.selectedIds);
    // }

    getMinisearchData(isInit = false) {
        this.setState({fetching: true});
        let finalData = [];
        axios
            .get(
                Constants.paths.ajaxBasePath +
                "prodotti/attributi/?token=" +
                this.context.user.token
            )
            .then((res) => {
                finalData = res.data.map((d) => ({
                    ...d,
                    optionsLabel: (
                        <Grid container>
                            <Grid item xs={1} style={{alignSelf: "center"}}>
                                <Chip
                                    size="small"
                                    label={d.id}
                                    style={{marginRight: this.context.theme.spacing(1)}}
                                />
                            </Grid>
                            <Grid item xs={5} style={{alignSelf: "center"}}>
                                {d.nome}
                            </Grid>
                            <Grid item xs={2} style={{alignSelf: "center"}}>
                                {d.tipo}
                            </Grid>
                            <Grid item xs={4} style={{alignSelf: "center"}}>
                                {d.regola}
                            </Grid>
                        </Grid>
                    ),
                }));
                this.setState({fetching: false, srcData: finalData}, () =>
                    this.initMinisearch(finalData, isInit)
                );
            });
    }
}

class ProductTagsSelector extends DialogSelector {
    //! NOT TESTED
    constructor(props) {
        super(props);
        this.elasticSearchAvailable = false;
        this.minisearchFields = ["id", "nome"];
        this.minisearchBoost = {id: 10, nome: 8};
    }

    getMinisearchData(isInit = false) {
        this.setState({fetching: true});
        let finalData = [];
        axios
            .get(
                Constants.paths.ajaxBasePath +
                "prodotti/tags/?all&token=" +
                this.context.user.token
            )
            .then((res) => {
                finalData = res.data.map((d) => ({
                    ...d,
                    optionsLabel: (
                        <Grid container>
                            <Grid item xs={1} style={{alignSelf: "center"}}>
                                <Chip
                                    size="small"
                                    label={d.id}
                                    style={{marginRight: this.context.theme.spacing(1)}}
                                />
                            </Grid>
                            <Grid item xs={5} style={{alignSelf: "center"}}>
                                {d.nome}
                            </Grid>
                            <Grid item xs={2} style={{alignSelf: "center"}}>
                                {d.note}
                            </Grid>
                        </Grid>
                    ),
                }));
                this.setState({fetching: false, srcData: finalData}, () =>
                    this.initMinisearch(finalData, isInit)
                );
            });
    }
}

export {
    CustomerSelector,
    ProductSelector,
    OffertaSelector,
    Offerta_ProductSelector,
    Restock_ProductSelector,
    ProductAttributesSelector,
    ProductTagsSelector
};
