import React from 'react';

// @ts-ignore
export default function Prezzo({children, decimali = 2, small = false}) {
    let value;
    try {
        value = parseFloat(children).toFixed(decimali).replace('.', ',') + " €";
        // if(typeof children !== 'string') console.error('Il componente <Prezzo> accetta soltanto stringhe, passato un ' + typeof children);

    } catch (e) {
        value = "0,00 €";
    }

    if (small)
        return <small>{value}</small>
    return <>{value}</>
}