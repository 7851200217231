import React from "react";
import Context from "../context/Context";
import { Paper, InputBase, IconButton } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";

class SearchBar extends React.Component {
  static contextType = Context;

  constructor(props) {
    super(props);
    this.catchReturn = this.catchReturn.bind(this);
    this.state = {
      query: "",
    };
  }

  catchReturn(e) {
    if (e.key == "Enter") {
      try{
        this.props.onBlur(e);
      }catch{
        //onBlur disattivato nei dispositivi ad alte prestazioni
      }
    }
  }

  render() {
    return (
      <React.Fragment>
        <Paper
          elevation={5}
          style={{
            marginTop: this.context.theme.spacing(3),
            marginBottom: this.context.theme.spacing(3),
            marginLeft: "auto",
            marginRight: "auto",
            padding: this.context.theme.spacing(1),
            paddingLeft: this.context.theme.spacing(3),
            paddingRight: this.context.theme.spacing(3),
            maxWidth: !this.context.matches.sm
              ? "80%" //XS
              : !this.context.matches.md
              ? "70%"
              : !this.context.matches.lg
              ? "40%"
              : "33%",
          }}
        >
          <InputBase
            fullWidth
            placeholder={this.props.placeholder || "Cerca..."}
            value={this.props.forcedQuery || this.state.query}
            disabled={!!this.props.forcedQuery}
            onChange={(e) => {
              this.setState({ query: e.target.value });
              this.props.onChange(e);
            }}
            onBlur={this.props.onBlur}
            onKeyPress={this.catchReturn}
            startAdornment={
              <IconButton size="large">
                <FontAwesomeIcon size="xs" icon={faSearch} />
              </IconButton>
            }
            endAdornment={
              <IconButton
                onClick={(e) => {
                  this.setState({ query: "" });
                  this.props.onClear(e);
                }}
                size="large">
                <FontAwesomeIcon size="xs" icon={faTimes} />
              </IconButton>
            }
          />
        </Paper>
      </React.Fragment>
    );
  }
}

export default SearchBar;
