import React from "react";
import Context from "../context/Context";
import Constants from "../context/Constants";
import MaterialTable from "material-table";
import { DatePicker } from "../components/DatePicker";
import TextareaInput from "../components/TextareaInput";
import { Button } from "@mui/material";
import {
  faCloudDownloadAlt,
  faCloudUploadAlt,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import materialTableIcons from "../support/materialTableIcons";

import axios from "axios";

import ProfileCard from "../components/ProfileCard";
import materialTableLocalisationIT from "../support/materialTableLocalisationIT";

class PrimeNote extends React.Component {
  constructor(props) {
    super(props);
    this.loadFile = this.loadFile.bind(this);
    this.downloadFile = this.downloadFile.bind(this);
    this.getData = this.getData.bind(this);
    this.state = { data: [] };
    this.loadedFiles = {};
  }
  static contextType = Context;
  componentDidMount() {
    // this.context.functions.setActiveRouteName("schede consegna");
    this.getData();
  }

  getData() {
    let finalData = [];
    axios
      .get(
        Constants.paths.ajaxBasePath +
          "prime-note/?token=" +
          this.context.user.token
      )
      .then((res) => {
        finalData = res.data;
        this.setState({
          data: finalData,
        });
        console.log("Data downloaded:");
        console.log(finalData);
      });
  }

  loadFile(data, event) {
    const allowedFileExtensions = ["pdf", "xls", "xlsx", "csv"];
    const rowData = data.rowData;
    const file = event.target.files[0];
    const reader = new FileReader();
    this.loadedFiles[rowData.id] = { input_file: file };
    var fileExtension = file.name.split(".").pop();
    if (!allowedFileExtensions.includes(fileExtension)) {
      alert(
        "Tipo file non valido!\nScegli tra: " + allowedFileExtensions.join(", ")
      );
      console.log("Wrong filetype: " + fileExtension);
      return false;
    }
    this.loadedFiles[rowData.id]["extension"] = fileExtension;
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.loadedFiles[rowData.id]["base64"] = reader.result;
      console.log("File loaded:");
      console.log(this.loadedFiles);
    };
    return true;
  }

  downloadFile(data) {
    const rowData = data;
    window.open(
      `${Constants.paths.ajaxBasePath}/prime-note?token=${this.context.user.token}&filename=${rowData.id_file}.${rowData.file_extension}&action=download` // <- This is what makes it open in a new window.
    );
  }

  render() {
    return (
      <Context.Consumer>
        {(consumer_data) => {
          return (
            <React.Fragment>
              <ProfileCard />
              <div className="data-table prime-note">
                <MaterialTable
                  title="Prime Note"
                  options={{
                    tableLayout: "auto",
                    pageSize: 15,
                    pageSizeOptions: [15, 30, 40, 50, 100, 200],
                    addRowPosition: "first",
                    detailPanelColumnAlignment: "right",
                    actionsColumnIndex: 100,
                    actionsCellStyle: {
                      textAlign: "center",
                    },
                  }}
                  columns={[
                    {
                      title: "#",
                      field: "id",
                      type: "numeric",
                      editable: "never",
                      hidden: true,
                    },
                    {
                      title: "Data caricamento",
                      field: "data_caricamento",
                      type: "date",
                      editable: "never",
                    },
                    {
                      title: "Data riferimento",
                      field: "data_riferimento",
                      type: "date",
                      initialEditValue: new Date(Date.now()),
                      editComponent: (props) => (
                        <DatePicker input_props={props} />
                      ),
                    },
                    {
                      title: "Sede",
                      field: "sede",
                      type: "string",
                      lookup: {
                        Rende: "Rende",
                        "Feroleto Antico": "Feroleto Antico",
                      },
                    },
                    {
                      title: "Nome richiedente",
                      field: "nome_operatore",
                      type: "string",
                      editable: "never",
                    },
                    {
                      title: "Note",
                      field: "note",
                      type: "string",
                      cellStyle: {
                        minWidth: "40vw",
                      },
                      editComponent: (props) => (
                        <TextareaInput input_props={props} />
                      ),
                    },
                    {
                      title: "File",
                      field: "file",
                      editable: "always",
                      headerStyle: { textAlign: "center" },
                      render: (d) => (
                        <Button
                          fullWidth
                          variant="contained"
                          color="primary"
                          size="small"
                          disabled={d.has_file == 1 ? false : true}
                          className=""
                          onClick={() => this.downloadFile(d)}
                          startIcon={
                            <FontAwesomeIcon
                              icon={
                                d.has_file == 1
                                  ? faCloudDownloadAlt
                                  : faQuestionCircle
                              }
                            />
                          }
                        >
                          {d.has_file == 1 ? "Scarica" : "N/D"}
                        </Button>
                      ),
                      editComponent: (d) => (
                        <Button
                          fullWidth
                          variant="contained"
                          color="primary"
                          size="small"
                          className=""
                          startIcon={
                            <FontAwesomeIcon icon={faCloudUploadAlt} />
                          }
                        >
                          <input
                            onChange={(e) => this.loadFile(d, e)}
                            type="file"
                            style={{ opacity: 0, position: "absolute" }}
                          />
                          {d.rowData.has_file == 1 ? "Sovrascrivi" : "Carica"}
                        </Button>
                      ),
                    },
                    {
                      title: "Tipo",
                      hidden: true,
                      field: "file_extension",
                      type: "string",
                      editable: "never",
                    },
                    {
                      title: "ID File",
                      hidden: true,
                      field: "id_file",
                      type: "numeric",
                      editable: "never",
                    },
                    {
                      title: "Has File",
                      hidden: true,
                      field: "has_file",
                      type: "numeric",
                      editable: "never",
                    },
                  ]}
                  data={this.state.data}
                  editable={{
                    onRowAdd: (newData) =>
                      new Promise((resolve, reject) => {
                        const data = this.state.data;
                        data.push(newData);
                        try {
                          newData["file"] = this.loadedFiles[newData["id"]][
                            "base64"
                          ];
                          newData["file_extension"] = this.loadedFiles[
                            newData["id"]
                          ]["extension"];
                        } catch {
                          newData["has_file"] = false;
                        }
                        const toPost = JSON.stringify({
                          row: newData,
                          token: consumer_data.user.token,
                          action: "create",
                        });
                        console.log("Posting...");
                        console.log(toPost);
                        axios
                          .post(
                            Constants.paths.ajaxBasePath + "prime-note/",
                            toPost
                          )
                          .then((res) => {
                            if (res.status >= 200 && res.status < 300) {
                              resolve();
                              this.getData();
                            } else {
                              reject();
                              throw new Error(res);
                            }
                          })
                          .catch((e) => {
                            reject();
                            alert("Errore nel form:" + e.response.status);
                          });
                      }),
                    onRowUpdate: (newData, oldData) =>
                      new Promise((resolve, reject) => {
                        const data = this.state.data;
                        const index = data.indexOf(oldData);
                        data[index] = newData;
                        try {
                          newData["file"] = this.loadedFiles[newData["id"]][
                            "base64"
                          ];
                          newData["file_extension"] = this.loadedFiles[
                            newData["id"]
                          ]["extension"];
                        } catch {
                          newData["has_file"] = false;
                        }
                        console.log("NEW DATA:");
                        console.log(newData);
                        const toPost = JSON.stringify({
                          row: newData,
                          token: consumer_data.user.token,
                          action: "update",
                        });
                        console.log("Posting...");
                        console.log(toPost);
                        axios
                          .post(
                            Constants.paths.ajaxBasePath + "prime-note/",
                            toPost
                          )
                          .then((res) => {
                            if (res.status >= 200 && res.status < 300) {
                              resolve();
                              this.getData();
                            } else {
                              reject();
                              throw new Error(res);
                            }
                          })
                          .catch((e) => {
                            alert(
                              "Il form non è stato inviato a causa di un errore: " +
                                e
                            );
                            reject();
                          });
                      }),
                    onRowDelete: (oldData) =>
                      new Promise((resolve, reject) => {
                        let data = this.state.data;
                        const index = data.indexOf(oldData);
                        data.splice(index, 1);

                        const toPost = JSON.stringify({
                          row: oldData,
                          token: consumer_data.user.token,
                          action: "delete",
                        });
                        axios
                          .post(
                            Constants.paths.ajaxBasePath + "prime-note/",
                            toPost
                          )
                          .then((res) => {
                            if (res.status >= 200 && res.status < 300) {
                              this.setState({ data }, () => {
                                console.log(oldData);
                                resolve();
                              });
                            } else {
                              reject();
                              throw new Error(res);
                            }
                          })
                          .catch((e) => {
                            alert(
                              "La riga non è stata eliminata a causa di un errore: " +
                                e
                            );
                            reject();
                          });
                      }),
                  }}
                  icons={materialTableIcons}
                  localization={materialTableLocalisationIT}
                />
              </div>
            </React.Fragment>
          );
        }}
      </Context.Consumer>
    );
  }
}

export default PrimeNote;
