import React, {useContext, useEffect, useRef, useState} from "react";
import axios from "axios";
import Constants from "../../context/Constants";
import Context from "../../context/Context";
import {
    Avatar,
    Box,
    Chip,
    Fab,
    Tooltip,
    Typography,
    Card,
    Grid,
    Switch,
    TextField,
    FormControlLabel,
    Button,
    Divider, InputAdornment, IconButton, Popover,
} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faEuroSign,
    faLockOpen,
    faLock,
    faLifeRing,
    faCircle, faPaperPlane, faSave, faHistory, faTrash,
} from "@fortawesome/free-solid-svg-icons";
import {faCircle as faCircleRegular} from "@fortawesome/free-regular-svg-icons";
import ProfileCard from "../../components/ProfileCard";
import ProductImage from "../../components/ProductImage";
import MaterialTable, {MTablePagination} from "material-table";
import materialTableIcons from "../../support/materialTableIcons";
import materialTableLocalisationIT from "../../support/materialTableLocalisationIT";
import SearchBar from "../../components/SearchBar";
// import MiniSearch from "minisearch";
// import stringIsType from "../../functions/stringIsType";
// import CollapseInOnMount from "../../components/CollapseInOnMount";
import {
    ProductAttributesSelector,
    ProductSelector,
} from "../../components/DialogSelector";
import SKUReplace from "../../components/SKUReplace";
// import PrezziDetailPanel from "./old.DetailPanel";
import TableProductCod from "../../components/TableProductCod";
// import TableSorting from "../../components/TableSorting";
// import CustomMaterialTablePagination from "../../components/CustomMaterialTablePagination";
import ProductExtendedDescriptionPopup from "../../components/ProductExtendedDescriptionPopup";
import ProductPricesDialog from "../../components/ProductPricesDialog";
import {CellNotesCommerciali} from "../../components/CellNotes";
import {enqueueSnackbar} from "notistack";

const MAX_SCAGLIONE = 4;
const MAX_RUOLO = 4;

function StatusDisplay(props) {
    const context = useContext(Context);
    const whichLedFn = (role) => {
        if (parseFloat(props.row[`prezzo_ev${role}s1`])) {
            if (parseFloat(props.row[`prezzo_ev${role}s${MAX_SCAGLIONE}`])) {
                return 3;
            } else {
                return 2;
            }
        } else {
            return 1;
        }
    };
    const generateGridFn = (row) => {
        let grid = [];
        for (let role = 1; role <= MAX_RUOLO; role++) {
            const thisLed = whichLedFn(role);
            grid.push(
                <Grid container key={`${row.id}-${role}`} spacing={1}>
                    <Grid item xs={4}>
                        <Typography variant="caption">EV{role}</Typography>
                    </Grid>
                    <Grid item xs={4} style={{textAlign: "right"}}>
                        <Typography
                            variant="caption"
                            style={{fontWeight: role == 3 ? "bold" : "normal"}}
                        >
                            {row[`prezzo_ev${role}s1`]?.replace(".", ",") + "€" || "N/D"}
                        </Typography>
                    </Grid>
                    <Grid item container xs={4}>
                        <Grid item xs={4}>
                            {
                                <FontAwesomeIcon
                                    size="xs"
                                    style={{
                                        color:
                                            thisLed == 1
                                                ? context.theme.palette.error.main
                                                : context.theme.palette.text.secondary,
                                    }}
                                    icon={thisLed == 1 ? faCircle : faCircleRegular}
                                />
                            }
                        </Grid>
                        <Grid item xs={4}>
                            {
                                <FontAwesomeIcon
                                    size="xs"
                                    style={{
                                        color:
                                            thisLed == 2
                                                ? context.theme.palette.warning.main
                                                : context.theme.palette.text.secondary,
                                    }}
                                    icon={thisLed == 2 ? faCircle : faCircleRegular}
                                />
                            }
                        </Grid>
                        <Grid item xs={4}>
                            {
                                <FontAwesomeIcon
                                    size="xs"
                                    style={{
                                        color:
                                            thisLed == 3
                                                ? context.theme.palette.success.main
                                                : context.theme.palette.text.secondary,
                                    }}
                                    icon={thisLed == 3 ? faCircle : faCircleRegular}
                                />
                            }
                        </Grid>
                    </Grid>
                </Grid>
            );
        }
        //Mostra prezzo xcode
        const thisLed = row[`prezzo_x`] ? 3 : 1;
        grid.push(
            <Grid container key={`${props.row}-xprice`} spacing={1}>
                <Grid item xs={12}>
                    <Divider/>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="caption">xPrezzo</Typography>
                </Grid>
                <Grid item xs={4} style={{textAlign: "right"}}>
                    <Typography variant="caption" style={{fontWeight: "normal"}}>
                        {row[`prezzo_x`] ? row[`prezzo_x`].replace(".", ",") + "€" : "N/D"}
                    </Typography>
                </Grid>
                <Grid item container xs={4}>
                    <Grid item xs={4}>
                        {
                            <FontAwesomeIcon
                                size="xs"
                                style={{
                                    color:
                                        thisLed == 1
                                            ? context.theme.palette.error.main
                                            : context.theme.palette.text.secondary,
                                }}
                                icon={thisLed == 1 ? faCircle : faCircleRegular}
                            />
                        }
                    </Grid>
                    <Grid item xs={4}>
                        {
                            <FontAwesomeIcon
                                size="xs"
                                style={{
                                    color:
                                        thisLed == 2
                                            ? context.theme.palette.warning.main
                                            : context.theme.palette.text.secondary,
                                }}
                                icon={thisLed == 2 ? faCircle : faCircleRegular}
                            />
                        }
                    </Grid>
                    <Grid item xs={4}>
                        {
                            <FontAwesomeIcon
                                size="xs"
                                style={{
                                    color:
                                        thisLed == 3
                                            ? context.theme.palette.success.main
                                            : context.theme.palette.text.secondary,
                                }}
                                icon={thisLed == 3 ? faCircle : faCircleRegular}
                            />
                        }
                    </Grid>
                </Grid>
            </Grid>
        );
        return <React.Fragment>{grid}</React.Fragment>;
    };
    return (
        <Card
            style={{
                padding: context.theme.spacing(1),
                ...(props.row.urgent //Fallo brillare di giallo se urgente
                    ? {borderColor: context.theme.palette.warning.main}
                    : {}),
            }}
            variant="outlined"
        >
            {generateGridFn(props.row)}
        </Card>
    );
}

class Prezzi extends React.Component {
    static contextType = Context;

    constructor(props) {
        super(props);
        this.onSearchChange = this.onSearchChange.bind(this);
        this.search = this.search.bind(this);
        //this.handleUnlock = this.handleUnlock.bind(this);
        this.handleOnDataSave = this.handleOnDataSave.bind(this);
        this.copyAll = this.copyAll.bind(this);
        this.tableFilter = this.tableFilter.bind(this);
        this.handlePricesDialogOpen = this.handlePricesDialogOpen.bind(this);
        this.handlePricesDialogClose = this.handlePricesDialogClose.bind(this);
        this.state = {
            data: [],
            results: [],
            fetching: false,
            searchValue: "",
            lastSearchValue: "",
            // unlocked: false,
            tableSelection: false,
            tableSelectionDisabledProdId: null,
            tableSorting: "rilevanza",
            tableSortingOrder: "down",
            tablePageSize: 50,
            tablePage: 1,
            tablePageCount: 1,
            tableRowCount: 0,
            pricesDialog: {
                open: false,
                idProd: undefined,
            },
            searchProductDialog: {
                open: false,
                disabledProdId: null,
            },
        };
        this.tableRef = React.createRef();
    }

    componentDidMount() {
        // this.getData(); #*Old
    }

    componentWillUnmount() {
        if (this.elasticSearchCancelToken)
            this.elasticSearchCancelToken.cancel("Annullo per unmount");
    }

    search(query, resetPage = true) {
        this.setState(
            (prev) => ({
                fetching: true,
                searchValue: query,
                tablePage: resetPage ? 1 : prev.tablePage,
            }),
            () => {
                let promise = new Promise((res, rej) => {
                    const thisSearch = typeof query == "undefined" ? " " : query;
                    let results = [];
                    const CancelToken = axios.CancelToken;
                    const source = CancelToken.source();
                    if (this.elasticSearchCancelToken)
                        this.elasticSearchCancelToken.cancel("Annullo per nuova ricerca");
                    this.elasticSearchCancelToken = source;
                    axios
                        .get(
                            Constants.paths.ajaxBasePath +
                            "prezzi/elastic/search/?token=" +
                            this.context.user.token +
                            "&filter=attivi" +
                            "&size=" +
                            this.state.tablePageSize +
                            "&from=" +
                            (this.state.tablePage - 1) * this.state.tablePageSize +
                            "&query=" +
                            encodeURIComponent(thisSearch),
                            {
                                cancelToken: source.token,
                            }
                        )
                        .then((resp) => {
                            let results = resp.data.hits || [];
                            results = results.map((r) => ({
                                ...r,
                                calc_options: JSON.parse(r.calc_options),
                            }));
                            this.setState(
                                {
                                    tableData: results,
                                    tablePageCount: Math.ceil(
                                        resp.data.totalCount / this.state.tablePageSize
                                    ),
                                    tableRowCount: resp.data.totalCount,
                                },
                                () => res()
                            );
                        })
                        .catch((err) => {
                            if (axios.isCancel(err)) rej(err);
                            else console.error(err);
                        });
                })
                    .catch((err) => {
                        if (!axios.isCancel(err)) console.error(err);
                    })
                    .finally(() => this.setState({fetching: false}));
            }
        );
    }

    tableFilter(row) {
        if (this.state.filterUrgentRows) {
            return row.urgent;
        } else {
            return true;
        }
    }

    copyAll(fromId, toId) {
    }

    async handlePricesDialogOpen(idProd) {
        this.setState((prev) => ({
            pricesDialog: {...prev.pricesDialog, open: true, idProd: idProd},
            searchProductDialog: {...prev.searchProductDialog, disabledProdId: idProd} //DB ID!
        }));
        console.log(idProd);
    }

    async handlePricesDialogClose() {
        this.setState((prev) => ({
            pricesDialog: {...prev.pricesDialog, open: false},
            searchProductDialog: {...prev.searchProductDialog, disabledProdId: null} //DB ID!
        }));
    }

    onSearchChange(e) {
        if (!this.context.device.lowSpec) this.search(e.target.value);
    }

    // handleUnlock(e) {
    //     this.setState((prev) => ({
    //         unlocked: !prev.unlocked,
    //     }));
    // }

    handleOnDataSave(singleOldD, newData, multiple = false, callback = false) {
        //* ATTENZIONE newD e newData sono due cose diverse!
        //* ATTENZIONE ALLA DIFFERENZA TRA ARRAY E DIZIONARI DI singleOldD e co.
        this.setState({fetching: true});
        new Promise((resolve, reject) => {
            let // indexToUpdate,
                // resultsIndexToUpdate,
                // newData,
                // newResults,
                toPost;
            // newData = this.state.data;
            // newResults = this.state.results;
            // for (let i = 0; i < (multiple ? singleNewD.length : 1); i++) {
            //   const thisNew = multiple ? singleNewD[i] : singleNewD;
            //   const thisOld = multiple ? singleOldD[i] : singleOldD;

            //   // indexToUpdate = this.state.data.indexOf(
            //   //   this.state.data.find((d) => d.id == thisOld.id)
            //   // );
            //   // resultsIndexToUpdate = this.state.results.indexOf(
            //   //   this.state.results.find((d) => d.id == thisOld.id)
            //   // );
            //   // newData[indexToUpdate] = thisNew;
            //   // newResults[resultsIndexToUpdate] = thisNew;
            // }
            console.log(arguments);
            // newData = newData.map(d => ({
            //   ...d
            // }));
            toPost = JSON.stringify({
                [`row${multiple ? "s" : ""}`]: newData,
                token: this.context.user.token,
                action: "update",
            });

            axios
                .post(Constants.paths.ajaxBasePath + "prezzi/", toPost)
                .then((res) => {
                    if (res.status >= 200 && res.status < 300) {
                        //TODO: per aggiornare la tabella chiama nuovamente search()
                        this.setState(
                            {
                                // data: newData,
                                // results: newResults,
                                fetching: false,
                            },
                            () => {
                               enqueueSnackbar("Listino aggiornato.", {
                                    variant: "success",
                                });
                                if (callback) callback();
                            }
                        );
                        resolve();
                    } else {
                        throw new Error(res);
                    }
                })
                .catch((e) => {
                    alert(
                        `Il form non è stato inviato a causa di un errore: ${e} - Puoi provare a reinviare la richiesta`
                    );
                    this.setState({
                        fetching: false,
                    });
                    reject();
                })
                .finally(() => {
                    console.log(toPost);
                });
        });
    }

    render() {
        // const {enqueueSnackbar, closeSnackbar} = this.props;
        return (
            <Context.Consumer>
                {(consumer_data) => (
                    <React.Fragment>
                        <ProfileCard/>
                        <SearchBar
                            forcedQuery={this.state.forcedSearchValue}
                            placeholder={null}
                            onChange={this.onSearchChange}
                            onBlur={(e) => {
                                this.setState({lastSearchValue: e.target.value});
                                if (this.context.device.lowSpec) this.search(e.target.value);
                            }}
                            onClear={(e) =>
                                this.setState(
                                    {
                                        forcedSearchValue: null,
                                    },
                                    () => {
                                        this.search();
                                    }
                                )
                            }
                        />
                        <FormControlLabel
                            style={{
                                color: consumer_data.theme.palette.text.primary,
                                marginBottom: consumer_data.theme.spacing(3),
                                width: '100%',
                                justifyContent: "center"
                            }}
                            control={
                                <Switch
                                    color="primary"
                                    checked={this.state.filterUrgentRows}
                                    onChange={(e) =>
                                        this.setState({filterUrgentRows: e.target.checked})
                                    }
                                />
                            }
                            labelPlacement="start"
                            label="Mostra solo urgenti"
                        />

                        {/*<Tooltip title={this.state.unlocked ? "Blocca" : "Sblocca"}>*/}
                        {/*    <Fab*/}
                        {/*        variant=""*/}
                        {/*        style={{*/}
                        {/*            zIndex: 99,*/}
                        {/*            position: "fixed",*/}
                        {/*            right: consumer_data.theme.spacing(2),*/}
                        {/*            bottom: consumer_data.theme.spacing(2),*/}
                        {/*            display: consumer_data.user.roles.includes("commerciale")*/}
                        {/*                ? undefined*/}
                        {/*                : "none",*/}
                        {/*            backgroundColor: this.state.unlocked*/}
                        {/*                ? consumer_data.theme.palette.warning.dark*/}
                        {/*                : undefined,*/}
                        {/*            // marginBottom: consumer_data.theme.spacing(3),*/}
                        {/*        }}*/}
                        {/*        onClick={this.handleUnlock}*/}
                        {/*    >*/}
                        {/*        <FontAwesomeIcon*/}
                        {/*            icon={this.state.unlocked ? faLockOpen : faLock}*/}
                        {/*            // style={{ marginRight: consumer_data.theme.spacing(1) }}*/}
                        {/*        />*/}
                        {/*        /!* {this.state.unlocked ? "SBLOCCATO" : "BLOCCATO"} *!/*/}
                        {/*    </Fab>*/}
                        {/*</Tooltip>*/}
                        <div>
                            <MaterialTable
                                tableRef={this.tableRef}
                                title="Prezzi"
                                icons={materialTableIcons}
                                localization={materialTableLocalisationIT}
                                // isLoading={this.state.fetching}
                                options={{
                                    // tableLayout: "fixed",
                                    pageSize: 10,
                                    paginationType: "normal",
                                    pageSizeOptions: [10,15, 20, 30, 40, 50],
                                    actionsColumnIndex: 0,
                                    filtering: false,
                                    search: false,
                                    sorting: false,
                                    selection: this.state.tableSelection,
                                    showSelectAllCheckbox: false,
                                    selectionProps: (rowData) => ({
                                        disabled:
                                            rowData.id == this.state.tableSelectionDisabledProdId,
                                        color: "primary",
                                        // checked: this.state.tableSelection
                                        //   ? rowData.tableData.checked || false
                                        //   : false,
                                    }),
                                }}
                                actions={[
                                    {
                                        icon: () => (
                                            <FontAwesomeIcon
                                                style={{margin: this.context.theme.spacing(0.5)}}
                                                icon={faEuroSign}
                                            />
                                        ),
                                        tooltip: "Modifica prezzi",
                                        onClick: (e, rowData) => this.handlePricesDialogOpen(rowData.id_prod),
                                    },
                                ]}
                                data={this.state.tableData}
                                // components={{
                                //   Pagination: (props) => (
                                //     <div style={{padding:this.context.theme.spacing(2)}}>
                                //       <MTablePagination {...props} />
                                //       <CustomMaterialTablePagination
                                //         tableRef={this.tableRef}
                                //         {...props}
                                //       />
                                //     </div>
                                //   ),
                                // }}
                                // onRowClick={(e, row_data, togglePanel) => !this.state.tableSelection&&togglePanel()}
                                // detailPanel={[
                                //   {
                                //     icon: () => (
                                //       <FontAwesomeIcon
                                //         style={{ margin: this.context.theme.spacing(0.5) }}
                                //         icon={faEuroSign}
                                //       />
                                //     ),
                                //     openIcon: () => (
                                //       <Box color="text.secondary">
                                //         <FontAwesomeIcon
                                //           style={{ margin: this.context.theme.spacing(0.5) }}
                                //           icon={faEuroSign}
                                //         />
                                //       </Box>
                                //     ),
                                //     render: (row_data) => (
                                //       <CollapseInOnMount>
                                //         <PrezziDetailPanel
                                //           currentTableRef={this.tableRef.current}
                                //           unlocked={this.state.unlocked}
                                //           row_data={row_data}
                                //           onDataSave={this.handleOnDataSave}
                                //           enqueueSnackbar={this.props.enqueueSnackbar}
                                //           toggleCopySelectionFn={this.toggleCopySelection}
                                //         />
                                //       </CollapseInOnMount>
                                //     ),
                                //   },
                                // ]}
                                columns={[
                                    {
                                        title: "",
                                        field: "img",
                                        editable: "never",
                                        cellStyle: {
                                            maxWidth: "7em",
                                            minWidth: "7em",
                                            textAlign: "center",
                                        },
                                        headerStyle: {
                                            textAlign: "center",
                                        },
                                        render: (row_data) => (
                                            <ProductImage
                                                containerSize={consumer_data.theme.spacing(10)}
                                                cod={row_data.sku}
                                                // size={100}
                                                // img={row_data.img}
                                            />
                                        ),
                                    },
                                    {
                                        title: "COD",
                                        field: "cod",
                                        editable: "never",
                                        cellStyle: {
                                            maxWidth: "10em",
                                            minWidth: "10em",
                                        },
                                        headerStyle: {
                                            textAlign: "left",
                                        },
                                        // cellStyle: {display:'block',maxWidth: "10px", width: "10px" },
                                        // headerStyle: {display:'block',maxWidth: "10px", width: "10px" },
                                        render: (row_data) => (
                                            <TableProductCod
                                                //allUfCodes={this.state.data.map((d) => d.uf_cod)}
                                                productObj={row_data}
                                                allowCustomisation={true}
                                                onFamilyClick={(famCode) => {
                                                    this.setState({
                                                        forcedSearchValue: row_data.this_cod_famiglia,
                                                    });
                                                    this.search(row_data.this_cod_famiglia);
                                                }}
                                            />
                                        ),
                                    },
                                    {
                                        title: "Descrizione",
                                        field: "descrizione",
                                        editable: "never",
                                        cellStyle: {
                                            // verticalAlign:'baseline',
                                            width: "100%",
                                            minWidth: !consumer_data.matches.sm
                                                ? "15em" //XS
                                                : !consumer_data.matches.md
                                                    ? "20em" //SM
                                                    : !consumer_data.matches.lg
                                                        ? "24rem" //MD
                                                        : !consumer_data.matches.xl
                                                            ? "25rem" //LG
                                                            : !consumer_data.matches.xxl
                                                                ? "45rem" //XL
                                                                : "65rem", //XXL
                                        },
                                        render: (row_data) => (
                                            <>
                                                {row_data.descrizione}
                                                <ProductExtendedDescriptionPopup
                                                    withButton
                                                    id={row_data.id_prod}
                                                />
                                            </>
                                        ),
                                    },

                                    {
                                        title: "Stato",
                                        field: "",
                                        editable: "never",
                                        cellStyle: {
                                            // verticalAlign:'baseline',
                                            minWidth: "15rem",
                                            maxWidth: "15rem",
                                        },
                                        render: (row_data) => <StatusDisplay row={row_data}/>,
                                    },
                                    {
                                        title: "Note",
                                        field: "",
                                        editable: "never",
                                        cellStyle: {
                                            // verticalAlign:'baseline',
                                            minWidth: "15rem",
                                            maxWidth: "15rem",
                                            minHeight: "100%",
                                        },
                                        render: (row_data) => (
                                            <>
                                                {/*<SKUReplace*/}
                                                {/*    key={row_data.sku}*/}
                                                {/*    enqueueSnackbar={this.props.enqueueSnackbar}*/}
                                                {/*    row={row_data}*/}
                                                {/*/>*/}
                                                <CellNotesCommerciali
                                                    idProd={row_data.id_prod}
                                                />
                                            </>
                                        ),
                                    },
                                    // {
                                    //   title: "legacy_uv",
                                    //   field: "uv",
                                    //   editable: "always",
                                    // },
                                ]}
                            />
                        </div>
                        <ProductSelector
                            disableLoadingBackdrop={true}
                            initialSearch={
                                this.state.forcedSearchValue || this.state.lastSearchValue
                            }
                            open={this.state.searchProductDialog.open}
                            onClose={() =>
                                this.setState((prev) => ({
                                    searchProductDialog: {
                                        ...prev.searchProductDialog,
                                        open: false,
                                    },
                                }))
                            }
                            onOpen={() =>
                                this.setState((prev) => ({
                                    searchProductDialog: {
                                        ...prev.searchProductDialog,
                                        open: true,
                                    },
                                }))
                            }
                            onSelect={(ids) => {
                                this.handleCopySelection(
                                    this.state.searchProductDialog.disabledProdId,
                                    ids.map((i) => i.id || i.id_prod)
                                );
                                this.setState((prev) => ({
                                    searchProductDialog: {
                                        ...prev.searchProductDialog,
                                        open: false,
                                    },
                                }));
                            }}
                            multiple
                            filter={"attivi"}
                            selectedIds={[this.state.searchProductDialog.disabledProdId]}
                        />
                        <ProductPricesDialog
                            idProd={this.state.pricesDialog.idProd}
                            open={this.state.pricesDialog.open}
                            onClose={() => this.handlePricesDialogClose()}
                            // enqueueSnackbar={this.props.enqueueSnackbar}
                            lastSearchValue={this.state.lastSearchValue}
                        />
                    </React.Fragment>
                )}
            </Context.Consumer>
        );
    }
}

export default Prezzi;
