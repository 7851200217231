import React from "react";
import ProfileCard from "../../../components/ProfileCard";
import axios from "axios";
import Context from "../../../context/Context";
import Constants from "../../../context/Constants";
import {
    Paper,
    Grid,
    Typography,
    FormControlLabel,
    Switch,
    Collapse,
    Button,
    CircularProgress,
    Box,
    TextField,
    Divider,
} from "@mui/material";
import {LinearProgressWithLabel} from "../../../components/Progresses";
import {enqueueSnackbar} from "notistack";

// import { withSnackbar } from "notistack";

class SyncProdotti extends React.Component {
    static contextType = Context;
    static maxRetries = 100;
    static retryTimeout = 5000; //MS
    constructor(props) {
        super();
        this.getAllIds = this.getAllIds.bind(this);
        this.launchRequests = this.launchRequests.bind(this);
        this.handleTermRecount = this.handleTermRecount.bind(this);
        this.state = {
            allIds: [],
            fetching: false,
            wpDestinations: new Set(["kartiell", "kartoclick"]),
            esolvDestinations: new Set(),
            propsToSync: new Set(["listini", "inventario", "stato"]),
            payloadSize: 20,
            transferred: 0,
            round: 1,
            shouldAbort: false,
            retries: 0,
            lastError: Error,
        };
    }

    componentDidMount() {
        this.getAllIds();
    }

    getAllIds() {
        let finalData = [];
        axios
            .get(
                Constants.paths.ajaxBasePath +
                `prodotti/?filter=stato07&token=` +
                this.context.user.token
            )
            .then((resp) => {
                finalData = resp.data.map((d) => d.id);
                this.setState({allIds: finalData});
            });
    }

    launchRequests(offset = 0, customPayloadSize = false) {
        this.setState({fetching: true, syncing: true});
        const maxRounds = 3;
        const thisIds = [...this.state.allIds].splice(
            offset,
            customPayloadSize || this.state.payloadSize
        );
        let promises = [];
        //*########
        //*LISTINI
        //*########
        if (this.state.round === 1) {
            if (this.state.propsToSync.has("listini")) {
                //*WP
                if (this.state.wpDestinations.size > 0) {
                    const wpToPost = JSON.stringify({
                        prodIds: thisIds,
                        token: this.context.user.token,
                        sites: Array.from(this.state.wpDestinations),
                    });
                    promises.push(
                        new Promise((resolve, reject) =>
                            axios
                                .post(
                                    Constants.paths.ajaxBasePath + "prezzi/utils/sync-wp/",
                                    wpToPost
                                )
                                .then((res) => {
                                    if (res.status >= 200 && res.status < 300) {
                                        resolve("WP listini OK");
                                    } else {
                                        throw new Error(res);
                                    }
                                })
                                .catch((e) => {
                                    reject(e);
                                })
                        )
                    );
                }
                //*ESOLVER
                // if (this.state.esolvDestinations.size > 0) {
                //   const esolvToPost = JSON.stringify({
                //     prodIds: thisIds,
                //     token: this.context.user.token,
                //     sites: Array.from(this.state.esolvDestinations),
                //   });
                //   promises.push(
                //     new Promise((resolve, reject) =>
                //       axios
                //         .post(
                //           Constants.paths.ajaxBasePath + "prezzi/utils/sync-esolv/",
                //           esolvToPost
                //         )
                //         .then((res) => {
                //           if (res.status >= 200 && res.status < 300) {
                //             resolve("ESOLV listini OK");
                //           } else {
                //             throw new Error(res);
                //           }
                //         })
                //         .catch((e) => {
                //           reject(e);
                //         })
                //     )
                //   );
                // }
            }

            if (this.state.propsToSync.has("stato")) {
                //*########
                //*STATO
                //*########
                //*WP
                if (this.state.wpDestinations.size > 0) {
                    const wpToPost = JSON.stringify({
                        prodIds: thisIds,
                        token: this.context.user.token,
                        sites: Array.from(this.state.wpDestinations),
                    });
                    promises.push(
                        new Promise((resolve, reject) =>
                            axios
                                .post(
                                    Constants.paths.ajaxBasePath +
                                    "prodotti/utils/update-wp-statuses/",
                                    wpToPost
                                )
                                .then((res) => {
                                    if (res.status >= 200 && res.status < 300) {
                                        resolve("WP stati OK");
                                    } else {
                                        throw new Error(res);
                                    }
                                })
                                .catch((e) => {
                                    reject(e);
                                })
                        )
                    );
                }
            }
            if (this.state.propsToSync.has("inventario")) {
                //*########
                //*INVENTARIO
                //*########
                if (this.state.wpDestinations.size > 0) {
                    const wpToPost = JSON.stringify({
                        prodIds: thisIds,
                        token: this.context.user.token,
                        sites: Array.from(this.state.wpDestinations),
                    });
                    promises.push(
                        new Promise((resolve, reject) =>
                            axios
                                .post(
                                    Constants.paths.ajaxBasePath + "inventario/utils/sync-wp/",
                                    wpToPost
                                )
                                .then((res) => {
                                    if (res.status >= 200 && res.status < 300) {
                                        resolve("WP inventario OK");
                                    } else {
                                        throw new Error(res);
                                    }
                                })
                                .catch((e) => {
                                    reject(e);
                                })
                        )
                    );
                }
                //*ESOLVER
                if (this.state.esolvDestinations.size > 0) {
                    const esolvToPost = JSON.stringify({
                        prodIds: thisIds,
                        token: this.context.user.token,
                        sites: Array.from(this.state.esolvDestinations),
                    });
                    promises.push(
                        new Promise((resolve, reject) =>
                            axios
                                .post(
                                    Constants.paths.ajaxBasePath + "inventario/utils/sync-esolv/",
                                    esolvToPost
                                )
                                .then((res) => {
                                    if (res.status >= 200 && res.status < 300) {
                                        resolve("ESOLV inventario OK");
                                    } else {
                                        throw new Error(res);
                                    }
                                })
                                .catch((e) => {
                                    reject(e);
                                })
                        )
                    );
                }
            }
        } else if (this.state.round === 2) {
            //*########
            //*STATO
            //*########
            //*WP
            console.log("ROUND 2");
            if (this.state.wpDestinations.size > 0) {
                const wpToPost = JSON.stringify({
                    prodIds: thisIds,
                    token: this.context.user.token,
                    sites: Array.from(this.state.wpDestinations),
                    parentsOnly: true,
                });
                promises.push(
                    new Promise((resolve, reject) =>
                        axios
                            .post(
                                Constants.paths.ajaxBasePath +
                                "prodotti/utils/update-wp-statuses/",
                                wpToPost
                            )
                            .then((res) => {
                                if (res.status >= 200 && res.status < 300) {
                                    resolve("WP stati OK");
                                } else {
                                    throw new Error(res);
                                }
                            })
                            .catch((e) => {
                                reject(e);
                            })
                    )
                );
            }
        } else if (this.state.round === 3) {
            promises.push(
                new Promise((resolve, reject) =>
                    //*Aggiorna tax
                    Array.from(this.state.wpDestinations).forEach((d) =>
                        this.handleTermRecount(d, resolve)
                    )
                )
            );
        }
        Promise.all(promises)
            .then((results) => {
                console.log(results);
                this.setState(
                    {
                        transferred:
                            parseInt(offset) +
                            parseInt(customPayloadSize || this.state.payloadSize),
                    },
                    () => {
                        if (
                            this.state.allIds.length > this.state.transferred &&
                            !this.state.shouldAbort
                        ) {
                            this.launchRequests(
                                parseInt(offset) +
                                parseInt(customPayloadSize || this.state.payloadSize)
                            );
                            this.setState({retries: 0});
                        } else {
                            if (this.state.round >= maxRounds) {
                                this.setState({
                                    fetching: false,
                                    syncing: false,
                                    transferred: 0,
                                    round: 1,
                                });
                                enqueueSnackbar("Prodotti sincronizzati!", {
                                    variant: "success",
                                    persist: true,
                                    preventDuplicate: true,
                                });
                            } else {
                                this.setState(
                                    (prev) => ({
                                        transferred: 0,
                                        round: ++prev.round,
                                    }),
                                    () => this.launchRequests(0)
                                );
                            }
                        }
                    }
                );
            })
            .catch((err) => {
                console.error(err);
                if (this.state.retries >= SyncProdotti.maxRetries) {
                    this.setState({fetching: false, syncing: false, transferred: 0});
                    alert("Max tentativi raggiunti" + err);
                } else {
                    this.setState(
                        (prev) => ({
                            //RETRY
                            retries: ++prev.retries,
                            lastError: err,
                        }),
                        () => {
                            setTimeout(
                                () =>
                                    this.launchRequests(
                                        offset,
                                        (customPayloadSize || this.state.payloadSize) -
                                        5 * this.state.retries >
                                        1 //Riduci di 5 * n tentativi la quantità del payload se i tentativi falliscono
                                            ? (customPayloadSize || this.state.payloadSize) -
                                            5 * this.state.retries
                                            : 1
                                    ),
                                SyncProdotti.retryTimeout
                            );
                        }
                    );
                }
            });
    }

    handlePropsToSyncChange(prop, checked) {
        this.setState((prev) => {
            let thisProps = new Set(prev.propsToSync);
            if (checked) thisProps.add(prop);
            else thisProps.delete(prop);
            return {propsToSync: thisProps};
        });
    }

    handleWpDestinationChange(site, checked) {
        this.setState((prev) => {
            let thisDest = new Set(prev.wpDestinations);
            if (checked) thisDest.add(site);
            else thisDest.delete(site);
            return {wpDestinations: thisDest};
        });
    }

    handleEsolvDestinationChange(site, checked) {
        this.setState((prev) => {
            let thisDest = new Set(prev.esolvDestinations);
            if (checked) thisDest.add(site);
            else thisDest.delete(site);
            return {esolvDestinations: thisDest};
        });
    }

    handleTermRecount(site, callback = false) {
        this.setState({fetching: true}, () =>
            axios
                .get(
                    Constants.paths.ajaxBasePath +
                    `maintenance/wp-recount-terms/?token=${this.context.user.token}&site=${site}`
                )
                .then((res) => {
                    this.setState({
                        fetching: false,
                    });
                    enqueueSnackbar("Tassonomie aggiornate!", {
                        variant: "success",
                        persist: false,
                        preventDuplicate: false,
                    });
                    if (callback) callback();
                })
                .catch((err) => {
                    alert(err);
                })
                .finally(() => {
                    this.setState({fetching: false});
                })
        );
    }

    render() {
        return (
            <React.Fragment>
                <ProfileCard/>
                <Paper
                    elevation={10}
                    style={{

                        maxWidth: this.context.theme.breakpoints.values.md,
                        margin: "auto",
                        marginTop: this.context.theme.spacing(6),
                        textAlign: 'center'
                    }}
                >
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant="h2">Sincronizza prodotti</Typography>
                            <Typography variant="caption">
                                Prodotti da caricare:{" "}
                                <strong>{this.state.allIds.length}</strong>
                            </Typography>
                        </Grid>
                        <Grid item container justifyContent="center">
                            <Grid item xs={12}>
                                <Typography variant="overline">Dati</Typography>
                            </Grid>
                            <FormControlLabel
                                control={
                                    <Switch
                                        disabled={this.state.fetching}
                                        checked={this.state.propsToSync.has("listini") || false}
                                        onChange={(e, checked) =>
                                            this.handlePropsToSyncChange("listini", checked)
                                        }
                                        color="primary"
                                    />
                                }
                                label="Listini"
                            />
                            <FormControlLabel
                                control={
                                    <Switch
                                        disabled={this.state.fetching}
                                        checked={this.state.propsToSync.has("inventario") || false}
                                        onChange={(e, checked) =>
                                            this.handlePropsToSyncChange("inventario", checked)
                                        }
                                        color="primary"
                                    />
                                }
                                label="Inventario"
                            />
                            <FormControlLabel
                                control={
                                    <Switch
                                        disabled={this.state.fetching}
                                        checked={this.state.propsToSync.has("stato") || false}
                                        onChange={(e, checked) =>
                                            this.handlePropsToSyncChange("stato", checked)
                                        }
                                        color="primary"
                                    />
                                }
                                label="Stato"
                            />
                        </Grid>{" "}
                        <Grid item container justifyContent="center">
                            <Grid item xs={12}>
                                <Typography variant="overline">Destinazioni</Typography>
                            </Grid>
                            <FormControlLabel
                                control={
                                    <Switch
                                        disabled={this.state.fetching}
                                        checked={this.state.wpDestinations.has("kartiell") || false}
                                        onChange={(e, checked) =>
                                            this.handleWpDestinationChange("kartiell", checked)
                                        }
                                        name="Kartiell"
                                        color="primary"
                                    />
                                }
                                label="Kartiell"
                            />
                            <FormControlLabel
                                control={
                                    <Switch
                                        disabled={this.state.fetching}
                                        checked={
                                            this.state.wpDestinations.has("kartoclick") || false
                                        }
                                        onChange={(e, checked) =>
                                            this.handleWpDestinationChange("kartoclick", checked)
                                        }
                                        name="Kartoclick"
                                        color="primary"
                                    />
                                }
                                label="Kartoclick"
                            />
                            <FormControlLabel
                                control={
                                    <Switch
                                        disabled={this.state.fetching}
                                        checked={this.state.esolvDestinations.has("rende") || false}
                                        onChange={(e, checked) =>
                                            this.handleEsolvDestinationChange("rende", checked)
                                        }
                                        name="eSolver"
                                        color="primary"
                                    />
                                }
                                label="eSolver"
                            />
                        </Grid>{" "}
                        <Grid item xs={12}>
                            <TextField
                                type="number"
                                variant="outlined"
                                label="Payload"
                                margin="dense"
                                value={this.state.payloadSize}
                                onChange={(e) =>
                                    this.setState({payloadSize: parseInt(e.target.value)})
                                }
                            />
                            <br/>
                            <Typography color="textSecondary" variant="caption">
                                Quanti prodotti verranno aggiornati per richiesta AJAX
                            </Typography>
                        </Grid>
                        <Collapse in={this.state.syncing} style={{width: "100%"}}>
                            <Grid
                                item
                                xs={12}
                                style={{
                                    paddingLeft: this.context.theme.spacing(4),
                                    paddingRight: this.context.theme.spacing(4),
                                }}
                            >
                                <Box style={{display: "inline-flex"}}>
                                    <Typography
                                        style={{marginRight: this.context.theme.spacing(1)}}
                                    >
                                        Trasferimento in corso...
                                    </Typography>
                                    <CircularProgress size={20}/>
                                </Box>
                                <LinearProgressWithLabel
                                    value={
                                        (this.state.transferred / this.state.allIds.length) * 100
                                    }
                                />
                                <Typography>
                                    {this.state.transferred}/{this.state.allIds.length}
                                    {this.state.retries > 0 ? (
                                        <Typography
                                            style={{color: this.context.theme.palette.warning.dark}}
                                        >
                                            Tentativo {this.state.retries + 1}/
                                            {SyncProdotti.maxRetries}
                                        </Typography>
                                    ) : (
                                        ""
                                    )}
                                </Typography>
                            </Grid>
                        </Collapse>
                        <Grid item xs={12}>
                            {!this.state.syncing ? (
                                <Button
                                    sx={{marginBottom: this.context.theme.spacing(3)}}

                                    onClick={(e) => this.launchRequests(0)}
                                    variant="contained"
                                    disabled={this.state.fetching}
                                >
                                    Avvia
                                </Button>
                            ) : (
                                <Button
                                    sx={{marginBottom: this.context.theme.spacing(3)}}
                                    onClick={() => this.setState({shouldAbort: true})}>
                                    Annulla
                                </Button>
                            )}
                        </Grid>
                    </Grid>
                </Paper>
                <Paper
                    elevation={10}
                    style={{
                        maxWidth: this.context.theme.breakpoints.values.sm,
                        margin: "auto",
                        marginTop: this.context.theme.spacing(4),
                        padding: this.context.theme.spacing(2),
                    }}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography sx={{textAlign: 'center'}} variant="h4">Riconta tassonomie</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Button
                                onClick={(e) => this.handleTermRecount("kartiell")}
                                fullWidth
                                variant="contained"
                                disabled={this.state.fetching}
                            >
                                Kartiell
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button
                                onClick={(e) => this.handleTermRecount("kartoclick")}
                                fullWidth
                                variant="contained"
                                disabled={this.state.fetching}
                            >
                                KartoClick
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            </React.Fragment>
        );
    }
}

export default SyncProdotti;
