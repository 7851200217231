import Constants from "../context/Constants";

function getPricesNr(productObj) {
  let toReturn = 0;
  Constants.chiaviListini.forEach((l, i) => {
    if (parseFloat(productObj[l]) > 0) {
      if (Constants.visibilitaAgentiListini.includes(l)) toReturn++; //Conta solo listini che dovrebbero essere visibili
    }
  });
  if (toReturn == 0) if (parseFloat(productObj.prezzo_x) > 0) toReturn = 1;
  return toReturn;
}

export default getPricesNr;
