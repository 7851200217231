import { faInfo, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Backdrop, Collapse } from "@mui/material";
import axios from "axios";
import MaterialTable from "material-table";
import React from "react";
import Constants from "../../context/Constants";
import Context from "../../context/Context";
import materialTableIcons from "../../support/materialTableIcons";
import materialTableLocalisationIT from "../../support/materialTableLocalisationIT";

export default class NomiTipici extends React.Component {
  static contextType = Context;
  constructor(props) {
    super(props);
    this.getData = this.getData.bind(this);
    this.handleRowAdd = this.handleRowAdd.bind(this);
    this.state = {};
  }

  componentDidMount() {
    this.getData();
  }
  getData(returnOnly = false) {
    axios
      .get(
        `${Constants.paths.ajaxBasePath}prodotti/nomi-tipici/?token=${this.context.user.token}`
      )
      .then((res) => {
        let newData = res.data.map((d) => ({
          ...d,
        }));
        this.setState({ nomiTipiciData: newData });
      });
  }
  handleRowAdd(newData, onSuccess = false, onReject = false) {
    const toPost = {
      token: this.context.user.token,
      row: newData,
      action: "create",
    };
    axios
      .post(`${Constants.paths.ajaxBasePath}prodotti/nomi-tipici/`, toPost)
      .then((res) => {
          if (onSuccess) onSuccess();
          this.getData();
      })
      .catch((err) => {
        alert("Nome tipico non valido o duplicato");
        if (onReject) onReject();
      });
  }
  handleRowUpdate(newData, oldData, onSuccess = false, onReject = false) {
    const toPost = {
      token: this.context.user.token,
      row: newData,
      action: "update",
    };
    axios
      .post(`${Constants.paths.ajaxBasePath}prodotti/nomi-tipici/`, toPost)
      .then((res) => {
          if (onSuccess) onSuccess();
          this.getData();
      })
      .catch((err) => {
        alert("Nome tipico non valido o duplicato");
        if (onReject) onReject();
      });
  }

  render() {
    return (
      <MaterialTable
        title="Nomi tipici"
        icons={materialTableIcons}
        localization={materialTableLocalisationIT}
        isLoading={this.state.fetching}
        columns={[
          {
            title: "ID",
            field: "id",
            editable: "never",
            width: "10%",
          },
          {
            title: "Nome",
            field: "nome",
            editable: "always",
            width: "60%",
          },
          {
            title: "Abbr.",
            field: "abbreviazione",
            editable: "always",
            width: "15%",
          },
          {
            title: "LG Abbr.",
            field: "legacy_abbreviazione",
            editable: "always",
            width: "15%",
          },
        ]}
        options={{
          // tableLayout: "fixed",
          search: true,
          pageSize: 25,
          pageSizeOptions: [25, 50, 100, 150, 200],
          //   filtering: true,
          //   sorting: true,
          addRowPosition: "first",
          actionsColumnIndex: 4,
        }}
        data={this.state.nomiTipiciData}
        editable={{
          onRowAdd: (newData) =>
            new Promise((res, rej) => {
              this.handleRowAdd(newData, res, rej);
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise((res, rej) => {
              this.handleRowUpdate(newData, oldData, res, rej);
            }),
        }}
      />
    );
  }
}
