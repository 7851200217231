import React, {StrictMode} from "react";
// import ReactDOM from "react-dom";
import {createRoot} from 'react-dom/client';
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import {HashRouter} from "react-router-dom";
// import {createMuiTheme, ThemeProvider} from "@mui/material";

// const theme = createMuiTheme()

const thisPath = window.location.pathname.split("/");


//Insegna a Zuckerberg come cazzo si fa:
const errorsToWarn = [
    "Warning:",
    "Material-UI:"
];
const oldConsError = console.error;
console.error = function (...args) {
    let toWarn = false;

    if (typeof args[0] === 'string') {
        errorsToWarn.forEach(function (_s) {
            if (args[0].startsWith(_s)) {
                toWarn = true;
            }
        })
    }

    toWarn ? console.warn(...args) : oldConsError(...args);
}

if (thisPath.includes("api")) {
    //Do nothing and let the api work alone
} else {
    function noop() {
    } //Disabilita tutti i log dalla Production build

    if (process.env.NODE_ENV !== "development") {
        console.log = noop;
        // console.warn = noop;
        // console.error = noop;
    }

    // ReactDOM.render(<App />, document.getElementById("root")); React 17 OBS
    const container = document.getElementById('root');
    const root = createRoot(container);


    root.render(
        <StrictMode>
            <HashRouter>
                <App rootElement={root}/>
            </HashRouter>
        </StrictMode>
    );

    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://bit.ly/CRA-PWA
    serviceWorker.unregister();
}
