import React, {forwardRef} from 'react';
import {
    faPlusCircle,
    faSearch,
    faLongArrowAltDown,
    faCheck,
    faEdit,
    faTrash,
    faTimesCircle,
    faStepBackward,
    faStepForward,
    faFastBackward,
    faFastForward,
    faFileExport,
    faTimes,
    faFilter,
    faInfoCircle, faChevronLeft, faChevronRight
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


export default {
    Add: forwardRef((props, ref) => (
        <FontAwesomeIcon
            {...props}
            className="text-success"
            icon={faPlusCircle}
        />
    )),
    Search: forwardRef((props, ref) => (
        <FontAwesomeIcon {...props} icon={faSearch}/>
    )),
    Filter: forwardRef((props, ref) => (
        <FontAwesomeIcon {...props} icon={faFilter} className="fa-xs"/>
    )),
    SortArrow: forwardRef((props, ref) => (
        <FontAwesomeIcon {...props} icon={faLongArrowAltDown}/>
    )),
    Check: forwardRef((props, ref) => (
        <FontAwesomeIcon
            {...props}
            color="error.main"
            className="fa-xs"
            icon={faCheck}
        />
    )),
    Edit: forwardRef((props, ref) => (
        <FontAwesomeIcon
            {...props}
            className="fa-xs"
            icon={faEdit}
        />
    )),
    Delete: forwardRef((props, ref) => (
        <FontAwesomeIcon
            {...props}
            className="fa-xs text-danger"
            icon={faTrash}
        />
    )),
    Clear: forwardRef((props, ref) => (
        <FontAwesomeIcon
            {...props}
            className="fa-xs"
            icon={faTimesCircle}
        />
    )),
    ResetSearch: forwardRef((props, ref) => (
        <FontAwesomeIcon
            {...props}
            className="fa-xs"
            icon={faTimesCircle}
        />
    )),
    FirstPage: forwardRef((props, ref) => (
        <FontAwesomeIcon
            {...props}
            className="fa-xs"
            icon={faFastBackward}
        />
    )),
    LastPage: forwardRef((props, ref) => (
        <FontAwesomeIcon
            {...props}
            className="fa-xs"
            icon={faFastForward}
        />
    )),
    NextPage: forwardRef((props, ref) => (
        <FontAwesomeIcon
            {...props}
            className="fa-xs"
            icon={faStepForward}
        />
    )),
    PreviousPage: forwardRef((props, ref) => (
        <FontAwesomeIcon
            {...props}
            className="fa-xs"
            icon={faStepBackward}
        />
    )),
    Remove: forwardRef((props, ref) => (
        <FontAwesomeIcon {...props} className="" icon={faTimes}/>
    )),
    Export: forwardRef((props, ref) => (
        <FontAwesomeIcon size='sm' {...props} className="" icon={faFileExport}/>
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
        <FontAwesomeIcon {...props} className="" style={{color: "red"}} icon={faTimes}/>
    )),
    Info: forwardRef((props, ref) => (
        <FontAwesomeIcon {...props} className="" icon={faInfoCircle}/>
    )),
    ChevronLeft: forwardRef((props, ref) => (
        <FontAwesomeIcon {...props} className="" icon={faChevronLeft}/>
    )),
    ChevronRight: forwardRef((props, ref) => (
        <FontAwesomeIcon {...props} className="" icon={faChevronRight}/>
    ))
}