import React from "react";
import { Box, Paper, Button } from "@mui/material";
import Context from "../context/Context";
import ProfileCard from "../components/ProfileCard";
import MaterialTable from "material-table";
import { DatePicker } from "../components/DatePicker";
import TextareaInput from "../components/TextareaInput";
import { TableSelectRender, TableSelectEdit } from "../components/TableSelect";

import axios from "axios";
import Constants from "../context/Constants";
import materialTableIcons from "../support/materialTableIcons";
import materialTableLocalisationIT from "../support/materialTableLocalisationIT";
import {
  TableFileButtonRender,
  TableFileButtonEdit
} from "../components/TableFileButton";

class ArchivioOfferte extends React.Component {
  constructor(props) {
    super(props);
    this.loadFile = this.loadFile.bind(this);
    this.downloadFile = this.downloadFile.bind(this);
    this.deleteFile = this.deleteFile.bind(this);
    this.getData = this.getData.bind(this);
    this.state = { data: [] };
    this.loadedFiles = {};
  }

  static contextType = Context;
  componentDidMount() {
    this.getData();
  }

  getData() {
    let finalData = [];
    axios
      .get(
        Constants.paths.ajaxBasePath +
          "offerte/?token=" +
          this.context.user.token
      )
      .then(res => {
        finalData = res.data;
        finalData = finalData.map(d => ({
          ...d,
          assegnazioniArray:
            d.assegnazioni == "" ? [] : d.assegnazioni.split(",")
        }));
        this.setState({
          data: finalData
        });
        console.log("Data downloaded:");
        console.log(finalData);
      });
  }

  loadFile(data, event) {
    const allowedFileExtensions = ["pdf", "xls", "xlsx", "csv"];
    const rowData = data.rowData;
    const file = event.target.files[0];
    const reader = new FileReader();
    this.loadedFiles[rowData.id] = { input_file: file };
    var fileExtension = file.name.split(".").pop();
    if (!allowedFileExtensions.includes(fileExtension)) {
      alert(
        "Tipo file non valido!\nScegli tra: " + allowedFileExtensions.join(", ")
      );
      console.log("Wrong filetype: " + fileExtension);
      return false;
    }
    this.loadedFiles[rowData.id]["extension"] = fileExtension;
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.loadedFiles[rowData.id]["base64"] = reader.result;
      console.log("File loaded:");
      console.log(this.loadedFiles);
    };
    return true;
  }

  downloadFile(data) {
    const rowData = data;
    window.open(
      `${Constants.paths.ajaxBasePath}/offerte?token=${this.context.user.token}&filename=${rowData.id_file}.${rowData.file_extension}&action=download` // <- This is what makes it open in a new window.
    );
  }

  deleteFile(data){
    console.log("######DELETE");
    const rowData = data;
    rowData.has_file=0;
    const index = this.state.data.indexOf(rowData);

    this.setState(prev=>{
      prev[index] = rowData;
      return prev;
    })
  }

  render() {
    return (
      <Context.Consumer>
        {consumer_data => {
          return (
            <div className="data-table offerte">
              <ProfileCard />
              <MaterialTable
                title="Offerte"
                options={{
                  tableLayout: "auto",
                  pageSize: 15,
                  pageSizeOptions: [15, 30, 60, 100],
                  addRowPosition: "first",
                  detailPanelColumnAlignment: "right",
                  actionsColumnIndex: 100,
                  actionsCellStyle: {
                    textAlign: "center"
                  }
                }}
                columns={[
                  {
                    title: "#",
                    field: "id",
                    type: "numeric",
                    editable: "never",
                    hidden: true
                  },
                  {
                    title: "COD",
                    field: "cod",
                    type: "string",
                    editable: "onAdd",
                    hidden: false
                  },
                  {
                    title: "Inizio",
                    field: "data_inizio",
                    type: "date",
                    editable: "always",
                    editComponent: props => <DatePicker input_props={props} />,
                    initialEditValue: new Date(Date.now())
                  },
                  {
                    title: "Fine",
                    field: "data_fine",
                    type: "date",
                    editable: "always",
                    editComponent: props => <DatePicker input_props={props} />,
                    initialEditValue: new Date(Date.now())
                  },
                  {
                    title: "Descrizione",
                    field: "descrizione",
                    type: "string",
                    cellStyle: { minWidth: "40vw" },
                    editable: "always",
                    editComponent: props => (
                      <TextareaInput input_props={props} />
                    )
                  },
                  {
                    title: "Assegnazioni",
                    field: "assegnazioniArray",
                    editable: "always",
                    initialEditValue: [],
                    render: d => <TableSelectRender value={d} />,
                    editComponent: props => (
                      <TableSelectEdit
                        input_props={props}
                        selects={Constants.agenti}
                      />
                    )
                  },
                  {
                    title: "Note",
                    field: "note",
                    type: "string",
                    cellStyle: {
                      minWidth: "25vw"
                    },
                    editComponent: props => (
                      <TextareaInput input_props={props} />
                    )
                  },
                  {
                    title: "File",
                    field: "file",
                    editable: "always",
                    headerStyle: { textAlign: "center" },
                    render: d => (
                      <TableFileButtonRender
                        value={d}
                        onClick={() => {
                          this.downloadFile(d);
                        }}
                      />
                    ),
                    editComponent: d => (
                      <TableFileButtonEdit
                        input_props={d}
                        onChange={this.loadFile}
                        onDelete={this.deleteFile}
                      />
                    )
                  },
                  {
                    title: "Tipo",
                    hidden: true,
                    field: "file_extension",
                    type: "string",
                    editable: "never"
                  },
                  {
                    title: "ID File",
                    hidden: true,
                    field: "id_file",
                    type: "numeric",
                    editable: "never"
                  },
                  {
                    title: "Has File",
                    hidden: true,
                    field: "has_file",
                    type: "numeric",
                    editable: "never"
                  }
                ]}
                data={this.state.data}
                editable={{
                  onRowAdd: newData =>
                    new Promise((resolve, reject) => {
                      const data = this.state.data;
                      newData.assegnazioni =
                        newData.assegnazioniArray.length > 0
                          ? newData.assegnazioniArray.join(",")
                          : "";
                      data.push(newData);
                      try {
                        newData["file"] = this.loadedFiles[newData["id"]][
                          "base64"
                        ];
                        newData["file_extension"] = this.loadedFiles[
                          newData["id"]
                        ]["extension"];
                        newData["has_file"]=true;
                      } catch {
                        newData["has_file"] = false;
                      }
                      const toPost = JSON.stringify({
                        row: newData,
                        token: consumer_data.user.token,
                        action: "create"
                      });
                      console.log("Posting...");
                      console.log(toPost);
                      axios
                        .post(Constants.paths.ajaxBasePath + "offerte/", toPost)
                        .then(res => {
                          if (res.status >= 200 && res.status < 300) {
                            resolve();
                            this.getData();
                          } else {
                            reject();
                            throw new Error(res);
                          }
                        })
                        .catch(e => {
                          reject();
                          alert("Errore nel form:" + e.response.status);
                        });
                    }),
                  onRowUpdate: (newData, oldData) =>
                    new Promise((resolve, reject) => {
                      const data = this.state.data;
                      const index = data.indexOf(oldData);
                      newData.assegnazioni =
                        newData.assegnazioniArray.length > 0
                          ? newData.assegnazioniArray.join(",")
                          : "";
                      data[index] = newData;
                      try {
                        newData["file"] = this.loadedFiles[newData["id"]][
                          "base64"
                        ];
                        newData["file_extension"] = this.loadedFiles[
                          newData["id"]
                        ]["extension"];
                        newData["has_file"]=true;
                      } catch {
                        newData["has_file"] = false;
                      }
                      console.log("NEW DATA:");
                      console.log(newData);
                      const toPost = JSON.stringify({
                        row: newData,
                        token: consumer_data.user.token,
                        action: "update"
                      });
                      console.log("Posting...");
                      console.log(toPost);
                      axios
                        .post(Constants.paths.ajaxBasePath + "offerte/", toPost)
                        .then(res => {
                          if (res.status >= 200 && res.status < 300) {
                            resolve();
                            this.getData();
                          } else {
                            reject();
                            throw new Error(res);
                          }
                        })
                        .catch(e => {
                          alert(
                            "Il form non è stato inviato a causa di un errore: " +
                              e
                          );
                          reject();
                        });
                    }),
                  onRowDelete: oldData =>
                    new Promise((resolve, reject) => {
                      let data = this.state.data;
                      const index = data.indexOf(oldData);
                      data.splice(index, 1);

                      const toPost = JSON.stringify({
                        row: oldData,
                        token: consumer_data.user.token,
                        action: "delete"
                      });
                      axios
                        .post(Constants.paths.ajaxBasePath + "offerte/", toPost)
                        .then(res => {
                          if (res.status >= 200 && res.status < 300) {
                            this.setState({ data }, () => {
                              console.log(oldData);
                              resolve();
                            });
                          } else {
                            reject();
                            throw new Error(res);
                          }
                        })
                        .catch(e => {
                          alert(
                            "La riga non è stata eliminata a causa di un errore: " +
                              e
                          );
                          reject();
                        });
                    })
                }}
                icons={materialTableIcons}
                localization={materialTableLocalisationIT}
              />
            </div>
          );
        }}
      </Context.Consumer>
    );
  }
}

export default ArchivioOfferte;
