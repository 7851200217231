import axios from "axios";
import Constants from "./Constants";

function logout(context) {
    context.setContext({
        user: {},
    });
    const darkTheme = localStorage.getItem("darkUi");
    localStorage.clear();
    localStorage.setItem("darkUi", darkTheme);
}

function capitalizeFirstLetter(string) {
    if (typeof string !== "undefined") {
        return string.charAt(0).toUpperCase() + string.slice(1);
    } else {
        return "";
    }
}

function setLowSpecDevice(bool, context, refreshPage=true) {
    // const context = useContext(Context);
    context.setContext((prev) => ({
        device: {
            ...prev.device,
            lowSpec: bool,
        },
    }));
    localStorage.setItem("lowSpecDevice", bool);
    if(refreshPage) window.location.reload();
}

function setUseElasticSearch(bool, context) {
    context.setContext((prev) => ({
        device: {
            ...prev.device,
            useElasticSearch: bool,
        },
    }));
    localStorage.setItem("useElasticSearch", bool);
}

function returnTiersFromListinoName(name) {
    let domain,
        tier,
        role,
        isOffer = false;
    if (/^prezzo_(ev)([0-9])s([0-9])$/.test(name)) {
        //Listino standard
        let matches = name.match(/^prezzo_(ev|ex|ec)([0-9])s([0-9])$/);
        domain = matches[1];
        role = matches[2];
        tier = matches[3];
    } else if (/^prezzo_(ex|ec)([0-9])(o)?$/.test(name)) {
        // Listini senza scaglioni
        let matches = name.match(/^prezzo_(ex|ec)([0-9])(o)?$/);
        domain = matches[1];
        role = matches[2];
        tier = null;
        isOffer = matches?.[3] === "o";
    } else {
        throw "È stato passato un nome di listino non riconosciuto!";
    }
    return {
        domain,
        tier,
        role,
        isOffer,
    };
}

async function getAllProdIds(filter = "attivi") {
    const token = localStorage.getItem("token");
    let finalData = [];
    await axios
        .get(
            Constants.paths.ajaxBasePath + `prodotti/?filter=${filter}&token=` + token
        )
        .then((resp) => {
            finalData = resp.data.map((d) => d.id);
        });
    return finalData;
}

async function getAllClientiIds(filter = "attivi") {
    const token = localStorage.getItem("token");
    let finalData = [];
    await axios
        .get(
            Constants.paths.ajaxBasePath + `clienti/?filter=${filter}&token=${token}`
        )
        .then((resp) => {
            finalData = resp.data.map((d) => d.id);
        });
    return finalData;
}

function setThemeMode(to = 'light', context) {
    if (to === 'light')
        to = false
    else if (to === 'dark') to = true
    else {
        console.error('Wrong theme mode supplied', to);
        return;
    }
    context.setContext(prev => ({
        ui: {
            ...prev.ui,
            dark: to
        }
    }));
    localStorage.setItem("darkUi", to);
}

async function getAgenti(ids = false, restrictToIdGest = true) {
    const token = localStorage.getItem('token');
    let idString = Array.isArray(ids) ? (ids.join(',')) : ids;
    try {
        let res = await axios.get(
            Constants.paths.ajaxBasePath + "agenti/"
            + "?token=" + token
            + (ids ? ("&ids" + idString) : "")
            + (restrictToIdGest ? "&restrictToIdGest=1" : "")
        );
        return res.data;
    } catch (e) {
        console.error(e);
        return false;
    }
}

export {
    logout,
    capitalizeFirstLetter,
    setLowSpecDevice,
    setUseElasticSearch,
    returnTiersFromListinoName,
    getAllClientiIds,
    getAllProdIds,
    setThemeMode,
    getAgenti
};
