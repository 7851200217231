import React from "react";
import ProfileCard from "../../../components/ProfileCard";
import axios from "axios";
import Context from "../../../context/Context";
import Constants from "../../../context/Constants";
import {
    Paper,
    Grid,
    Typography,
    FormControlLabel,
    Switch,
    Collapse,
    Button,
    CircularProgress,
    Box,
    TextField,
    Divider,
} from "@mui/material";
import {LinearProgressWithLabel} from "../../../components/Progresses";
// import {withSnackbar} from "notistack";
import {getAllProdIds, getAllClientiIds} from "../../../context/GlobalFunctions";
import {enqueueSnackbar} from "notistack";

class ElasticUtils extends React.Component {
    static contextType = Context;
    static maxRetries = 10;

    constructor(props) {
        super(props);
        this.getAllProdIds = this.getAllProdIds.bind(this);
        this.getAllClientiIds = this.getAllClientiIds.bind(this);
        this.launchAllRequests = this.launchAllRequests.bind(this);
        this.launchRequests = this.launchRequests.bind(this);
        this.state = {
            allProdIds: [],
            allClientiIds: [],
            progressStep: 0,
            progressStepTotal: 2,
            progressActualIdsTotal: 0,
            fetching: false,
            indexing: false,
            payloadSize: 200,
            transferred: 0,
            shouldAbort: false,
            retries: 0,
            lastError: Error,
        };
    }

    componentDidMount() {
        this.getAllProdIds();
        this.getAllClientiIds();
    }

    launchAllRequests() {
        const step1 = (nextStep) => {
            this.setState(
                {
                    progressStep: 1,
                    progressActualIdsTotal: this.state.allProdIds.length,
                },
                () => {
                    this.launchRequests(
                        0,
                        false,
                        this.state.allProdIds,
                        Constants.paths.ajaxBasePath + "prodotti/elastic/utils/reindex/",
                        nextStep
                    );
                }
            );
        };

        const step2 = (nextStep) => {
            this.setState(
                {
                    progressStep: 2,
                    progressActualIdsTotal: this.state.allClientiIds.length,
                },
                () => {
                    this.launchRequests(
                        0,
                        false,
                        this.state.allClientiIds,
                        Constants.paths.ajaxBasePath + "clienti/elastic/utils/reindex/",
                        nextStep
                    );
                }
            );
        };

        step1(step2);
    }

    launchRequests(
        offset = 0,
        customPayloadSize = false,
        ids = [],
        path = "",
        onFinished = undefined
    ) {
        this.setState({indexing: true});
        const thisIds = [...ids].splice(
            offset,
            customPayloadSize || this.state.payloadSize
        );
        console.log(thisIds);
        let promises = []; // Prevedi più richieste simultanee in futuro
        const toPost = JSON.stringify({
            ids: thisIds,
            token: this.context.user.token,
        });
        //Send ids to index
        promises.push(
            new Promise((resolve, reject) =>
                axios
                    .post(path, toPost)
                    .then((res) => {
                        if (res.status >= 200 && res.status < 300) {
                            resolve("Reindex OK");
                        } else {
                            throw new Error(res);
                        }
                    })
                    .catch((e) => {
                        reject(e);
                    })
            )
        );
        //Finally
        Promise.all(promises)
            .then((results) => {
                console.log(results);
                this.setState(
                    {
                        transferred:
                            parseInt(offset) +
                            parseInt(customPayloadSize || this.state.payloadSize),
                    },
                    () => {
                        if (
                            ids.length > this.state.transferred &&
                            !this.state.shouldAbort
                        ) {
                            this.launchRequests(
                                parseInt(offset) +
                                parseInt(customPayloadSize || this.state.payloadSize),
                                false,
                                ids,
                                path,
                                onFinished
                            );
                            this.setState({retries: 0});
                        } else {
                            this.setState(
                                {
                                    indexing: false,
                                    transferred: 0,
                                },
                                () => {
                                    enqueueSnackbar("Sincronizzati!", {
                                        variant: "success",
                                        persist: true,
                                        preventDuplicate: true,
                                    });
                                    if (onFinished) onFinished();
                                }
                            );
                        }
                    }
                );
            })
            .catch((err) => {
                console.error(err);
                if (this.state.retries >= ElasticUtils.maxRetries) {
                    this.setState({indexing: false, transferred: 0}, () => {
                        alert("Max tentativi raggiunti" + err);
                    });
                } else {
                    this.setState(
                        (prev) => ({
                            //RETRY
                            retries: ++prev.retries,
                            lastError: err,
                        }),
                        () => {
                            setTimeout(
                                () =>
                                    this.launchRequests(
                                        offset,
                                        (customPayloadSize || this.state.payloadSize) -
                                        5 * this.state.retries >
                                        1 //Riduci di 5 * n tentativi la quantità del payload se i tentativi falliscono
                                            ? (customPayloadSize || this.state.payloadSize) -
                                            5 * this.state.retries
                                            : 1,
                                        ids,
                                        path,
                                        onFinished
                                    ),
                                ElasticUtils.retryTimeout
                            );
                        }
                    );
                }
            });
    }

    getAllProdIds() {
        getAllProdIds('attivi').then((res) => this.setState({allProdIds: res}));
    }

    getAllClientiIds() {
        getAllClientiIds().then((res) => this.setState({allClientiIds: res}));
    }

    render() {
        // const {enqueueSnackbar, closeSnackbar} = this.props;
        return (
            <>
                <ProfileCard/>
                <Paper
                    elevation={10}
                    sx={{
                        maxWidth: this.context.theme.breakpoints.values.md,
                        margin: "auto",
                        marginTop: this.context.theme.spacing(6)
                    }}
                >
                    <Grid container spacing={3} sx={{textAlign: 'center'}}>
                        <Grid item xs={12}>
                            <Typography variant="h2">Elastic utils</Typography>
                            <Typography>
                                Prodotti nel DB: <strong>{this.state.allProdIds.length}</strong>
                            </Typography>
                            <Typography>
                                Clienti nel DB:{" "}
                                <strong>{this.state.allClientiIds.length}</strong>
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                type="number"
                                variant="outlined"
                                label="Payload"
                                margin="dense"
                                value={this.state.payloadSize}
                                onChange={(e) =>
                                    this.setState({payloadSize: parseInt(e.target.value)})
                                }
                            />
                            <br/>
                            <Typography color="textSecondary" variant="caption">
                                Quanti prodotti verranno aggiornati per richiesta AJAX
                            </Typography>
                        </Grid>
                        <Collapse in={this.state.indexing} style={{width: "100%"}}>
                            <Grid
                                item
                                xs={12}
                                style={{
                                    paddingLeft: this.context.theme.spacing(4),
                                    paddingRight: this.context.theme.spacing(4),
                                }}
                            >
                                <Box style={{display: "inline-flex"}}>
                                    <Typography
                                        style={{marginRight: this.context.theme.spacing(1)}}
                                    >
                                        Trasferimento in corso...
                                    </Typography>
                                    <CircularProgress size={20}/>
                                </Box>
                                <Typography>
                                    Step {this.state.progressStep}/{this.state.progressStepTotal}
                                </Typography>
                                <LinearProgressWithLabel
                                    value={
                                        (this.state.transferred /
                                            this.state.progressActualIdsTotal) *
                                        100
                                    }
                                />
                                <Typography>
                                    {this.state.transferred}/{this.state.progressActualIdsTotal}
                                    {this.state.retries > 0 ? (
                                        <Typography
                                            style={{color: this.context.theme.palette.warning.dark}}
                                        >
                                            Tentativo {this.state.retries + 1}/
                                            {ElasticUtils.maxRetries}
                                        </Typography>
                                    ) : (
                                        ""
                                    )}
                                </Typography>
                            </Grid>
                        </Collapse>
                        <Grid item xs={12}>
                            <Button
                                disabled={this.state.fetching || this.state.indexing}
                                onClick={(e) => this.launchAllRequests()}
                                style={{
                                    background: this.context.theme.palette.warning.main,
                                    marginBottom: this.context.theme.spacing(4)
                                }}
                                variant="contained"
                                color="primary"
                            >
                                Indicizza
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            </>
        );
    }
}

export default ElasticUtils;
