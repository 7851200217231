import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faMoneyCheckAlt,
    faCalendarAlt,
    faUserTie,
    faTachometerAlt,
    faFile,
    faEuroSign,
    faUsers,
    faBox,
    faTruck,
    faCashRegister,
    faUser,
    faIdCard,
    faArchive,
    faClipboard,
    faFileInvoiceDollar,
    faWarehouse,
    faCogs,
    faCog,
    faUserFriends,
    faDatabase,
    faSitemap, faStar, faHandHoldingDollar, faTruckFast, faFileInvoice,
} from "@fortawesome/free-solid-svg-icons";
import Home from "../routes/Home";
import Prezzi from "../routes/Prezzi/Prezzi";
import Clienti from "../routes/Clienti";
import Prodotti from "../routes/Prodotti";
import Agenti from "../routes/Agenti/Agenti";
import ItinerariAgenti from "../routes/Agenti/ItinerariAgenti";
import ControlloDocumentiFiscali from "../routes/ControlloDocumentiFiscali";
import PrimeNote from "../routes/PrimeNote";
import ArchivioOfferte from "../routes/ArchivioOfferte";
import Profilo from "../routes/Profilo";
import Ordini from "../routes/Ordini";
import Inventario from "../routes/Inventario";
import SyncProdotti from "../routes/Utilità/WooCommerce/SyncProdotti";
// import SpecialMeringoloCustomers from "../routes/SpecialMeringoloCustomers";
import GeneraSku from "../routes/Utilità/Misc/GeneraSku";
import ElasticUtils from "../routes/Utilità/Misc/ElasticUtils";
import RelClassificazioneAttributi from "../routes/Meta/RelClassificazioneAttributi";
import NomiTipici from "../routes/Meta/NomiTipici";
import Attributi from "../routes/Meta/Attributi";
import RigeneraDescrizioni from "../routes/Utilità/Misc/RigeneraDescrizioni";
import GeneraFileImportTs from "../routes/Utilità/TeamSystem/GeneraFileImportTs";
import GeneraFileImportWc from "../routes/Utilità/WooCommerce/GeneraFileImportWc";
import DefaultTags from "../routes/Meta/DefaultTags";
import InventarioCompleto from "../routes/Speciali/InventarioCompleto";
import CalcoloProvvigionale from "../routes/Agenti/CalcoloProvvigionale";
import SpedizioniEtichette from "../routes/Spedizioni/SpedizioniEtichette";

const Routes = [
    {
        type: "link",
        title: "Dashboard",
        icon: <FontAwesomeIcon icon={faTachometerAlt}/>,
        link: "",
        beta: false,
        role: "any",
        isRoute: true,
        component: <Home/>,
    },
    {
        type: "link",
        title: "Prodotti",
        icon: <FontAwesomeIcon icon={faBox}/>,
        link: "/prodotti",
        beta: true,
        role: "cassa",
        isRoute: true,
        component: <Prodotti/>,
    },
    // {
    //   type: "link",
    //   title: "Ordini",
    //   icon: <FontAwesomeIcon icon={faClipboard} />,
    //   link: "/ordini",
    //   beta: true,
    //   role: "admin",
    //   isRoute: true,
    //   component: <Ordini />
    // },
    {
        type: "link",
        title: "Prezzi",
        icon: <FontAwesomeIcon icon={faEuroSign}/>,
        link: "/prezzi",
        beta: false,
        role: "commerciale",
        isRoute: true,
        component: <Prezzi/>,
    },
    {
        type: "link",
        title: "Inventario",
        icon: <FontAwesomeIcon icon={faWarehouse}/>,
        link: "/inventario",
        beta: false,
        role: "magazzino",
        isRoute: true,
        component: <Inventario/>,
    },
    {
        type: "link",
        title: "Clienti",
        icon: <FontAwesomeIcon icon={faUsers}/>,
        link: "/clienti",
        beta: false,
        role: "commerciale",
        isRoute: true,
        component: <Clienti/>,
    },

    {
        type: "nested",
        title: "Agenti",
        icon: <FontAwesomeIcon icon={faUserTie}/>,
        children: [
            {
                type: "link",
                title: "Schede",
                icon: <FontAwesomeIcon icon={faIdCard}/>,
                link: "/agenti/anagrafiche",
                beta: true,
                role: "admin",
                isRoute: true,
                component: <Agenti/>,
            },
            {
                type: "link",
                title: "Ordini",
                icon: <FontAwesomeIcon icon={faFileInvoiceDollar}/>,
                link: "/agenti/ordini",
                beta: false,
                role: "agente",
                isRoute: true,
                component: <Ordini/>,
            },
            // {
            //     type: "link",
            //     title: "Clienti assegnati",
            //     icon: <FontAwesomeIcon icon={faUserFriends}/>,
            //     link: "/agenti/clienti_meringolo",
            //     beta: false,
            //     role: "agente",
            //     user: "g.meringolo",
            //     isRoute: true,
            //     component: <SpecialMeringoloCustomers/>,
            // },
            {
                type: "link",
                title: "Itinerari",
                icon: <FontAwesomeIcon icon={faCalendarAlt}/>,
                link: "/agenti/itinerari",
                beta: true,
                role: "admin",
                isRoute: true,
                component: <ItinerariAgenti/>,
            },
            {
                type: "link",
                title: "Provvigioni",
                link: "/agenti/calcolo-provvigioni",
                icon: <FontAwesomeIcon icon={faHandHoldingDollar}/>,
                beta: false,
                role: "commerciale",
                isRoute: true,
                component: <CalcoloProvvigionale/>
            }
        ],
    },
    {
        type: "nested",
        title: "Spedizioni",
        link: "/spedizioni",
        icon: <FontAwesomeIcon icon={faTruckFast}/>,
        role: "magazzino",
        isRoute: true,
        children: [
            {
                type: "link",
                title: "Etichette",
                icon: <FontAwesomeIcon icon={faFileInvoice}/>,
                link: "/spedizioni/etichette",
                role: 'magazzino',
                isRoute: true,
                component: <SpedizioniEtichette />
            }
        ]
    },
    {
        type: "nested",
        title: "Speciali",
        icon: <FontAwesomeIcon icon={faStar}/>,
        children: [
            {
                type: "link",
                title: "Inventario Completo",
                icon: <FontAwesomeIcon icon={faStar}/>,
                link: "/speciali/inventario-completo",
                beta: false,
                role: "magazzino",
                isRoute: true,
                component: <InventarioCompleto/>,
            }
        ]
    },
    {
        type: "nested",
        title: "Meta",
        icon: <FontAwesomeIcon icon={faDatabase}/>,
        children: [
            {
                type: "link",
                title: "Nomi tipici",
                icon: <FontAwesomeIcon icon={faSitemap}/>,
                link: "/meta/nomi-tipici",
                beta: false,
                role: "admin",
                isRoute: true,
                component: <NomiTipici/>,
            },
            {
                type: "link",
                title: "Classificazioni",
                icon: <FontAwesomeIcon icon={faSitemap}/>,
                link: "/meta/classificazioni",
                beta: false,
                role: "admin",
                isRoute: true,
                component: <RelClassificazioneAttributi/>,
            },
            {
                type: "link",
                title: "Attributi",
                icon: <FontAwesomeIcon icon={faSitemap}/>,
                link: "/meta/attributi",
                beta: false,
                role: "admin",
                isRoute: true,
                component: <Attributi/>,
            },
            {
                type: "link",
                title: "Default Tags",
                icon: <FontAwesomeIcon icon={faSitemap}/>,
                link: "/meta/default-tags",
                beta: false,
                role: "admin",
                isRoute: true,
                component: <DefaultTags/>,
            },
        ],
    },
    {
        type: "nested",
        title: "Archivio",
        icon: <FontAwesomeIcon icon={faArchive}/>,
        children: [
            {
                type: "link",
                title: "Documenti fiscali",
                icon: <FontAwesomeIcon icon={faFile}/>,
                link: "/archivio/documenti-fiscali",
                beta: false,
                role: "admin",
                isRoute: true,
                component: <ControlloDocumentiFiscali/>,
            },
            // {
            //     type: "link",
            //     title: "Schede consegna",
            //     icon: <FontAwesomeIcon icon={faTruck}/>,
            //     link: "/archivio/schede-consegna",
            //     beta: true,
            //     role: "cassa",
            //     isRoute: true,
            //     component: <SchedeConsegna/>,
            // },
            {
                type: "link",
                title: "Prime note",
                icon: <FontAwesomeIcon icon={faCashRegister}/>,
                link: "/archivio/prime-note",
                beta: true,
                role: "cassa",
                isRoute: true,
                component: <PrimeNote/>,
            },
            {
                type: "link",
                title: "Offerte",
                icon: <FontAwesomeIcon icon={faMoneyCheckAlt}/>,
                link: "/archivio/offerte",
                beta: true,
                role: "agente",
                isRoute: true,
                component: <ArchivioOfferte/>,
            },
        ],
    },
    {
        type: "divider",
        isRoute: false,
    },
    {
        type: "nested",
        title: "Utilità",
        icon: <FontAwesomeIcon icon={faCogs}/>,
        children: [
            {
                type: 'overline',
                title: 'MISC'
            },
            {
                type: "link",
                title: "Genera SKU",
                icon: <FontAwesomeIcon icon={faCog}/>,
                link: "/utilita/genera-sku",
                beta: false,
                role: "admin",
                isRoute: true,
                component: <GeneraSku/>,
            },
            {
                type: "link",
                title: "Elastic",
                icon: <FontAwesomeIcon icon={faCog}/>,
                link: "/utilita/elastic-utils",
                beta: false,
                role: "admin",
                isRoute: true,
                component: <ElasticUtils/>,
            },
            {
                type: "link",
                title: "Rigenera descrizioni",
                icon: <FontAwesomeIcon icon={faCog}/>,
                link: "/utilita/rigenera-descrizioni",
                beta: false,
                role: 'admin',
                isRoute: true,
                component: <RigeneraDescrizioni/>
            },
            {
                type: 'overline',
                title: 'WooCommerce'
            },
            {
                type: "link",
                title: "Sync Prodotti",
                icon: <FontAwesomeIcon icon={faCog}/>,
                link: "/utilita/sync-prodotti",
                beta: false,
                role: "admin",
                isRoute: true,
                component: <SyncProdotti/>,
            },
            {
                type: "link",
                title: "Genera file d'importazione WC",
                icon: <FontAwesomeIcon icon={faCog}/>,
                link: "/utilita/genera-file-import-wc",
                beta: false,
                role: 'admin',
                isRoute: true,
                component: <GeneraFileImportWc/>
            },
            {
                type: 'overline',
                title: 'TeamSystem'
            },
            {
                type: "link",
                title: "Genera file d'importazione TS",
                icon: <FontAwesomeIcon icon={faCog}/>,
                link: "/utilita/genera-file-import-ts",
                beta: false,
                role: 'admin',
                isRoute: true,
                component: <GeneraFileImportTs/>
            }
        ],
    },
    {
        type: "link",
        title: "Profilo",
        icon: <FontAwesomeIcon icon={faUser}/>,
        link: "/profilo",
        beta: false,
        isRoute: true,
        role: 'any',

        component: <Profilo/>,
    },
];

export default Routes;