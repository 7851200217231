import React, {useEffect, useState, useContext} from "react";
import Context from "../../context/Context";
import Constants from "../../context/Constants";
import axios from "axios";
import moment from "moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faEye,
    faFaceRollingEyes,
    faFile, faFileExcel, faHashtag,
    faLongArrowAltRight,
    faUser,
    faUserTie, faWarning
} from "@fortawesome/free-solid-svg-icons";
import ProfileCard from "../../components/ProfileCard";

import {
    Box,
    Button, ButtonGroup, CircularProgress,
    Grid, IconButton,
    MenuItem,
    Paper, Popover,
    Select, Step, StepButton, StepContent, Stepper,
    TextField,
    Typography
} from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import {getAgenti} from "../../context/GlobalFunctions";
import MaterialTable from "material-table";
// import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
// import {LocalizationProvider} from "@mui/x-date-pickers";
import {DatePicker, DateTimePicker} from "../../components/DatePicker";
import MaterialTableIcons from "../../support/materialTableIcons";
import MaterialTableLocalisationIT from "../../support/materialTableLocalisationIT";
import Prezzo from "../../components/Prezzo";
import ProductExtendedDescriptionPopup from "../../components/ProductExtendedDescriptionPopup";

const fileDownload = require('js-file-download');

export default function CalcoloProvvigionale({}) {
    //Context
    const context = useContext(Context);

    //States
    const [fetching, setFetching] = useState(false);
    const [downloading, setDownloading] = useState(false);
    const [agentiData, setAgentiData] = useState([]);
    const [agentiSelezionati, setAgentiSelezionati] = useState([]);
    const [dateStart, setDateStart] = useState(null);
    const [dateEnd, setDateEnd] = useState(null);
    const [offset, setOffset] = useState(0);
    const [limit, setLimit] = useState(0);
    const [rawData, setRawData] = useState([]);
    const [orderedData, setOrderedData] = useState([]);
    const [errorPopoverOpen, setErrorPopoverOpen] = useState(false);
    const [errorPopoverAnchor, setErrorPopoverAnchor] = useState(undefined);
    const [errorPopoverContentArray, setErrorPopoverContentArray] = useState([]);

    //Hooks
    //- On Mount
    useEffect(() => {
        getAgenti(false, true).then(data => {
            // get();
            data.push({
                first_name: 'Cash',
                last_name:'',
                id: 'cash',
                id_gest: 'cash'
            });
            setAgentiData(data);
            setAgentiSelezionati(data.map(a => a.id_gest));
        });
        return () => {
        };
    }, []);

    useEffect(() => { //Trasforma i dati in un livello per fare felice material table
        let orderedData = [];
        // let thisId = 0;
        for (let agenteData of rawData) { //Livello 0 - Agente
            if (!agenteData['agente']) continue;
            orderedData.push({
                // id: ++thisId,
                id_tab: agenteData['agente']['id'],
                desc: agenteData['agente']['ragione_sociale'],
                id: agenteData['agente']['id'],
                type: 'agente',
                qta: agenteData['documenti']?.length || 0,
                totale: agenteData['documenti'].reduce((acc, d) => acc + (d['totale_merce'] || 0), 0),
                provvigione: agenteData['documenti'].reduce((acc, doc) => acc + doc['provvigione']?.['importo'], 0),
                provvigione_perc: agenteData['documenti'].flatMap(d => (d['provvigione']?.['perc_media'])).reduce((acc, d, i, src) => acc + (d / src.length), 0),
                errori: agenteData['documenti']
                    .map(d => d['righe']
                        .map(r => r ? r['errori'] ?
                            r['errori']
                                .map(err => ({
                                    ...err,
                                    ref: `${d['cliente_meta']['ragione_sociale']} -> ${d['protocollo']}/${d['_id_gest_sezionale']} -> #${r['n_riga']}`
                                })) : [] : []))
                    .flat(3)
            })
            ;
            //----------------
            for (const documento of agenteData['documenti']) { // Livello 1 - Cliente
                if (!orderedData.some(d => d.id === agenteData['agente']['id']
                    + "-"
                    + documento['id_cliente'])) {
                    orderedData.push({
                        parentId: agenteData['agente']['id'],
                        id: agenteData['agente']['id'] + "-" + documento['id_cliente'],
                        id_tab: documento['id_cliente'],
                        desc: documento['cliente_meta']?.['ragione_sociale'],
                        qta: agenteData['documenti'].reduce((acc, doc) => acc + (doc['id_cliente'] === documento['id_cliente'] ? 1 : 0), 0),
                        type: 'cliente',
                        totale: agenteData['documenti'].reduce((acc, doc) => acc + (doc['id_cliente'] === documento['id_cliente'] ? doc['totale_merce'] : 0), 0),
                        provvigione: agenteData['documenti'].reduce((acc, doc) => acc + (doc['id_cliente'] === documento['id_cliente'] ? doc['provvigione']?.['importo'] : 0), 0),
                        provvigione_perc: agenteData['documenti'].flatMap(d => (d['id_cliente'] === documento['id_cliente'] ? d['provvigione']?.['perc_media'] : [])).reduce((acc, d, i, src) => acc + (d / src.length), 0),
                        errori: agenteData['documenti']
                            ?.map(d => (d['id_cliente'] === documento['id_cliente'] ? documento : []))
                            ?.map(d => d['righe']
                                ?.map(r => r ?
                                    r['errori'] ?
                                        r['errori'].map(e => ({
                                            ...e,
                                            ref: `${d['protocollo']}/${d['_id_gest_sezionale']} -> #${r['n_riga']}`
                                        }))
                                        : []
                                    : []
                                ))
                            .flat(3)
                            .filter(e => e)
                    });
                }
                //--------------------
                orderedData.push({ //Libvello 2 - Documento
                    parentId: agenteData['agente']['id'] + "-" + documento['id_cliente'],
                    id: documento['id_gest'],
                    type: 'documento',
                    qta: documento['righe'].length || 0,
                    id_tab: documento['protocollo'] + "/" + documento['_id_gest_sezionale'],
                    totale: documento['totale_merce'],
                    provvigione: documento['provvigione']?.['importo'] || 0,
                    provvigione_perc: documento['provvigione']?.['perc_media'] || 0,
                    desc: documento["_id_gest_tipo"] + " del " + moment(documento?.['data_documento']).format('DD/MM/YYYY'),
                    errori: (documento['righe'].length
                        ? documento['righe']
                            .map(r => r?.['errori']
                                ? r['errori']
                                    .map(e => ({
                                        ...e,
                                        ref: `#${r['n_riga']}`
                                    }))
                                : [])
                        : [])
                        .flat(3)
                        .filter(e => e)
                });
                //--------------------
                for (const riga of documento['righe']) { //Libvello 3 - Riga
                    if (!riga) continue; //Probabilmente è il trasporto, salta
                    orderedData.push({
                        id: documento['id_gest'] + "-" + riga['n_riga'],
                        type: 'riga',
                        desc: riga['prod_meta']?.['descrizione'],
                        parentId: documento['id_gest'],
                        id_tab: riga['_sku'],
                        qta: riga['qta'],
                        _prezzo_listino: riga['prezzo_l3'],
                        totale: riga['prezzo_finito'],
                        provvigione: riga['provvigione']?.['importo'] * riga['qta'],
                        provvigione_perc: riga['provvigione']?.['perc_provvigione'],
                        errori: (riga['errori'] ? riga['errori'].map(e => ({
                            ...e,
                            ref: `#${riga['n_riga']}`
                        })) : []).flat(2).filter(e => e),
                        _prod_id: riga['prod_meta']?.['id'], //Usato dal componente della desc estesa,
                        _cat_provv: riga['prod_meta']?.['cat_provv'],
                        _sconto_effettivo: riga['provvigione']?.['sconto_effettivo'],
                        _totale_singolo: riga['prezzo_finito_singolo']

                    });
                }
            }
        }
        // console.table(rawData[0]['documenti']?.flatMap(r => r['righe']));
        setOrderedData(orderedData);
        return () => {
            setOrderedData([]);
        };
    }, [rawData]);


    //Functions
    const get = async () => {
        setFetching(true);
        let res = await axios.get(
            Constants.paths.ajaxBasePath
            + "agenti/provvigioni/calcolo/"
            + "?token=" + context.user.token
            + "&idAgente=" + agentiSelezionati.join(",")
            + "&dateStart=" + getFormattedDate(dateStart)
            + "&dateEnd=" + getFormattedDate(dateEnd)
            + "&offset=" + offset
            + "&limit=" + limit
        );
        setRawData(res.data);
        setFetching(false);
    }

    const downloadExcel = async () => {
        setDownloading(true);
        let res = await axios.get(
            Constants.paths.ajaxBasePath
            + "agenti/provvigioni/calcolo/"
            + "?token=" + context.user.token
            + "&idAgente=" + agentiSelezionati.join(",")
            + "&dateStart=" + getFormattedDate(dateStart)
            + "&dateEnd=" + getFormattedDate(dateEnd)
            + "&offset=" + offset
            + "&limit=" + limit
            + "&download=xlsx",
            {
                responseType: "blob"
            }
        );
        fileDownload(res.data, 'Report provvigioni ' + getFormattedDate(dateStart) + getFormattedDate(dateEnd) + ".xlsx");
        setDownloading(false);
    }

    const getFormattedDate = (date) => {
        let d = new Date(date);
        return (d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate());
    };

    return <>
        <ProfileCard/>
        <Paper style={{padding: context.theme.spacing(2)}}>
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant={'h6'}>Calcolo provvigionale</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Stepper orientation={'vertical'}>
                        <Step>
                            <StepButton onClick={e => setRawData([])}>Elaborazione</StepButton>
                            <StepContent>
                                <Grid container spacing={4}>

                                    <Grid item xs={3}>
                                        <Typography variant={'overline'}>Agenti
                                            ({agentiSelezionati.length})</Typography>
                                        <Select
                                            fullWidth
                                            value={agentiSelezionati || []}
                                            multiple
                                            // input={<OutlinedInput label="Name" />}
                                            variant={'outlined'}
                                            onChange={(ev) => {
                                                setAgentiSelezionati(ev.target.value);
                                            }}
                                        >
                                            {agentiData.map(a =>
                                                <MenuItem
                                                    value={a.id_gest}>{a.first_name + " " + a.last_name}</MenuItem>
                                            )}
                                        </Select>
                                    </Grid>

                                    <Grid item xs={3}>
                                        <Typography variant={'overline'}>Inizio</Typography><br/>
                                        <DatePicker
                                            fullWidth
                                            inputProps={{value: dateStart, onChange: d => setDateStart((d))}}
                                        />
                                    </Grid>

                                    <Grid item xs={3}>
                                        <Typography variant={'overline'}>Fine</Typography><br/>
                                        <DatePicker
                                            fullWidth
                                            inputProps={{value: dateEnd, onChange: d => setDateEnd((d))}}
                                        />
                                    </Grid>

                                    {/*<Grid item xs={1}>*/}
                                    {/*    <Typography variant={'overline'}>Limite</Typography><br/>*/}
                                    {/*    <TextField*/}
                                    {/*        fullWidth*/}
                                    {/*        variant={'outlined'}*/}
                                    {/*        value={limit}*/}
                                    {/*        onChange={e => setLimit(parseInt(e.target.value))}*/}
                                    {/*    />*/}
                                    {/*</Grid>*/}
                                    <Grid item xs={2}>
                                        <Typography variant={'overline'}>Calcola</Typography><br/>
                                        <IconButton
                                            fullWidth
                                            variant={'outlined'}
                                            disabled={
                                                (!(agentiSelezionati && dateStart && dateEnd))
                                            }
                                            onClick={e => get()}
                                            sx={{color: context.theme.palette.success.main}}
                                            size="large">
                                            <FontAwesomeIcon
                                                icon={faEye}/>
                                        </IconButton>
                                        {downloading
                                            ?
                                            <CircularProgress
                                                size={24}
                                            />
                                            : <IconButton
                                                fullwidth
                                                variant={'outlined'}
                                                disabled={
                                                    (!(agentiSelezionati && dateStart && dateEnd))
                                                }
                                                onClick={e => downloadExcel()}
                                                sx={{color: context.theme.palette.success.main}}
                                                size="large"
                                            >
                                                <FontAwesomeIcon icon={faFileExcel}/>
                                            </IconButton>
                                        }
                                    </Grid>

                                </Grid>
                            </StepContent>
                        </Step>

                        <Step expanded={!!rawData.length}>
                            <StepButton>Controllo {fetching && <CircularProgress size={12}/>}</StepButton>
                            <StepContent>
                                <Grid container spacing={4}>
                                    <Grid item xs={12}>
                                        <MaterialTable
                                            isLoading={fetching}
                                            icons={MaterialTableIcons}
                                            localization={MaterialTableLocalisationIT}
                                            parentChildData={
                                                (row, rows) => rows.find(a => a.id == row.parentId)
                                            }
                                            title={
                                                (rawData
                                                    ? (rawData).reduce((acc, d) => acc
                                                        + d['documenti']
                                                            ? d['documenti'].reduce((acc2, d2) =>
                                                                    acc2 + (d2['righe']?.length || 0)
                                                                , 0)
                                                            : 0
                                                        , 0)
                                                    : 0) + " righe elaborate"}
                                            columns={
                                                [
                                                    {
                                                        title: 'ID',
                                                        field: 'id_tab',
                                                        render: rd => <><FontAwesomeIcon icon={
                                                            rd.type === 'riga'
                                                                ? faHashtag
                                                                : rd.type === 'cliente'
                                                                    ? faUser
                                                                    : rd.type === 'documento'
                                                                        ? faFile
                                                                        : rd.type === 'agente'
                                                                            ? faUserTie : undefined
                                                        }/> {rd.id_tab}</>
                                                    },
                                                    {
                                                        title: "Desc",
                                                        field: 'desc',
                                                        cellStyle: {width: "40%"},
                                                        render: rd => <>
                                                            {rd.desc}{rd.type === 'riga' &&
                                                            <ProductExtendedDescriptionPopup id={rd._prod_id}/>}
                                                        </>
                                                    },
                                                    {
                                                        title: "Qtà",
                                                        field: "qta",
                                                        cellStyle: {width: "10%"},
                                                        render: rd => ("" + rd['qta'] +
                                                            (rd['type'] === 'riga'
                                                                    ? (rd['qta'] < 2 ? " pezzo" : " pezzi")
                                                                    : rd['type'] === 'documento'
                                                                        ? (rd['qta'] < 2 ? " rigo" : " righe")
                                                                        : rd['type'] === 'cliente'
                                                                            ? (rd['qta'] < 2 ? " doc." : " doc.")
                                                                            : (rd['qta'] < 2 ? " cliente" : " clienti")
                                                            )
                                                        )
                                                    },
                                                    {
                                                        title: "Provvigione",
                                                        field: "provvigione",
                                                        render: (rd) => <>
                                                            <Prezzo>{rd.provvigione || 0}</Prezzo> ({rd.provvigione_perc?.toFixed(2)}%) {rd._cat_provv ? `[C${rd._cat_provv}]` : ""}
                                                        </>
                                                    },
                                                    {
                                                        title: "Totale",
                                                        field: 'totale',
                                                        render: rd => rd.type === 'riga'
                                                            ? <Grid container spacing={1}>
                                                                <Grid item>
                                                                    <Typography variant={'overline'}>
                                                                        <small>
                                                                            Listino
                                                                        </small>
                                                                    </Typography>
                                                                    <br/>
                                                                    <Prezzo>
                                                                        {rd._prezzo_listino}
                                                                    </Prezzo>
                                                                </Grid>

                                                                <Grid item>
                                                                    <Typography variant={'overline'}>
                                                                        <small>
                                                                            Sconto
                                                                        </small>
                                                                    </Typography>
                                                                    <br/>
                                                                    <Box sx={{textAlign: 'right'}}>
                                                                        {rd._sconto_effettivo || 0}%
                                                                    </Box>
                                                                </Grid>

                                                                <Grid item>
                                                                    <Typography variant={'overline'}>
                                                                        <strong>
                                                                            <small>
                                                                                Riga
                                                                            </small>
                                                                        </strong>
                                                                    </Typography>
                                                                    <br/>
                                                                    <strong>
                                                                        <Prezzo>{rd._totale_singolo}</Prezzo>
                                                                    </strong>
                                                                </Grid>

                                                                <Grid item>
                                                                    <Typography variant={'overline'}>
                                                                        <strong>
                                                                            <small>
                                                                                Totale
                                                                            </small>
                                                                        </strong>
                                                                    </Typography>
                                                                    <br/>
                                                                    <Prezzo>{rd.totale}</Prezzo>
                                                                </Grid>
                                                            </Grid>
                                                            : <Prezzo>{rd.totale}</Prezzo>
                                                    },
                                                    {
                                                        title: "Info",
                                                        field: "",
                                                        // width: 5,
                                                        cellStyle: {width: "5%"},
                                                        render: rd =>
                                                            (rd['errori']
                                                                && rd['errori']
                                                                    .flat().length)
                                                                ? <IconButton
                                                                    onClick={e => {
                                                                        setErrorPopoverAnchor(e.currentTarget);
                                                                        setErrorPopoverOpen(true);
                                                                        setErrorPopoverContentArray(rd['errori'] ? rd['errori'].map(e => e ? e.ref + ": " + e.message : "") : []);
                                                                    }}
                                                                >
                                                                    <FontAwesomeIcon
                                                                        color={context.theme.palette.warning.main}
                                                                        icon={faWarning}/>
                                                                </IconButton>
                                                                : <></>

                                                    }
                                                ]}
                                            data={orderedData}
                                            // options={{exportButton:true}}
                                        />

                                    </Grid>
                                </Grid>
                            </StepContent>
                        </Step>
                        <Step expanded={!!rawData.length}>
                            <StepButton>Esportazione</StepButton>
                            <StepContent>
                                <Grid container spacing={4}>
                                    <Grid item xs={12}>
                                        <ButtonGroup>
                                            <LoadingButton
                                                variant={"contained"}
                                                loading={downloading}
                                                loadingIndicator={<CircularProgress color="inherit" size={16}/>}
                                                disabled={
                                                    (!(agentiSelezionati && dateStart && dateEnd))
                                                }
                                                onClick={e => downloadExcel()}
                                            ><FontAwesomeIcon icon={faFileExcel}
                                                              style={{marginRight: context.theme.spacing(1)}}/>Scarica
                                                in Excel</LoadingButton>
                                        </ButtonGroup>
                                    </Grid>
                                </Grid>
                            </StepContent>
                        </Step>
                    </Stepper>
                </Grid>
            </Grid>
        </Paper>
        <Popover
            open={errorPopoverOpen}
            onClose={e => setErrorPopoverOpen(false)}
            anchorEl={errorPopoverAnchor}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            sx={{display: 'block'}}
            PaperProps={{
                sx: {
                    background: context.theme.palette.warning.dark,
                    fontWeight: 'bold',
                    color: context.theme.palette.common.white
                }
            }}
        >
            <Grid container sx={{maxWidth: context.theme.breakpoints.md, padding: context.theme.spacing(1)}}
                  spacing={1}>
                {errorPopoverContentArray.map(e => <Grid item xs={12}>{e}</Grid>)}
            </Grid>
        </Popover>
    </>;
}