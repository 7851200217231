import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCloudDownloadAlt,
  faQuestionCircle,
  faCloudUploadAlt,
  faTrash
} from "@fortawesome/free-solid-svg-icons";
import { Button, ButtonGroup } from "@mui/material";

class Render extends React.Component {
  render() {
    return (
      <Button
        fullWidth
        variant="contained"
        color="primary"
        size="small"
        disabled={this.props.value.has_file == 1 ? false : true}
        className=""
        onClick={this.props.onClick}
        startIcon={
          <FontAwesomeIcon
            icon={
              this.props.value.has_file == 1
                ? faCloudDownloadAlt
                : faQuestionCircle
            }
          />
        }
      >
        {this.props.value.has_file == 1 ? "Scarica" : "N/D"}
      </Button>
    );
  }
}

class Edit extends React.Component {
    constructor(props){
        super(props);
        console.log(this.props.onChange);
    }
  render() {
    return (
      <ButtonGroup>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          size="small"
          className=""
          startIcon={<FontAwesomeIcon icon={faCloudUploadAlt} />}
        >
          <input
            onChange={e => this.props.onChange(this.props.input_props, e)}
            type="file"
            style={{ opacity: 0, position: "absolute" }}
          />
          {this.props.input_props.rowData.has_file == 1
            ? "Sovrascrivi"
            : "Carica"}
        </Button>
        <Button size="small" variant="contained" color="primary" onClick={e=>this.props.onDelete(this.props.input_props.rowData,e)}>
          <FontAwesomeIcon icon={faTrash} />
        </Button>
      </ButtonGroup>
    );
  }
}

export { Render as TableFileButtonRender, Edit as TableFileButtonEdit };
