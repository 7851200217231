import React from "react";
import Context from "../context/Context";
import Constants from "../context/Constants";
import { Button, ButtonGroup, TextField } from "@mui/material";
import MaterialTable from "material-table";
import { DatePicker } from "../components/DatePicker";
import TextareaInput from "../components/TextareaInput";

import materialTableIcons from "../support/materialTableIcons";

import axios from "axios";

import { it } from "date-fns/locale";

import ProfileCard from "../components/ProfileCard";
import materialTableLocalisationIT from "../support/materialTableLocalisationIT";
import { CustomerSelector } from "../components/DialogSelector";

import handyScroll from "handy-scroll";
import "handy-scroll/dist/handy-scroll.css";

class TableCustomerSelector extends React.Component {
  constructor(props) {
    super(props);
    this.handleClose = this.handleClose.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.state = {
      open: false,
      value: this.props.input_props.value || "Seleziona",
    };
  }

  handleClose() {
    this.setState({ open: false });
  }

  handleOpen() {
    this.setState({ open: true });
  }

  handleSelect(customer) {
    this.props.input_props.onChange(
      "(" + customer.id + ")" + "\n" + customer.ragione_sociale
    );
    this.setState({ open: false });
  }

  handleDelete() {
    this.props.input_props.onChange("");
    this.setState({ open: false });
  }

  render() {
    return (
      <React.Fragment>
        <Button
          variant="contained"
          fullWidth
          size="small"
          onClick={this.handleOpen}
        >
          {(this.props.input_props.value &&
            this.props.input_props.value
              .match(/(\([0-9]*\))/g)
              .toString()
              .replace("(", "")
              .replace(")", "")) ||
            "Seleziona"}
        </Button>

        <CustomerSelector
          open={this.state.open}
          onClose={this.handleClose}
          onOpen={this.handleOpen}
          onSelect={this.handleSelect}
          onEmpty={this.handleDelete}
        />
      </React.Fragment>
    );
  }
}

export default class ControlloDocumentiFiscali extends React.Component {
  static contextType = Context;
  constructor(props) {
    super(props);
    this.getData = this.getData.bind(this);
    this.getRichiedenteData = this.getRichiedenteData.bind(this);
    this.state = {};
    this.col_currencySetting = {
      locale: "It-it",
      currencyCode: "EUR",
    };
    this.date_fns_locale = it;
    this.contentRef = React.createRef();
  }

  getTableHorizontalScrollContainer() {
    let toReturn;
    // try {
    //   toReturn = document.querySelectorAll(
    //     "[class^=Component-horizontalScrollContainer-]" //TODO: A quanto pare la classe varia nella prod, aggiungi eccezione 
    //   )[0].firstChild.firstChild;
    // } catch (error) {
    try {
      toReturn = document.querySelectorAll(".data-table.documenti-fiscali")[0]
        .firstChild.children[1].firstChild.firstChild;
    } catch (error) {
      toReturn = false;
      console.warn(error);
    }
    // }
    return toReturn;
  }

  componentDidMount() {
    // this.context.functions.setActiveRouteName("documenti fiscali");
    this.getData();
    let name;
    if ((name = this.getTableHorizontalScrollContainer()))
      handyScroll.mount(name);
    this.resizeTimer = setInterval(() => {
      window.dispatchEvent(new Event("resize")); //Chiama questo evento per far funzionare la floating scrollbar, il motivo di questa 'hack' è il fatto che il componente della tabella sia sviluppata male
    }, Constants.heartbeat.fastest);
  }

  componentWillUnmount() {
    clearTimeout(this.resizeTimer);
  }

  getRichiedenteData() {
    return {
        "Alfredo Tripodi": "Alfredo Tripodi",
        "Antonino Siclari": "Antonino Siclari",
        "Antonio Gagliostro": "Antonio Gagliostro",
        "Domenico Astorino": "Domenico Astorino",
        "Enrico Campise": "Enrico Campise",
        "Gianluca D'Agostino": "Gianluca D'Agostino",
        "Gianpiero Savelli": "Gianpiero Savelli",
        "Giuseppe Bellantonio": "Giuseppe Bellantonio",
        "Michela Mallamace": "Michela Mallamace",
        "Samuele Mancuso": "Samuele Mancuso",
        "Sebastiano Crinò": "Sebastiano Crinò",
        "Dario Fazio":"Dario Fazio",
        "Carlo Stabile":"Carlo Stabile"
      }
  }

  getData() {
    let finalData = [];
    axios
      .get(
        Constants.paths.ajaxBasePath +
          "variazioni-documenti-fiscali/?token=" +
          this.context.user.token
      )
      .then((res) => {
        finalData = res.data.map((row) => {
          try {
            row.differenza_importo = parseFloat(
              row.differenza_importo.replace(",", ".")
            );
          } catch {
            //Do nothing and hope for the best
          }
          return row;
        });
        this.setState({
          data: finalData,
        });
        // console.log(finalData);
      });
  }

  render() {
    return (
      <Context.Consumer>
        {(consumer_data) => {
          return (
            <React.Fragment>
              <ProfileCard />
              <div className="data-table documenti-fiscali dense">
                <MaterialTable
                  options={{
                    exportAllData: true,
                    exportButton: true,
                    exportDelimiter: ";",
                    tableLayout: "auto",
                    pageSize: 15,
                    pageSizeOptions: [15, 30, 40, 50, 100, 200],
                    addRowPosition: "first",
                    detailPanelColumnAlignment: "right",
                    actionsColumnIndex: 100,
                    actionsCellStyle: {
                      textAlign: "center",
                    },
                  }}
                  columns={[
                    {
                      title: "#",
                      field: "protocollo",
                      type: "numeric",
                      editable: "onAdd",
                      initialEditValue: "0",
                      headerStyle: {
                        maxWidth: "0.8rem",
                        textAlign: "left",
                      },
                      cellStyle: {
                        textAlign: "center",
                      },
                    },
                    {
                      title: "Data richiesta",
                      field: "data_richiesta",
                      type: "date",
                      editComponent: (props) => (
                        <DatePicker inputProps={props} />
                      ),
                      initialEditValue: new Date(Date.now()),
                    },
                    {
                      title: "Sede",
                      field: "sede",
                      type: "string",
                      lookup: {
                        2: "Rende",
                        1: "Feroleto Antico",
                      },
                    },
                    {
                      title: "Nome richiedente",
                      field: "nome_richiedente",
                      type: "string",
                      lookup: this.getRichiedenteData(),
                    },
                    {
                      title: "Cliente",
                      field: "cliente",
                      type: "string",
                      cellStyle: {
                        whiteSpace: "pre-wrap",
                      },
                      editComponent: (props) => (
                        <TableCustomerSelector input_props={props} />
                      ),
                    },
                    {
                      title: "Data fattura",
                      field: "data_fattura",
                      type: "date",
                      editComponent: (props) => (
                        <DatePicker inputProps={props} />
                      ),
                      initialEditValue: new Date(Date.now()),
                    },
                    {
                      title: "Nr. fattura",
                      field: "numero_fattura",
                      type: "string",
                      customSort: (a, b) => {
                        return (
                          parseInt(
                            a.numero_fattura.replace("/L", "").replace("/R", "")
                          ) -
                          parseInt(
                            b.numero_fattura.replace("/L", "").replace("/R", "")
                          )
                        );
                      },
                    },
                    {
                      title: "Differenza d'importo",
                      field: "differenza_importo",
                      type: "currency",
                      headerStyle: {
                        textAlign: "right",
                        maxWidth: "6em",
                      },
                      cellStyle: {
                        textAlign: "right",
                        maxWidth: "6em",
                      },
                      currencySetting: this.col_currencySetting,
                      customSort: (a, b) => {
                        try {
                          //console.log(a.differenza_importo, b.differenza_importo);
                          return (
                            parseFloat(a.differenza_importo.replace(" €", "")) -
                            parseFloat(b.differenza_importo.replace(" €", ""))
                          );
                        } catch {
                          return (
                            parseFloat(a.differenza_importo) -
                            parseFloat(b.differenza_importo)
                          );
                        }
                      },
                    },
                    {
                      title: "Motivazione",
                      field: "motivazione",
                      type: "string",
                      cellStyle: {
                        whiteSpace: "pre-wrap",
                      },
                      headerStyle: {
                        minWidth: "24rem",
                      },
                      editComponent: (props) => (
                        <TextareaInput input_props={props} />
                      ),
                    },
                    {
                      title: "Azione proposta",
                      field: "azione_proposta",
                      type: "string",
                      cellStyle: {
                        whiteSpace: "pre-wrap",
                      },
                      headerStyle: {
                        minWidth: "5rem",
                      },
                      editComponent: (props) => (
                        <TextareaInput input_props={props} />
                      ),
                    },
                    {
                      title: "Esito",
                      field: "esito",
                      lookup: {
                        Autorizzato: "Autorizzato",
                        "Autorizzato con note": "Autorizzato con note",
                        Respinto: "Respinto",
                        Sospeso: "Sospeso",
                      },
                    },
                    {
                      title: "Note",
                      field: "note",
                      type: "string",
                      cellStyle: {
                        whiteSpace: "pre-wrap",
                      },
                      headerStyle: {
                        minWidth: "12rem",
                      },
                      editComponent: (props) => (
                        <TextareaInput input_props={props} />
                      ),
                    },
                    {
                      title: "Data nota di credito",
                      field: "data_notacredito",
                      type: "date",
                      editComponent: (props) => (
                        <DatePicker inputProps={props} />
                      ),
                      initialEditValue: new Date(Date.now()),
                    },
                    {
                      title: "Nr. nota di credito",
                      field: "numero_notacredito",
                      type: "string",
                      customSort: (a, b) => {
                        return (
                          parseInt(
                            a.numero_notacredito
                              .replace("/L", "")
                              .replace("/R", "")
                          ) -
                          parseInt(
                            b.numero_notacredito
                              .replace("/L", "")
                              .replace("/R", "")
                          )
                        );
                      },
                    },
                    // {
                    //   title: "Informazioni aggiuntive",
                    //   field: "informazioni_aggiuntive",
                    //   type: "string"
                    // },
                    {
                      title: "Imputazione",
                      field: "imputazione",
                      type: "string",
                      cellStyle: {
                        whiteSpace: "pre-wrap",
                      },
                      headerStyle: {
                        minWidth: "5rem",
                      },
                      editComponent: (props) => (
                        <TextareaInput input_props={props} />
                      ),
                    },
                    // {
                    //   title: "Note direzione",
                    //   field: "note_direzione"
                    // }
                  ]}
                  data={this.state.data}
                  title="Controllo documenti fiscali"
                  editable={{
                    onRowAdd: (newData) =>
                      new Promise((resolve, reject) => {
                        const data = this.state.data;
                        try {
                          newData.differenza_importo = parseFloat(
                            newData.differenza_importo.replace(",", ".")
                          );
                        } catch {
                          //Do nothing and hope for the best
                        }
                        data.push(newData);
                        const toPost = JSON.stringify({
                          row: newData,
                          token: consumer_data.user.token,
                          action: "create",
                        });
                        console.log(toPost);
                        axios
                          .post(
                            Constants.paths.ajaxBasePath +
                              "variazioni-documenti-fiscali/",
                            toPost
                          )
                          .then((res) => {
                            if (res.status >= 200 && res.status < 300) {
                              // this.setState(data, () => resolve());
                              resolve();
                              this.getData();
                            } else {
                              reject();
                              throw new Error(res);
                            }
                          })
                          .catch((e) => {
                            reject();
                            alert("Errore nel form:" + e.response.status);
                          });
                      }),
                    onRowUpdate: (newData, oldData) =>
                      new Promise((resolve, reject) => {
                        const data = this.state.data;
                        const index = data.indexOf(oldData);
                        try {
                          newData.differenza_importo = parseFloat(
                            newData.differenza_importo.replace(",", ".")
                          );
                        } catch {
                          //Do nothing and hope for the best
                        }
                        data[index] = newData;
                        const toPost = JSON.stringify({
                          row: newData,
                          token: consumer_data.user.token,
                          action: "update",
                        });
                        console.log(toPost);
                        axios
                          .post(
                            Constants.paths.ajaxBasePath +
                              "variazioni-documenti-fiscali/",
                            toPost
                          )
                          .then((res) => {
                            if (res.status >= 200 && res.status < 300) {
                              //this.setState({ data });
                              resolve();
                              this.getData();
                            } else {
                              throw new Error(res);
                            }
                          })
                          .catch((e) => {
                            alert(
                              "Il form non è stato inviato a causa di un errore: " +
                                e
                            );
                            reject();
                          });
                      }),
                    onRowDelete: (oldData) =>
                      new Promise((resolve, reject) => {
                        let data = this.state.data;
                        const index = data.indexOf(oldData);
                        data.splice(index, 1);

                        const toPost = JSON.stringify({
                          row: oldData,
                          token: consumer_data.user.token,
                          action: "delete",
                        });

                        axios
                          .post(
                            Constants.paths.ajaxBasePath +
                              "variazioni-documenti-fiscali/",
                            toPost
                          )
                          .then((res) => {
                            if (res.status >= 200 && res.status < 300) {
                              this.setState({ data });
                              resolve();
                            } else {
                              throw new Error(res);
                            }
                          })
                          .catch((e) => {
                            alert(
                              "La riga non è stata eliminata a causa di un errore: " +
                                e
                            );
                            reject();
                          });
                      }),
                  }}
                  icons={materialTableIcons}
                  localization={materialTableLocalisationIT}
                />
              </div>
            </React.Fragment>
          );
        }}
      </Context.Consumer>
    );
  }
}
