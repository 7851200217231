import React, {useContext} from "react";
import {
    List,
    ListItem,
    Grid,
    Box,
    Typography,
    Divider,
} from "@mui/material";
// import getPricesNr from "../functions/countAvailablePrices";
import Constants from "../context/Constants";
import Context from "../context/Context";

function GeneratePricesPopupContent(props) {
    const context = useContext(Context);
    //TODO: Order by price
    //TODO: Manage discounts
    // let thisPrices = props.acquisto
    //   ? props.productObj.prezzi_acquisto || {}
    //   : props.productObj.prezzi_vendita || {};
    let lists = [];
    Constants.chiaviListini.forEach((l, i) => {
        if (Constants.visibilitaAgentiListini.includes(l)) {
            if (props.productObj[l] && parseFloat(props.productObj[l]) > 0) {
                let thisDiscounts = [];
                for (let i2 = 1; i2 < 6; i2++) {
                    thisDiscounts.push(
                        props.productObj[`sconto_${i2}`]
                            ? props.productObj[`sconto_${i2}`]
                            : 0
                    );
                }
                lists.push(
                    <ListItem
                        key={l}
                        style={{minWidth: "25em"}}
                        button={props.onClick ? true : false}
                        onClick={() => {
                            if (props.onClick) props.onClick(props.productObj[l]);
                        }}
                    >
                        <Grid container spacing={2} justifyContent="space-between" alignItems="baseline">
                            <Grid item container xs={'auto'}>
                                {/* <Grid item xs={12}>
                {(props.productObj["cod"] ? "EV" : "LV") + (i+1)}
              </Grid> */}
                                <Grid item xs={12}>
                                    <Typography variant="body2"
                                                style={props.productObj?.[l + "_nome"]
                                                    ? {color: context.theme.palette.warning.main}
                                                    : {}}
                                    >
                                        {props.productObj?.[l + "_nome"]
                                            ?? Constants.nomiListini[i]}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={'auto'} style={{textAlign: "end"}}>
                                {props.productObj?.[l.replace('prezzo', 'scaglione')] > 1 &&
                                    <strong style={{color: context.theme.palette.warning.main}}>
                                        {`${props.productObj?.[l.replace('prezzo', 'scaglione')]}pz+`}
                                    </strong>
                                }
                            </Grid>
                            {/*<Grid item xs={2} style={{textAlign: "end"}}>*/}
                            {/*    <Box color="text.disabled">{props.productObj[l]}€</Box>*/}
                            {/*</Grid>*/}
                            {/*<Grid item xs={2} style={{textAlign: "end"}}>*/}
                            {/*    <Box color="text.disabled">*/}
                            {/*        {(thisDiscounts[0] &&*/}
                            {/*                thisDiscounts.map((d) => "+" + d + "%")) ||*/}
                            {/*            "+0%"}*/}
                            {/*    </Box>*/}
                            {/*</Grid>*/}
                            <Grid item xs={"auto"} style={{textAlign: "end"}}>
                                <strong>{props.productObj[l] /*Should calc total*/}€</strong>
                            </Grid>
                        </Grid>
                    </ListItem>
                );
            }
        }
    });

    return (
        <List>
            {lists.length === 0 ? (
                <ListItem>
                    <Grid container spacing={2} justifyContent="space-between">
                        <Grid item>
                            <i>
                                Prezzo standard:{" "}
                                {parseFloat(props.productObj.prezzo_x)
                                    .toFixed(2)
                                    .replace(".", ",") + "€" || "N/D"}
                            </i>
                        </Grid>
                    </Grid>
                </ListItem>
            ) : (
                lists.map((l) => l)
            )}
            {props.productObj["cod"] && (
                <React.Fragment>
                    <Divider/>
                    <ListItem>
                        <Typography variant="caption">
                            Sconto su articoli:
                            <strong>
                                {" "}
                                {props.productObj["sconto_articolo"] == 1 ? "Sì" : "No"}
                            </strong>
                        </Typography>
                    </ListItem>
                </React.Fragment>
            )}
        </List>
    );
}

export {GeneratePricesPopupContent};
