import {
  Button,
  Card,
  Grid,
  InputAdornment,
  InputLabel,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useContext,useState } from "react";
import ProfileCard from "../../../components/ProfileCard";
import Constants from "../../../context/Constants";
import Context from "../../../context/Context";

export default function GeneraFileImportTs(props) {
  const context = useContext(Context);
  const [days, setDays] = useState(1);

  const requestFile = async () => {
    window.open(
      `${Constants.paths.ajaxBasePath}prodotti/utils/team-system/generate-import-file?token=${context.user.token}&daysThreshold=${days}&filename=Import.csv&action=download` // <- This is what makes it open in a new window.
    );
    // let req = await axios.get(Constants.paths.ajaxBasePath +
    // `prodotti/utils/team-system/generate-import-file/?\
    // token=${context.user.token}\
    // &daysThreshold=${days}`
    // );
    // console.log(req.data);
    return;
  };

  return (
    <>
      <ProfileCard />
      <Typography variant="h2" color="textPrimary" textAlign={'center'}>
        Genera <code style={{color: context.theme.palette.warning.main}}>Import.csv</code> per importazione su TS
      </Typography>
      <br />
      <Grid container style={{ width: "100%", textAlign: 'center' }} justifyContent="center">
        <Grid item alignContent="center" xs={5}>
          <Card style={{ padding: context.theme.spacing(4) }}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Typography>
                  I prodotti devono essere stati modificati entro gli ultimi:
                              </Typography>
                          </Grid>
                          <Grid item xs={12} style={{justifyContent:'center'}}>
                              <TextField
                                  value={days}
                                  onChange={e=>setDays(e.target.value)}
                  type={"number"}
                  variant={"outlined"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">Giorni</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  download="Import.csv"
                  href={ `${Constants.paths.ajaxBasePath}prodotti/utils/team-system/generate-import-file/?token=${context.user.token}&daysThreshold=${days}`}
                  variant="contained"
                  color="primary"
                >
                  Download
                </Button>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}
