export function titoloMessaggioRandom(nome: string = "Peppi", tipo: "successo" | "errore" | "info" = "successo") {
    let frasiErrore = [
        `Iapri l'occhi ${nome}`,
        `Cazzu stai facendo ${nome}?`,
        `A poi jamu da cu tia ${nome}!`,
        `A facimu a cooperativa ${nome}!`,
        `A cu cazzu pensi ${nome}?`
    ];

    let frasiInfo = [
        `Sta attento ${nome}!`
    ]

    let frasiSuccesso = [
        `Bravo ${nome} 👍👍👍`,
        `Ottimo lavoro ${nome}!`,
        `${nome} u numeru 1!`
    ]

    let frasiToUse = [];
    switch (tipo) {
        case 'successo':
            frasiToUse = frasiSuccesso;
            break;

        case "info":
            frasiToUse = frasiInfo;
            break;

        case "errore":
            frasiToUse = frasiErrore
            break;
    }
    return frasiToUse[Math.floor(Math.random() * frasiToUse.length)];
}