import React from "react";
import axios from "axios";
import Constants from "../context/Constants";
import Context from "../context/Context";
import {
  Button,
  ButtonGroup,
  TextField,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Grid,
  Divider,
  Box,
  Chip,
  Avatar,
  Card,
  InputAdornment,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { SlideUpTransition } from "../components/Transitions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faMinus,
  faWarehouse,
  faArrowRight,
  faTimes,
  faCheck,
  faHourglassHalf,
  faBarcode
} from "@fortawesome/free-solid-svg-icons";
import ProfileCard from "../components/ProfileCard";
import ProductImage from "../components/ProductImage";
import MaterialTable from "material-table";
import materialTableIcons from "../support/materialTableIcons";
import materialTableLocalisationIT from "../support/materialTableLocalisationIT";
import { ToggleButtonGroup, ToggleButton } from '@mui/material';
// import { withSnackbar } from "notistack";
// import ProductBarcode from "../components/ProductBarcode";
import SearchBar from "../components/SearchBar";
import MiniSearch from "minisearch";
import stringIsType from "../functions/stringIsType";
import Feedback from "../components/Feedback";
import TableProductCod from "../components/TableProductCod";

import handyScroll from "handy-scroll";
import "handy-scroll/dist/handy-scroll.css";
import TableSorting from "../components/TableSorting";
import {enqueueSnackbar} from "notistack";
//import renderHTML from "react-render-html";

class RilevazioneDimensioni extends React.Component {
  static contextType = Context;
  constructor(props) {
    super(props);
    this.getData = this.getData.bind(this);
    this.handleDialogClose = this.handleDialogClose.bind(this);
    this.handleDialogOpen = this.handleDialogOpen.bind(this);
    this.setNewData = this.setNewData.bind(this);
    this.canSend = this.canSend.bind(this);
    this.updateDialogData = this.updateDialogData.bind(this);
    this.state = {
      a: 0,
      l: 0,
      p: 0,
      peso: 0,
      cod_barre: "",
      cod_ricerca_extra: "",
      done: true,
      rilevatore: "",
      dialogOpen: false,
      sendingAJAX: false,
    };
  }

  getData(callback) {
    axios
      .get(
        `${Constants.paths.ajaxBasePath}inventario/rilevazione/dati/?token=${this.context.user.token}&prod_ids=${this.props.id_prod}`
      )
      .then((res) => {
        let resp = res.data[0];
        this.setState(
          {
            a: resp?.a || 0,
            l: resp?.l || 0,
            p: resp?.p || 0,
            peso: resp?.peso || 0,
            cod_barre: resp?.cod_barre || "",
            cod_ricerca_extra: resp?.cod_ricerca_extra || "",
            rilevatore: resp?.rilevatore || "",
          },
          () => {
            callback && callback(resp);
          }
        );
      });
  }

  sendData() {
    console.log(this.state);
    const toPost = JSON.stringify({
      token: this.context.user.token,
      id_prod: this.props.id_prod,
      a: parseFloat(this.state.new_a.toString().replace(",", ".")),
      l: parseFloat(this.state.new_l.toString().replace(",", ".")),
      p: parseFloat(this.state.new_p.toString().replace(",", ".")),
      peso: this.state.new_peso,
      cod_barre: this.state.new_cod_barre_nd ? "" : this.state.new_cod_barre,
      // cod_ricerca_extra: this.state.new_cod_ricerca_extra_nd
      //   ? ""
      //   : this.state.new_cod_ricerca_extra,
    });

    axios
      .post(
        Constants.paths.ajaxBasePath + "inventario/rilevazione/dati/",
        toPost
      )
      .then((resp) => {
        enqueueSnackbar("Dati inviati!", {
          variant: "success",
          preventDuplicate: true,
        });
        this.getData();
        this.setState({ dialogOpen: false });
      })
      .catch((err) => {
        alert("Errore:" + err);
      })
      .finally(() =>
        this.setState({
          sendingAJAX: false,
        })
      );
  }

  updateDialogData(callback) {
    this.setState(
      {
        new_a: this.state.a != 0 ? this.state.a : "",
        new_l: this.state.l != 0 ? this.state.l : "",
        new_p: this.state.p != 0 ? this.state.p : "",
        new_peso: this.state.peso != 0 ? this.state.peso : "",
        new_cod_barre: this.state.cod_barre,
        //new_cod_ricerca_extra: this.state.cod_ricerca_extra,
      },
      () => callback && callback()
    );
  }

  componentDidMount() {
    this.getData();
    this.updateTimer = setInterval(
      () => this.getData(),
      Constants.heartbeat.main
    );
  }

  componentWillUnmount() {
    clearInterval(this.updateTimer);
  }

  handleDialogOpen() {
    this.updateDialogData(() => {
      this.setState({
        dialogOpen: true,
      });
    });
  }

  handleDialogClose() {
    this.setState({
      dialogOpen: false,
    });
  }

  setNewData(prop, val, callback = false) {
    this.setState(
      (prev) => ({
        ...prev,
        [`new_${prop}`]: val,
      }),
      () => callback && callback()
    );
  }

  canSend() {
    let required = [
      "new_a",
      "new_l",
      "new_p",
      "new_peso",
      "new_cod_barre",
      //"new_cod_ricerca_extra",
    ];
    if (this.state.new_cod_barre_nd)
      required = required.filter((val) => val != "new_cod_barre");

    // if (this.state.new_cod_ricerca_extra_nd)
    //   required = required.filter((val) => val != "new_cod_ricerca_extra");

    let toReturn = true;

    required.forEach((val) => {
      if (!this.state[val] || this.state[val] == 0 || this.state[val] == "")
        toReturn = false;
    });
    return toReturn;
  }

  // shouldComponentUpdate(nextProps, nextState) {
  //   if (this.state.done !== nextState.done) return true;
  //   else return false;
  // }

  render() {
    // const {enqueueSnackbar, closeSnackbar} = this.props;
    return (
      <React.Fragment>
        <Card variant="outlined">
          <Button
            style={{
              width: "100%",
              minWidth: this.context.theme.spacing(25),
              textTransform: "none",
            }}
            onClick={this.handleDialogOpen}
          >
            <Grid container>
              <Grid container item spacing={1} justifyContent="space-evenly">
                <Grid item>
                  <Typography variant="caption">
                    <b>{this.state.a?.toString().replace(".", ",")}</b> cm
                  </Typography>
                  <br />
                  <Typography variant="caption">Alt.</Typography>
                </Grid>
                <Divider orientation="vertical" />
                <Grid item>
                  <Typography variant="caption">
                    <b>{this.state.l?.toString().replace(".", ",")}</b> cm
                  </Typography>
                  <br />
                  <Typography variant="caption">Larg.</Typography>
                </Grid>
                <Divider orientation="vertical" />
                <Grid item>
                  <Typography variant="caption">
                    <b>{this.state.p?.toString().replace(".", ",")}</b> cm
                  </Typography>
                  <br />
                  <Typography variant="caption">Prof.</Typography>
                </Grid>
              </Grid>
              <Divider
                style={{
                  width: "100%",
                  marginTop: this.context.theme.spacing(0.5),
                  marginBottom: this.context.theme.spacing(0.5),
                }}
              />
              <Grid item style={{ textAlign: "center", width: "100%" }}>
                <Typography variant="caption" style={{ float: "left" }}>
                  Peso:
                </Typography>
                <Typography variant="caption" style={{ float: "right" }}>
                  <b>{this.state.peso}</b> g
                </Typography>
              </Grid>
              <Divider
                style={{
                  width: "100%",
                  marginTop: this.context.theme.spacing(0.5),
                  marginBottom: this.context.theme.spacing(0.5),
                }}
              />
              <Grid item xs={12}>
                <Typography variant="caption" style={{ float: "left" }}>
                  Codbar
                </Typography>
                <Typography variant="caption" style={{ float: "right" }}>
                  {this.state.cod_barre || "N/D"}
                </Typography>
              </Grid>
              {/* <Grid item xs={12}>
                <Typography variant="caption" style={{ float: "left" }}>
                  Codbar+
                </Typography>{" "}
                <Typography variant="caption" style={{ float: "right" }}>
                  {this.state.cod_ricerca_extra || "N/D"}
                </Typography>
              </Grid> */}
              <Divider
                style={{
                  width: "100%",
                  marginTop: this.context.theme.spacing(0.5),
                  marginBottom: this.context.theme.spacing(0.5),
                }}
              />
              {this.state.rilevatore ? (
                <Typography
                  style={{
                    fontSize: "80%",
                    marginTop: 0,
                    width: "100%",
                    color: this.context.theme.palette.success.main,
                  }}
                  variant="caption"
                >
                  Censito da: <b>{this.state.rilevatore}</b>
                </Typography>
              ) : (
                <Typography
                  style={{
                    fontSize: "80%",
                    marginTop: 0,
                    color: this.context.theme.palette.warning.dark,
                    width: "100%",
                  }}
                  variant="caption"
                >
                  <b>Da censire</b>
                </Typography>
              )}{" "}
            </Grid>
          </Button>
        </Card>
        <Dialog
          scroll="body"
          fullWidth={true}
          fullScreen={!this.context.matches.sm}
          maxWidth="sm"
          open={this.state.dialogOpen}
          TransitionComponent={SlideUpTransition}
          onClose={this.handleDialogClose}
        >
          <DialogTitle>Censisci prodotto</DialogTitle>
          <DialogContent
            style={{
              alignSelf: "center",
              overflow: "hidden",
              padding: this.context.theme.spacing(3),
            }}
          >
            <Grid container spacing={3}>
              <Grid item xs={12} md={4} style={{ textAlign: "center" }}>
                <ProductImage
                  style={{ textAlign: "center" }}
                  containerSize={"100%"}
                  cod={this.props.cod}
                  // img={this.props.img}
                />
              </Grid>
              <Grid container item xs={12} md={8}>
                <Grid
                  container
                  item
                  xs={12}
                  spacing={0}
                  justifyContent="space-between"
                  alignItems="baseline"
                >
                  <Grid item>
                    <Typography variant="">{this.props.uf_cod}</Typography>
                  </Grid>
                  <Grid item>
                    <Typography style={{ fontWeight: "bold" }}>
                      {this.props.cod}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Chip
                      // onClick={() =>
                      //   this.search(this.props.this_cod_famiglia)
                      // }
                      avatar={<Avatar>{this.props.num_varianti}</Avatar>}
                      size="small"
                      label={`${this.props.this_cod_famiglia}`}
                      style={{ marginBottom: this.context.theme.spacing(0.3) }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <br />
                    <Divider />
                    <br />
                  </Grid>
                </Grid>
                <div
                  style={{
                    height: !this.context.matches.sm ? "7em" : "8em",
                    overflowY: "auto",
                  }}
                >
                  {/*<Typography>*/}
                  {/*  {renderHTML(*/}
                  {/*    this.props.descrizione_estesa || this.props.descrizione*/}
                  {/*  )}*/}
                  {/*</Typography>*/}
                </div>
              </Grid>
            </Grid>
            <div style={{ height: this.context.theme.spacing(3) }}></div>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <TextField
                  label="Alt."
                  variant="outlined"
                  InputProps={{
                    type: "numeric",
                    endAdornment: (
                      <InputAdornment position="end">cm</InputAdornment>
                    ),
                  }}
                  value={this.state.new_a?.toString().replace(".", ",") || ""}
                  onChange={(e) => this.setNewData("a", e.target.value)}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Larg."
                  variant="outlined"
                  InputProps={{
                    type: "numeric",
                    endAdornment: (
                      <InputAdornment position="end">cm</InputAdornment>
                    ),
                  }}
                  value={this.state.new_l?.toString().replace(".", ",") || ""}
                  onChange={(e) => this.setNewData("l", e.target.value)}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Prof."
                  variant="outlined"
                  InputProps={{
                    type: "numeric",
                    endAdornment: (
                      <InputAdornment position="end">cm</InputAdornment>
                    ),
                  }}
                  value={this.state.new_p?.toString().replace(".", ",") || ""}
                  onChange={(e) => this.setNewData("p", e.target.value)}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Peso"
                  variant="outlined"
                  InputProps={{
                    type: "numeric",
                    endAdornment: (
                      <InputAdornment position="end">g</InputAdornment>
                    ),
                  }}
                  value={this.state.new_peso || ""}
                  onChange={(e) => this.setNewData("peso", e.target.value)}
                />
              </Grid>
              <Grid item xs={8}>
                <TextField
                  style={{ width: "100%" }}
                  label="Cod barre"
                  variant="outlined"
                  InputProps={{
                    type: "numeric",
                    endAdornment: (
                      <InputAdornment position="end">
                        <FontAwesomeIcon icon={faBarcode}></FontAwesomeIcon>
                      </InputAdornment>
                    ),
                  }}
                  value={this.state.new_cod_barre || ""}
                  onChange={(e) => this.setNewData("cod_barre", e.target.value)}
                  disabled={this.state.new_cod_barre_nd}
                />
              </Grid>
              <Grid item xs={4} style={{ alignSelf: "center" }}>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={this.state.new_cod_barre_nd}
                        onChange={(e) => {
                          this.setState({
                            new_cod_barre_nd: e.target.checked,
                          });
                        }}
                      />
                    }
                    label="Codice assente"
                  />
                </FormGroup>
              </Grid>
            </Grid>
          </DialogContent>
          <Grid container justifyContent="space-between">
            <Grid item>
              <DialogActions>
                <Feedback
                  info={`(${this.props.id_prod}) ${this.props.cod} => ${this.props.descrizione_estesa}`}
                />
              </DialogActions>
            </Grid>
            <Grid item>
              <DialogActions>
                <Button onClick={this.handleDialogClose}>Annulla</Button>
                <Button
                  disabled={this.state.sendingAJAX || !this.canSend()}
                  onClick={() =>
                    this.setState({ sendingAJAX: true }, () => this.sendData())
                  }
                  variant="contained"
                  color="primary"
                >
                  Conferma
                </Button>
              </DialogActions>
            </Grid>
          </Grid>
        </Dialog>
      </React.Fragment>
    );
  }
}

class StatusRilevazioni extends React.Component {
  static contextType = Context;
  constructor(props) {
    super(props);
    this.state = {
      // statoFeroleto: 0,
      // statoRende: 0,
    };
    //this.getStatus = this.getStatus.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  // getStatus(callback) {
  //   axios
  //     .get(
  //       `${Constants.paths.ajaxBasePath}inventario/rilevazione/stato/?token=${this.context.user.token}&prod_ids=${this.props.id_prod}`
  //     )
  //     .then((res) => {
  //       let resp = res.data;
  //       this.setState(
  //         {
  //           statoFeroleto: parseInt(
  //             resp.find((e) => e.id_sede == 1)?.stato || 0
  //           ),
  //           statoRende: parseInt(resp.find((e) => e.id_sede == 2)?.stato || 0),
  //         },
  //         () => {
  //           callback && callback();
  //         }
  //       );
  //     });
  // }

  handleChange(sede, to) {
    if (!to) to = 0;
    const toPost = JSON.stringify({
      token: this.context.user.token,
      id_prod: this.props.id_prod,
      id_sede: sede == "feroleto" ? 1 : 2,
      status: to,
    });

    axios
      .post(
        Constants.paths.ajaxBasePath + "inventario/rilevazione/stato/",
        toPost
      )
      .then((resp) => {
       enqueueSnackbar("Avanzamento variato!", {
          variant: "success",
          preventDuplicate: true,
        });
        this.props.setNewState(this.props.id_prod, sede, to);
      })
      .catch((err) => {
        alert("Errore:" + err);
      });
  }

  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    // const {enqueueSnackbar, closeSnackbar} = this.props;
    return (
      <React.Fragment>
        <Grid container spacing={1} style={{ justifyContent: "center" }}>
          <Grid item>
            <ToggleButtonGroup
              orientation="vertical"
              value={this.props.statoFeroleto}
              exclusive
              onChange={(e, newVal) => this.handleChange("feroleto", newVal)}
            >
              <ToggleButton value={2}>
                <FontAwesomeIcon
                  style={{
                    color:
                      this.props.statoFeroleto == 2
                        ? this.context.theme.palette.success.main
                        : "inherit",
                  }}
                  icon={faCheck}
                />
              </ToggleButton>
              <ToggleButton value={1}>
                <FontAwesomeIcon
                  style={{
                    color:
                      this.props.statoFeroleto == 1
                        ? this.context.theme.palette.warning.main
                        : "inherit",
                  }}
                  icon={faHourglassHalf}
                />
              </ToggleButton>
              <ToggleButton value={0}>
                <FontAwesomeIcon
                  style={{
                    color:
                      this.props.statoFeroleto == 0
                        ? this.context.theme.palette.error.main
                        : "inherit",
                  }}
                  icon={faTimes}
                />
              </ToggleButton>
            </ToggleButtonGroup>
            <br />
            <Typography variant="caption">Feroleto</Typography>
          </Grid>
          <Grid item>
            <ToggleButtonGroup
              orientation="vertical"
              value={this.props.statoRende}
              exclusive
              onChange={(e, newVal) => this.handleChange("rende", newVal)}
            >
              <ToggleButton value={2}>
                <FontAwesomeIcon
                  style={{
                    color:
                      this.props.statoRende == 2
                        ? this.context.theme.palette.success.main
                        : "inherit",
                  }}
                  icon={faCheck}
                />
              </ToggleButton>
              <ToggleButton value={1}>
                <FontAwesomeIcon
                  style={{
                    color:
                      this.props.statoRende == 1
                        ? this.context.theme.palette.warning.main
                        : "inherit",
                  }}
                  icon={faHourglassHalf}
                />
              </ToggleButton>
              <ToggleButton value={0}>
                <FontAwesomeIcon
                  style={{
                    color:
                      this.props.statoRende == 0
                        ? this.context.theme.palette.error.main
                        : "inherit",
                  }}
                  icon={faTimes}
                />
              </ToggleButton>
            </ToggleButtonGroup>
            <br />
            <Typography variant="caption">Rende</Typography>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

class EditStock extends React.Component {
  static contextType = Context;
  constructor(props) {
    super(props);
    this.closeDialog = this.closeDialog.bind(this);
    this.openDialog = this.openDialog.bind(this);
    this.setAction = this.setAction.bind(this);
    this.putStock = this.putStock.bind(this);
    this.state = {
      dialogOpen: false,
      action: "c",
      stockVariation: 0,
      fetching: false,
    };
  }

  putStock(productId, sede, action, variation) {
    console.log("Updating stock:", arguments);
    this.setState({ fetching: true });
    const toPost = JSON.stringify({
      token: this.context.user.token,
      id_prod: productId,
      action: action == "c" ? "addstock" : "removestock",
      qty: variation,
      sede: sede,
    });
    axios
      .post(Constants.paths.ajaxBasePath + "inventario/", toPost)
      .then(() => {
       enqueueSnackbar("Magazzino aggiornato", {
          variant: "success",
        });
        this.props.updateSingleFn(
          productId,
          action == "c"
            ? parseInt(this.props.input_props[`stock_${sede}`]) +
                parseInt(variation)
            : parseInt(this.props.input_props[`stock_${sede}`]) -
                parseInt(variation),
          sede
        );
        this.closeDialog();
      })
      .catch((e) => {
        enqueueSnackbar("Errore caricamento", {
          variant: "error",
        });
        this.setState({ fetching: false });
        console.error(e);
      });
  }

  closeDialog() {
    this.setState({
      action: "c",
      stockVariation: 0,
      dialogOpen: false,
    });
  }

  openDialog() {
    this.setState({ dialogOpen: true, fetching: false });
  }
  setAction(e, newVal) {
    if (newVal) {
      this.setState({ action: newVal });
    }
  }

  render() {
    return (
      <React.Fragment>
        <div
          style={{
            ...this.props.style,
            //marginTop: this.context.theme.spacing(2),
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            variant="outlined"
            style={{
              pointerEvents: "none",
              borderTopRightRadius: "0",
              borderBottomRightRadius: "0",
              borderRight: "0",
              width: "100%",
              textTransform: "none",
            }}
          >
            {this.props.helperText}
          </Button>
          <Button
            style={{
              borderLeft: "1px solid",
              borderTopLeftRadius: "0",
              borderBottomLeftRadius: "0",
              borderColor: this.context.theme.palette.grey["400"],
            }}
            onClick={this.openDialog}
            variant="contained"
            disableElevation
          >
            {this.props.input_props[`stock_${this.props.sede}`]}
          </Button>
        </div>
        <Dialog
          fullScreen={!this.context.matches.sm}
          scroll="body"
          fullWidth={true}
          maxWidth="sm"
          open={this.state.dialogOpen}
          TransitionComponent={SlideUpTransition}
          onClose={this.closeDialog}
        >
          <DialogTitle>
            Varia {this.props.sede == "rende" ? "Rende" : "Feroleto"}
          </DialogTitle>
          <DialogContent
            style={{
              alignSelf: "center",
              overflow: "hidden",
              padding: this.context.theme.spacing(3),
            }}
          >
            <Grid container spacing={3} alignItems="center">
              <Grid
                item
                xs={5}
                style={{
                  textAlign: "right",
                  fontSize: "3rem",
                }}
              >
                <FontAwesomeIcon icon={faWarehouse} />
              </Grid>
              <Grid
                item
                xs={2}
                style={{
                  textAlign: "center",
                  fontSize: "3rem",
                }}
              >
                <FontAwesomeIcon
                  icon={faArrowRight}
                  style={{
                    transition: "all ease 0.4s",
                    transform: `rotate(${
                      this.state.action == "c" ? 180 : 0
                    }deg)`,
                    color:
                      this.state.action == "c"
                        ? this.context.theme.palette.success.main
                        : this.context.theme.palette.error.main,
                  }}
                  // transform={{ rotate: this.state.action == "c" ? 90 : 0 }}
                />
              </Grid>
              <Grid
                item
                xs={5}
                style={{
                  textAlign: "left",
                }}
              >
                <Typography style={{ fontSize: "3rem" }}>
                  <strong>
                    {this.state.action == "c" ? "+" : "-"}
                    {this.state.stockVariation}
                  </strong>
                  <small
                    style={{
                      fontSize: "60%",
                      color: this.context.theme.palette.text.disabled,
                    }}
                  >
                    pz
                  </small>
                </Typography>
              </Grid>
            </Grid>
            <br />
            <Divider />
            <br />
            <div style={{ height: this.context.theme.spacing(0.5) }}></div>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4} style={{ textAlign: "center" }}>
                <ProductImage
                  style={{ textAlign: "center" }}
                  containerSize={"100%"}
                  cod={this.props.input_props.cod}
                  // img={this.props.input_props.img}
                />
              </Grid>
              <Grid container item xs={12} md={8}>
                <Grid
                  container
                  item
                  xs={12}
                  spacing={0}
                  justifyContent="space-between"
                  alignItems="baseline"
                >
                  <Grid item>
                    <Typography variant="">
                      {this.props.input_props.uf_cod}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography style={{ fontWeight: "bold" }}>
                      {this.props.input_props.cod}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Chip
                      onClick={() =>
                        this.search(this.props.input_props.this_cod_famiglia)
                      }
                      avatar={
                        <Avatar>{this.props.input_props.num_varianti}</Avatar>
                      }
                      size="small"
                      label={`${this.props.input_props.this_cod_famiglia}`}
                      style={{ marginBottom: this.context.theme.spacing(0.3) }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <br />
                    <Divider />
                    <br />
                  </Grid>
                </Grid>
                <div
                  style={{
                    height: !this.context.matches.sm ? "7em" : "8em",
                    overflowY: "auto",
                  }}
                >
                  <Typography>
                    {this.props.input_props.descrizione_estesa ||
                      this.props.input_props.descrizione}
                  </Typography>
                </div>
              </Grid>
            </Grid>
            <div style={{ height: this.context.theme.spacing(3) }}></div>
            <Grid container spacing={3} justifyContent="center">
              <Grid item xs={12} sm={6}>
                <ToggleButtonGroup
                  value={this.state.action}
                  exclusive
                  onChange={this.setAction}
                  style={{
                    width: "100%",
                    height: "100%",
                    minHeight: this.context.theme.spacing(5),
                  }}
                >
                  <ToggleButton
                    style={{ width: "100%", height: "auto" }}
                    value="c"
                  >
                    <Box color={this.context.theme.palette.success.main}>
                      Carica
                    </Box>
                  </ToggleButton>
                  <ToggleButton
                    style={{ width: "100%", height: "auto" }}
                    value="s"
                  >
                    <Box color={this.context.theme.palette.error.main}>
                      Scarica
                    </Box>
                  </ToggleButton>
                </ToggleButtonGroup>
              </Grid>
              <Grid item xs={12} sm={6}>
                <ButtonGroup //! THROWS ERROR (Red Warning...) BECAUSE OF THE TEXTFIELD COMPONENT!!
                  style={{
                    // marginRight: consumer_data.theme.spacing(2)
                    display: "flex",
                  }}
                  variant="outlined"
                >
                  <Button
                    style={{ minWidth: "33.333%" }}
                    onClick={(e) =>
                      this.setState((prev) => ({
                        stockVariation:
                          prev.stockVariation > 0 ? --prev.stockVariation : 0,
                      }))
                    }
                  >
                    <FontAwesomeIcon size="sm" icon={faMinus} />
                  </Button>
                  <TextField
                    //label="Quantità"
                    placeholder="Quantità"
                    style={{
                      minWidth: "33.333%",
                      // maxWidth: "5rem",
                      borderRadius: 0,
                    }}
                    className="input-align-center input-no-border-radius"
                    value={this.state.stockVariation}
                    onChange={(e) => {
                      let newQty = parseInt(e.target.value);
                      this.setState((prev) => ({
                        stockVariation: newQty > 0 ? newQty : 0,
                      }));
                    }}
                  />
                  <Button
                    style={{ minWidth: "33.333%" }}
                    onClick={(e) =>
                      this.setState((prev) => ({
                        stockVariation: ++prev.stockVariation,
                      }))
                    }
                  >
                    <FontAwesomeIcon size="sm" icon={faPlus} />
                  </Button>
                </ButtonGroup>
                {/* {this.props.input_props.uv > 1 && ( //Serviva per la vecchia gestione del magazzino
                  <Typography
                    style={{ textAlign: "left" }}
                    align="left"
                    className="MuiFormHelperText-contained MuiFormHelperText-root"
                    // style={{
                    //   marginTop: consumer_data.theme.spacing(0.5)
                    // }}
                  >
                    {this.state.stockVariation}pz. ={" "}
                    {this.state.stockVariation / this.props.input_props.uv} cf.
                  </Typography>
                )} */}
              </Grid>
            </Grid>
            <br />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeDialog}>Annulla</Button>
            <Button
              disabled={this.state.fetching}
              onClick={() =>
                this.setState({ fetching: true }, () =>
                  this.putStock(
                    this.props.input_props.id,
                    this.props.sede,
                    this.state.action,
                    this.state.stockVariation
                  )
                )
              }
              variant="contained"
              color="primary"
            >
              Conferma
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

class Inventario extends React.Component {
  static contextType = Context;
  constructor(props) {
    super(props);
    this.getData = this.getData.bind(this);
    this.getProgressData = this.getProgressData.bind(this);
    this.updateSingle = this.updateSingle.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.search = this.search.bind(this);
    this.directSetProgressData = this.directSetProgressData.bind(this);
    this.tableSort = this.tableSort.bind(this);
    this.getTableHorizontalScrollContainer =
      this.getTableHorizontalScrollContainer.bind(this);
    // this.handleFeedbackRequest = this.handleFeedbackRequest.bind(this);
    // this.handleFeedbackClose = this.handleFeedbackClose.bind(this);
    this.state = {
      data: [],
      results: [],
      fetching: true,
      searchValue: "",
      tableSorting: "rilevanza",
      tableSortingOrder: "down",
      searchScores: {},
      progressStatus: {}, //{id:2} 0=Stop,1=In esecuzione,2=Terminato
    };
  }

  getProgressData(callback) {
    axios
      .get(
        `${Constants.paths.ajaxBasePath}inventario/rilevazione/stato/?token=${this.context.user.token}`
      )
      .then((res) => {
        let resp = res.data;
        let newData = {};
        resp.forEach((val, i) => {
          if (!newData[val["id_prod"]])
            newData[val["id_prod"]] = {
              feroleto: 0,
              rende: 0,
            };
          newData[val["id_prod"]][val["id_sede"] == 1 ? "feroleto" : "rende"] =
            parseInt(val["stato"] || 0);
        });
        // console.log(resp);
        this.setState({ progressStatus: newData }, () => {
          callback && callback();
        });
      });
  }

  directSetProgressData(id, sede, stato) {
    this.setState((prev) => ({
      progressStatus: {
        ...prev.progressStatus,
        [id]: {
          ...prev.progressStatus[id],
          [sede]: parseInt(stato),
        },
      },
    }));
  }

  getTableHorizontalScrollContainer() {
    let toReturn;
    // try {
    //   toReturn = document.querySelectorAll(
    //     "[class^=Component-horizontalScrollContainer-]" //TODO: A quanto pare la classe varia nella prod, aggiungi eccezione
    //   )[0].firstChild.firstChild;
    // } catch (error) {
    try {
      toReturn =
        document.querySelectorAll(".table.inventario")[0].firstChild.children[1]
          .firstChild.firstChild;
    } catch (error) {
      toReturn = false;
      console.warn(error);
    }
    // }
    return toReturn;
  }

  updateSingle(id, newVal, sede) {
    let data = this.state.data;
    for (let i = 0; i < data.length; i++) {
      let e = data[i];
      if (e.id == id) {
        data[i][`stock_${sede}`] = newVal;
        data[i]["stock_totale"] =
          parseInt(data[i][`stock_rende`]) +
          parseInt(data[i][`stock_feroleto`]);
        break;
      }
    }
    this.setState({ data: data });
  }

  componentDidMount() {
    this.getData();
    this.getProgressData();
    let name;
    if ((name = this.getTableHorizontalScrollContainer()))
      handyScroll.mount(name);
    this.resizeTimer = setInterval(() => {
      window.dispatchEvent(new Event("resize")); //Chiama questo evento per far funzionare la floating scrollbar, il motivo di questa 'hack' è il fatto che il componente della tabella sia svilkuppata male
    }, Constants.heartbeat.fastest);

    this.updateTimer = setInterval(
      this.getProgressData,
      Constants.heartbeat.fast
    );
  }

  componentWillUnmount() {
    clearInterval(this.updateTimer);
    clearInterval(this.resizeTimer);
  }

  getData() {
    this.setState({ fetching: true });
    let finalData = [];
    axios
      .get(
        Constants.paths.ajaxBasePath +
          "inventario/?token=" +
          this.context.user.token
      )
      .then((res) => {
        finalData = res.data.map((d) => ({
          ...d,
          stock_rende: parseInt(d.stock_rende),
          stock_feroleto: parseInt(d.stock_feroleto),
          this_variation: 0,
          id: parseInt(d.id),
        }));
        this.setState(
          {
            data: finalData,
            fetching: false,
          },
          () => {
            this.initMinisearch(finalData);
          }
        );
      });
  }

  initMinisearch(finalData) {
    const defaultSearchOptions = {
      fuzzy: true,
      combineWith: "AND",
      prefix: true,
    };
    const searchFields = [
      "cod",
      "uf_cod",
      "x_code",
      "descrizione_estesa",
      "this_cod_famiglia",
      "cod_barre",
      "cod_ricerca_extra",
    ];
    if (finalData.length > 0) {
      this.minisearch = new MiniSearch({
        fields: searchFields,
        storeFields: ["id"],
        searchOptions: {
          ...defaultSearchOptions,
          boost: {
            cod: 10,
            uf_cod: 9,
            x_code: 8,
            this_cod_famiglia: 8,
            cod_barre: 8,
            cod_ricerca_extra: 6,
            descrizione_estesa: 3,
          },
        },
      });
      // finalData = finalData.map((d) => { //Serviva a convertire gli array in stringhe
      //   for (var key in d) {
      //     // check if the property/key is defined in the object itself, not in parent
      //     if (
      //       d[key] &&
      //       searchFields.includes(key) &&
      //       typeof d[key] == "object"
      //     ) {
      //       d[key] = d[key].join(",");
      //     }
      //   }
      //   return d;
      // });
      this.minisearch.addAllAsync(finalData).then(() => {
        this.setState({ fetching: false }, this.search);
      });
    } else {
      this.setState({ fetching: false });
    }
  }

  search(query) {
    this.setState({ fetching: true });
    let promise = new Promise((res, rej) => {
      const minChars = 3;
      const thisSearch = typeof query == "undefined" ? "" : query;
      const allData = this.state.data;
      let results = [];
      if (thisSearch == "" || thisSearch.length < minChars) {
        results = allData;
      } else {
        if (stringIsType(query, "codFamiglia")) {
          //Sii preciso con il cod famiglia
          results = allData.filter((d) => d.this_cod_famiglia === query);
        } else if (stringIsType(query, "codPrimario")) {
          //Sii preciso con il cod primario
          results = allData.filter((d) => d.cod === query);
        } else if (stringIsType(query, "barcode")) {
          //Sii preciso con il cod barre
          results = allData.filter((d) => {
            let codes = [d.cod_barre];
            let cod_ricerca_extra_array;
            if (d.cod_ricerca_extra) {
              if (typeof d.cod_ricerca_extra == "string") {
                cod_ricerca_extra_array = d.cod_ricerca_extra.split(";");
              }
              cod_ricerca_extra_array.map((c) => codes.push(c));
            }
            return codes.includes(query);
          });
        } else {
          results = this.minisearch.search(thisSearch); //Cerca con fuzzy
        }
      }
      //Store ids only
      // results = results.map((d) => d.id);
      this.setState((prev) => {
        let searchScores = {};
        results.forEach((d) => {
          searchScores[d.id] = d.score;
        });
        return {
          searchScores: searchScores,
          results: results.map((d) => d.id),
          fetching: false,
        };
      }, res);
    });
  }

  onSearchChange(e) {
    if (!this.context.device.lowSpec) this.search(e.target.value);
  }

  tableSort(a, b) {
    switch (this.state.tableSorting) {
      case "descrizione":
        return 0; //Già ordinato dalla query
      case "avanzamento":
        return (
          this.state.progressStatus[b.id]?.["rende"] ||
          0 + this.state.progressStatus[b.id]?.["feroleto"] ||
          0 -
            (this.state.progressStatus[a.id]?.["rende"] ||
              0 + this.state.progressStatus[a.id]?.["feroleto"] ||
              0)
        );
      case "qta":
        return (
          b.stock_rende + b.stock_feroleto - (a.stock_rende + a.stock_feroleto)
        );
      case "qta_feroleto":
        return b.stock_rende - a.stock_feroleto;
      case "qta_rende":
        return b.stock_rende - a.stock_rende;
      case "rilevanza":
      default:
        return this.state.searchScores[b.id] - this.state.searchScores[a.id];
    }
  }

  render() {
    // const {enqueueSnackbar, closeSnackbar} = this.props;
    return (
      <Context.Consumer>
        {(consumer_data) => (
          <React.Fragment>
            <ProfileCard />
            <SearchBar
              forcedQuery={this.state.forcedSearchValue}
              placeholder={null}
              onChange={this.onSearchChange}
              onBlur={
                this.context.device.lowSpec
                  ? (e) => this.search(e.target.value)
                  : null
              }
              onClear={(e) =>
                this.setState(
                  {
                    forcedSearchValue: null,
                  },
                  () => {
                    this.search();
                  }
                )
              }
            />
            <TableSorting
              order
              orderValue={this.state.tableSortingOrder}
              onOrderChange={(e, val) =>
                this.setState({ tableSortingOrder: val })
              }
              value={this.state.tableSorting}
              onChange={(e, val) => this.setState({ tableSorting: val })}
              options={[
                {
                  value: "rilevanza",
                  desc: "Rilevanza",
                  short: "Ril.",
                },
                {
                  value: "descrizione",
                  desc: "Descrizione",
                  short: "Desc.",
                },
                {
                  value: "avanzamento",
                  desc: "Avanzamento",
                  short: "Avnz.",
                },
                {
                  value: "qta",
                  desc: "Qtà Totale",
                  short: "Qtà",
                },
                {
                  value: "qta_feroleto",
                  desc: "Qtà Feroleto",
                  short: "Qtà F",
                },
                {
                  value: "qta_rende",
                  desc: "Qtà Rende",
                  short: "Qtà R",
                },
              ]}
            />
            <div className="table inventario">
              <MaterialTable
                data={
                  this.state.tableSortingOrder == "down"
                    ? this.state.data
                        .filter((d) => this.state.results.includes(d.id))
                        .sort((a, b) => this.tableSort(a, b))
                    : this.state.data
                        .filter((d) => this.state.results.includes(d.id))
                        .sort((a, b) => this.tableSort(a, b))
                        .reverse()
                }
                title="Inventario"
                icons={materialTableIcons}
                localization={materialTableLocalisationIT}
                isLoading={this.state.fetching}
                options={{
                  tableLayout: "auto",
                  pageSize: 15,
                  pageSizeOptions: [15, 20, 30, 40, 50],
                  actionsColumnIndex: 100,
                  filtering: false,
                  search: false,
                  sorting: false,
                }}
                columns={[
                  {
                    title: "Avanzamento",
                    field: "",
                    editable: "never",
                    cellStyle: {
                      maxWidth: "10em",
                      minWidth: "10em",
                      textAlign: "center",
                    },
                    headerStyle: {
                      textAlign: "center",
                    },
                    // customSort: (a, b) =>
                    //   parseInt(this.state.progressStatus[a.id]?.rende || 0) +
                    //   parseInt(
                    //     this.state.progressStatus[a.id]?.feroleto || 0
                    //   ) -
                    //   (parseInt(this.state.progressStatus[b.id]?.rende || 0) +
                    //     parseInt(
                    //       this.state.progressStatus[b.id]?.feroleto || 0
                    //     )),
                    // cellStyle: { display: 'block',maxWidth: "150px", width: "150px" },
                    // headerStyle: { display: 'block',maxWidth: "150px", width: "150px" },
                    render: (row_data) => (
                      <StatusRilevazioni
                        key={row_data.id}
                        id_prod={row_data.id}
                        statoFeroleto={
                          this.state.progressStatus[row_data.id]?.feroleto || 0
                        }
                        statoRende={
                          this.state.progressStatus[row_data.id]?.rende || 0
                        }
                        // enqueueSnackbar={this.props.enqueueSnackbar}
                        setNewState={this.directSetProgressData}
                      />
                    ),
                  },
                  {
                    title: "Img",
                    field: "img",
                    editable: "never",
                    cellStyle: {
                      maxWidth: "7em",
                      minWidth: "7em",
                      textAlign: "center",
                    },
                    headerStyle: {
                      textAlign: "center",
                    },
                    // cellStyle: { display: 'block',maxWidth: "150px", width: "150px" },
                    // headerStyle: { display: 'block',maxWidth: "150px", width: "150px" },
                    render: (row_data) => (
                      <ProductImage
                        containerSize={consumer_data.theme.spacing(10)}
                        cod={row_data.cod}
                        size={100}
                        // img={row_data.img}
                      />
                    ),
                  },
                  {
                    title: "COD",
                    field: "cod",
                    editable: "never",
                    cellStyle: {
                      maxWidth: "10em",
                      minWidth: "10em",
                    },
                    headerStyle: {
                      textAlign: "left",
                    },
                    // cellStyle: {display:'block',maxWidth: "10px", width: "10px" },
                    // headerStyle: {display:'block',maxWidth: "10px", width: "10px" },
                    render: (row_data) => (
                      <TableProductCod
                        allowCustomisation={true}
                        productObj={row_data}
                        onFamilyClick={(famCode) => {
                          this.setState({
                            forcedSearchValue: famCode,
                          });
                          this.search(famCode);
                        }}
                      />
                    ),
                  },
                  {
                    title: "Descrizione",
                    field: "descrizione_estesa",
                    render: (row_data) =>
                      row_data["descrizione_estesa"].replace(
                        /(<([^>]+)>)/gi,
                        ""
                      ),
                    editable: "never",
                    cellStyle: {
                      // verticalAlign:'baseline',
                      width: "100%",
                      minWidth: !consumer_data.matches.sm
                        ? "16em" //XS
                        : !consumer_data.matches.md
                        ? "18em" //SM
                        : !consumer_data.matches.lg
                        ? "28rem" //MD
                        : !consumer_data.matches.xl
                        ? "38rem" //LG
                        : !consumer_data.matches.xxl
                        ? "40rem" //XL
                        : "46rem", //XXL
                    },
                  },
                  {
                    title: "Dimensioni",
                    field: "",
                    cellStyle: {
                      minWidth: "15vw",
                      textAlign: "center",
                    },
                    headerStyle: {
                      textAlign: "center",
                    },
                    render: (row_data) => (
                      <RilevazioneDimensioni
                        id_prod={row_data.id}
                        key={row_data.id}
                        img={row_data.img}
                        cod={row_data.cod}
                        uf_cod={row_data.uf_cod}
                        descrizione_estesa={row_data.descrizione_estesa}
                        this_cod_famiglia={row_data.this_cod_famiglia}
                        num_varianti={row_data.num_varianti}
                        // enqueueSnackbar={this.props.enqueueSnackbar}
                      />
                    ),
                  },
                  {
                    title: "Disponibilità",
                    field: "stock_totale",
                    editable: "never",
                    cellStyle: {
                      marginLeft: consumer_data.theme.spacing(3),
                    },
                    headerStyle: {
                      width: "100%",
                      // right: consumer_data.theme.spacing(1.5),
                      textAlign: "center",
                    },
                    render: (row_data) => (
                      <div
                        style={{
                          // marginRight: this.context.theme.spacing(3),
                          display: "block",
                        }}
                      >
                        <EditStock
                          key={row_data.id}
                          updateSingleFn={this.updateSingle}
                          // enqueueSnackbar={this.props.enqueueSnackbar}
                          closeSnackbar={this.props.closeSnackbar}
                          sede="feroleto"
                          input_props={row_data}
                          helperText="Feroleto"
                        />
                        <EditStock
                          updateSingleFn={this.updateSingle}
                          // enqueueSnackbar={this.props.enqueueSnackbar}
                          closeSnackbar={this.props.closeSnackbar}
                          sede="rende"
                          input_props={row_data}
                          helperText="Rende"
                          style={{
                            marginTop: this.context.theme.spacing(0.5),
                          }}
                        />
                        <div
                          style={{
                            marginTop: this.context.theme.spacing(0.5),
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <Button
                            variant="outlined"
                            style={{
                              textTransform: "none",
                              pointerEvents: "none",
                              borderTopRightRadius: "0",
                              borderBottomRightRadius: "0",
                              borderRight: "0",
                              width: "100%",
                            }}
                          >
                            Totale
                          </Button>

                          <Button
                            variant="outlined"
                            style={{
                              pointerEvents: "none",
                              borderTopLeftRadius: "0",
                              borderBottomLeftRadius: "0",
                            }}
                            disableElevation
                          >
                            {row_data.stock_totale}
                          </Button>
                        </div>
                      </div>
                    ),
                  },
                  {
                    //Dato che questo modulo fa piangere anche i monaci, ho dovuto fare così per dare uno schifo di senso al padding.
                    headerStyle: { padding: this.context.theme.spacing(1) },
                    cellStyle: { padding: this.context.theme.spacing(1) },
                  },
                ]}
              />
            </div>
          </React.Fragment>
        )}
      </Context.Consumer>
    );
  }
}

export default Inventario;
