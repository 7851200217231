import React, { forwardRef } from "react";
import MaterialTable from "material-table";
import Context from "../../context/Context";
import Constants from "../../context/Constants";
import moment from "moment";
import localization from "moment/locale/it";

import materialTableIcons from "../../support/materialTableIcons";
import materialTableLocalisationIT from "../../support/materialTableLocalisationIT";
import download from "downloadjs";
import {
  Button,
  Grid,
  Chip,
  Popover,
  List,
  ListItem,
  Typography,
  Divider,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faRedoAlt,
  faCloudDownloadAlt,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
moment.locale("it", localization);
class StoricoPanel extends React.Component {
  static contextType = Context;
  constructor(props) {
    super(props);
    this.generateOrderContentPopup = this.generateOrderContentPopup.bind(this);
    this.cloneOrder = this.cloneOrder.bind(this);
    this.getHistory = this.getHistory.bind(this);
    this.elasticSearchCancelToken = null;
    this.state = {
      fetching: false,
      history: [],
      orderContentPopup: {
        open: false,
        target: undefined,
        content: undefined,
      },
    };
  }

  componentDidMount() {
    this.getHistory();
  }

  async getHistory(query = false) {
    // return { totalCount: 1, data: [], page: 1 }
    // await console.log(query);
    // this.setState({ fetching: true });
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    if (this.elasticSearchCancelToken)
    this.elasticSearchCancelToken.cancel("Annullo ricerca precedente");
    this.elasticSearchCancelToken = source;

    let finalData = [];
    let totalCount = 0;
    await axios
      .get(
        Constants.paths.ajaxBasePath +
          "ordini-agenti/storico/?token=" +
          this.context.user.token +
          "&query=" +
          query.search +
          "&size=" +
          query.pageSize +
          "&from=" +
          query.page
      )
      .then((res) => {
        finalData = res.data.data;
        totalCount = res.data.totalCount;
        if (typeof finalData != "object") {
          console.warn(
            "Received offerte AJAX result is NOT an object (Array)!",
            finalData
          );
          // this.setState({
          //   history: [],
          //   fetching: false,
          // });
          throw "Received offerte AJAX result is NOT an object (Array)!";
        }
        finalData = finalData.map((d) => ({
          ...d,
          sourceData: d,
          articoli: JSON.parse(d.articoli),
          formatted_cliente: (
            <React.Fragment>
              <Chip
                size="small"
                label={d.cliente_id}
                style={{ marginRight: this.context.theme.spacing(1) }}
              />
              {d.cliente_ragione_sociale}
            </React.Fragment>
          ),
          formatted_data_ricezione: moment(d.data_ricezione).format("L"),
        }));

        console.log("History data:", finalData);
        // this.setState({
        //   history: finalData,
        //   fetching: false,
        // });
      });
    return {
      data: finalData,
      page: query.page,
      totalCount: totalCount
    };
  }

  setFetching(to) {
    this.setState({ fetching: to });
  }

  generateOrderContentPopup(e, row) {
    let thisContent = row.articoli.map((a, i, all) => (
      <React.Fragment>
        <ListItem key={i}>
          <Grid container spacing={2} alignContent="space-between">
            <Grid item xs={3} style={{ alignSelf: "center" }}>
              <Chip size="small" label={a.uf_cod || a.v_cod} />
              <br />
              <Typography variant="caption">
                {a.uf_cod ? a.v_cod || "" : ""}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>{a.descrizione}</Typography>
              {a.user_note && (
                <Typography style={{ fontStyle: "italic" }} variant="caption">
                  {"Note:" + a.user_note}
                </Typography>
              )}
            </Grid>
            <Grid item xs={3} style={{ alignSelf: "center" }}>
              <Typography align="right">
                {a.qta + " x " + a.user_prezzo}€
              </Typography>
            </Grid>
          </Grid>
        </ListItem>
        {i === all.length - 1 ? undefined : <Divider />}
      </React.Fragment>
    ));
    this.setState({
      orderContentPopup: {
        open: true,
        target: e.currentTarget,
        content: <List>{thisContent}</List>,
      },
    });
  }

  cloneOrder(order) {
    let newOrder = order.sourceData;
    console.log(newOrder);
    alert("Spiacente, funzione non implementata!");
    //this.props.loadActiveFn(order);
  }

  downloadOrder(order) {
    const rowData = order;
    axios
      .get(
        `${Constants.paths.ajaxBasePath}ordini-agenti/storico/?token=${this.context.user.token}&order_id=${rowData.id}&action=downloadsingle`,
        { responseType: "blob" }
      )
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          //console.log(res.data);
          download(res.data, `Ordine #${rowData.id}.pdf`);
        } else {
          throw new Error(res);
        }
      });
    // window.open(
    //   `${Constants.paths.ajaxBasePath}ordini-agenti/storico/?token=${this.context.user.token}&order_id=${rowData.id}&action=downloadsingle` // <- This is what makes it open in a new window.
    // );
  }

  render() {
    return (
      <Context.Consumer>
        {(consumer_data) => (
          <React.Fragment>
            {this.props.activeTab === 3 ? (
              <div className="data-table dense">
                <MaterialTable
                  isLoading={this.props.fetching}
                  data={(query) => this.getHistory(query)}
                  title="Storico"
                  icons={{
                    ...materialTableIcons,
                  }}
                  localization={materialTableLocalisationIT}
                  options={{
                    exportAllData: true,
                    exportButton: true,
                    exportDelimiter: ";",
                    headerStyle: {
                      textAlign: "center",
                    },
                    actionsColumnIndex: 1000,
                    tableLayout: "auto",
                    pageSize: 20,
                    pageSizeOptions: [20, 40, 50, 100, 200],
                  }}
                  actions={[
                    {
                      isFreeAction: false,
                      icon: forwardRef((props, ref) => (
                        <FontAwesomeIcon
                          {...props}
                          size="sm"
                          icon={faCloudDownloadAlt}
                        />
                      )),
                      tooltip: "Scarica PDF",
                      onClick: (e, row) => {
                        this.downloadOrder(row);
                      },
                    },
                    {
                      isFreeAction: false,
                      icon: forwardRef((props, ref) => (
                        <FontAwesomeIcon
                          {...props}
                          size="sm"
                          icon={faRedoAlt}
                        />
                      )),
                      tooltip: "Clona ordine",
                      onClick: (e, row) => {
                        this.cloneOrder(row);
                      },
                    },
                  ]}
                  columns={[
                    {
                      title: "#",
                      field: "id",
                      type: "numeric",
                      editable: "never",
                      initialEditValue: "0",
                      headerStyle: {
                        maxWidth: "3rem",
                        textAlign: "right",
                      },
                      cellStyle: {
                        maxWidth: "3em",
                        textAlign: "right",
                      },
                    },
                    {
                      title: "Offerta",
                      field: "id_offerta",
                      headerStyle: { minWidth: "15rem", width: "15rem" },
                      cellStyle: { minWidth: "15rem", width: "15rem" },
                      render: (data) => (
                        <Grid container>
                          <Grid
                            item
                            xs={5}
                            style={{ alignSelf: "center", textAlign: "right" }}
                          >
                            <Chip
                              size="small"
                              label={data.id_offerta || "NESSUNA"}
                              style={{
                                marginRight: consumer_data.theme.spacing(1),
                              }}
                            />
                          </Grid>
                          <Grid item xs={7} style={{ alignSelf: "center" }}>
                            {data.offerta_titolo || "Default"}
                          </Grid>
                        </Grid>
                      ),
                    },
                    {
                      title: "Cliente",
                      field: "cliente_ragione_sociale",
                      headerStyle: { minWidth: "20rem", width: "20rem" },
                      cellStyle: { minWidth: "20rem", width: "20rem" },
                      render: (data) => (
                        <Grid container>
                          <Grid
                            item
                            xs={2}
                            style={{ alignSelf: "center", textAlign: "right" }}
                          >
                            <Chip
                              size="small"
                              label={data.cliente_id}
                              style={{
                                marginRight: consumer_data.theme.spacing(1),
                              }}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={10}
                            style={{
                              alignSelf: "center",
                              paddingLeft: consumer_data.theme.spacing(1),
                            }}
                          >
                            {data.cliente_ragione_sociale}
                          </Grid>
                        </Grid>
                      ),
                    },
                    {
                      title: "Contenuto",
                      field: "articoli",
                      headerStyle: { minWidth: "10rem" },
                      render: (data) => (
                        <Button
                          onClick={(e) =>
                            this.generateOrderContentPopup(e, data)
                          }
                          variant="outlined"
                          size="small"
                          fullWidth
                        >
                          {data.articoli.length}{" "}
                          {data.articoli.length > 1 ? "Articoli" : "Articolo"}
                        </Button>
                      ),
                    },
                    {
                      title: "Note",
                      field: "note",
                      headerStyle: { minWidth: "20rem" },
                      cellStyle: { textAlign: "center" },
                    },
                    {
                      title: "Invio",
                      field: "formatted_data_ricezione",
                      cellStyle: { textAlign: "center" },
                    },
                    {
                      title: "Totale (IVA incl.)",
                      field: "totale_ordine",
                      type: "numeric",
                      style: {},
                      render: (row) => (
                        <Typography variant="h6">
                          {row.totale_ordine.replace(".", ",") + "€"}
                        </Typography>
                      ),
                    },
                  ]}
                />
                <Popover
                  elevation={24}
                  open={this.state.orderContentPopup.open}
                  anchorEl={this.state.orderContentPopup.target}
                  anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  onClose={(e) =>
                    this.setState((prev) => ({
                      orderContentPopup: {
                        ...prev.orderContentPopup,
                        open: false,
                      },
                    }))
                  }
                  style={{ width: "100%" }}
                >
                  {this.state.orderContentPopup.content}
                </Popover>
              </div>
            ) : undefined}
          </React.Fragment>
        )}
      </Context.Consumer>
    );
  }
}
export default StoricoPanel;
