import React from "react";
import {TextField } from "@mui/material";
class TextareaInput extends React.Component {
  render() {
    return (
      <TextField
        multiline={true}
        style={{ whiteSpace: "nowrap" }}
        inputProps={{
          value: this.props.input_props.value,
          onChange: e => {
            this.props.input_props.onChange(e.target.value);
          }
        }}
      ></TextField>
    );
  }
}

export default TextareaInput;
