import React, {useContext, useEffect, useState} from "react";
import {
    Box,
    Button,
    ButtonGroup,
    Card,
    Grid,
    Typography,
    IconButton,
    TextField,
    Popover,
    Divider,
    InputAdornment,
    Collapse, ButtonBase, Tooltip
} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faEuroSign,
    faInfoCircle,
    faTrash,
    faCaretDown,
    faMinus,
    faPlus,
    faPercent,
    faHistory, faStar,
} from "@fortawesome/free-solid-svg-icons";
import Context from "../../../context/Context";

import {GeneratePricesPopupContent} from "../../../components/PricesPopupContent";

import TableProductCod from "../../../components/TableProductCod";
import ProductImage from "../../../components/ProductImage";
import ListInventoryDisplay from "../../../components/ListInventoryDisplay";
import getPricesNr from "../../../functions/countAvailablePrices";
import ProductOrderHistory from "../../../components/ProductOrderHistory";
import Feedback from "../../../components/Feedback";
import ProductExtendedDescriptionPopup from "../../../components/ProductExtendedDescriptionPopup";
import formatPrice from "../../../functions/formatPrice";
import {Info} from "@mui/icons-material";

function calcRowTotal(productObj, asFloat = false) {
    let floatVal = parseFloat(productObj.user_prezzo * productObj.qta);
    // if(productObj.uso_punti){
    //     floatVal-=productObj.uso_punti;
    // }
    if (asFloat) return floatVal;
    else
        return (
            <>
                {formatPrice(floatVal)}
            </>
        );
}

function Description({
                         editable = false,
                         desc,
                         extendedDesc,
                         onChange,
                         prod
                     }) {
    return <>{editable ? (
        <TextField
            variant="outlined"
            multiline
            fullWidth
            value={desc}
            rows={3}
            onChange={(e) =>
                onChange(
                    e,
                    prod,
                    "desc"
                )
            }
        />
    ) : (
        <Typography variant="body1" align="left">
            {desc}
            {extendedDesc && (
                <ProductExtendedDescriptionPopup
                    withButton
                    id={prod.id}
                    buttonStyle={{float: 'right'}}
                />
            )}
        </Typography>
    )}</>
}

function SideButton({
                        vertical,
                        removeFn,
                        id_cliente,
                        feedbackText,
                        id_prod,
                        overrideProductFromHistory,
                        iconSize = 'large'
                    }) {
    const [historyOpen, setHistoryOpen] = useState(false);
    const [anchor, setAnchor] = useState(null);
    return <>
        <Grid container
              direction={vertical ? "column" : "row"}
              style={{alignSelf: 'center'}}
        >
            <Grid item xs={4} alignSelf={'center'}>
                <IconButton
                    disabled={!id_cliente}
                    style={{textAlign: "right"}}
                    onClick={(e) => {
                        setAnchor(e.currentTarget);
                        setHistoryOpen(!historyOpen);
                    }}
                    size={iconSize}>
                    <Box>
                        <FontAwesomeIcon icon={faHistory}/>
                    </Box>
                    {/* <Box color="text.error">Rimuovi</Box> */}
                </IconButton>
            </Grid>
            <Grid item xs={4} alignSelf={'center'}>
                <Feedback
                    withButton={false}
                    info={feedbackText}
                    iconSize={iconSize}
                />
            </Grid>
            <Grid item xs={4} alignSelf={'center'}>
                <IconButton
                    style={{textAlign: "right"}}
                    onClick={() => removeFn()}
                    size={iconSize}>
                    <Box color="error.main">
                        <FontAwesomeIcon icon={faTrash}/>
                    </Box>
                    {/* <Box color="text.error">Rimuovi</Box> */}
                </IconButton>
            </Grid>
        </Grid>
        <Popover
            open={historyOpen}
            onClose={() => setHistoryOpen(false)}
            anchorEl={anchor}
            anchorOrigin={{
                vertical: "center",
                horizontal: "left",
            }}
            transformOrigin={{
                vertical: "center",
                horizontal: "right",
            }}
        >
            <ProductOrderHistory
                id_cliente={id_cliente}
                id_prod={id_prod}
                handleClose={() => setHistoryOpen(false)}
                overrideFn={overrideProductFromHistory}
            />
        </Popover>
    </>
}

function NotesField({
                        style,
                        onBlur,
                        initValue
                    }) {
    const context = useContext(Context);
    const [value, setValue] = useState("");


    useEffect(() => {
        setValue(initValue)
    }, []);


    return (
        <>
            <Typography
                sx={{marginLeft: context.theme.spacing(1), float: 'left'}}
                variant="overline"
            > Note </Typography>
            <TextField
                style={{
                    ...style,
                    width: "100%",
                }}
                fullWidth
                label=""
                placeholder={'Note'}
                variant="outlined"
                margin="none"
                value={value}
                onChange={(e) => setValue(e.target.value)}
                onBlur={onBlur || null}
            />
        </>
    );
}

function PointsField({
                         availablePoints,
                         onChange,
                         onBlur,
                         onUseAll,
                         usedPoints
                     }) {
    const context = useContext(Context);
    return <>
        <Typography
            sx={{marginLeft: context.theme.spacing(1), float: 'left'}}
            variant="overline"
        > Punti (Su Totale) </Typography>
        <Tooltip
            title={'Arrotondamento automatico in base al decimale del prezzo singolo minimo possibile con i punti accumulati'}
            sx={{float: 'right'}}>
            <IconButton size={'small'}>
                <Info fontSize={'50%'}/>
            </IconButton>
        </Tooltip>
        <TextField
            className="input-align-right"
            type="number"
            error={parseFloat(parseFloat(availablePoints).toFixed(2)) < 0}
            helperText={
                <Grid container justifyContent={'space-between'}>
                    <Grid item>
                        {"Disp: " + formatPrice(availablePoints)}
                    </Grid>
                    <Grid item>
                        <ButtonBase size={'small'} sx={{
                            textTransform: 'uppercase',
                            fontWeight: 'bold',
                            color: context.theme.palette.primary.main,
                            fontSize: '100%'
                        }}
                                    onClick={e => onUseAll((parseFloat(availablePoints) + parseFloat(usedPoints)))}>
                            Usa Max
                        </ButtonBase>
                    </Grid>
                </Grid>
            }
            placeholder="0,00"
            value={usedPoints}
            onChange={e => onChange(e)}
            onBlur={e => onBlur(e)}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start"
                                    sx={{color: context.theme.palette.success.main}}>
                        <FontAwesomeIcon icon={faStar}/>
                    </InputAdornment>
                ),
            }}
        />
    </>
}

function QuantityField({
                           onPlusClick,
                           onMinusClick,
                           onChange,
                           onBlur,
                           product,
                           hasError = false
                       }) {
    const context = useContext(Context);
    return <>
        <Typography
            style={{marginLeft: context.theme.spacing(1)}}
            variant="overline"
        >
            Quantità
        </Typography>
        <br/>
        <ButtonGroup //! THROWS ERROR (Red Warning...) BECAUSE OF THE TEXTFIELD COMPONENT!!
            // style={{
            //   marginRight: consumer_data.theme.spacing(2)
            // }}
            variant="outlined"
        >
            <Button
                onClick={(e) =>
                    onChange(
                        e,
                        product,
                        "qtaMinus"
                    )
                }
            >
                <FontAwesomeIcon size="sm" icon={faMinus}/>
            </Button>
            <TextField
                error={
                    hasError ?
                        true
                        : product.imballo
                            ? product.qta %
                            parseInt(product.imballo) !==
                            0
                            : false
                }
                //label="Quantità"
                placeholder="Quantità"
                style={{
                    minWidth: 0,
                    // maxWidth: "5rem",
                    borderRadius: 0,
                }}
                className="input-align-center input-no-border-radius"
                value={product.qta}
                onChange={(e) =>
                    onChange(
                        e,
                        product,
                        "qta"
                    )
                }
                onBlur={(e) =>
                    onBlur(
                        e,
                        product,
                        "qta"
                    )
                }
            />
            <Button
                onClick={(e) =>
                    onChange(
                        e,
                        product,
                        "qtaPlus"
                    )
                }
            >
                <FontAwesomeIcon size="sm" icon={faPlus}/>
            </Button>
        </ButtonGroup>
        {
            parseInt(
                product.imballo || product.legacy_uv
            ) > 1 && (
                <Typography
                    style={{textAlign: "left"}}
                    align="left"
                    className="MuiFormHelperText-contained MuiFormHelperText-root"
                    // style={{
                    //   marginTop: consumer_data.theme.spacing(0.5)
                    // }}
                >
                    Imballo:{" "}
                    {product.imballo ||
                        product.legacy_uv}
                    pz
                </Typography>
            )
        }</>
}

function DiscountField({
                           onChange,
                           onBlur,
                           product,
                           disabled = false,
                           placeholder = "",
                           hasError = false
                       }) {
    const context = useContext(Context);

    return <>
        <Typography
            style={{marginLeft: context.theme.spacing(1)}}
            variant="overline"
        >
            Sconto
        </Typography>
        <br/>
        <TextField
            disabled={disabled}
            error={hasError}
            fullWidth
            helperText={
                (product.max_sconto &&
                    parseFloat(product.max_sconto) !== 100)
                    ? product.note_sconto
                        ? product.note_sconto
                        : `Max ${product.max_sconto}%`
                    : false
            }
            className="input-align-center"
            type="number"
            placeholder={(disabled && placeholder) ? placeholder : '0'}
            variant="outlined"
            // style={{
            //   marginRight: consumer_data.theme.spacing(2)
            // }}
            value={product.user_sconto
                ? product.user_sconto
                : placeholder}
            onChange={(e) =>
                onChange(
                    e,
                    product,
                    "sconto"
                )
            }
            onBlur={(e) =>
                onBlur(
                    e,
                    product,
                    "sconto"
                )
            }
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <FontAwesomeIcon size="sm" icon={faPercent}/>
                    </InputAdornment>
                ),
            }}
        />
    </>
}


function PriceField({
                        onChange,
                        onBlur,
                        points = 0,
                        usedPoints = 0,
                        availablePoints = 0,
                        product,
                        containerProps = {},
                        isPointsOffer,
                        minPossiblePrice,
                        hasError = false
                    }) {
    const context = useContext(Context);
    const [popupAnchor, setPopupAnchor] = useState(null);
    const [popupOpen, setPopupOpen] = useState(false);


    const handlePricePopupOpen = (event) => {
        setPopupAnchor(event.target);
        setPopupOpen(true);
    }

    const handlePricePopupClose = () => {
        setPopupOpen(false);
    }
    return <Grid container {...containerProps}>
        <Grid item xs={6}>
            {/*Mezza colonna perché doveva contenere i punti*/}
            <Typography
                style={{marginLeft: context.theme.spacing(1)}}
                variant="overline"
            >
                {getPricesNr(product) > 0 ? (
                    <>
                        <Button
                            style={{
                                marginLeft:
                                    context.theme.spacing(0.75) * -1,
                                marginTop: context.theme.spacing(0.25) * -1,
                                position: "absolute",
                                fontSize: 12,
                            }}
                            onClick={e => handlePricePopupOpen(e)}
                        >
                            PREZZI ({getPricesNr(product)})
                            <FontAwesomeIcon
                                style={{
                                    marginTop:
                                        context.theme.spacing(0.25) * -1,
                                    marginLeft: context.theme.spacing(0.5),
                                }}
                                icon={faCaretDown}
                            />
                        </Button>
                        <Popover
                            open={popupOpen}
                            onClose={e => handlePricePopupClose()}
                            anchorEl={popupAnchor}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "center",
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "center",
                            }}
                        >
                            <GeneratePricesPopupContent
                                onClick={(newPrice = 0.0) => {
                                    onChange(
                                        undefined,
                                        product,
                                        "prezzo",
                                        newPrice
                                    );
                                    handlePricePopupClose();
                                }}
                                acquisto={false}
                                productObj={product}
                                // customListiniNames={this} //Idea abbandonata usa direttamente l'oggetto prodotto
                            />
                        </Popover>
                    </>
                ) : (
                    "Prezzo"
                )}
            </Typography>
        </Grid>
        <Grid item xs={12}>
            <ButtonGroup variant="outlined" orientation="vertical">
                <TextField
                    error={hasError}
                    className="input-align-right"
                    type="number"
                    helperText={
                        <Grid container justifyContent={'space-between'}>
                            <Grid item>
                                {((!context.matches.md && isPointsOffer) ? "B" : "Base")
                                    + ": "
                                    + (product.v_prezzo
                                        ? formatPrice(product.v_prezzo)
                                        : "N/D")}
                            </Grid>
                            {
                                isPointsOffer
                                && <Grid item
                                         sx={{
                                             color:
                                                 product.options?.punti?.uso
                                                     ? (((product.prezzo_l3 || 0) - availablePoints - usedPoints) > product.user_prezzo)
                                                         ? context.theme.palette.error.main
                                                         : context.theme.palette.success.main
                                                     : context.theme.palette.success.main
                                         }}>
                                    {product.options?.punti?.uso
                                        ? (context.matches.md ? "Min" : "Min") + ": " + formatPrice(minPossiblePrice, 3)
                                        : (context.matches.md ? "Punti" : "Pnt") + ": " + formatPrice(points, 3)
                                    }
                                </Grid>
                            }
                        </Grid>
                    }
                    placeholder="0,000"
                    //variant="outlined"
                    // style={{ maxWidth: "7rem" }}
                    value={product.user_prezzo}
                    onBlur={(e) =>
                        onBlur(
                            e,
                            product,
                            "prezzo",
                            false,
                            false,
                            true
                        )
                    }
                    onChange={(e) =>
                        onChange(
                            e,
                            product,
                            "prezzo"
                        )
                    }
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <FontAwesomeIcon icon={faEuroSign}/>
                            </InputAdornment>
                        ),
                    }}
                />
                <Collapse
                    in={
                        parseFloat(product.v_prezzo || 0).toFixed(
                            2
                        ) !=
                        parseFloat(
                            product.user_prezzo || 0
                        ).toFixed(2)
                    }
                >
                    <Button
                        sx={{marginTop: context.theme.spacing(1)}}
                        onClick={(e) => {
                            onChange(
                                e,
                                product,
                                "prezzoBase",
                                product.user_prezzo
                            );
                            onChange(
                                e,
                                product,
                                "sconto",
                                0
                            );
                        }}
                        size="small"
                        variant="contained"
                        fullWidth
                    >
                        Imposta base
                    </Button>
                </Collapse>
            </ButtonGroup>
        </Grid>
    </Grid>
}

function RowTotalsField({
                            total,
                            rowPoints = false
                        }) {
    const context = useContext(Context);
    return <Grid container>
        <Grid item xs={12} sx={{textAlign: 'right'}}>
            <Typography
                sx={{
                    display: "inline-block",
                    fontWeight: "normal",
                    marginTop: context.theme.spacing(2.5)
                }}
                variant="h6"
            >
                Totale
            </Typography>
        </Grid>
        <Grid item xs={12} sx={{textAlign: 'right'}}>
            <Typography
                variant="h6"
                color="textPrimary"
                align="right"
                style={{
                    marginTop: context.theme.spacing(0.5),
                    minWidth: context.theme.spacing(10),
                    display: "inline-block",
                    fontWeight: 'bold',
                    fontSize: '150%'
                }}
            >
                {total}
            </Typography>
        </Grid>
        <Grid item
              xs={12}
              sx={{textAlign: 'right'}}
        >
            {rowPoints !== false
                && <Typography
                    variant={'caption'}
                    sx={{
                        color: (context.theme.palette.success.main),
                        textAlign: 'right'
                    }}>
                    {
                        "Punti: " + formatPrice(rowPoints)
                    }
                </Typography>
            }
        </Grid>
    </Grid>
}

class SchedaProdotto extends React.Component {
    static inlineNotesImgCss =
        "<style>.product-info-popover img{max-width:100%;border-radius:4px}</style>";

    constructor(props) {
        super(props);
        // this.handlePricePopupOpen = this.handlePricePopupOpen.bind(this);
        // this.handlePricePopupClose = this.handlePricePopupClose.bind(this);
        this.handleInfoPopupOpen = this.handleInfoPopupOpen.bind(this);
        this.handleInfoPopupClose = this.handleInfoPopupClose.bind(this);
        this.handleUserNotesChange = this.handleUserNotesChange.bind(this);

        this.state = {
            pricePopupOpen: false,
            infoPopupOpen: false,
            historyPopupOpen: false,
            points: 0
        };
    }

    // handleHistoryPopupOpen(event) {
    //     this.setState({
    //         historyPopupAnchor: event.target,
    //         historyPopupOpen: true,
    //     });
    // }

    handleInfoPopupOpen(event) {
        this.setState({
            infoPopupAnchor: event.target,
            infoPopupOpen: true,
        });
    }

    handleInfoPopupClose() {
        this.setState({
            infoPopupOpen: false,
        });
    }

    handleUserNotesChange(e) {
        this.props.handleProductChange(e, this.props.product, "note");
    }

    render() {
        return (
            <Context.Consumer>
                {(consumer_data) => (
                    <>
                        <Card
                            key={this.props.product.id}
                            elevation={0}
                            variant={"outlined"}
                            style={{
                                margin: !consumer_data.matches.sm
                                    ? consumer_data.theme.spacing(0) //XS
                                    : !consumer_data.matches.md
                                        ? consumer_data.theme.spacing(1) //SM
                                        : consumer_data.theme.spacing(2), //MD+
                                padding: !consumer_data.matches.sm
                                    ? consumer_data.theme.spacing(2) //XS
                                    : !consumer_data.matches.md
                                        ? consumer_data.theme.spacing(2) //SM
                                        : consumer_data.theme.spacing(3), //MD+
                                marginBottom: !consumer_data.matches.sm
                                    ? consumer_data.theme.spacing(2)//XS
                                    : !consumer_data.matches.md
                                        ? consumer_data.theme.spacing(2) //SM
                                        : consumer_data.theme.spacing(3), //MD+
                                borderColor: this.props.errorMessages.length ? consumer_data.theme.palette.error.main : null
                            }}
                        >
                            {/*ALL*/}
                            <Grid
                                container
                                alignContent="flex-start"
                                alignItems={'center'}
                                justifyContent={'space-between'}
                                spacing={2}
                                style={{
                                    textAlign:
                                        consumer_data.matches.xs && !consumer_data.matches.md
                                            ? "inherit"
                                            : "initial",
                                }}
                            >
                                {/*COD*/}
                                <Grid
                                    item
                                    xs={3}
                                    sm={2}
                                    md={2}
                                    lg={1}
                                    style={{textAlign: "left"}}
                                >
                                    <ProductImage
                                        containerSize={consumer_data.theme.spacing(8)}
                                        cod={this.props.product.cod}
                                    />
                                </Grid>
                                <Grid item
                                      xs={5}
                                      sm={7}
                                      md={2}
                                      lg={2}
                                >
                                    <Typography variant={'caption'}>
                                        #{this.props.index}
                                    </Typography>
                                    <br/>
                                    <TableProductCod
                                        noEan
                                        noFamily
                                        allowCustomisation={false}
                                        manualUfCod={this.props.product.id == "99999"}
                                        onUfCodChange={(e) =>
                                            this.props.handleProductChange(
                                                e,
                                                this.props.product,
                                                "ufCod"
                                            )
                                        }
                                        productObj={this.props.product}
                                    />
                                    {this.props.product.note_commerciali && (
                                        <Typography color={"secondary"} variant="caption">
                                            [
                                            <i>{this.props.product.note_commerciali}</i>
                                            ]
                                        </Typography>
                                    )}
                                    {this.props.product.posizione && (
                                        <Typography variant="caption">
                                            Pos.:{" "}
                                            <strong>{this.props.product.posizione}</strong>
                                        </Typography>
                                    )}
                                    {this.props.product.note ? (
                                        <IconButton
                                            onClick={this.handleInfoPopupOpen}
                                            style={{
                                                marginTop: consumer_data.theme.spacing(0.5) * -1,
                                            }}
                                            size="xs"
                                        >
                                            <FontAwesomeIcon size="xs" icon={faInfoCircle}/>
                                        </IconButton>
                                    ) : (
                                        ""
                                    )}
                                </Grid>
                                {
                                    consumer_data.matches.md &&
                                    <Grid item
                                          xs={5}
                                          md={4}
                                          lg={5}
                                          style={{textAlign: "left"}}>
                                        <Description
                                            editable={this.props.product.id == "99999"}
                                            desc={this.props.product.descrizione}
                                            extendedDesc={this.props.product.descrizione_estesa}
                                            onChange={this.props.handleProductChange.bind(this)}
                                            prod={this.props.product}
                                        />
                                    </Grid>}
                                <Grid item
                                      xs={2}
                                      sm={1}
                                      md={1}
                                      lg={2}
                                >
                                    <ListInventoryDisplay
                                        style={{background: "none"}}
                                        extended={consumer_data.matches.lg}
                                        id_prod={this.props.product.id}
                                    />
                                </Grid>

                                <Grid item
                                      xs={2}
                                      md={2}
                                      lg={2}
                                      style={{textAlign: "right"}}>
                                    <SideButton
                                        id_prod={this.props.product.id}
                                        id_cliente={this.props.customer.id}
                                        removeFn={this.props.removeThisProduct}
                                        feedbackText={`(${this.props.product.id} | ${this.props.product.cod}) ${this.props.product.v_descrizione}`}
                                        vertical={!consumer_data.matches.md}
                                        iconSize={!consumer_data.matches.md
                                            ? "medium" // SM
                                            : "large" // LG
                                        }
                                        overrideProductFromHistory={(
                                            v_prezzo,
                                            user_sconto,
                                            qta
                                        ) => {
                                            //? Attento al setState asinchorno, risolvi con una catena di callback, in futuro trasforma in async/await
                                            this.props.handleProductChange(
                                                false,
                                                this.props.product,
                                                "prezzoBase",
                                                v_prezzo,
                                                (newProd) =>
                                                    this.props.handleProductChangeBlur(
                                                        false,
                                                        newProd,
                                                        "prezzoBase",
                                                        v_prezzo,
                                                        (newProd) =>
                                                            this.props.handleProductChange(
                                                                false,
                                                                newProd,
                                                                "sconto",
                                                                user_sconto,
                                                                (newProd) =>
                                                                    this.props.handleProductChangeBlur(
                                                                        false,
                                                                        newProd,
                                                                        "sconto",
                                                                        user_sconto,
                                                                        (newProd) =>
                                                                            this.props.handleProductChange(
                                                                                false,
                                                                                newProd,
                                                                                "qta",
                                                                                qta,
                                                                                (newProd) =>
                                                                                    this.props.handleProductChangeBlur(
                                                                                        false,
                                                                                        newProd,
                                                                                        "qta",
                                                                                        qta
                                                                                    )
                                                                            )
                                                                    )
                                                            )
                                                    )
                                            );
                                        }}
                                    />
                                </Grid>

                                {!consumer_data.matches.md
                                    && <Grid item xs={12}>
                                        <Description
                                            editable={this.props.product.id == "99999"}
                                            desc={this.props.product.descrizione}
                                            extendedDesc={this.props.product.descrizione_estesa}
                                            onChange={this.props.handleProductChange.bind(this)}
                                            prod={this.props.product}
                                        />
                                    </Grid>}
                            </Grid>
                            <Divider
                                style={{
                                    minWidth: "100%",
                                    marginTop: consumer_data.theme.spacing(2),
                                    marginBottom: consumer_data.theme.spacing(2),
                                }}
                            />
                            <Grid
                                item
                                container
                                xs={12}
                                spacing={consumer_data.matches.lg ? 2 : 1}
                                style={{
                                    justifyContent: !consumer_data.matches.md
                                        ? "space-between"
                                        : "flex-end",
                                    alignContent: "center",
                                }}
                            >
                                {/*SECONDA FILA*/}
                                {consumer_data.matches.md && //MD+
                                    <>
                                        <Grid item
                                            // xs={2}
                                              md={(this.props.usesPoints)
                                                  ? 2
                                                  : 3}
                                              lg={(this.props.usesPoints)
                                                  ? 2
                                                  : 4}>
                                            <NotesField
                                                initValue={this.props.product.user_note}
                                                onBlur={this.handleUserNotesChange}
                                            />
                                        </Grid>
                                    </>}
                                <Grid
                                    item
                                    xs={4}
                                    sm={3}
                                    md={(this.props.usesPoints)
                                        ? 2
                                        : 3}
                                    lg={2}
                                    style={{textAlign: "initial"}}
                                >
                                    {/*QUANTITA*/}
                                    <QuantityField
                                        onChange={this.props.handleProductChange.bind(this)}
                                        onBlur={this.props.handleProductChangeBlur.bind(this)}
                                        product={this.props.product}
                                        hasError={this.props.hasQuantityError}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={4}
                                    sm={3}
                                    md={2}
                                    style={{textAlign: "initial"}}
                                >
                                    {/*Sconto*/}
                                    <DiscountField
                                        onChange={this.props.handleProductChange.bind(this)}
                                        onBlur={this.props.handleProductChangeBlur.bind(this)}
                                        product={this.props.product}
                                        disabled={this.props.isPointsOffer && this.props.usesPoints}
                                        placeholder={(this.props.isPointsOffer && this.props.usesPoints)
                                            ? "(Utilizza punti)"
                                            : ""}
                                        hasError={this.props.hasDiscountError}
                                    />
                                </Grid>

                                <Grid
                                    item
                                    xs={4}
                                    sm={3}
                                    md={2}
                                    lg={2}
                                >
                                    {/*PREZZO*/}

                                    <PriceField
                                        containerProps={{xs: 12}}
                                        onChange={this.props.handleProductChange.bind(this)}
                                        onBlur={
                                            this.props.handleProductChangeBlur.bind(this)
                                        }
                                        product={this.props.product}
                                        isPointsOffer={this.props.isPointsOffer}
                                        usedPoints={this.props.product.uso_punti}
                                        points={this.props.points}
                                        availablePoints={this.props.availablePoints}
                                        minPossiblePrice={this.props.minPossibilePrice}
                                        hasError={this.props.hasPriceError}
                                    />
                                </Grid>
                                {this.props.usesPoints
                                    && consumer_data.matches.md
                                    && <Grid item md={2}>
                                        {/*<Divider variant={"middle"} orientation={'vertical'} />*/}
                                        <PointsField
                                            availablePoints={this.props.availablePoints}
                                            usedPoints={this.props.product.uso_punti}
                                            onChange={(e) =>
                                                this.props.handleProductChange(
                                                    e,
                                                    this.props.product,
                                                    "usoPunti"
                                                )
                                            }
                                            onBlur={(e) =>
                                                this.props.handleProductChangeBlur(
                                                    e,
                                                    this.props.product,
                                                    "usoPunti",
                                                    false,
                                                    false,
                                                    true
                                                )
                                            }
                                            onUseAll={
                                                (amnt) => this.props.handleProductChangeBlur(
                                                    null,
                                                    this.props.product,
                                                    "usoPunti",
                                                    amnt,
                                                    false,
                                                    true
                                                )
                                            }
                                        />
                                    </Grid>
                                }

                                {/*PREZZO*/}
                                {!consumer_data.matches.md //SM
                                    ? (
                                        <>
                                            <Grid item xs={this.props.usesPoints ? 5 : 9}>
                                                <NotesField
                                                    initValue={this.props.product.user_note}
                                                    onBlur={this.handleUserNotesChange}
                                                />
                                            </Grid>
                                            {this.props.usesPoints
                                                && <Grid item xs={4}>
                                                    <PointsField
                                                        availablePoints={this.props.availablePoints}
                                                        usedPoints={this.props.product.uso_punti}
                                                        onChange={(e) =>
                                                            this.props.handleProductChange(
                                                                e,
                                                                this.props.product,
                                                                "usoPunti"
                                                            )
                                                        }
                                                        onBlur={(e) =>
                                                            this.props.handleProductChangeBlur(
                                                                e,
                                                                this.props.product,
                                                                "usoPunti",
                                                                false,
                                                                false,
                                                                true
                                                            )
                                                        }
                                                        onUseAll={
                                                            (amnt) => this.props.handleProductChangeBlur(
                                                                null,
                                                                this.props.product,
                                                                "usoPunti",
                                                                amnt,
                                                                false,
                                                                true
                                                            )
                                                        }
                                                    />
                                                </Grid>
                                            }
                                            <Grid item
                                                  xs={3}
                                                  md={2}
                                                  sx={{textAlign: 'right'}}
                                                  alignSelf={'center'}
                                            >
                                                <RowTotalsField total={calcRowTotal(this.props.product)}/>
                                            </Grid>
                                        </>
                                    ) : ( //SM+
                                        <Grid item sm={3} md={2} lg={2}>
                                            <RowTotalsField
                                                total={calcRowTotal(this.props.product)}
                                                rowPoints={
                                                    this.props.isPointsOffer
                                                        ? this.props.usesPoints
                                                            ? false
                                                            : this.props.points * this.props.product.qta
                                                        : false
                                                }
                                            />
                                        </Grid>
                                    )}
                            </Grid>
                            <Collapse in={this.props.errorMessages.length}>
                                <Grid item xs={12}>
                                    <Typography color={'error'} variant={'body2'}>
                                        {this.props.errorMessages.map(err => <>{err}</>)}
                                    </Typography>
                                </Grid>
                            </Collapse>
                            <Popover
                                open={this.state.infoPopupOpen}
                                onClose={this.handleInfoPopupClose}
                                anchorEl={this.state.infoPopupAnchor}
                                className="product-info-popover"
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "center",
                                }}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "center",
                                }}
                            >
                                <Typography
                                    style={{
                                        whiteSpace: "pre-wrap",
                                        padding: consumer_data.theme.spacing(2),
                                    }}
                                >{this.props.product.note}
                                    {/*{renderHTML(SchedaProdotto.inlineNotesImgCss)}*/}
                                    {/*{renderHTML(this.props.product.note || "")}*/}
                                </Typography>
                            </Popover>
                        </Card>
                    </>
                )}
            </Context.Consumer>
        )
            ;
    }
}

export default SchedaProdotto;
